<script>
  import debounce from "lodash-es/debounce";
  import orderBy from "lodash-es/orderBy";
  import uniqBy from "lodash-es/uniqBy";
  import startCase from "lodash-es/startCase";
  import MiniSearch from "minisearch";
  import { createEventDispatcher, onDestroy } from "svelte";
  import Record from "$components/record/RecordItem.svelte";
  import { comparer } from "$utils/sort";

  const dispatch = createEventDispatcher();

  let selected = null;
  export let required = false;

  export let items = [];
  //import { space as match } from "./select";

  //$: console.log("items=", items);

  export let label = null;
  let type = "space";
  export let placeholder = "search";
  let searched;
  let results = [];
  let datalist = null;
  let all = false;
  let focused = null;
  let query = "";
  let input;

  //$: console.log("match=", $match);

  const miniSearch = new MiniSearch({
    fields: ["display"],
    searchOptions: {
      prefix: true,
      boost: { display: 2 },
    },
    storeFields: ["id", "key"],
    extractField: (document, fieldName) =>
      fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
  });

  $: itemsById = (items || []).reduce((map, item) => {
    return (map[item.id] = item) && map;
  }, {});

  $: if (!placeholder && items)
    placeholder = [
      ...new Set(items.map((i) => startCase(`${i.format} ${i.type}`))),
    ].join("/");

  //$:console.log("formats=", [...new Set((items || []).map(i => i.format))]);

  $: searched =
    items &&
    query &&
    uniqBy(
      orderBy(
        miniSearch.search(query),
        ["score", label],
        ["desc", "asc", "asc", "asc"]
      ),
      "id"
    );
  $: results =
    (searched && searched.map((result) => itemsById[result.id])) ||
    ((focused && items) || (all && items) || []).sort(comparer("display"));

  $: if (results.length == 1) select(results[0].id);

  // $: console.log("results=", results);

  $: if (items) {
    miniSearch.removeAll();
    miniSearch.addAll(items);
  } else {
    miniSearch.removeAll();
  }

  const oninput = debounce(
    function (e) {
      // console.log("suggest=", miniSearch.autoSuggest(e.target.value));
      // console.log("search=", miniSearch.search(e.target.value));
      console.log("about to set query = ", e.target.value);
      query = e.target.value;
    },
    150,
    {
      leading: true,
      trailing: true,
    }
  );

  $: if (selected) clearQuery();

  onDestroy(function () {
    clearQuery();
  });

  function select(value) {
    clearQuery();
    all = false;
    dispatch(type, (selected = itemsById[value] ?? value));
  }

  function labeler(item) {
    return item["addr:unit"] || item.name || item.display;
  }

  function clearQuery() {
    query = "";
    if (input) input.value = "";
    if (input) input.blur();
  }
</script>

<fieldset class="record {type}">
  {#if label}
    <label for="{type}-search">{label}</label>
  {/if}
  <input
    id="{type}-search"
    placeholder={focused ? "type to filter" : placeholder}
    type="text"
    on:input={oninput}
    on:change={oninput}
    autocorrect="off"
    autocapitalize="characters"
    spellcheck="false"
    autocomplete="off"
    required={!!required}
    on:focus={(e) => false && (focused = true)}
    on:blur={(e) => false && (focused = false)}
    list={datalist}
    bind:this={input}
  />
  {#if items && items.length}
    <!-- <select on:change={(e) => select(e.target.value)}>
      <option value="">---</option>
      {#each items as item}
        <option value={item.id}>{labeler(item)}</option>
      {/each}
    </select> -->
    <button type="button" class="list all" on:click={(e) => (all = !all)}
      >List</button
    >
    {#if datalist}
      <datalist id={datalist}>
        {#each items as item}
          <option value={labeler(item)} />
        {/each}
      </datalist>
    {/if}
  {/if}
</fieldset>
<ul class="results">
  {#each results as item}
    <li>
      <button
        class="record"
        type="button"
        value={item.id}
        on:click={(e) => select(e.currentTarget.value)}
        ><Record {item} url={false} /></button
      >
    </li>
  {/each}
</ul>
