<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import NewPolicyPermit from "$components/form/NewPolicyPermit.svelte";
  import { policies as allpolicies } from "$utils/propertystores";
  import { generatePolicy } from "$utils/policy";

  export let item: Permit;
  export let values: Record<string, any> = {};

  $: console.log("duplicating permit=", item);

  const events = createEventDispatcher();

  // find a matching policy to use by default
  $: policy = $allpolicies?.find(
    (p) =>
      p.audience.admin &&
      p.issue.enabled &&
      p.policy &&
      p.policy === item.policy?.policy
  );

  //if no policy, try and fall back
  $: if (!policy)
    policy = generatePolicy(
      item.property?.id ?? item.scope,
      item?.amenity,
      !!item?.continuous
    );

  $: policies = [policy].concat(
    $allpolicies.filter(($policy) => {
      if (!$policy) return false;
      if (!$policy?.issue.enabled) return false;
      if ($policy.amenity !== item.amenity) return false;

      // filter out the pre-added policy
      if (policy?.policy && $policy.policy === policy.policy) return false;
      // if (!policy.policy && !$policy.policy && $policy.title === policy.title)
      //   return false;

      //if (policy?.policy && $policy.policy === policy?.policy) return true; // allow through the original policy
      return $policy.audience.admin;
    })
  );

  // duplicate means picking an admin policy
</script>

<NewPolicyPermit
  duplicate={item}
  {policy}
  {policies}
  on:cancel={(e) => events("cancel")}
  on:create={(e) => events("complete")}
  on:change={(e) => events("change", e.detail)}
  {values}
  ><header><h1>Duplicate this {item.title}:</h1></header></NewPolicyPermit
>
