<script lang="ts">
  import Fullscreen from "./Fullscreen.svelte";
  import MapLevelSelect from "./MapLevelSelect.svelte";
  import PropertyMap from "./PropertyMap.svelte";
  export let selected: { id: string; scope: string } | null = null;
  //$: console.log("selected=", selected);

  export let theme = "cool";
  let level: string = null;
  let levels: { [key: string]: any } = {};
  let usesatellite = false;
  let fullscreen = false;
</script>

<Fullscreen bind:active={fullscreen}>
  <PropertyMap
    property={selected?.scope}
    selected={selected?.id}
    theme={usesatellite ? "satellite" : theme}
    interactive={fullscreen}
    {level}
    on:level={(e) => (level = e.detail)}
    bind:levels
  >
    <!-- <slot /> -->
    <form>
      <fieldset>
        <input
          type="checkbox"
          name="style"
          value="satellite"
          on:change={({ currentTarget: target }) =>
            (usesatellite = target.checked)}
        />
        <MapLevelSelect {levels} bind:level />
      </fieldset>
      <fieldset>
        <input
          type="checkbox"
          name="fullscreen"
          value="fullscreen"
          bind:checked={fullscreen}
        />
      </fieldset>
    </form>
  </PropertyMap>
</Fullscreen>
