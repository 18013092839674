<script lang="ts">
  import ServiceData from "./ServiceData.svelte";
  import ServiceLogo from "./ServiceLogo.svelte";

  export let title: string = null;
  export let service: any;
  export let logo = true;
</script>

<h1>
  <!-- {#if title}{title} {/if} -->
  <ServiceData {service} {title} />
  {#if logo}
    <ServiceLogo {service} />
  {/if}
</h1>
