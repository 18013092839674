<script lang="ts">
  import { details } from "$utils/propertystores";
  export let item: Typed;
  $: active = !!item && $details[item.id];

  function toggleExpand() {
    if (!item) return;
    details.update(($values) => {
      if ($values[item.id]) delete $values[item.id];
      else $values[item.id] = true;
      //$values[item.id] = !$values[item.id];
      return $values;
    });
  }
</script>

{#if active}
  <slot />
{/if}
<button type="button" on:click={toggleExpand}>{active ? "less" : "more"}</button
>
