<script lang="ts">
  import ItemFiles from "$components/item/ItemFiles.svelte";
  import ItemNotes from "$components/item/ItemNotes.svelte";
  import NoteFileForItem from "$components/item/NoteFileForItem.svelte";
  import set from "lodash-es/set";
  import unset from "lodash-es/unset";

  export let item: Vehicle | Tenant | Space | Media | Unit;
  export let attachments: any;

  function fileCreating(e) {
    const file = e.detail;
    //console.log("creating file=", file);
    if (file?.type != "file") return;
    attachments = set(attachments, ["items", file.id], file);
    //console.log("attachments=", attachments);
    //if (attachments?.items) attachments.items[file.url] = file;
    //attachments = attachments;
  }
  function fileCreated(e) {
    const file = e.detail;
    if (file?.type != "file") return;
    if (file.local) {
      if (unset(attachments, ["items", file.id])) attachments = attachments;
    } else {
      //console.log("about to store item=", file);
      attachments = set(attachments, ["items", file.id], file);
      //if (attachments?.items) attachments.items[file.id] = file;
    }
    //attachments = attachments;
  }
  function onNoteCreated(e) {
    const note = e.detail;
    if (note?.type != "note") return;
    //if (attachments?.items) attachments.items[file.id] = file;
    attachments = set(attachments, ["items", note.id], note);
  }
  function onNoteRemoved(e: CustomEvent<Typed>) {
    const note = e.detail;
    if (note?.type != "note") return;
    //if (attachments?.items) attachments.items[file.id] = file;
    if (unset(attachments, ["items", note.id])) attachments = attachments;
    // if (!attachments?.items?.[note.id]) return;
    // delete attachments.items[note.id];
    // attachments = attachments;
    // console.log("attachments=", attachments);
    // attachments = unset(attachments, ["items", note.id]);
  }
</script>

<NoteFileForItem
  {item}
  on:creating={fileCreating}
  on:created={fileCreated}
  on:created={onNoteCreated}
/>
<ItemFiles
  item={{
    ...item,
    attached: attachments,
  }}
/>
<ItemNotes
  readonly={false}
  item={{
    ...item,
    attached: attachments,
  }}
  on:removed={onNoteRemoved}
/>
