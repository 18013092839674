<script lang="ts">
  //   import { onMount } from "svelte";

  export let inview: boolean = false;
  let classname: string | null = null;
  export { classname as class };
  let element: HTMLLIElement;
  //   onMount(() => {
  //     if (scrollIntoView) element.scrollIntoView({ behavior: "smooth" });
  //   });
  $: inview && element?.scrollIntoView({ behavior: "smooth" });
</script>

<li class={classname} bind:this={element}>
  <slot />
</li>
