<script lang="ts">
  import { unitPrefixed } from "$utils/unit";

  export let item: Addressable;

  //$: console.log("item=", item);

  $: building = item["building:ref"];
  $: floor = item["level:ref"];

  $: addrdisplay = [item["addr:housenumber"], item["addr:street"]]
    .filter(Boolean)
    .join(" ");
  $: addrunit =
    item["addr:unit"] && `${unitPrefixed(item["addr:unit"], item.format)}`;
</script>

{#if item}
  <address>
    <data class="street" value={addrdisplay}>{addrdisplay}</data>
    <!-- {:else if item["addr:street"]}
            <data class="street" value={item["addr:street"]}
              >{item["addr:street"]}</data
            > -->
    {#if addrunit}
      <data class="unit" value={item["addr:unit"]}>{addrunit}</data>
    {/if}
    <slot />
  </address>
{/if}
