<script lang="ts">
  import Loading from "../Loading.svelte";
  import ServiceData from "./ServiceData.svelte";
  import ServiceLogo from "./ServiceLogo.svelte";

  export let item: {
    type: string;
    service: Service;
    name?: string;
    record: boolean | string;
    logo?: boolean;
  };
</script>

<aside class="service">
  {#if item.logo}
    <ServiceLogo service={item.service} />
  {/if}
  <ol class="service">
    <li>
      <ServiceData service={item.service} status="connected" />
    </li>
    <li>
      {#if null == item.record}<Loading />{:else}<data
          value={(!!item.record).toString()}
          >{item.record || `No linked ${item.name || "record"}`}</data
        >{/if}
    </li>
  </ol>
</aside>
