import { derived, writable } from "svelte/store";
import { params } from "./params";

const STATE = writable<{
  action: string;
  id: string;
  source: Typed;
  context: Record<string, any>;
  values: Record<string, any>;
}>();

const state = derived(STATE, ($state) => ({
  ...$state,
  start: init,
  ...($state?.id
    ? {
        cancel,
        complete,
        progress,
      }
    : {}),
}));

state.subscribe(($state) => console.log("$state=", $state));

const action = derived(STATE, ($state) => $state?.action);
const source = derived(STATE, ($state) => $state?.source);
const id = derived(STATE, ($state) => $state?.source?.id);
const values = derived(STATE, ($state) => $state?.values);

function stated(
  action: string | null = null,
  source: Typed | null = null,
  values: Record<string, any> = {},
  context: Record<string, any> = {}
) {
  return {
    action,
    id: source?.id,
    source,
    context: context ?? {},
    values: values ?? {},
  };
}

function init(
  action: string,
  source: Typed,
  values?: Record<string, any>,
  context?: Record<string, any>
) {
  console.log("init state=", action, source, values);
  STATE.set(stated(action, source, values, context));
  // STATE.update(function (state) {
  //   // if (
  //   //   true === state?.values &&
  //   //   !confirm("You have unsaved changes, continue?")
  //   // )
  //   //   return state;

  //   return stated(action, source, values);
  // });
}

export function progress(values?: Record<string, any>) {
  //console.log("handled=", values);
  STATE.update(function (state) {
    if (!state || !state.source) return state;
    state.values = values ?? {};
    return state;
  });
}
function cancel() {
  STATE.set(stated());
}
function complete() {
  STATE.set(stated());
}

export { state, values, action, id, source, init as start, cancel, complete };

export const updating = {
  state,
  action,
  source,
  id,
  values,
  progress,
  start: init,
  //   edit,
  //   duplicate,
  //   revoke,
  //update: init,
  cancel,
  complete,
};

export default updating;

// wipe on params change
params.subscribe(() => STATE.set(null));
