<script context="module">
    import TimeIntervalItem from "./TimeIntervalItem.svelte";

    const currency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0,
        //maximumFractionDigits: 0,
    }).format;
</script>
<script>
    export let item;
    $: value = item.values[item.interval] || Object.values(item.values)[0];
</script>
<aside class="{item.type} summary">
    <h1>
        <TimeIntervalItem {item} month={true} />
        
        <!-- <time datetime="{item.interval}">
        <dfn>{item.label}</dfn></time> -->
    </h1>
    <data class="net count" value="{value||""}">{currency(value / 100) || ""}</data>
</aside>