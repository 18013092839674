<script lang="ts">
  import debounce from "lodash-es/debounce";
  import orderBy from "lodash-es/orderBy";
  import uniqBy from "lodash-es/uniqBy";
  import MiniSearch from "minisearch";
  import { createEventDispatcher, onDestroy, tick } from "svelte";
  import Record from "$components/record/RecordItem.svelte";
  import { autocreate } from "$utils/vehicle";
  //import { geo } from "@parkingboss/svelte-utils";

  let selected = null;
  export let property = null;
  export let required = false;
  export let scannable = false;

  export let items = [];
  //import { vehicle as match } from "./select";

  export let name: string = null;
  export let placeholder = "License Plate/Tag";
  export let label = null;
  let scan = false;
  let scanning = false;
  let preview;
  let type = "vehicle";
  let datalist = null;
  let query = "";

  let searched;
  let results = [];

  let cam;

  //$: console.log("match=", $match);
  //$: console.log("geo=", $geo);

  $: if (cam) cam.scrollIntoView();

  const miniSearch = new MiniSearch({
    fields: ["display"],
    searchOptions: {
      prefix: true,
      boost: { display: 2 },
    },
    storeFields: ["id", "key"],
    extractField: (document, fieldName) =>
      fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
  });

  $: itemsById = (items || []).reduce((map, item) => {
    return (map[item.id] = item) && map;
  }, {});

  //   $: if(items) name = proper([...new Set(items.map(i => i.format))].join(", "));

  $: autogenerated = query
    ? [
        autocreate(query),
        //   {
        //     type,
        //     id: query.toUpperCase().replace(/[^A-Z0-9]/gi, ""),
        //     display: query.toUpperCase().replace(/[^A-Z0-9]/gi, ""),
        //   },
      ].filter(Boolean)
    : [];

  $: searched =
    items &&
    query &&
    uniqBy(
      orderBy(miniSearch.search(query), ["score", "display"], ["desc", "asc"]),
      "id"
    );

  //$: console.log("searched=", searched);
  $: results = autogenerated.every(
    (v1) => !!searched.find((v2) => v2.key.toUpperCase() === v1.id)
  )
    ? (searched && searched.map((result) => itemsById[result.id])) || []
    : autogenerated.concat(
        (searched && searched.map((result) => itemsById[result.id])) || []
      );

  // $: console.log("results=", results);

  $: if (items) {
    miniSearch.removeAll();
    miniSearch.addAll(items);
  } else {
    miniSearch.removeAll();
  }

  // we're gonna do a different check on blur
  async function onblur(e) {
    await tick();
    if (1 === results.length) select(results[0]);
  }

  const oninput = debounce(
    function (e) {
      // console.log("suggest=", miniSearch.autoSuggest(e.target.value));
      // console.log("search=", miniSearch.search(e.target.value));
      //("about to set match store = ", e.target.value);
      query = e.target.value;
    },
    150,
    {
      leading: true,
      trailing: true,
    }
  );

  $: if (selected) clearQuery();

  function clearPreview() {
    if (!preview) return;

    const revoke = preview;
    preview = null;
    URL.revokeObjectURL(revoke);
  }

  onDestroy(function () {
    clearQuery();
    clearPreview();
  });

  const evented = createEventDispatcher();

  function select(value) {
    clearQuery();
    clearPreview();
    selected = value;
    evented(type, (selected = value));
  }
  function clearQuery() {
    query = "";
  }
</script>

<fieldset class="record {type}">
  {#if label}
    <label for="{type}-search">{label}</label>
  {/if}
  <input
    id="vehicle-search"
    {name}
    value={query}
    maxlength="10"
    {placeholder}
    type="text"
    on:blur={onblur}
    on:input={oninput}
    on:change={oninput}
    required={!!required}
    autocorrect="off"
    autocomplete="off"
    autocapitalize="characters"
    spellcheck="false"
    list={datalist}
  />
  {#if datalist && items?.length}
    <select on:change={(e) => select(e.currentTarget.value)}>
      <option value="">---</option>
      {#each items as item}
        <option value={item.id}>{item.display}</option>
      {/each}
    </select>
    {#if datalist}
      <datalist id={datalist}>
        {#each items as item}
          <option value={item.display} />
        {/each}
      </datalist>
    {/if}
  {/if}
  <!-- {#if scannable}
      <button
        class="scan vehicle"
        class:active={scan}
        class:scanning
        type="button"
        on:click={(e) => (scan = !scan)}
      >
      </button>
    {/if} -->
</fieldset>
<ul class="results">
  {#each results as item}
    <li>
      <button
        type="button"
        class="record"
        value={item.id || item.display}
        on:click={(e) => select(item)}><Record {item} url={false} /></button
      >
    </li>
  {/each}
</ul>
