<script lang="ts">
  import ItemsList from "$components/item/ItemsList.svelte";
  import Loading from "$components/Loading.svelte";
  import Warning from "$components/Warning.svelte";

  export let violation: Violation;
  export let property: Property;
</script>

<section class="violation">
  {#if violation && property}
    {#if violation.scope != property.id}
      <header><Warning message="Not at this property" /></header>
      <section />
    {:else}
      <header><h1>Violation</h1></header>
      <section>
        <ItemsList
          class="activity"
          items={[violation]}
          context={{ removable: false }}
        />
      </section>
    {/if}
  {:else}
    <header><Loading /></header>
  {/if}
</section>
