<script lang="ts" context="module">
  let i = 0;
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Input from "./Input.svelte";

  const type = "number";
  export let name: string = null;
  export let label: string = null;
  export let placeholder: string = null;
  export let required = false;
  export let value: string = "";
  export let min: string | number = null;
  export let max: string | number = null;
  export let autocomplete: string = null;

  const id = `${name}-${i++}`;

  if (label && !placeholder) placeholder = required ? "required" : "optional";

  const events = createEventDispatcher<{
    change: {
      name: string;
      value: string;
    };
  }>();

  function change(name: string, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    if (name)
      events("change", {
        name,
        value,
      });
  }
</script>

<fieldset>
  {#if label}
    <label for={id}>{label}</label>
  {/if}
  <Input
    {type}
    {id}
    {name}
    {required}
    {placeholder}
    {value}
    {min}
    {max}
    {autocomplete}
    on:change={({ detail }) => change(detail.name, detail.value)}
  />
</fieldset>
