<script>
  import debounce from "lodash-es/debounce";
  import identity from "lodash-es/identity";
  import orderBy from "lodash-es/orderBy";
  import uniqBy from "lodash-es/uniqBy";
  import MiniSearch from "minisearch";
  import { createEventDispatcher, onDestroy } from "svelte";

  const dispatch = createEventDispatcher();

  export let selected;
  export let required = false;
  export let autoclear = false;

  export let items = [];
  import { unit as match } from "./select";

  //$: console.log("items=",items);

  let name = "unit";
  let searched;
  let results = [];

  //$: console.log("match=", $match);

  const miniSearch = new MiniSearch({
    fields: ["addr:unit", "name", "display"],
    searchOptions: {
      prefix: true,
      boost: { name: 2 },
    },
    storeFields: ["id", "addr:unit", "name", "display"],
    extractField: (document, fieldName) =>
      fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
  });

  $: itemsById = (items || []).reduce((map, item) => {
    return (map[item.id] = item) && map;
  }, {});

  $: if (items)
    name = proper([...new Set(items.map((i) => i.format))].join(", "));

  //$:console.log("formats=", [...new Set((items || []).map(i => i.format))]);

  $: searched =
    items &&
    $match &&
    uniqBy(
      orderBy(
        miniSearch.search($match),
        ["score", label],
        ["desc", "asc", "asc", "asc"]
      ),
      "id"
    );
  $: results =
    (searched && searched.map((result) => itemsById[result.id])) || [];

  $: if (results.length == 1) select(results[0].id);

  // $: console.log("results=", results);

  $: if (items) {
    miniSearch.removeAll();
    miniSearch.addAll(items);
  } else {
    miniSearch.removeAll();
  }

  const oninput = debounce(
    function (e) {
      // console.log("suggest=", miniSearch.autoSuggest(e.target.value));
      // console.log("search=", miniSearch.search(e.target.value));
      //console.log("about to set match store = ", e.target.value);
      match.set(e.target.value);
    },
    150,
    {
      leading: true,
      trailing: true,
    }
  );

  $: if (selected) match.set("");

  onDestroy(function () {
    match.set("");
  });

  function select(value) {
    match.set("");
    dispatch("unit", (selected = itemsById[value]));
    if (autoclear) clear();
  }

  function label(item) {
    return item["addr:unit"] || item.name || item.display;
  }

  export function clear() {
    selected = null;
    match.set("");
  }
</script>

<ul>
  <li>
    <input
      id="unit-search"
      name="unit"
      placeholder={name}
      type="text"
      on:input={oninput}
      on:change={oninput}
      autocorrect="off"
      autocapitalize="characters"
      value={$match}
      spellcheck="false"
      autocomplete="off"
      required={!!required}
    />
    {#if items && items.length}
      <select on:change={(e) => select(e.target.value)}>
        {#each items as item}
          <option value={item.id}>{label(item)}</option>
        {/each}
      </select>
      <datalist id="unit-search-options">
        {#each items as item}
          <option value={label(item)} />
        {/each}
      </datalist>
    {/if}
  </li>

  {#each results as item}
    <li>
      <button
        type="button"
        value={item.id}
        on:click={(e) => select(e.target.value)}
        ><data class="unit id {item.format + ''}" value={item.id}
          >{label(item)}</data
        ></button
      >
    </li>
  {/each}
</ul>
