<script>
  import Time from "../Time.svelte";
  import AuthorizationNavItem from "./AuthorizationNavItem.svelte";

  export let item;
  export let interactive = true;
</script>

<article class="{item.type} details">
  <header>
    <h1>
      {#each [item.principal]
        .filter((item) => item && item.id)
        .slice(0, 1) as user}
        <data class="user {user.type}" value={user.id}>{user.name}</data>
      {/each}
      <!-- <data class="role" value="{ auth.roles.admin ? "admin" : auth.roles.patrol ? "patrol" : "user" }">{ auth.roles.admin ? "Full Access" : auth.roles.patrol ? "Enforcement" : "View Only" }</data> -->
    </h1>
  </header>
  <dl>
    <dt>Login email</dt>
    <dd><a href="mailto:{item.principal.email}">{item.principal.email}</a></dd>
    <dt>Added</dt>
    <dd>
      <Time datetime={item.valid.min.datetime} timezone={item.timezone}>
        {#each [item.valid.min.by]
          .filter((item) => item && item.id)
          .slice(0, 1) as user}
          <a href="mailto:{user.email}"
            ><data class="user {user.type}" value={user.id}
              ><dfn>by</dfn> {user.name}</data
            ></a
          >
        {/each}
      </Time>
    </dd>
  </dl>
  {#if interactive}
    <AuthorizationNavItem {item} />
  {/if}
</article>
