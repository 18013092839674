<script lang="ts" context="module">
  import { fetchPropertyGeoFeatures } from "$components/map/api";

  export const features = writable<FeatureCollection>({
    type: "FeatureCollection",
    features: [],
  });

  propertyId.subscribe(async ($id) => {
    if (!$id) return;
    var json = await fetchPropertyGeoFeatures($id);
    console.log("loaded property features:", json);
    features.set({
      type: "FeatureCollection",
      features: json.features.filter(
        (f) =>
          f.properties?.man_made === "sign" ||
          f.properties?.information === "sign"
      ),
    });
  });

  features.subscribe(($data) => console.log("geo features:", $data));

  export type IdentifiedFeature = Feature & { id: string };
</script>

<script lang="ts">
  import SignageDocs from "$components/signage/SignageDocs.svelte";
  import SignageEditor from "$components/signage/SignageEditor.svelte";
  import SignageMap from "$components/signage/SignageMap.svelte";
  import { param } from "$utils/params";
  import { propertyId } from "$utils/propertystores";
  import type { FeatureCollection } from "geojson";
  import type { Feature } from "maplibre-gl";
  import { writable } from "svelte/store";

  export let mode: string | null;
  export let property: Property;
  let levels: Record<string, any>;
</script>

{#if mode === "edit"}
  <SignageEditor {property} {features} />
{:else if mode === "print"}
  <SignageDocs features={$features} {property}>
    <header>
      <h1>Signage Plan</h1>
      <nav>
        <ul>
          <li>
            <a href="/{property.type}/{property.id}/signage">Map</a>
          </li>
          <li>
            <a href="/{property.type}/{property.id}/signage/edit">Edit</a>
          </li>
        </ul>
      </nav>
    </header>
  </SignageDocs>
{:else}
  <SignageMap {property} features={$features}>
    <header>
      <h1>Signage Plan</h1>
      <nav>
        <ul>
          <li>
            <a href="/{property.type}/{property.id}/signage/print">Print</a>
          </li>
          <li>
            <a href="/{property.type}/{property.id}/signage/edit">Edit</a>
          </li>
        </ul>
      </nav>
    </header>
  </SignageMap>
{/if}
