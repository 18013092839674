<script>
  import startCase from "lodash-es/startCase";
  import { fetchUpdateSpaces } from "$utils/api";
  import { spacesUpdated } from "$utils/propertystores";

  export let item;

  $: value = values(item) || "standard";

  $: submitting = !item;

  async function submit(e) {
    if (!item) return;

    submitting = true;

    await fetchUpdateSpaces({
      type: item.type,
      scope: item.scope,
      id: item.id,
      [e.target.name]: e.target.value,
    });

    // anything else?
    spacesUpdated.set(Date.now());
  }

  function values(item) {
    if ("motorcycle" === item?.parking_space) return "motorcycle";
    return item?.size;
  }
</script>

<dt>Size</dt>
<dd>
  <data class="size" value={value || ""}>{startCase(value)}</data>
  <form class="v2" on:change={submit}>
    {#if submitting}
      Saving
    {:else}
      <label for="space-size">Change</label>
      <select id="space-size" name="size" {value}>
        <option value="standard">Standard</option>
        <option value="compact">Compact</option>
        <option value="ultracompact">Ultracompact</option>
        <option value="oversize">Oversize</option>
        <option value="motorcycle">Motorcycle</option>
      </select>
    {/if}
  </form>
</dd>
