<script>
  import { tenantsByUnit } from "$utils/propertystores";
  import { sortBy } from "$utils/sort";
  import ItemsList from "./item/ItemsList.svelte";
  import UnitTenantsItem from "./unit/UnitTenantsItem.svelte";
</script>

<section>
  <header>
    <h1>Status</h1>
  </header>
  <ItemsList
    class="activity highlight"
    items={sortBy(Object.values($tenantsByUnit || {}), "unit.display")}
    types={{ unitstatus: UnitTenantsItem }}
  />
</section>
