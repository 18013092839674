<script>
  import { format, toZonedTime } from "date-fns-tz";
  import { title as proper } from "$utils/text";
  import { href } from "$utils/router";

  export let item = null;
  //$: console.log("archived=", item);
  $: subject = item && item.subject;
  $: [minstr, maxstr] = item && subject.valid.interval.split("/");
  $: [min, max] =
    [minstr, maxstr].map((d) => d && toZonedTime(d, subject.timezone)) || [];
</script>

<aside class="previous">
  <h1>
    Archived {proper(subject.format || subject.type)}
  </h1>
  <p>
    <time datetime={subject.valid.interval}>
      <time datetime={maxstr}
        >{format(max, "h:mm a EEE MMM d, yyyy zzz", {
          timeZone: subject.timezone,
        })}</time
      >
    </time>
  </p>
  {#if item.latest}
    <a href={href(item.latest)}>Current</a>
  {/if}
</aside>
