<script lang="ts">
  import ServiceLogo from "./ServiceLogo.svelte";

  export let service: Service;
  export let title: string = null;
  export let status: string = null;
  export let logo = false;
</script>

<data class="service" value={service.id || service.service}
  >{#if title}<dfn>{title}</dfn>{/if}
  {service.name}
  {#if status}{status}{/if}
  {#if logo}
    <ServiceLogo {service} />{/if}</data
>
