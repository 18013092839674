<script context="module" lang="ts">
  import { fetchProperties } from "$utils/api";
  import { readable } from "svelte/store";

  // this attempts to fetch all properties the user could access, either through direct assignment or admin
  const properties = readable(null, function start(set) {
    fetchProperties("*").then((json) => set(json.properties));

    return function stop() {
      set(null);
    };
  });
</script>

<script lang="ts">
  import SearchForm from "$components/form/SearchForm.svelte";
  import { writable } from "svelte/store";
  import PortfolioNav from "./PortfolioNav.svelte";
  import debounce from "lodash-es/debounce";
  import { store } from "$components/property/search";
  import PropertiesList from "$components/property/PropertiesList.svelte";

  const query = writable<string>("");
  const results = store(properties, query);

  const search = debounce(function (value) {
    query.set(value);
  }, 250);
</script>

<header>
  <h1>Portfolio</h1>
  <nav class="properties">
    <SearchForm
      class="properties"
      placeholder="Properties"
      value={$query}
      on:change={(e) => search(e.detail.value || null)}
    />
    <PropertiesList class="portfolio" properties={$results} />
  </nav>
  <PortfolioNav />
</header>
