<script>
  import { fetchUpdateSpaces as update } from "$utils/api";
  import { spacesUpdated as updated } from "$utils/propertystores";

  export let item;

  $: value = item?.["capacity:disabled"];
  $: submitting = !item;

  async function submit(e) {
    if (!item) return;

    submitting = true;

    await update({
      type: item.type,
      scope: item.scope,
      id: item.id,
      [e.target.name]: e.target.value,
    });

    // anything else?
    updated.set(Date.now());
  }
</script>

<dt>Accessible</dt>
<dd>
  <data class="capacity-disabled" value={value || ""}
    >{value ? "Yes" : "No"}</data
  >
  <form class="v2" on:change={submit}>
    {#if submitting}
      Saving
    {:else}
      <label for="space-capacity-disabled">Change</label>
      <select
        id="space-capacity-disabled"
        name="capacity:disabled"
        value={(value || "0") + ""}
      >
        <option value="0">No</option>
        <option value="1">Yes</option>
      </select>
    {/if}
  </form>
</dd>
