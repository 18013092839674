<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let name: string = null;
  export let id: string = null;
  export let value: string = null;
  export let required = false;

  const events = createEventDispatcher();

  function change(name: string, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    if (name)
      events("change", {
        name,
        value,
      });
  }
</script>

<select
  {id}
  {name}
  {required}
  {value}
  on:change={({ currentTarget: target }) => change(target.name, target.value)}
>
  <slot />
</select>
