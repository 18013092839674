//import { qs } from "@parkingboss/utils";
import { params } from "./params";
import page from "page";

// bootstrap the previously loaded page
export const router = page;

export default router;

//qs mechanics
router("*", function (ctx, next) {
  // don't run auth, we're running out of band

  ctx.query = Object.assign(qs(ctx.querystring), qs(ctx.hash));
  next();
});

function qs(str) {
  const query = new URLSearchParams(str);
  return [...query.keys()].reduce((result, k) => {
    var values = query.getAll(k);
    if (values?.length === 1) {
      result[k] = values[0];
    } else if (values?.length) {
      result[k] = values;
    }
    return result;
  }, {});
}

// qs mechanics
// page("*", function (ctx, next) {
//   // don't run auth, we're running out of band
//   const query = new URLSearchParams(ctx.querystring);
//   ctx.query = [...query.keys()].reduce((result, k) => {
//     var values = query.getAll(k);
//     if (values?.length === 1) {
//       result[k] = values[0];
//     } else if (values?.length) {
//       result[k] = values;
//     }
//     return result;
//   }, ctx.query ?? {});
//   //console.log(ctx.query);

//   next();
// });

// // hash mechanics
// page("*", function (ctx, next) {
//   // don't run auth, we're running out of band
//   const query = new URLSearchParams(ctx.hash);
//   ctx.query = [...query.keys()].reduce((result, k) => {
//     var values = query.getAll(k);
//     if (values?.length === 1) {
//       result[k] = values[0];
//     } else if (values?.length) {
//       result[k] = values;
//     }
//     return result;
//   }, ctx.query ?? {});
//   //console.log(ctx.query);

//   next();
// });

export function state(ctx, next) {
  params.set(
    Object.assign(
      {},
      ctx.params,
      ctx.query,
      qs(location.hash && location.hash.substring(1))
    )
  );
  if (!!next) next();
}

export function route(url, history = true) {
  if (!history) router.replace(url); //router.redirect(url); //
  else router(url);
}

export function end(ctx) {
  state(ctx);
}

export function query(key, value, path) {
  const qs = new URLSearchParams(window.location.search);

  if (typeof key == "object") {
    for (const [k, v] of Object.entries(key)) {
      if (v) qs.set(k, v);
      else qs.delete(k);
    }
  } else {
    if (value) qs.set(key, value);
    else qs.delete(key);
  }

  let q = qs.toString();
  if (q) q = "?" + q;
  route((path || window.location.pathname) + q);
}

export const bases = {
  vehicle: "./vehicles/",
  space: "./spaces/",
  tenant: "./tenants/",
  unit: "./tenants/",
  media: "./media/",
};
const identifier = {
  //vehicle: (item) => item.key,
};

function identify(record) {
  if (!record) return null;
  if (typeof identifier[record._type || record.type] === "function")
    return identifier[record._type || record.type](record);
  return record.id || record.key;
}
export function href(record) {
  //console.log("href=", record);
  if (!record) return null;
  if (!record.scope || (!record.type && !record._id)) return null;
  return `/properties/${record.scope?.id || record.scope}/${
    record._type || record.type
  }/${identify(record)}`;
  if (!bases[record._type || record.type]) return null;
  return bases[record._type || record.type] + identify(record);
}
