<script lang="ts">
  import { title } from "$utils/text";

  export let item: PermitIssuePolicy;
</script>

<article class="{item.type} details">
  <header>
    <h1>{title(item.title)}</h1>
  </header>
  <dl>
    <!-- <dt>Amenity</dt>
    <dd>{item.amenity}</dd> -->
    <dt>Access</dt>
    <dd>
      {item.audience.admin
        ? "Manager"
        : "parking" === item.amenity
        ? "Parking Attendant App"
        : "Amenity Pass App"}
    </dd>
  </dl>
</article>
