<script lang="ts">
  import Loading from "../Loading.svelte";

  export let item: {
    type: string;
    policies: PermitIssuePolicy[];
  };

  $: text =
    item?.policies &&
    Object.values(item.policies)
      .reduce((result, policy) => {
        if (!policy.vehicle?.fineblock) return result;
        if (result.length) result.push(policy.title);
        else result.push("Blocked by " + policy.title);
        return result;
      }, [] as string[])
      //.filter((policy) => policy.vehicle?.fineblock)
      //.map((policy) => policy.title)
      .join(", ");
</script>

<aside class="{item.type} summary">
  <header><h1>Unpaid Fines</h1></header>
  {#if item.policies}
    <p>
      {text || "Nothing configured"}
    </p>
  {:else}
    <Loading />
  {/if}
</aside>
