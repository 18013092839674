<script context="module" lang="ts">
  const now30s = time({ seconds: 30 });
</script>

<script lang="ts">
  import { parseISO } from "date-fns";

  import { status as permitStatus } from "$utils/permit";
  import { time } from "$utils/timestores";
  export let item: Permit;
  $: status = permitStatus(item, nowutc);
  $: nowutc = item && new Date(Math.max(Date.now(), $now30s.getTime()));

  $: timezone = item && item.timezone;

  $: issuedutc = item && parseISO(item.issued.datetime);

  $: [startstr, endstr] = item?.valid.interval.split("/") ?? ["", ""];
  $: startutc =
    startstr &&
    new Date(Math.max(parseISO(startstr), Math.min(issuedutc, Date.now())));
  $: endutc = endstr && parseISO(endstr);

  $: cancelled = item && item.cancelled;
  $: revoked = item && item.revoked;
  $: pending = item && !cancelled && nowutc < startutc;
  $: expired = item && (item.expired || (endutc && nowutc > endutc));
  $: active = item && !expired && !pending && !cancelled;
</script>

{#if item}
  <time class="valid {status}" datetime={item.valid.interval}>
    {#if active}
      <data class="valid" value={status}>Active</data>
    {:else if pending}
      <data class="valid" value={status}>Future</data>
    {:else if cancelled}
      <data class="valid" value={status}>Canceled</data>
    {:else if revoked}
      <data class="valid" value={status}>{revoked.title}</data>
    {:else if expired}
      <data class="valid" value={status}>Ended</data>
    {/if}
  </time>
{/if}
