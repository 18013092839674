<script lang="ts">
  import RentableRecordItem from "./RentableRecordItem.svelte";
  import RentableUnlinkedItem from "./RentableUnlinkedItem.svelte";

  export let item: any = null;
  export let record: string | null = null;
  console.log("rentable=", item);
</script>

{#if null != item?.subject?.id}
  <RentableRecordItem {item} {record} />
{:else}
  <RentableUnlinkedItem {item} {record} />
{/if}
