<script>
  import { comparer, numericDesc } from "$utils/sort";
  import {
    toHours,
    toMinutes,
    toDays,
    toMilliseconds,
    parse,
  } from "duration-fns";
  import { scaleLinear } from "d3-scale";
  import { stringToDateInterval } from "./item/ItemsList.svelte";
  import UsageMeterSubjectItem from "./UsageMeterSubjectItem.svelte";
  import { format } from "date-fns";
  import { toZonedTime } from "date-fns-tz";
  import TimeIntervalItem from "./TimeIntervalItem.svelte";
  import { title as proper } from "$utils/text";
  import upperFirst from "lodash-es/upperFirst";
  import MeterDescription from "./MeterDescription.svelte";

  export let item;

  $: interval =
    item && item.interval && stringToDateInterval(item.interval, item.timezone);
  $: meter = item.meter;
  $: minimum = item.minimum;
  //$: console.log("usage item=", item);
  $: numeric = !!meter.count || !!meter.concurrent; // numeric vs time
  $: threshold = numeric
    ? meter.count ?? meter.concurrent
    : toMilliseconds(meter.time); //(item.meter.minimum);
  //$:  = toMilliseconds(minimum)
  //$: asDays = parse(item.meter.minimum).days > 0 && !(value % (24 * 60 * 60 * 1000));

  $: percentage = scaleLinear().domain([0, threshold]).range([0, 100]);

  $: scale = scaleLinear()
    .domain([
      0,
      Math.max(
        threshold * 1,
        meter.minimum && meter.time && meter.per
          ? toMilliseconds(meter.per)
          : 0,
        ...Object.values(item.items).map(
          (i) => i.count || toMilliseconds(i.time)
        )
      ), // max number
      //: Math.max(threshold * 1.5, ...Object.values(item.items).map(i => toMilliseconds(i.time))) + 4 // max time
      //: toMilliseconds(item.meter.period) + 4 // max time
    ])
    .range([0, 100]);

  //$: console.log("max = ",  Math.max(threshold * 1.5, ...Object.values(item.items).map(i => i.count || toMilliseconds(i.time))) + 10);
</script>

<article
  class="{item.type} items details"
  class:minimum={meter.minimum}
  class:maximum={meter.maximum}
>
  <header>
    <h1>
      <dfn
        >{(meter.title && upperFirst(meter.title)) ||
          `${proper(meter.group?.replace("tenant", "unit")) || ""} ${
            meter.minimum ? "Monitoring" : "Limit"
          }`}</dfn
      >
    </h1>
    {#if !meter.ratio}
      <TimeIntervalItem {item} />
    {:else}
      <time datetime={item.interval}>
        <time datetime=""><abbr>{format(interval.start, "MMM d")}</abbr></time>
        -
        <time datetime=""
          ><abbr
            >{format(toZonedTime(item.generated, item.timezone), "MMM d")}</abbr
          ></time
        >
      </time>
    {/if}
    <!-- <time datetime={item.interval}
      ><data class="count" value={item.count}
        >{item.count === 0 ? "0" : item.count}
        {#if item.minimum < 1.0}<dfn>at {item.minimum * 100}–100%</dfn
          >{/if}</data
      ></time
    > -->
  </header>
  <data class="count" value={item.count}
    >{item.count === 0 ? "0" : item.count}{#if item.minimum < 1.0}
      <dfn>at {item.minimum * 100}–100%</dfn>{/if}</data
  >
  <dl>
    <dt>Policy</dt>
    <dd>
      {#each Object.values(item.meter.subjects) as item, i}
        {#if i > 0}&nbsp;+
        {/if}<data value={item.id}>{item.title}</data>
      {/each}
    </dd>
    <dt>{meter.minimum ? "Threshold" : "Limit"}</dt>
    <dd><MeterDescription item={item.meter} title={!item.meter.title} /></dd>
  </dl>
  <!-- <UsageMinimumSummaryItem {item} /> -->
  <!-- <dl> -->
  <!-- <dt>
        {#each Object.values(item.meter.subjects) as item}
        <data value="{item.id}">{item.title}</data>
        {/each}
    </dt>
    <dd></dd>
    <dd><figure>
        <svg width="100%" height="32">
        <text x="{scale(minimum).toFixed(3)}%" class="minimum">{numeric ? minimum : asDays ? toDays(minimum) + " days" : toHours(minimum) + " hours" }</text>
        <line class="minimum" x1="{scale(minimum).toFixed(3)}%" x2="{scale(minimum).toFixed(3)}%" y1="0" y2="100%" stroke-width="1" stroke-linecap="round" />
    </svg>
    </figure></dd> -->
  <ol class="activity">
    {#each Object.values(item.items)
      .map((item) => [item, item.count || toMilliseconds(item.time)])
      .sort(comparer((item) => item[1], numericDesc)) as [item, used]}
      <li>
        <UsageMeterSubjectItem {item} header={false} />
      </li>
      <!-- <dt>
        {#each [item.subject].filter(record) as item}
          <Record {item} />
        {/each}
      </dt> -->
      <!-- <dd>
        
    </dd> -->
      <!-- <dd>
        {#if meter.maximum}
          <time class="used" datetime={item.time}
            ><abbr
              >{numeric
                ? used
                : meter.minimum
                ? toHours(used).toFixed(1).replace(".0", "") + " hours"
                : percentage(used).toFixed(1) + "%"}</abbr
            ></time
          >
        {/if}
        <figure class={item.type}>
          <svg width="100%" height="32">
            <rect
              class:minimum={meter.minimum}
              class:maximum={minimum < 1.0}
              class:full={minimum < 1.0 && percentage(used) > 99.0}
              class="used"
              x="0%"
              y="20%"
              height="60%"
              rx="3"
              ry="3"
              width="{scale(used)}%"
            /> -->
      <!-- <line class="used" x1="0" x2="{scale(used).toFixed(3)}%" y1="50%" y2="50%" stroke-width="10" stroke-linecap="round" /> -->
      <!-- {#if meter.minimum}
            <line class="minimum" x1="{scale(threshold)}%" x2="{scale(threshold)}%" y1="0" y2="100%" stroke-width="2" />
            {/if} -->
      <!-- <text x="0" y="100%" class="used">{Math.round(toHours(usedMs))}</text> -->
      <!-- </svg>
        </figure>
      </dd> -->
      <!-- {#if meter.minimum}
        <dd>
          <time class="used" datetime={item.time}
            ><abbr
              >{numeric
                ? used
                : meter.minimum
                ? toHours(used).toFixed(1).replace(".0", "") + " hours"
                : percentage(used).toFixed(1) + "%"}</abbr
            ></time
          >
        </dd>
      {/if}-->
    {/each}
  </ol>
  <!-- </dl> -->
</article>
