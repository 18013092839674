<script lang="ts">
  export let src: string;
  export let alt: string;
  let className: string = "";
  export { className as class };

  let loading: boolean = true;
  let error: boolean = false;
  let loaded: boolean = false;
</script>

<img
  class={className}
  class:loading
  class:error
  class:loaded
  {src}
  {alt}
  on:error={(e) => (loaded = loading = !(error = true))}
  on:load={(e) => (error = loading = !(loaded = true))}
/>
