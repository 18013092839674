<script lang="ts">
  import RecordItem from "$components/record/RecordItem.svelte";
  import { thumbnail } from "$utils/filepreview";
  import { dateparts, datetime } from "$utils/temporal";
  import { time } from "$utils/timestores";
  import { autocreate } from "$utils/vehicle";

  export let item: any;
  export let record: Vehicle | null | undefined = null;

  //   $: console.log("item=", item);
</script>

<article class="present enforce">
  <dl>
    <dt>Scanned</dt>
    {#if !record}
      <dd>
        <RecordItem
          item={typeof item.vehicle == "string"
            ? autocreate(item.vehicle, item.scope)
            : item.vehicle}
        />
      </dd>
      <dd>
        <time datetime={item.present.item.valid}>
          {datetime(
            item.present.valid,
            item.present.item.timezone,
            ({ month, day, hour, minute }) =>
              `${month} ${day} ${hour}:${minute}`
          )}
        </time>
      </dd>
    {:else}
      <dd>
        <time datetime={item.present.item.valid}>
          {datetime(item.present.valid, item.present.item.timezone)}
        </time>
      </dd>
    {/if}
    <!-- <dd>
      <ul class="images">
        {#each Object.values(item.present.items)
          .map((p) => p.file)
          .filter(Boolean)
          .map( (file) => [file, dateparts(Temporal.Instant.from(file.created))] ) as [file, date]}
          <li>
            <figure class="image" class:uploading={file.local}>
              <a
                download={file.name}
                href={file.url}
                type={file.format}
                target="_blank"
                ><img
                  alt="thumbnail"
                  loading="lazy"
                  src={thumbnail(file, 200, 200, true)}
                /></a
              >
              <figcaption>
                <time datetime={file.created}>{date.month} {date.day}</time>
              </figcaption>
            </figure>
          </li>
        {/each}
      </ul>
    </dd> -->

    {#if item.permitted === false}
      <dt>Status</dt>
      <dd>Unpermitted at time of scan</dd>
    {:else}
      {#each Object.values(item.permitted?.items ?? {}) as permitted}
        <dt>
          <time datetime={permitted.valid}>
            {#if permitted.source.type === "permit"}Permit{:else}{permitted
                .source.name}{/if}</time
          >
        </dt>
        <dd>
          <RecordItem
            item={typeof permitted.vehicle == "string"
              ? autocreate(permitted.vehicle, permitted.scope)
              : permitted.vehicle}
          />
        </dd>
      {:else}
        <dt>Status</dt>
        <dd>Unpermitted at time of scan</dd>
      {/each}
    {/if}
  </dl>
  <figure class="scans">
    {#each [...Object.values(item.present.items), item.present.item].sort((a, b) => -1 * Temporal.Instant.compare(a.valid.split("/")[0], b.valid.split("/")[0])) as item}
      {#if item.file?.url}
        <a href={item.file.url} target="blank">
          <time datetime={item.created}
            >{datetime(
              item.file.created,
              item.timezone,
              ({ month, day }) => `${month} ${day}`
            )}</time
          >
          <img src={thumbnail(item.file, 100)} alt="thumbnail" loading="lazy" />
        </a>
      {/if}
    {/each}
  </figure>
</article>
