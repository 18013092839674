<script context="module">
  import { time } from "$utils/timestores";
  import {
    eachMonthOfInterval,
    eachDayOfInterval,
    endOfMonth,
    isSameDay,
  } from "date-fns";
  import format from "date-fns/format";

  import { stringToDateInterval } from "./item/ItemsList.svelte";
  import { toZonedTime } from "date-fns-tz";
  import { createEventDispatcher } from "svelte";

  const nowutc = time({ seconds: 60 });
</script>

<script>
  export let interval;
  export let timezone;
  export let accessed;
  export let violations;
  export let selected = null;

  const dispatch = createEventDispatcher();

  function change(day) {
    dispatch("change", (selected = day));
  }

  //$: console.log("cal=", interval, accessed, violations);

  $: now = toZonedTime($nowutc, timezone);
  $: parsed = interval && stringToDateInterval(interval);
  $: months = (parsed && eachMonthOfInterval(parsed).reverse()) || [];
</script>

<figure>
  <ol class="months">
    {#each months as month}
      <li class="month">
        <h1>{format(month, "MMM yyyy")}</h1>
        <ol class="month days">
          {#each eachDayOfInterval( { start: month, end: endOfMonth(month) } ) as day}
            <li
              on:click={(e) => change(day)}
              class="day {format(day, 'EEE')}"
              class:today={isSameDay(day, now)}
              class:accessed={accessed?.[day]}
              class:violations={violations?.[day]}
              class:selected={selected && isSameDay(day, selected)}
            >
              {format(day, "d")}
            </li>
          {/each}
        </ol>
      </li>
    {/each}
  </ol>
</figure>
