<script>
  import EnforcementChartItem from "./EnforcementChartItem.svelte";
  import EnforcementSummaryItem from "./EnforcementSummaryItem.svelte";

  export let item;
</script>

<article class="{item.type} user details">
  <header>
    <h1>
      {#each [item.principal, item.subject]
        .filter((item) => item && item.id)
        .slice(0, 1) as user}
        <a href="mailto:{user.email}"
          ><data class="user {user.type}" value={user.id}>{user.name}</data></a
        >
      {/each}
    </h1>
  </header>
  <EnforcementSummaryItem {item} />
  <!-- <EnforcementChartItem {item} /> -->
</article>
