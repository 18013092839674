<script lang="ts">
  import { title as proper } from "$utils/text";
  import { createEventDispatcher } from "svelte";
  export let item: any = null;
  let id = `permit-entry-${item.id}`;

  export let name: string = "entry";
  export let value: Record<string, boolean> = null;

  const events = createEventDispatcher<{
    change: {
      name: string;
      value: Record<string, boolean>;
    };
  }>();

  function change(name: string, id: string, selected: boolean) {
    value ??= {};
    value[id] = selected;

    if (name)
      events("change", {
        name,
        value,
      });
  }
</script>

<input
  {id}
  type="checkbox"
  {name}
  value={item.id}
  checked={value?.[item.id] === true}
  on:change={({ currentTarget: target }) =>
    change(target.name, target.value, target.checked)}
/><label for={id}
  ><h1>
    {[
      item.name,
      //item.keypad?.code?.length && "Entry Code",
      //item.remote?.enabled && `${proper(item.action)} Button`,
      //proper(item.action),
    ]
      .filter((i) => null != i)
      .join(" with ")}
  </h1>
  {#if true == item.remote?.enabled}
    <data value="remote">Live {proper(item.action)} Button</data>
  {/if}
  {#if true == item.keypad?.dynamic}
    <data value="keypad">Auto-generated Entry Codes</data>
  {/if}
  {#if item.keypad?.code?.length}
    <data value="keypad">Shared Entry Code</data>
    <!-- <EntryKeypadItem item={item.keypad} /> -->
  {/if}
  {#if item.provider?.service}
    <img
      alt="{item.provider.name} logo"
      class="service logo"
      src="/{item.provider.service}.svg"
    />
  {/if}
</label>
