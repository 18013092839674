<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import { thumbnail } from "$utils/filepreview";

  export let label: string = null;
  export let name: string = "file";

  export let items: File[] = null;

  export let value: Record<string, boolean> = null;

  $: if (null == value && null != items)
    value = items.reduce((a, i) => {
      a[i.id] = true;
      return a;
    }, {});

  const events = createEventDispatcher<{
    change: {
      name: string;
      value: Record<string, boolean>;
    };
  }>();

  function change(name: string, id: string, selected: boolean) {
    value ??= {};
    value[id] = selected;

    if (name)
      events("change", {
        name,
        value,
      });
  }
</script>

<fieldset class="files">
  {#if label}
    <label for="files-select">{label}</label>
  {/if}
  <ul class="files">
    {#each items as file}
      <li>
        <input
          type="checkbox"
          checked={value?.[file.id] !== false}
          {name}
          value={file.id}
          on:change={({ currentTarget: target }) =>
            change(target.name, target.value, target.checked)}
        />
        <figure class="image" class:uploading={file.local}>
          <a
            download={file.name}
            href={file.url}
            type={file.format}
            target="_blank"
            ><img
              alt="thumbnail"
              loading="lazy"
              src={thumbnail(file, 80, 80, true)}
            /></a
          >
        </figure>
      </li>
    {/each}
  </ul>
</fieldset>
