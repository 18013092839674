<script context="module">
</script>

<script>
  import identity from "lodash-es/identity";

  import TimeIntervalItem from "./TimeIntervalItem.svelte";
  import TimeItem from "./TimeItem.svelte";
  export let item;
  export let interval = true;
</script>

<aside
  class={[item.type, item.state, "summary"].filter(identity).join(" ")}
  class:none={item?.count === 0}
>
  <header>
    <h1>
      {item.title}
    </h1>

    {#if interval}
      {#if item.interval === "/"}
        <time datetime="/">All</time>
      {:else if item.interval}
        <TimeIntervalItem {item} />
      {:else if item.datetime}
        <TimeItem {item} />
      {:else}
        <time datetime="">Loading&hellip;</time>
      {/if}
    {/if}
  </header>
  {#if null != item.count}
    <time datetime={item.interval}
      ><data class="count" value={item.count}
        >{item.count === 0 ? item.zero || "0" : item.count}</data
      ></time
    >
  {/if}
</aside>
