<script lang="ts">
  import { createEventDispatcher } from "svelte";

  const eventing = createEventDispatcher();

  export let levels: { [key: string]: any } = {};
  export let level: string;
  //$: console.log("level=", level);

  $: levelslist = Object.values(levels ?? {})
    .map((v: any) => ({
      ...v,
      value: v.level,
      ref: v["level:ref"],
    }))
    //.filter((i) => "" !== i.level)
    .sort(
      (a: any, b: any) =>
        nandor(parseFloat(a.level), -1000) - nandor(parseFloat(b.level), -1000)
    );
  function nandor(value, fallback) {
    return isNaN(value) ? fallback : value;
  }
  function label(ref: string) {
    if (ref === "Outdoors") return "Outside";
    return ref;
  }
  $: if (null != level) {
    eventing("change", level);
    eventing("level", level);
  }
</script>

<ul>
  {#each levelslist as item}
    <li>
      <input
        type="radio"
        name="level"
        value={item.value}
        bind:group={level}
        id="level-{item.value}"
      /><label for="level-{item.value}">{label(item.ref)}</label>
    </li>
  {:else}
    <li>
      <input
        type="radio"
        name="level"
        value="outside"
        bind:group={level}
        id="level-outside"
      /><label for="level-outside">Outside</label>
    </li>
  {/each}
</ul>
