<script>
  import store from "store/dist/store.modern";

  export let url;
  export let updated = null;
  //export let welcome;
  export let attention = null;
  export let selected = false;

  $: updatedTime = updated && Date.parse(updated);
  //", updated, updatedTime);
  $: stored = store.get(key(url));
  $: lastTime = stored && Date.parse(stored);

  $: highlight =
    updatedTime <= Date.now() && (!lastTime || updatedTime > lastTime);

  function click() {
    if (highlight) {
      store.set(key(url), updated);
      setTimeout(function () {
        stored = updated;
      }, 1 * 1000);
    }
  }
  function key(path) {
    return "highlight-" + path;
  }
</script>

{#if url}
  <a
    href={url}
    on:click={click}
    data-updated={updated}
    class:selected
    class:highlight
    data-attention={attention || null}><slot></slot></a
  >
{/if}
