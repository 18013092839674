import type {
  GeoJSONSource,
  //Map as VectorGLMap,
  MapSourceDataEvent,
  MapDataEvent,
  MapMouseEvent,
  Popup,
  LngLatLike,
  LngLatBoundsLike,
  MapOptions as MapOptions,
  FillStyleLayer as FillLayer,
  TypedStyleLayer as AnyLayer,
  Map as VectorGLMap,
  StyleSpecification,
  MapStyleDataEvent,
  MapLibreEvent as MapEvent,
  MapGeoJSONFeature,
  MapLayerMouseEvent,
  Marker,
  PointLike,
  ControlPosition,
  IControl
} from "maplibre-gl";
import type { Feature, FeatureCollection, BBox } from "geojson";
import mapboxgl from "mapbox-gl";
import maplibregl from "maplibre-gl";
import { getContext, setContext } from "svelte";
import type { Readable } from "svelte/store";
import polylabel from "polylabel";

const key = "mapgl";

const mapboxAccessToken =
  "pk.eyJ1IjoicGFya2luZ2Jvc3MiLCJhIjoiY2swY3VheHQyMDE1ejNtbjV4M3RoeTQ5cyJ9.toumXl_aMY5GgH45lZyiuA";

mapboxgl.accessToken = mapboxAccessToken;

// export interface MapEvent extends EventData {
//   target: VectorGLMap;
// }

export function getSvelteContext() {
  return getContext(key) as {
    style: Readable<StyleSpecification>;
    map: Readable<VectorGLMap>;
    loaded: Readable<boolean>;
    styledmap: Readable<VectorGLMap>;
    metersPerPixel: Readable<number | null>;
    pixelsPerMeter: Readable<number | null>;
  };
}
export function setSvelteContext(context: {
  style: Readable<StyleSpecification>;
  map: Readable<VectorGLMap>;
  loaded: Readable<boolean>;
  styledmap: Readable<VectorGLMap>;
  metersPerPixel: Readable<number | null>;
  pixelsPerMeter: Readable<number | null>;
}) {
  return setContext(key, context);
}

interface Model3D {
  url: string;
  type: string;
  bbox: number[];
}

//export { key };
export type {
  StyleSpecification,
  MapSourceDataEvent,
  MapGeoJSONFeature,
  MapEvent,
  MapStyleDataEvent,
  Popup,
  LngLatLike,
  GeoJSONSource,
  VectorGLMap,
  MapMouseEvent,
  MapLayerMouseEvent,
  MapOptions,
  LngLatBoundsLike,
  AnyLayer,
  FillLayer,
  Model3D,
  Marker,
  Feature,
  FeatureCollection,
  BBox,
  PointLike as Point,
  IControl,
  ControlPosition
};

export const engines = {
  maplibregl: maplibregl,
  mapboxgl: mapboxgl,
  "mapbox-gl": mapboxgl,
  "maplibre-gl": maplibregl,
  mapbox: mapboxgl,
  maplibre: maplibregl,
};

export const engine =
  engines[
  new URL(window.location.href).searchParams.get("renderer") || "maplibre"
  ];

export { mapboxAccessToken };

export function scalebbox(factor: number, bounds: LngLatBoundsLike) {
  if (!bounds) return bounds;
  const xmin = bounds[0];
  const xmax = bounds[2];
  const ymin = bounds[1];
  const ymax = bounds[3];
  const xdiff = Math.abs((xmax - xmin) * factor);
  const ydiff = Math.abs((ymax - ymin) * factor);
  return [
    xmin - xdiff,
    ymin - ydiff,
    xmax + xdiff,
    ymax + ydiff,
  ] as LngLatBoundsLike;
}

export function pointFromFeature(feature: Feature): LngLatLike {
  return (
    (feature &&
      feature.geometry.type == "Point" &&
      feature.geometry.coordinates) ||
    (feature &&
      feature.geometry.type == "Polygon" &&
      polylabel(feature.geometry.coordinates))
  );
}
