<script lang="ts">
  import RecordItem from "$components/record/RecordItem.svelte";
  import { spaces } from "$utils/propertystores";

  export let item: Space;

  $: value = item?.["access:shared"];

  $: others =
    value?.split(/[,;]/).reduce((acc, cur) => {
      $spaces?.items[cur] && acc.push($spaces?.items[cur]);
      return acc;
    }, [] as Space[]) ?? [];
</script>

<dt>Shared/Tandem</dt>
<!-- {#each others as item}
  <dd>
    <RecordItem {item} />
  </dd>
{:else} -->
<dd>
  {#if others.length}
    <ul>
      {#each others as item}
        <li>
          <RecordItem {item} />
        </li>
      {/each}
    </ul>
  {:else}
    <data class="access-shared" value={item["access:shared"] || ""}
      >{(value && "Yes") || "No"}</data
    >
  {/if}
</dd>
<!-- {/each} -->
