<script lang="ts" context="module">
  let i = 0;
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Select from "./Select.svelte";

  export let name: string = null;
  export let label: string = null;
  export let required = false;
  export let value: string = "";

  //export let multiple = false;

  const id = `${name || "select"}-${i++}`;

  const events = createEventDispatcher();

  function change(name: string, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    if (name)
      events("change", {
        name,
        value,
      });
  }
</script>

<fieldset>
  {#if label}
    <label for={id}>{label}</label>
  {/if}
  <Select
    {id}
    {name}
    {required}
    {value}
    on:change={({ detail }) => change(detail.name, detail.value)}
    ><slot /></Select
  >
</fieldset>
