<script context="module" lang="ts">
  import { time } from "$utils/timestores";
  const now30s = time({ seconds: 30 });
  async function copyon(e: Event) {
    var target = e.target as HTMLButtonElement | HTMLAnchorElement;

    var value =
      (target as HTMLButtonElement).value || (target as HTMLAnchorElement).href;

    if (!value) return; // nothing to copy

    //if ((target as HTMLButtonElement).value) target.select();

    await navigator.clipboard.writeText(value);

    target.classList.add("copied");

    setTimeout((cl) => target.classList.remove(cl), 3000, "copied");
  }
</script>

<script lang="ts">
  export let item: Permit;
  import NotifyLink from "../NotifyLink.svelte";
  import { fetchUpdatePermit } from "$utils/api";
  import { details, permitsUpdated } from "$utils/propertystores";
  import { isSystemAdmin } from "$utils/propertystores";
  import { state as editing } from "$utils/item";
  import { parseISO } from "date-fns";
  import Copy from "$components/util/Copy.svelte";

  $: revokable = item?.revokable.includes(item.scope) || $isSystemAdmin;
  $: pass = item && item.pass;

  async function exempt(permit) {
    if (!window.confirm("This is permanent and cannot be undone. Continue?"))
      return;

    const json = await fetchUpdatePermit(permit, {
      exempt: true,
    });

    // do we need to do anything else?
    permitsUpdated(true);
  }
  $: issuedutc = item && parseISO(item.issued.utc);
  $: [startstr, endstr] = item?.valid.interval.split("/") ?? ["", ""];
  $: startutc =
    startstr &&
    new Date(Math.max(parseISO(startstr), Math.min(issuedutc, Date.now())));
  $: endutc = endstr && parseISO(endstr);
  $: nowutc = item && new Date(Math.max(Date.now(), $now30s));

  $: cancelled = item && item.cancelled;
  $: pending = item && !cancelled && nowutc < startutc;
  $: expired = item && (item.expired || (endutc && nowutc > endutc));
  $: active = item && !expired && !pending && !cancelled;
</script>

<nav>
  <h1>Options</h1>
  <ul>
    {#if item.amenity === "parking"}
      <li>
        <NotifyLink {item} />
      </li>
    {/if}

    {#if pending || active}
      {#if pass}
        <li>
          <a
            class="pass"
            href={pass.doc.pdf.url.replace(
              "https://passes.parkingboss.com",
              "https://parking-passes.imgix.net"
            )}
            target="_blank"
            download
          >
            Print/PDF
          </a>
        </li>
      {/if}
      {#if revokable}
        <li>
          <button
            type="button"
            class="revoke"
            on:click={(e) => $editing.start("revoke", item)}>Revoke</button
          >
          <!-- <a
            href="permits/{item.id}/revoke"
            on:click|preventDefault={(e) => (revoke = true)}>Revoke</a
          > -->
        </li>
        {#if item.continuous}
          <li>
            <button
              type="button"
              class="edit"
              on:click={(e) => $editing.start("edit", item)}>Edit</button
            >
            <!-- <a href="permits/{item.id}/edit">Edit</a> -->
          </li>
        {/if}
      {/if}
    {/if}
    {#if !pass}
      <li>
        <button
          type="button"
          class="duplicate"
          on:click={(e) => $editing.start("duplicate", item)}>Duplicate</button
        >
        <!-- <a href="permits/{item.id}/duplicate">Duplicate</a> -->
      </li>
    {/if}
    {#if item.exemptable}
      <li>
        <button on:click={(e) => exempt(item)} class="credit" type="button"
          >Credit Usage</button
        >
      </li>
    {/if}
    <li>
      <a
        class="copy"
        href={item.url}
        target="_blank"
        on:click|preventDefault={copyon}>Copy Link</a
      >
    </li>
    <!-- <a href="{permit.url}" target="_blank" type="text/html"></a> -->
  </ul>
</nav>
