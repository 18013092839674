<script lang="ts">
  import { format, toZonedTime } from "date-fns-tz";
  import { fetchRemoveNote } from ".";
  import { createEventDispatcher } from "svelte";

  export let item: Typed & any;
  export let readonly: boolean = true;

  let submitting = false;
  $: console.log("contact item = ", item);
  const events = createEventDispatcher<{
    removed: typeof item;
  }>();

  async function remove() {
    if (
      !confirm(
        "Are you sure you want to remove this note? This is permanent and cannot be undone."
      )
    )
      return;
    if (submitting) return;
    submitting = true;
    await fetchRemoveNote(item.id);
    submitting = false;
    events("removed", item);
  }
</script>

<!-- <article class="note {item.type}" data-id="{item.id}">
    <h1>
        <data class="{item.type}" value="{item.id}">Note</data>
    </h1> -->

<blockquote class="note {item.type}" data-id={item.id}>
  <p>{item.text}</p>
  <!-- {#each item.lines as line}
    <p>{line}</p>
    {/each} -->
  <cite>
    <time datetime={item.issued.datetime}
      ><abbr class="time"
        >{format(toZonedTime(item.issued.datetime, item.timezone), "h:mm a", {
          timeZone: item.getTimezoneOffset,
        })}</abbr
      >
      <abbr class="date"
        >{format(
          toZonedTime(item.issued.datetime, item.timezone),
          "MMM d yyyy",
          {
            timeZone: item.getTimezoneOffset,
          }
        )}</abbr
      >
      <abbr class="timezone"
        >{format(toZonedTime(item.issued.datetime, item.timezone), "zzz", {
          timeZone: item.getTimezoneOffset,
        })}</abbr
      >
      {#each [item.issued.by, item.issued.user]
        .filter((item) => item && item.id)
        .slice(0, 1) as user}
        <a href="mailto:{user.email}"
          ><data class="user {user.type}" value={user.id}
            ><dfn>by</dfn> {user.name}</data
          ></a
        >
      {/each}
    </time>
    {#if !readonly}
      <button
        type="button"
        disabled={submitting}
        class="remove"
        on:click={remove}>{submitting ? "removing…" : "remove"}</button
      >
    {/if}
  </cite>
</blockquote>
<!-- </article> -->
