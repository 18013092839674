<script context="module">
  import { time } from "$utils/timestores";
  import { tenantExternalCRMInfo } from "$utils/recordstores";
  import { externalCRM, units } from "$utils/propertystores";
</script>

<script>
  import Record from "$components/record/RecordItem.svelte";
  import { comparer, textAsc } from "$utils/sort";
  import ItemsList from "$components/item/ItemsList.svelte";
  import { onDestroy, onMount } from "svelte";
  import ResidentItem from "$components/ResidentItem.svelte";
  import VehicleItem from "$components/VehicleItem.svelte";
  import { route } from "$utils/router";
  import UnitFormatItem from "$components/unit/UnitFormatItem.svelte";
  import PropertySelectedMap from "$components/PropertySelectedMap.svelte";
  import RentableItem from "$components/rentable/RentableItem.svelte";
  import Time from "$components/Time.svelte";
  import Loading from "$components/Loading.svelte";
  import Info from "$components/Info.svelte";
  import ServiceSummaryItem from "$components/service/ServiceSummaryItem.svelte";
  import Warning from "$components/Warning.svelte";
  import UnitStatusTimelineItem from "$components/unit/UnitStatusTimelineItem.svelte";
  import ServiceConnectedRecordItem from "$components/service/ServiceConnectedRecordItem.svelte";
  import ServiceLogo from "$components/service/ServiceLogo.svelte";

  // this data we get externally
  export let id = null;
  export let property = null;
  export let item = null;
  export let data = null; // this is the fast/frequent loaded now data

  export let map = true;
  export let minimal = false;

  let attachments = {};
  $: if (id && !data) attachments = {}; // clear on change

  $: if (data?.attachments?.["for"][item?.id])
    attachments = data?.attachments?.["for"][item?.id];

  // $: console.log("tenant=", item);
  // $: console.log("details=", data);
  // $: console.log("$conflicts=", $conflicts);

  onMount(function () {
    window.scrollTo(0, 0);
  });
</script>

<section class="unit detail record" data-record="unit">
  <header>
    <h1>
      {#if item}
        <Record
          url={false}
          item={[item.subject, item].find((item) => item && item.id)}
        />
        <slot />
        <select
          name="unit"
          value={item.subject?.id ?? item.subject}
          on:change={(e) =>
            route(
              `/properties/${$units.items[e.target.value].scope}/units/${
                e.target.value
              }`
            )}
        >
          {#if $units}
            {#each Object.values($units.items).sort(comparer("display", textAsc)) as item}
              <option value={item.id}>{item.display}</option>
            {/each}
          {/if}
        </select>
      {:else}
        Loading&hellip;
      {/if}
    </h1>
    {#if item}
      {#if map && !minimal && property?.map}
        <PropertySelectedMap selected={item} />
      {/if}

      <dl>
        <UnitFormatItem {item} />
        <dt>Synced</dt>
        <dd>
          <Time datetime={data.generated} />
          <!-- <time datetime={data.generated}
            >{format(parseISO(data.generated), "MMM d yyyy h:mm a zzz")}</time
          > -->
        </dd>
      </dl>
    {/if}
  </header>

  {#if item}
    {#if $externalCRM?.enabled}
      <section class="crm">
        <header>
          <h1>Property Sync <ServiceLogo service={$externalCRM.service} /></h1>
          <!-- <ServiceTitle service={$externalCRM.service} /> -->
        </header>
        <ItemsList
          class="space service activity"
          items={[
            //$externalCRM.service,
            {
              type: "connected",
              //connected: $tenantExternalCRMInfo?.connected,
              service: $externalCRM.service,
              name: "unit",
              record:
                $tenantExternalCRMInfo?.status?.unit &&
                `Linked to unit ${$tenantExternalCRMInfo?.status?.unit}`,
              //name: `unit ${$tenantExternalCRMInfo?.status?.unit}`,
            },
            $tenantExternalCRMInfo?.status,
          ]}
          loading={false}
          context={{
            record: item.id,
          }}
          types={{
            connected: ServiceConnectedRecordItem,
            service: ServiceSummaryItem,
            unitstatus: UnitStatusTimelineItem,
          }}
        />
        {#if $tenantExternalCRMInfo}
          <!-- <ItemsList
            class="unit status activity"
            items={[
              $tenantExternalCRMInfo.service,
              $tenantExternalCRMInfo.status,
            ]}
            loading={false}
            context={{
              record: unit.id,
            }}
            types={{
              service: ServiceSummaryItem,
              unitstatus: UnitStatusItem,
            }}
          /> -->
          <ItemsList
            class="resident items"
            items={$tenantExternalCRMInfo.residents}
            loading={false}
            context={{
              record: item.id,
            }}
            types={{
              resident: ResidentItem,
            }}><Info message="No residents" /></ItemsList
          >

          <ItemsList
            class="vehicle items"
            items={$tenantExternalCRMInfo.vehicles}
            loading={false}
            context={{
              record: item.id,
            }}
            types={{
              unitvehicle: VehicleItem,
            }}
            highlight={{
              //unitvehicle: true,
            }}><Info message="No vehicles" /></ItemsList
          >
          <ItemsList
            class="rentable items"
            items={$tenantExternalCRMInfo.rentables}
            loading={false}
            context={{
              record: item.id,
            }}
            types={{
              rentable: RentableItem,
            }}
            highlight={{
              //rentable: true,
            }}><Info message="No rentables" /></ItemsList
          >
        {/if}
      </section>
    {/if}
  {/if}
</section>
