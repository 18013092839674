<script>
  import TimeInterval from "./TimeInterval.svelte";
  export let item;
  export let month = false;
  export let past = null;
</script>

<TimeInterval
  interval={item.interval}
  timezone={item.timezone}
  {month}
  past={past ?? item.past}
/>
