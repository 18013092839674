<script lang="ts" context="module">
  import { scaleTime, scaleLinear } from "d3-scale";
  //import { Temporal } from 'temporal-polyfill'
  import {
    addWeeks,
    endOfDay,
    isEqual,
    isWithinInterval,
    startOfDay,
  } from "date-fns";
  import { format as formatDate, toZonedTime } from "date-fns-tz";
  import { addDays } from "date-fns";
  import TimeNow from "./SVGTimeNow.svelte";
  import { parseToDateInterval } from "$utils/datetime";
  const calendar = {
    P1D: "day",
    P1W: "week",
    P1M: "month",
    P1Y: "year",
  };
</script>

<script lang="ts">
  export let interval: string = null;
  export let timezone: string = null;
  export let intervals: { [key: string]: number } = {};
  export let width = "100%";
  export let height = "100";
  let className: string = null;
  let formatValue = (val: number) => val.toString();
  export { className as class, formatValue as format };
  export let target: { minimum: number; per: string } = null;
  export let now: string = null;

  const scale = 80;

  $: nowlocal = now && toZonedTime(now, timezone);

  //$: target = item?.subject.enforcement?.target;

  $: parsedInterval = interval && parseToDateInterval(interval, timezone);

  //$: max = item.max;

  $: ({ dates, weeks } = Object.entries(intervals)
    .map(([interval, count]) => ({
      interval,
      count,
      per: null,
      ...parseToDateInterval(interval, timezone),
    }))
    .reduce(
      (results, i) => {
        if (i.interval == interval) return results; // overall value;
        if (isEqual(addDays(i.start, 1), i.end)) {
          i.per = "P1D";
          results.dates.push(i);
        } else if (isEqual(addWeeks(i.start, 1), i.end)) {
          i.per = "P1W";
          results.weeks.push(i);
        }

        // switch(i.per) {
        //     case "P1W":

        //         break;
        //     case "P1D":
        //         results[0].push(i);
        //         break;
        // }
        return results;
      },
      {
        dates: [],
        weeks: [],
      }
    ));

  $: ticks = weeks.map((i) => ({ per: i.per, tick: i.start }));

  $: average = Math.round(
    weeks.reduce((sum, i) => sum + i.count, 0) / weeks.length
  );
  $: max = Math.max(
    (target?.minimum || 0) * 1.5,
    average * 1.5,
    ...weeks.map((i) => i.count)
  );

  $: start = Math.min(
    ...weeks.map(({ start }) => start),
    parsedInterval.start as number
  );
  $: end = Math.max(
    ...weeks.map(({ end }) => end),
    parsedInterval.end as number
  );

  $: timescale = scaleTime().domain([start, end]).range([0, 100]);
  $: numscale = scaleLinear()
    .domain([max, 0])
    .range([0, scale - 4]);
</script>

<svg {width} height="100">
  <svg width="100%" height="80" class="values">
    <rect height="100%" width="100%" />
    {#if nowlocal && !isNaN(timescale(nowlocal))}
      <rect
        class="today"
        x="{timescale(startOfDay(nowlocal))}%"
        y="0%"
        height="100%"
        width="{timescale(endOfDay(nowlocal)) -
          timescale(startOfDay(nowlocal))}%"
      />
    {/if}
    {#each [...weeks, ...dates] as { start, end, per, count }}
      {#if count > 0}
        <rect
          rx="3"
          ry="3"
          class="{className} {calendar[per]} {per}"
          x="{timescale(start)}%"
          y={numscale(count)}
          height="200%"
          width="{timescale(end) - timescale(start)}%"
        />
      {/if}
    {/each}
  </svg>

  <g class="legend">
    {#each ticks as { tick, per }}
      {#if timescale(tick) >= 0}
        <line
          stroke-width="1"
          class="date {calendar[per]} {per}"
          class:min={timescale(tick) == 0}
          x1="{timescale(tick)}%"
          x2="{timescale(tick)}%"
          y1="0"
          y2="100%"
        />
        <text class="date {calendar[per]} {per}" x="{timescale(tick)}%" y="100%"
          >{formatDate(tick, "MMM d")}</text
        >
      {/if}
    {/each}
    <line class="date max" x1="100%" x2="100%" y1="0%" y2="100%" />
  </g>

  <svg width="100%" height="80" class="context">
    {#if nowlocal && !isNaN(timescale(nowlocal))}
      <TimeNow x="{timescale(nowlocal)}%" />
    {/if}

    {#if average}
      <line
        stroke-width="2"
        class="average"
        x1="0"
        x2="100%"
        y1={numscale(average)}
        y2={numscale(average)}
      />
      <text class="average" x="0%" y={numscale(average)} y2={numscale(average)}
        >Average {formatValue(average)} per week</text
      >
    {/if}
    {#if target?.minimum}
      <line
        stroke-dasharray="4 2"
        stroke-width="2"
        class="minimum"
        x1="0"
        x2="100%"
        y1={numscale(target.minimum)}
        y2={numscale(target.minimum)}
      />
      <text
        class="minimum"
        x="100%"
        y={numscale(target.minimum)}
        y2={numscale(target.minimum)}
        >Target {formatValue(target.minimum)} per {calendar[target.per] ||
          target.per}</text
      >
    {/if}
  </svg>
</svg>
