<script lang="ts">
  export let property: Property;
</script>

<ol class="promos">
  <li>
    <article class="promo">
      <img src="/bookable-amenities.png" />

      <h1>Bookable Amenities</h1>

      <p>Promote fair usage of your most popular amenities.</p>
      <a
        target="_blank"
        href="https://communityboss.com/{property.format}/amenity-boss"
      >
        Learn More
      </a>
    </article>
  </li>
  <li>
    <article class="promo">
      <img src="/pool-passes.png" />

      <h1>Pool Passes</h1>
      <p>The simple way to control your overcrowded pools.</p>
      <a
        target="_blank"
        href="https://communityboss.com/{property.format}/amenity-boss"
      >
        Learn More
      </a>
    </article>
  </li>
  <li>
    <article class="promo">
      <img src="/rentable-spaces.png" />

      <h1>Rentable Spaces</h1>

      <p>Make it easy to reserve and pay for your rentable spaces.</p>

      <a
        target="_blank"
        href="https://communityboss.com/{property.format}/amenity-boss"
      >
        Learn More
      </a>
    </article>
  </li>
  <li>
    <article class="promo">
      <img src="/bike-tracking.png" />
      <h1>Bike Tracking</h1>
      <p>Keep track of resident bikes and other stored equipment.</p>
      <a
        target="_blank"
        href="https://communityboss.com/{property.format}/amenity-boss"
      >
        Learn More
      </a>
    </article>
  </li>
  <li>
    <article class="promo">
      <img src="/smart-access.png" />

      <h1>Smart Access</h1>
      <!-- Grant access only during reservation time
      Keep amenities secure when not in use -->
      <p>Integrated access control to keep secure when not in use.</p>

      <a
        target="_blank"
        href="https://communityboss.com/{property.format}/amenity-boss"
      >
        Learn More
      </a>
    </article>
  </li>
</ol>
