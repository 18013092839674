<script context="module">
  import { scaleTime, scaleLinear } from "d3-scale";
  import {
    addSeconds,
    addWeeks,
    areIntervalsOverlapping,
    differenceInDays,
    eachDayOfInterval,
    eachWeekOfInterval,
    endOfDay,
    isEqual,
    isWithinInterval,
    startOfDay,
    startOfWeek,
  } from "date-fns";
  import { format, toZonedTime } from "date-fns-tz";
  import addDays from "date-fns/addDays";
  import range from "lodash-es/range";
  import { time } from "$utils/timestores";
  import { stringToDateInterval } from "./item/ItemsList.svelte";
  import TimeNow from "./svg/SVGTimeNow.svelte";
  const calendar = {
    P1D: "day",
    P1W: "week",
    P1M: "month",
    P1Y: "year",
  };
  const nowutc = time({ minutes: 30 });
</script>

<script>
  import { isSameDay } from "date-fns";

  const tickCount = 5;

  export let item;

  //$: console.log("item=", item);

  let dateformat = "MMM d";

  const scale = 80;

  $: nowlocal = toZonedTime($nowutc, item?.timezone);

  $: interval =
    item && stringToDateInterval(item.interval || item.valid, item.timezone);

  $: intervals = Object.values(item?.values || {})
    .map((interval) => ({
      interval,
      ...stringToDateInterval(interval, item.timezone),
    }))
    .filter((value) => areIntervalsOverlapping(value, interval));

  $: if (differenceInDays(nowlocal, start) < 8) dateformat = "eee";

  // $: [ dates, weeks ] = intervals.reduce((results, i) => {

  //     if(i.interval == (item.interval || item.valid)) return results; // overall value;
  //     if(isEqual(addDays(i.start, 1), i.end)) {
  //         i.per = "P1D";
  //         results[0].push(i);
  //     } else if(isEqual(addWeeks(i.start, 1),i.end)) {
  //         i.per = "P1W";
  //         results[1].push(i);
  //     }
  //     return results;
  // }, [
  //     [],
  //     []
  // ]);

  // $: average = Math.round(weeks.reduce((sum,  i ) => isWithinInterval(nowlocal, i) ? sum : sum + i.count, 0) / (weeks.filter(w => !isWithinInterval(nowlocal, w)).length));
  // $: max = Math.max((target?.minimum || 0) * 1.5, average * 1.5, ...weeks.map(i => i.count));

  $: start = Math.min(interval.start);
  $: end = Math.max(addSeconds(interval.end, 1));

  $: timescale = scaleTime().domain([start, end]).range([0, 100]);

  // $: console.log(
  //   "interval=",
  //   interval,
  //   new Date(start),
  //   new Date(end),
  //   differenceInDays(end, start)
  // );

  $: ticks =
    differenceInDays(end, start) < 8
      ? eachDayOfInterval(interval)
      : isEqual(start, startOfWeek(start))
        ? eachWeekOfInterval(interval)
        : range(start, end, (end - start) / tickCount);

  // $: console.log("ticks=", ticks);

  //$: numscale = scaleLinear().domain([ max, 0 ]).range([ 0, scale - 4 ]);
</script>

<figure class="chart">
  <svg height="52" width="100%">
    <svg class="values" height="32" width="100%">
      <rect width="100%" height="100%" />
      {#if nowlocal}
        <rect
          class="today"
          data-datetime={item.interval}
          x="{timescale(startOfDay(nowlocal))}%"
          y="0%"
          height="100%"
          width="{timescale(endOfDay(nowlocal)) -
            timescale(startOfDay(nowlocal))}%"
        />
      {/if}
      {#each intervals as item}
        <rect
          class="session"
          data-datetime={item.interval}
          x="{timescale(item.start)}%"
          y="0%"
          height="100%"
          width="{timescale(item.end) - timescale(item.start)}%"
        />
      {/each}
    </svg>
    <g class="legend" data-datetime={item.valid}>
      {#each ticks as tick}
        <line
          class="date"
          class:min={0 == timescale(tick)}
          x1="{timescale(tick)}%"
          x2="{timescale(tick)}%"
          y1="0%"
          y2="100%"
        />

        <text
          data-datetime={new Date(tick).toISOString()}
          class="date min"
          class:today={dateformat === "eee" &&
            nowlocal &&
            isSameDay(tick, nowlocal)}
          x="{timescale(tick)}%"
          y="100%"
          >{dateformat === "eee" && nowlocal && isSameDay(tick, nowlocal)
            ? "Today"
            : format(tick, dateformat)}</text
        >
      {/each}
      <line class="date max" x1="100%" x2="100%" y1="0%" y2="100%" />
    </g>

    <svg class="context" height="32" width="100%">
      <TimeNow x="{timescale(nowlocal)}%" />
    </svg>
  </svg>
</figure>
