<script lang="ts">
  import PropertyMap from "$components/PropertyMap.svelte";
  import MapMarker from "$components/map/MapMarker.svelte";
  import MapWebGl from "$components/map/MapWebGL.svelte";
  import type { FeatureCollection } from "geojson";
  import type { Readable } from "svelte/motion";

  export let property: Property;
  export let features: FeatureCollection;
</script>

<section class="signage">
  {#if property}
    <slot />
    <PropertyMap {property}>
      {#each features.features as feature}
        <MapMarker {feature} anchor="left">
          <h1>{feature.properties?.inscription || ""}</h1>
        </MapMarker>
      {/each}
    </PropertyMap>
  {/if}
</section>
