<script>
import identity from "lodash-es/identity";


    export let contact;

    $: emails = Object.values(contact.emails || [ contact.email ]).filter(identity);
</script>
{#if contact}
<address class="contact">
    <dl>
        {#if contact.name}
        <dt>Name</dt><dd>{contact.name}</dd>
        {/if}
        {#if emails.length}
        <dt>Email</dt>
        {#each emails as email}
            <dd><a href="mailto:{contact.email}">{contact.email}</a></dd>
        {/each}
        {/if}
        {#if contact.phone}
        <dt>Phone</dt>
        <dd><a href="tel:">{contact.phone}</a></dd>
        {/if}
    </dl>
    
  </address>
{/if}