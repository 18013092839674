<script context="module">
  import { time } from "$utils/timestores";
  const now = time({ seconds: 60 });
</script>

<script>
  import Record from "$components/record/RecordItem.svelte";
  import { comparer, dateAsc, dateDesc } from "$utils/sort";
  import { addYears, isSameDay, parse, parseISO } from "date-fns";
  import identity from "lodash-es/identity";
  import { title as proper } from "$utils/text";
  import ItemsList from "$components/item/ItemsList.svelte";
  import { onDestroy, onMount } from "svelte";
  import { permits, spaceExternalCRMInfo, policies } from "$utils/recordstores";
  import CountSummaryItem from "$components/CountSummaryItem.svelte";
  import PropertySelectedMap from "$components/PropertySelectedMap.svelte";
  import Time from "$components/Time.svelte";
  import Loading from "$components/Loading.svelte";
  import RentableItem from "$components/rentable/RentableItem.svelte";
  import RecordDetailsNotesFiles from "$components/RecordDetailsNotesFiles.svelte";
  import {
    externalCRM,
    propertyServices,
    pricingFor,
  } from "$utils/propertystores";
  import ServiceSummaryItem from "$components/service/ServiceSummaryItem.svelte";
  import SpaceInfoItem from "$components/space/SpaceInfoItem.svelte";
  import ServiceConnectedRecordItem from "$components/service/ServiceConnectedRecordItem.svelte";
  import ServiceLogo from "$components/service/ServiceLogo.svelte";
  import EntryCapabilityItem from "$components/EntryCapabilityItem.svelte";
  import RecordPermitPolicies from "$components/permit/RecordPermitPolicies.svelte";

  // this data we get externally
  export let id = null;
  export let property = null;
  export let item = null;
  export let data = null; // this is the fast/frequent loaded now data
  export let minimal = false;

  let date = null;

  $: timezone = property && property.timezone;

  $: nowutc = $now;

  $: parking = "parking" === item?.format;

  //$: console.log("external=", $spaceExternalCRMInfo);

  // $: console.log("space=", item);
  //$: console.log("details=", data);

  let attachments = {};
  $: if (id && !data) attachments = {}; // clear on change

  $: if (data?.attachments?.["for"][item?.id])
    attachments = data?.attachments?.["for"][item?.id];

  $: entry = data ? data?.entry?.["for"]?.[item?.id] ?? { items: {} } : null;
  $: if (entry?.items)
    Object.values(entry.items).forEach((entry) => {
      entry.provider =
        $propertyServices?.items[entry.provider] ?? entry.provider;
    });
  //$: console.log("entry=", entry, $propertyServices);

  $: activeRecentUpcomingPermits = data?.permits; // loads active + future
  //$: notes = data?.notes[id]; // extract notes (loads all history)
  $: permitHistory = $permits; // loads the last year

  //$: console.log("donotpermit=", donotpermit);

  $: active = Object.values(activeRecentUpcomingPermits?.items || {})
    .filter(
      (p) =>
        !p.cancelled &&
        !p.expired &&
        Math.max(nowutc, Date.now()) > parseISO(p.valid.min.datetime) &&
        !(p.valid.max && parseISO(p.valid.max.datetime) < nowutc)
    )
    .sort((a, b) =>
      dateAsc(
        a.valid.max?.datetime || addYears(new Date(), 100),
        b.valid.max?.datetime || addYears(new Date(), 100)
      )
    );

  $: upcoming = Object.values(activeRecentUpcomingPermits?.items || {})
    .filter(
      (p) =>
        !p.cancelled &&
        !p.expired &&
        Math.max(nowutc, Date.now()) < parseISO(p.valid.min.datetime)
    )
    .sort(comparer("valid.min.datetime", dateAsc));

  // aggregate fast and long past permits
  $: past = Object.values(
    Object.assign(
      {},
      permitHistory?.items || {},
      activeRecentUpcomingPermits?.items || {}
    )
  )
    .filter(
      (p) =>
        p.cancelled ||
        p.expired ||
        (p.valid.max &&
          Math.max(nowutc, Date.now()) > parseISO(p.valid.max.datetime))
    )
    .sort(comparer("valid.max.datetime", dateDesc));

  onMount(function () {
    window.scrollTo(0, 0);
  });

  onDestroy(function () {});

  // function groupNotes(notes) {
  //   if (!notes) return notes;
  //   return notes.sort(comparer("issued.datetime", dateDesc));
  //   let prev;
  //   return notes
  //     .sort(comparer("issued.datetime", dateDesc))
  //     .reduce((list, item) => {
  //       const local = toZonedTime(item.issued.datetime, item.timezone);
  //       if (!prev || !isSameDay(prev, local)) {
  //         prev = local;
  //         list.push({
  //           type: "date",
  //           date: item.issued.datetime,
  //           timezone: item.timezone,
  //         });
  //       }
  //       list.push(item);
  //       return list;
  //     }, []);
  // }
</script>

<section class="space detail record" data-record="space">
  <header>
    <h1>
      {#if item}
        <Record
          url={false}
          item={[item.subject, item].find((item) => item && item.id)}
        />
        <slot />
      {:else}
        Loading&hellip;
      {/if}
    </h1>
    {#if item}
      <!-- <time data-valid="PT5M"></time>	 -->
      {#if !minimal && property?.map}
        <PropertySelectedMap selected={item} />
      {/if}

      <dl>
        {#if item.format}
          <dt>Type</dt>
          <dd>
            <!-- {#if "storage" === item.format && item.storage}
                <data class="storage" value={item.storage}
                  >{proper(item.storage)}</data
                >
              {/if} -->
            <data class="format" value={item.format}
              >{proper(item.format)}
            </data>
          </dd>
        {/if}
        {#if item.storage}
          <dt>Storage</dt>
          <dd>
            <data class="storage" value={item.storage}
              >{proper(item.storage)}</data
            >
          </dd>
        {/if}
        {#if item.color}
          <dt>Color</dt>
          <dd>
            <data class="color" value={item.color.toLowerCase()}
              >{item.color}</data
            >
          </dd>
        {/if}

        {#if item.capacity != 1}
          <dt>Capacity</dt>
          <dd>{item.capacity}</dd>
        {/if}
        <dt>Pricing</dt>
        {#each Object.values($pricingFor?.[item.id]?.items ?? {}) as item}
          <dd>
            <data class="price" value={item.id}>{item.description}</data>
          </dd>
        {:else}
          <dd>
            {#if null == $pricingFor}<Loading />{:else}None configured{/if}
          </dd>
        {/each}

        <dt>Synced</dt>
        <dd>
          <Time datetime={data.generated} />
        </dd>
      </dl>
    {/if}
  </header>
  {#if item}
    {#if $externalCRM?.enabled}
      <section class="crm">
        <header>
          <h1>Property Sync <ServiceLogo service={$externalCRM.service} /></h1>
          <!-- <ServiceTitle service={$externalCRM.service} /> -->
        </header>
        <ItemsList
          class="space service activity"
          items={[
            //$externalCRM.service,
            {
              type: "connected",
              service: $externalCRM.service,
              name: "rentable",
              record: $spaceExternalCRMInfo?.connected && `Linked to rentables`,
            },
          ]}
          loading={false}
          context={{
            record: item.id,
          }}
          types={{
            connected: ServiceConnectedRecordItem,
            service: ServiceSummaryItem,
          }}
        />
        {#if $spaceExternalCRMInfo}
          <!-- <ServiceLinkedSummaryItem
            title="Synced from"
            item={$spaceExternalCRMInfo.service}
          /> -->

          <ItemsList
            class="rentables activity"
            items={$spaceExternalCRMInfo.rentables}
            types={{
              rentable: RentableItem,
            }}
            context={{
              record: id,
            }}
          />
        {:else if !$spaceExternalCRMInfo}
          <Loading message="Loading synced info" />
        {/if}
      </section>
    {/if}
    <section class="permits">
      <header>
        <h1>
          {#if parking}{proper(item.format)}{:else}Activity{/if}
        </h1>
        <nav>
          <ul>
            <li>
              <RecordPermitPolicies
                record={item}
                {property}
                policies={$policies?.filter(
                  (policy) => !!policy?.audience?.admin
                )}
              />
            </li>
          </ul>
        </nav>

        <!-- {#each $policies.filter((p) => p.amenity !== "parking" && p.audience.admin && p.tenant.request) as policy}
              <data value={policy.policy}>{policy.title}</data>
            {/each} -->
      </header>
      <nav />
      <ItemsList
        class="items"
        items={[
          active?.length === 0 && {
            type: "permits",
            title: "Current Status",
            state: "active",
            count: active.length,
            zero: "No Active Permits",
            datetime: activeRecentUpcomingPermits?.generated,
            timezone: activeRecentUpcomingPermits?.timezone,
          },
          ...active,
        ].filter(identity)}
        types={{
          permits: CountSummaryItem,
        }}
        highlight={{
          permit: true,
        }}
      />
      <ItemsList class="info" items={upcoming} types={{}}>
        <!-- <figure class="empty parking permits">No upcoming parking</figure> -->
      </ItemsList>
      <ItemsList
        class="info"
        items={[
          permitHistory && {
            type: "permits",
            title: "Recent",
            count: permitHistory && past.length,
            state: "history",
            //zero: "No Permits",
            interval: permitHistory?.valid,
            timezone: timezone,
            past: {
              P7D: "past week",
              P30D: "past month",
              P365D: "past year",
            },
          },
          ...past,
        ]}
        types={{
          permits: CountSummaryItem,
        }}
        full={{
          permits: true,
        }}
      />
    </section>

    <section class="notes">
      <header>
        <h1>Notes & Info</h1>
      </header>
      <RecordDetailsNotesFiles {item} {attachments} />
      <ItemsList
        class="entry activity"
        items={entry && Object.values(entry.items)}
        types={{
          entry: EntryCapabilityItem,
        }}
      />
      <ItemsList
        class=""
        items={[item]}
        types={{
          space: SpaceInfoItem,
        }}
      />
    </section>
    <!-- <section class="usage">
        <header><h1>Parking History</h1></header>
        <ItemsList class="items" items={[ {
            type:"permits",
            title:"Parking History",
            count: permitHistory && past.length,
            interval: permitHistory?.valid,
            timezone: timezone,
        }, ...past]} types={
            {
                "permits":CountSummaryItem
            }
        } />
    </section> -->
  {/if}
</section>
