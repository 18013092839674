<script>
import startCase from "lodash-es/startCase";
export let item = null;
//$: console.log(item);
</script>
{#if item}
<aside class="violations alert" class:exception={item.active} data-id="{item.id}">
    {#if item.active}
    <h1>
        <data value="{item.id}"><dfn>{startCase(item.title.toLowerCase())}</dfn></data>
    </h1>
    {/if}
</aside>
{/if}