<script>
  import { format, thumbnail } from "$utils/filepreview";
  import { comparer, dateAsc, dateDesc } from "$utils/sort";
  import { upload } from "$utils/upload";
  import FileForItem from "./FileForItem.svelte";

  export let item = null;
  export let create = false;

  $: items = item?.attached?.items ?? item?.items ?? {};
  //$: console.log("images=", items);

  $: files = Object.values(uploading)
    .filter((i) => !!i)
    .reverse()
    .concat(
      Object.values(items)
        .filter((i) => i.type == "file")
        .sort(comparer("created", dateDesc))
    );

  //$: console.log("item=", item);

  let uploading = {};

  export function creating(e) {
    var item = e.detail;
    if (item?.type != "file") return;
    uploading[item.url] = item;
  }
  export function created(e) {
    var item = e.detail;
    if (item?.type != "file") return;
    if (item.local) {
      delete uploading[item.url];
      uploading = uploading;
    } else {
      //console.log("about to store item=", item);
      items[item.id] = item;
      items = items;
    }
  }
</script>

{#if item}
  {#if create || files?.length}
    <ul class="images">
      {#if create}
        <li>
          <FileForItem {item} on:creating={creating} on:created={created} />
        </li>
      {/if}
      {#each files as file}
        <li>
          <figure class="image" class:uploading={file.local}>
            <a
              download={file.name}
              href={format(file.url)}
              type={file.format}
              target="_blank"
              ><img
                alt="thumbnail"
                loading="lazy"
                src={thumbnail(file, 80, 80, true)}
              /></a
            >
          </figure>
        </li>
        <!-- {:else}
      <li />
      <li />
      <li /> -->
      {/each}
      <!-- {#each Object.values(item.attached?.items || item.attachments?.items || item.files || item.attachments || [])
      .filter((i) => i.type == "file")
      .sort(comparer("created", dateDesc)) as file}
      <li>
        <figure class="image">
          <a href={file.url} target="_blank"
            ><img loading="lazy" src={thumbnail(file.url, 80, 80, true)} /></a
          >
        </figure>
      </li>
    {/each} -->
    </ul>
  {/if}
{/if}
