<script lang="ts">
  import RecordItem from "$components/record/RecordItem.svelte";

  export let item = null;
  export let record: string | null = null;
  console.log("rentable=", item);
  $: rentedToRecord =
    record &&
    (record == (item.by?.id ?? item.by) ||
      (item.by?.length && item.by.find((i) => i.id === record)));
</script>

<article class="rentable details">
  <header>
    <h1><data value="false">No linked record</data></h1>
  </header>
  <dl>
    <dt>Rentable</dt>
    <dd><data class="rentable" value={item.id}>{item.name}</data></dd>
    <dt>Type</dt>
    <dd>
      {[item.category.name, item.category.description]
        .filter((i) => !!i)
        .join(" • ")}
    </dd>
    {#if !rentedToRecord}
      <dt>Rented to</dt>
      <dd>
        {#if item.by?.length}<ul>
            {#each item.by as item}<li><RecordItem {item} /></li>{/each}
          </ul>{/if}
      </dd>
    {/if}
    <!-- <dt>Source</dt>
    <dd>{item.service.name}</dd> -->
  </dl>
  <!-- <dl>
    <dt>Type</dt>
    <dd>{item.rentableType.description}</dd>
    <dt>Name</dt>
    <dd>{item.name}</dd>
    <dt>Category</dt>
    <dd>{item.rentableType.name}</dd>
  </dl> -->
</article>
