<script context="module">
  import orderBy from "lodash-es/orderBy";
  import { param } from "$utils/params";
  import { autocreate as autocreateVehicle, plate } from "$utils/vehicle";

  import {
    units,
    vehicles,
    media,
    spaces,
    propertyId,
  } from "$utils/propertystores";
  import MiniSearch from "minisearch";
  import { derived } from "svelte/store";
  import Record from "$components/record/RecordItem.svelte";

  const search = param("lookup");

  var types = {
    vehicle: {
      autocreate: autocreateVehicle,
    },
  };

  const normalize = {
    vehicle: function (query) {},
  };

  const searchable = {
    unit: true,
    tenant: false,
    vehicle: true,
    media: true,
    space: true,
  };

  const sources = {
    vehicle: vehicles,
    unit: units,
    media: media,
    space: spaces,
  };

  const indexes = {
    vehicle: new MiniSearch({
      fields: ["key", "display"],
      searchOptions: {
        prefix: true,
      },
      // storeFields: [
      //     "id",
      //     "addr:unit",
      //     "name",
      //     "display"
      // ],
      extractField: (document, fieldName) =>
        fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
    }),
    unit: new MiniSearch({
      fields: [
        "addr:unit",
        "addr:housenumber",
        "addr:street",
        // "name",
        "display",
      ],
      searchOptions: {
        prefix: true,
        fuzzy: true,
        boost: { name: 2 },
      },
      // storeFields: [
      //     "id",
      //     "addr:unit",
      //     "name",
      //     "display"
      // ],
      extractField: (document, fieldName) =>
        fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
    }),
    space: new MiniSearch({
      fields: ["display"],
      searchOptions: {
        prefix: true,
        fuzzy: true,
        //boost: { name: 2 },
      },
      // storeFields: [
      //     "id",
      //     "name",
      //     "display"
      // ],
      extractField: (document, fieldName) =>
        fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
    }),
    media: new MiniSearch({
      fields: ["display"],
      searchOptions: {
        prefix: true,
        fuzzy: true,
        //boost: { name: 2 },
      },
      // storeFields: [
      //     "id",
      //     "name",
      //     "display"
      // ],
      extractField: (document, fieldName) =>
        fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
    }),
  };

  const searchers = {};

  // build a set of derived searchers
  for (const [type, index] of Object.entries(indexes)) {
    if (searchers[type]) continue;

    // setup indexing
    searchers[type] = derived(
      [sources[type]],
      ([$source], set) => {
        //index.removeAll(); // clear

        // how were we getting duplicates??

        // asyncify?

        // rebuild, use default searchable pass

        if ($source) {
          for (const item of Object.values($source.items || {})) {
            if (index.has(item.id)) index.replace(item);
            else index.add(item);
          }
          // await index.addAllAsync(
          //   Object.values($source.items || {}).filter(
          //     (item) => !index.has(item.id)
          //   )
          // );
        }

        set(index);
      },
      index
    );
  }

  const resultsByType = {
    vehicle: derived(
      [search, searchers.vehicle, sources.vehicle],
      ([$query, $index, $state], set) => {
        // if query changes, will throw this away
        if (!$query || typeof $query != "string") return set(null); // nothing to search for

        const normalized = plate($query);

        if (!normalized) {
          return set({
            type: "vehicle",
            query: $query,
            items: [],
          });
        }

        const searched = $index.search(normalized).reduce((results, result) => {
          var item = $state.items[result.id];
          if (!item) return results;
          if (results[item.id]) return results; // duplicate

          results[item.id] = Object.assign(result, {
            item,
          });

          return results;
        }, {});

        // we have our results
        set({
          type: "vehicle",
          query: $query,
          items: searched,
        });
      }
    ),
  };

  // build basic results;
  for (const [type, index] of Object.entries(searchers)) {
    if (resultsByType[type]) continue;

    // setup indexing
    resultsByType[type] = derived(
      [search, index, sources[type]],
      ([$query, $index, $source], set) => {
        // if query changes, will throw this away
        if (!$query || typeof $query != "string") return set(null); // nothing to search for

        const normalized = $query;

        if (!normalized)
          return set({
            type,
            query: $query,
            items: [],
          });

        // results
        const searched = $index.search(normalized).reduce((results, result) => {
          var item = $source.items[result.id];
          if (!item) return results;
          if (results[item.id]) return results; // duplicate

          results[item.id] = Object.assign(result, {
            item,
          });

          return results;
        }, {});

        // we have our results
        set({
          type,
          query: $query,
          items: Object.values(searched),
        });
      }
    );
  }

  function label(result) {
    return result.item["addr:unit"] || result.item.name || result.item.display;
  }

  function sortByType(result) {
    switch (result.item.type) {
      case "unit":
        return 2;
      case "space":
        return 1;
      default:
        return 0;
    }
  }

  const results = derived(
    [propertyId, search, ...Object.values(resultsByType)],
    ([$scope, $query, ...results]) => {
      // console.log(
      //   "results=",
      //   results.flatMap((r) => r?.items || [])
      // );

      if (!$query) return [];

      var items = results.reduce((results, result) => {
        if (result?.items) results.push(...Object.values(result.items));
        return results;
      }, []);

      //console.log("search items=", results, items);

      // auto create
      let autoCreated = autocreateVehicle($query, $scope);

      if (autoCreated) {
        items.push({
          score: Math.max(
            items.reduce((max, item) => Math.max(max, item.score), 0),
            5.5
          ),
          item: autoCreated,
        });
      }

      return orderBy(
        items,
        ["score", sortByType, label],
        ["desc", "desc", "asc"]
      ).map((result) => result.item);
      // sort by score, display
    }
  );

  //results.subscribe($results => console.log("$results=", $results));
</script>

<script>
  $: showResults = !!$search;
</script>

{#if showResults && $results.length}
  <ul class="lookup results">
    {#each $results as item}
      <li><Record {item} /></li>
    {/each}
  </ul>
{/if}
