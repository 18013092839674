export function autocreate(key, scope) {
  key = plate(key);
  if (!key) return null;
  if (key.length < 2) return null;
  return {
    scope,
    type: "vehicle",
    id: key,
    key: key,
    display: key,
  };
}

export function plate(key) {
  if (!key || typeof key !== "string") return null;
  key = key
    .toUpperCase()
    .replace(/[^A-Z0-9]/gi, "")
    .replace("O", "0");
  if (key.length < 2) return null;
  if (key.length > 8) return null;
  return key;
}
