<script lang="ts" context="module">
  function enforcefeature(item: any): Feature {
    console.log(
      "enforcefeature=",
      item,
      item.present.item.position.lon,
      item.present.item.position.lat
    );
    if (!item.present.item.position.lon || !item.present.item.position.lat)
      return null;
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          item.present.item.position.lon,
          item.present.item.position.lat,
        ],
      },
      id: item.present.item.id,
      properties: {
        type: "enforce",
        vehicle: item.vehicle.display ?? item.vehicle,
      },
    };
  }
</script>

<script lang="ts">
  import MapLevelSelect from "$components/MapLevelSelect.svelte";
  import PropertyMap from "$components/PropertyMap.svelte";
  import MapMarker from "$components/map/MapMarker.svelte";
  import VehiclesPresentHeatmap from "./VehiclesPresentHeatmap.svelte";
  import { type FeatureCollection, type Feature } from "$components/map";
  import { autocreate } from "$utils/vehicle";
  import RecordItem from "$components/record/RecordItem.svelte";
  import { param } from "$utils/params";

  export let enforce: any;

  export let theme = "cool";

  let level: string = "outside";
  let levels: { [key: string]: any } = {};
  let usesatellite = false;
</script>

{#if enforce}
  <PropertyMap
    theme={usesatellite ? "satellite" : theme || "cool"}
    property={enforce.scope}
    {level}
    on:level={(e) => (level = e.detail)}
    bind:levels
    interactive={true}
    overlay
  >
    <!-- {#each fc.features as feature}
        <MapMarker
          {feature}
          on:click={(e) =>
            route(
              `/properties/${e.detail.properties.scope}/vehicle/${e.detail.properties.vehicle}`
            )}
          ><RecordItem
            item={autocreate(
              feature.properties.vehicle,
              feature.properties.scope?.id ?? feature.properties.scope
            )}
          /></MapMarker
        >
      {/each} -->

    <VehiclesPresentHeatmap present={enforce.present} />

    <!-- <VehiclesEnforceMapPoints features={fc} /> -->
    {#each [enforce]
      .map((enforce) => [enforce, enforcefeature(enforce)])
      .filter(([item, feature]) => Boolean(feature)) as [item, feature]}
      <MapMarker {feature}>
        <data
          class="enforce"
          value={item.permitted
            ? "permitted"
            : item.immobilize
              ? "immobilize"
              : item.remove
                ? "remove"
                : Object.values(item.warned.items).length
                  ? "rewarn"
                  : "warn"}
        >
          <RecordItem
            url={false}
            item={typeof item.vehicle == "string"
              ? autocreate(item.vehicle, item.scope)
              : item.vehicle}
          />
        </data>
      </MapMarker>
    {/each}
    <form>
      <fieldset>
        <input
          type="checkbox"
          name="style"
          value="satellite"
          on:change={({ currentTarget: target }) =>
            (usesatellite = target.checked)}
        />
        <MapLevelSelect {levels} bind:level />
      </fieldset>
    </form>
  </PropertyMap>
{/if}
