<script lang="ts">
  import NavTo from "$components/NavTo.svelte";
  import { smscreen } from "$utils/behaviorstores";
  import { view } from "$utils/uistores";
  import { help } from "$utils/help";

  export let active: boolean = false;
</script>

<nav class="app" class:active={true === active}>
  <ul class="primary">
    <li>
      <NavTo selected={$view == "properties"} url="/properties"
        >Properties</NavTo
      >
    </li>
    <!-- <li>
      <NavTo selected={$view == "propertiesusers"} url="/properties/users"
        >Teams</NavTo
      >
    </li>
    <li>
      <NavTo selected={$view == "propertiespayments"} url="/properties/payments"
        >Payments</NavTo
      >
    </li> -->
  </ul>
  <ul class="system ancillary">
    <li>
      <a href="/help" on:click={help}>Get Help</a>
    </li>
    <li>
      <a href="https://my.communityboss.app/user">Account</a>
    </li>
    <!-- <li>
          <a href="https://updates.parkingboss.com" target="_blank">Updates</a>
      </li> -->
    <li>
      <a href="/logout">Log out</a>
    </li>
    <!-- <li>
          <a href="mailto:help@communityboss.com" rel="rt" data-unread="0"></a>
      </li> -->
  </ul>
</nav>
