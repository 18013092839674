<script lang="ts" context="module">
  let i = 0;
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let label = "Parking";
  export let policies = [];
  export let policy: any = null;
  export let blank: string = "";
  const id = `policy-${i++}`;

  //$: if (!policy && policies.length) policy = policies[0];

  $: itemsById = policies.reduce((map, item) => {
    map[item.subject || item.title] = item;
    return map;
  }, {});

  const events = createEventDispatcher<{
    change: {
      name: string;
      value: string;
    };
  }>();

  function change(name: string, newvalue: Policy) {
    if (newvalue === policy) return;
    policy = newvalue;
    if (name)
      events("change", {
        name,
        value: policy,
      });
  }
</script>

{#if label}
  <fieldset>
    <label for={id}>{label}</label>
    <data value={policy?.subject || ""}>{policy?.title || ""}</data>
    <select
      {id}
      value={policy?.subject || policy?.title || ""}
      on:change={(e) => change("policy", itemsById[e.target.value])}
    >
      {#if blank}
        <option value="">{blank}</option>
      {/if}
      {#each policies as item}
        <option value={item.subject || item.title}>{item.title}</option>
      {/each}
    </select>
    <!-- <ul>
    {#each policies as item}
      <li><input type="radio" name="policy" value={item.id} /> {item.title}</li>
    {/each}
  </ul> -->
  </fieldset>
{:else}
  <select
    {id}
    value={policy?.subject || policy?.title}
    on:change={(e) => change("policy", itemsById[e.target.value])}
  >
    {#if blank}
      <option value="">{blank}</option>
    {/if}
    {#each policies as item}
      <option value={item.subject || item.title}>{item.title}</option>
    {/each}
  </select>
{/if}
