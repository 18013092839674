import { derived, type Readable } from "svelte/store";
import { format, parseISO } from "date-fns";
import { toZonedTime, format as formatTZ } from "date-fns-tz";

export function toZoneISOString(local: string, tz: string) {
  return formatTZ(local, "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: tz });
}

export function timeLocal(store: Readable<string>, tz: string) {
  return derived(store, (value) => toZonedTime(value, tz));
}

export function utcToZoned(value: string, timezone: string) {
  return toZonedTime(value, timezone);
}

export function display(date: Date) {
  return format(date, "EE MMM d yyyy h:mm a");
}

export function iso8601Offset(date: Date) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
}
export function iso8601(date: Date | null) {
  if (!date) return "";
  return date.toISOString();
}

type Interval = { start: Date; end: Date };
type Dates = [Date, Date];
type DateParser = (str: string) => Date;

export const stringToInterval = function (
  interval: string | Interval,
  parser?: DateParser
): Interval {
  if (typeof interval !== "string") return interval;

  const [start, end] = interval
    .split("/")
    .map((d) => (!!parser ? parser(d) : new Date(d)));
  return { start, end };
};

export const stringToDates = function (
  interval: string | Dates,
  parser?: DateParser
): Dates {
  if (typeof interval !== "string") return interval;

  const int = interval
    .split("/")
    .map((d) => (!!parser ? parser(d) : new Date(d)));

  if (int.length != 2) {
    console.error("Invalid Interval String", interval);
    throw new Error("Invalid Interval String!");
  }

  return int as Dates;
};

export function isDate(x: any): x is Date {
  return Boolean(x.toISOString);
}

function minMaxDate(
  input: string | Date[] | null,
  f: (...x: number[]) => number
): Date | null {
  if (!input) return null;
  if (isDate(input)) return input;
  if (typeof input == "string")
    return new Date(f(...stringToDates(input).map((x) => +x)));
  return null;
}

export const maxDate = (input: string | Date[] | null) =>
  minMaxDate(input, Math.max);

export const minDate = (input: string | Date[] | null) =>
  minMaxDate(input, Math.min);

export const toInterval = function (value) {
  if (!value) return null;

  // single date
  if (value.toISOString)
    return {
      start: value,
      end: value,
    };

  // array of dates/num/string
  if (Array.isArray(value))
    return {
      start: value[0] && new Date(value[0]),
      end: value[1] && new Date(value[1]),
    };

  return stringToInterval(value);
};

export function parseToDateInterval(
  input: string | null,
  timezone: string
): null | Interval {
  if (!input) return null;

  return input
    .split("/")
    .map((str) =>
      str && timezone ? toZonedTime(str, timezone) : parseISO(str)
    )
    .reduce(
      function reduceToInterval(interval, date, i): Interval {
        if (i === 0 && date) interval.start = date;
        else if (i === 1 && date) interval.end = date;
        return interval;
      },
      {
        start: timezone ? toZonedTime(new Date(), timezone) : new Date(),
        end: timezone ? toZonedTime(new Date(), timezone) : new Date(),
      }
    );
}
