<script context="module" lang="ts">
  import { time } from "$utils/timestores";
  import { currency } from "$utils/format";
  import WeekDayChart from "../svg/WeeksDaysChart.svelte";

  const nowutc = time({ minutes: 30 });
</script>

<script lang="ts">
  export let item;
</script>

<figure class="chart">
  <WeekDayChart
    interval={item.interval}
    timezone={item.timezone}
    intervals={item?.[item.type]}
    class={item.type}
    now={$nowutc.toISOString()}
    format={(val) => currency(val / 100)}
  />
</figure>
