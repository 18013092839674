import type {
  GeoJSONSource,
  Map as VectorGLMap,
  MapSourceDataEvent,
  MapDataEvent,
  MapMouseEvent,
  Popup,
  LngLatLike,
  LngLatBoundsLike,
  MapboxOptions as MapOptions,
  Layer,
  AnyLayer,
  FillLayer,
} from "mapbox-gl";
import type {
  Style,
  Style as StyleSpecification,
  MapboxEvent as MapEvent,
  MapboxGeoJSONFeature as MapGeoJSONFeature,
  MapLayerMouseEvent,
} from "mapbox-gl";
import mapboxgl from "mapbox-gl";
import maplibregl from "maplibre-gl";
import { getContext, setContext } from "svelte";
import type { Readable } from "svelte/store";
import bbox from "geojson-bbox";
import square from "@turf/square";

const key = "mapgl";

const mapboxAccessToken =
  "pk.eyJ1IjoicGFya2luZ2Jvc3MiLCJhIjoiY2swY3VheHQyMDE1ejNtbjV4M3RoeTQ5cyJ9.toumXl_aMY5GgH45lZyiuA";

mapboxgl.accessToken = mapboxAccessToken;

// export interface MapEvent extends EventData {
//   target: VectorGLMap;
// }

export function getSvelteContext(): {
  style: Readable<StyleSpecification>;
  map: Readable<VectorGLMap>;
  styledmap: Readable<VectorGLMap>;
} {
  return getContext(key);
}
export function setSvelteContext(context: {
  style: Readable<StyleSpecification>;
  map: Readable<VectorGLMap>;
  styledmap: Readable<VectorGLMap>;
}) {
  return setContext(key, context);
}

export { key };
export type {
  Style,
  StyleSpecification,
  MapSourceDataEvent,
  MapGeoJSONFeature,
  MapEvent,
  MapDataEvent,
  Popup,
  LngLatLike,
  GeoJSONSource,
  VectorGLMap,
  MapMouseEvent,
  MapLayerMouseEvent,
  MapOptions,
  LngLatBoundsLike,
  Layer,
  AnyLayer,
  FillLayer,
};

export const engines = {
  maplibregl: maplibregl,
  mapboxgl: mapboxgl,
};

export const engine =
  engines[
    new URL(window.location.href).searchParams.get("renderer") || "maplibregl"
  ];

export { mapboxAccessToken, bbox };

export { square as squarebbox };

export function scalebbox(factor: number, bounds: LngLatBoundsLike) {
  if (!bounds) return bounds;
  const xmin = bounds[0];
  const xmax = bounds[2];
  const ymin = bounds[1];
  const ymax = bounds[3];
  const xdiff = Math.abs((xmax - xmin) * factor);
  const ydiff = Math.abs((ymax - ymin) * factor);
  return [
    xmin - xdiff,
    ymin - ydiff,
    xmax + xdiff,
    ymax + ydiff,
  ] as LngLatBoundsLike;
}
