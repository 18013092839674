<script>
  import Time from "./Time.svelte";

  import UserSessionChartItem from "./UserSessionChartItem.svelte";

  export let item;
  //$: console.log("item=", item);
</script>

<article class="{item.type} details">
  <header>
    <h1>
      {#each [item.principal]
        .filter((item) => item && item.id)
        .slice(0, 1) as user}
        <a href="mailto:{user.email}"
          ><data class="user {user.type}" value={user.id}
            ><dfn>by</dfn> {user.name}</data
          ></a
        >
      {/each}
    </h1>
  </header>
  <dl>
    <dt>Last</dt>
    <dd>
      <Time
        datetime={item.latest}
        timezone={item.timezone}
        empty="Hasn't Logged In"
      />
    </dd>
  </dl>
  <UserSessionChartItem {item} />
</article>
