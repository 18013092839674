import { writable, derived, type Readable, readable, get } from "svelte/store";
import {
  resolveProperty,
  fetchPaymentMetrics,
  fetchUnitExternalStatus,
  //fetchUnitExternalInfo,
  fetchNegativePermittables,
  fetchUnits,
  fetchVehicles,
  fetchMedias,
  fetchSpaces,
  fetchPrices,
  fetchAuthorizations,
  fetchPrincipalAuthorizations,
  fetchSessions,
  fetchUnitsTenants,
  fetchProperty,
  fetchPropertyPermitPolicies,
  fetchPropertyViolationPolicies,
  resolvePolicy,
  fetchPermits,
  fetchServices,
  fetchSpaceStatus,
  fetchEnforcement,
  fetchViolation,
  fetchUnitExternalOccupancy,
} from "./api";
import store from "store/dist/store.modern";
import { param } from "./params";
import {
  parseISO,
  format,
  addDays,
  addMonths,
  endOfDay,
  addMinutes,
  startOfWeek,
  addWeeks,
  endOfWeek,
  startOfMonth,
  isToday,
  addHours,
  startOfDay,
  startOfHour,
} from "date-fns";
import { sortBy, textAsc } from "./sort";

import { onlineVisibleTime, time } from "./timestores";
import { range } from "d3";
import { auth } from "./auth";
import throttle from "lodash-es/throttle";
import { Temporal } from "temporal-polyfill";
import type { AvailabilityFor } from "src/types";
import bosse from "./bosse";

//const comparer = textAsc;

//export const state = items;

//camera.subscribe(value => console.log("camera=", value));

// make this triggerable from mqtt?

export const propertyId: Readable<string> = param("property", true);

// async function updateProperty(property) {
//   fetchAndStoreProperty(property);
// }

export const propertyUpdated = writable();
export const enforcementUpdated = writable();

// // once you're on a property, assumed until the actual value changes
// // store previous value and use value equality to prevent frothy requests
// let propertyRefresher = null;
// let previousPropertyId = null;
// propertyId.subscribe(async (value) => {
//   //if(!!propertyRefresher) clearInterval(propertyRefresher); // alway stop the scheduler

//   if (!value) return; // don't do anything, but keep previous value cached - leave refreshers active

//   if (value === previousPropertyId) return; // the assignment changed, but not the actual value;

//   // we have a new ID
//   if (!!propertyRefresher) clearInterval(propertyRefresher); // stop the previous scheduler

//   previousPropertyId = value;

//   //console.log("propertyId changed=", value);

//   updateProperty(value);
//   propertyRefresher = setInterval(() => updateProperty(value), 10 * 60 * 1000);
// });

export const property = derived<
  [typeof propertyId, Readable<unknown>, Readable<unknown>, Readable<unknown>],
  Property
>(
  [
    propertyId,
    onlineVisibleTime({ minutes: 10 }),
    enforcementUpdated,
    propertyUpdated,
  ],
  function updater([$propertyId], set) {
    if (!$propertyId) return set(null);

    const cached = store.get(`properties/${$propertyId}`);

    if (cached) set(cached); // update from local...

    fetchProperty($propertyId).then((json) =>
      set(resolveProperty($propertyId, json.items))
    );
  }
);

export const alertOffsite = readable(false);
export const confirmedOffsite = readable(false);

// export const alertOffsite = derived<
//   [typeof property, typeof onsite, typeof confirmedOffsite],
//   Property
// >([property, onsite, confirmedOffsite], ([$property, $onsite, $confirmed]) => {
//   //if(null == $onsite || true === $onsite) return null;
//   // onsite must be false
//   if (false === $onsite && $property?.id != $confirmed) return $property;
//   return null;
// });

export const lpr = derived<typeof property, Boolean>(
  property,
  ($property) =>
    !!(
      $property &&
      $property.vehicles &&
      $property.vehicles.recognition &&
      $property.vehicles.recognition.enabled
    )
);

export const suspend = derived<[typeof property, Readable<Date>], Suspend>(
  [property, time("PT10M")],
  ([$property, $now]) => {
    const item = $property?.suspend as Suspend;
    if (item) {
      const date = new Date(item.datetime);
      item.pending = date > $now;
      item.active = date < $now;
    }

    return item;
  }
);

//property.subscribe($value => console.log("property=", $value));

export const timezone = writable<string>(null);
property.subscribe(($value: Property) => {
  $value && store.set(`properties/${$value.id}`, $value);

  if ($value) timezone.set($value.timezone);
});

const legacyPoliciesCutoff = parseISO("2023-11-18T00:00:00Z").getTime();

export const legacyPolicies = derived<typeof property, PermitIssuePolicy[]>(
  property,
  ($property) => {
    if (!$property?.parking?.enabled) return [];

    const items: PermitIssuePolicy[] = [];

    if (
      $property?.created &&
      parseISO($property.created).getTime() > legacyPoliciesCutoff
    )
      return items;

    if ($property?.vehicles?.permits)
      items.push({
        type: "policy",
        amenity: "parking",
        id: null,
        policy: null, //permit.policy?.policy,
        subject: null,
        scope: $property.id,
        title: "Special Permit",
        audience: {
          title: "Admin Only",
          admin: true,
          public: false,
        },
        permit: {
          continuous: false,
          temporary: true,
          exempt: true,
        },
        issue: {
          enabled: true,
        },
        vehicle: {
          request: true,
          required: true,
        },
        media: {
          request: false,
          required: false,
        },
        tenant: {
          request: !!$property?.units?.enabled,
          required: false,
        },
        space: {
          request: !!$property?.spaces?.enabled,
          required: false,
        },
        name: {
          request: true,
          required: false,
        },
        tel: {
          request: true,
          required: false,
        },
        email: {
          request: true,
          required: false,
        },
        // description: {
        //   label: "Info",
        //   placeholder: "make/model/color",
        //   request: true,
        //   required: false,
        // },
        notes: {
          request: true,
          required: false,
        },
      });
    if ($property?.media?.enabled)
      items.push({
        type: "policy",
        amenity: "parking",
        id: null,
        policy: null, //permit.policy?.policy,
        subject: null,
        scope: $property.id,
        title: "Assign Smart Decal",
        audience: {
          title: "Admin Only",
          admin: true,
          public: false,
        },
        permit: {
          continuous: true,
          exempt: true,
        },
        issue: {
          enabled: true,
        },
        vehicle: {
          request: true,
          required: false,
        },
        media: {
          request: true,
          required: true,
        },
        tenant: {
          request: !!$property?.units?.enabled,
          required: false,
        },
        space: {
          request: !!$property?.spaces?.enabled,
          required: false,
        },
        name: {
          request: true,
          required: false,
        },
        tel: {
          request: true,
          required: false,
        },
        email: {
          request: true,
          required: false,
        },
        notes: {
          request: true,
          required: false,
        },
        // description: {
        //   label: "Info",
        //   placeholder: "make/model/color",
        //   request: true,
        //   required: false,
        // },
      });
    if ($property?.spaces?.permits)
      items.push({
        type: "policy",
        amenity: "parking",
        id: null,
        policy: null, //permit.policy?.policy,
        subject: null,
        scope: $property.id,
        title: "Assign Space",
        audience: {
          title: "Admin Only",
          admin: true,
          public: false,
        },
        permit: {
          continuous: true,
          exempt: true,
        },
        issue: {
          enabled: true,
        },
        vehicle: {
          request: true,
          required: false,
        },
        media: {
          request: $property?.media?.enabled,
          required: false,
        },
        tenant: {
          request: !!$property?.units?.enabled,
          required: false,
        },
        space: {
          request: true,
          required: true,
        },
        name: {
          request: true,
          required: false,
        },
        tel: {
          request: true,
          required: false,
        },
        email: {
          request: true,
          required: false,
        },
        // description: {
        //   label: "Info",
        //   placeholder: "make/model/color",
        //   request: true,
        //   required: false,
        // },
        notes: {
          request: true,
          required: false,
        },
      });

    if ($property?.passes?.enabled)
      items.push({
        type: "policy",
        amenity: "parking",
        id: null,
        policy: null, //permit.policy?.policy,
        subject: null,
        scope: $property.id,
        title: "Printed Pass",
        audience: {
          title: "Admin Only",
          admin: true,
          public: false,
        },
        permit: {
          continuous: false,
          temporary: true,
          exempt: true,
          printed: {
            required: true,
          },
        },
        issue: {
          enabled: true,
        },
        vehicle: {
          request: false,
          required: false,
        },
        media: {
          request: false,
          required: false,
        },
        tenant: {
          request: !!$property?.units?.enabled,
          required: false,
        },
        space: {
          request: false,
          required: false,
        },
        name: {
          request: true,
          required: false,
        },
        tel: {
          request: true,
          required: false,
        },
        email: {
          request: true,
          required: false,
        },
        notes: {
          request: true,
          required: false,
        },
        // instructions: {
        //   label: "Pass",
        //   placeholder: "instructions",
        //   request: true,
        //   required: false,
        // },
        // reason: {
        //   request: true,
        //   required: true,
        // },
      });

    return items;
  }
);

export const permitPoliciesData = derived<[Readable<string>], any>(
  [propertyId],
  function updater([$propertyId], set) {
    if (!$propertyId) return set(null); // loading

    fetchPropertyPermitPolicies($propertyId).then(function (json) {
      //console.log("polices json=", json);

      // for (const [key, value] of Object.entries(json.policies.items)) {
      //   json.policies.items[key] = resolvePolicy(value, json);
      // }

      // const items = Object.keys(json.policies.items).map((item) =>
      //   resolvePolicy(item, json)
      // );
      //.filter((item) => item.amenity == "parking");

      set(json.policies);
    });
  }
);

export const violationPoliciesData = derived<[Readable<string>], any>(
  [propertyId],
  function updater([$propertyId], set) {
    if (!$propertyId) return set(null); // loading

    fetchPropertyViolationPolicies($propertyId).then(function (json) {
      //console.log("polices json=", json);

      // for (const [key, value] of Object.entries(json.policies.items)) {
      //   json.policies.items[key] = resolvePolicy(value, json);
      // }

      // const items = Object.keys(json.policies.items).map((item) =>
      //   resolvePolicy(item, json)
      // );
      //.filter((item) => item.amenity == "parking");

      set(json.policies);
    });
  }
);

export const violationPolicies = derived<
  [typeof propertyId, typeof violationPoliciesData],
  ViolationIssuedPolicy[]
>([propertyId, violationPoliciesData], function updater([$id, $data], set) {
  if (!$id || !$data) return set(null); // loading

  const items = Object.entries($data["for"][$id]?.items ?? {}).map(
    ([key, value]) => {
      const resolved =
        $data.items[value as string] ?? $data.items[key as string] ?? value;
      return resolved as ViolationIssuedPolicy;
    }
  );
  //.filter((item) => item.amenity == "parking");

  set(items);
});

export const policies = derived<
  [typeof propertyId, typeof permitPoliciesData, typeof legacyPolicies],
  PermitIssuePolicy[]
>(
  [propertyId, permitPoliciesData, legacyPolicies],
  function updater([$id, $data, $legacy], set) {
    if (!$id || !$data) return set(null); // loading

    let legacyParkingMedia = true;
    let legacyParkingSpace = true;
    let legacyParkingVehicle = true;

    const items = Object.keys($data["for"][$id]?.items ?? {})
      .map((item) => {
        const resolved = $data.items[item];
        if (
          resolved?.amenity === "parking" &&
          resolved?.audience?.admin &&
          resolved?.media?.required &&
          resolved?.permit?.continuous
        )
          legacyParkingMedia = false;
        if (
          resolved?.amenity === "parking" &&
          resolved?.audience?.admin &&
          resolved?.space?.required &&
          resolved?.permit?.continuous
        )
          legacyParkingSpace = false;
        if (
          resolved?.amenity === "parking" &&
          resolved?.audience?.admin &&
          resolved?.vehicle?.required &&
          resolved?.permit?.temporary
        )
          legacyParkingVehicle = false;
        return resolved;
      })
      .concat(
        $legacy.filter((item) => {
          if (
            !legacyParkingMedia &&
            item.media?.required &&
            item.permit?.continuous
          )
            return false;
          if (
            !legacyParkingSpace &&
            item.space?.required &&
            item.permit?.continuous
          )
            return false;
          if (
            !legacyParkingVehicle &&
            item.vehicle?.required &&
            item.permit?.temporary
          )
            return false;
          return true;
        })
      );
    //.filter((item) => item.amenity == "parking");

    set(items);
  }
);

export const policyId = param("policy");
export const policy = derived(
  [policyId, policies],
  ([$policyId, $policies]) =>
    $policyId && $policies && $policies.find((item) => item.policy == $policyId)
);

// export const parkingPolicies = derived(
//   [propertyId],
//   function updater([$propertyId], set) {
//     if (!$propertyId) return set(null); // loading

//     fetchPropertyPolicies($propertyId).t;

//     //console.log("polices json=", json);

//     const items = Object.keys(json.policies.items)
//       .map((item) => resolvePolicy(item, json))
//       .filter((item) => item.amenity == "parking");

//     set(items);
//   }
// );

export const isSystemAdmin = derived(auth, function updater($auth, set) {
  if (!$auth) return set(null); // loading

  fetchPrincipalAuthorizations($auth.sub).then((authorizations) =>
    set(authorizations.system)
  );
});

// export const policies = derived([property, state], ([property, state]) => {
//   if (!property) return null;
//   if (!state["policies"]) return null;
//   var policies = Object.values(state["policies"] || {}).map(
//     (version, policy) => state[policy] || state[version]
//   );
//   //console.log("policies=", policies);
//   if (!policies.every((item) => !!item)) return null; // not done loading

//   return policies
//     .filter(
//       (item) =>
//         !!item && item.scope === property.id && item.amenity == "parking"
//     )
//     .map((item) => hydratePolicy(item, state))
//     .sort((a, b) => comparer(a.title, b.title));
// });

export const unitsUpdated = writable();
export const spacesUpdated = writable();

export const units = derived<
  [typeof propertyId, Readable<unknown>, Readable<unknown>],
  Units
>(
  [propertyId, unitsUpdated, onlineVisibleTime({ minutes: 15 })],
  ([$propertyId], set) => {
    if (!$propertyId) return set(null);

    fetchUnits($propertyId).then((json) => set(json.units));
  }
);

export const vehicles = derived<
  [typeof propertyId, Readable<unknown>],
  Vehicles
>([propertyId, onlineVisibleTime({ minutes: 15 })], ([$propertyId], set) => {
  if (!$propertyId) return set(null);

  fetchVehicles($propertyId).then((json) => set(json.vehicles));
});

export const media = derived<[typeof propertyId], Medias>(
  [propertyId],
  ([$propertyId], set) => {
    if (!$propertyId) return set(null);

    fetchMedias($propertyId).then((json) => set(json.media));
  }
);

export const spaces = derived<[typeof propertyId, Readable<unknown>], Spaces>(
  [propertyId, spacesUpdated],
  ([$propertyId], set) => {
    if (!$propertyId) return set(null);

    fetchSpaces($propertyId).then((json) => set(json.spaces));
  }
);

//spaces.subscribe(($value) => console.log("spaces=", $value));

// only on load
const pricesData = derived<[typeof propertyId], any>(
  [propertyId],
  ([$propertyId], set) => {
    if (!$propertyId) return set(null);

    fetchPrices($propertyId).then(set);
  }
);

// export const units = derived([property, unitsData], ([property, state]) => {
//   if (!property) return null;
//   if (!state) return null;
//   return map(
//     get(state, "units.items"),
//     (value, key) => (state.items || state)[key]
//   )
//     .filter((item) => !!item && item.scope === property.id)
//     .sort((a, b) => comparer(a.display, b.display));
// });

// export const tenants = derived([ property, state ], ([ $property, $state ]) => {
//     if(!$property) return null;
//     //console.log("state=", $state);
//     if(!$state || !$state.tenants) return null;
//     return Object.entries($state.tenants.items || $state.tenants).map(([ key, value ]) => $state[key] || value).filter(item => !!item && item.scope === $property.id);
//     //return map(get(state, "tenants.items"), (value, key) => state[key]).filter(item => !!item && item.scope === property.id);
// });

export const propertyServices = derived(
  [propertyId, onlineVisibleTime({ minutes: 30 })],
  ([$propertyId], set) => {
    if (!$propertyId) return set(null);

    //var json = await
    fetchServices($propertyId).then(function (json) {
      if (
        json.services["for"]?.[$propertyId] &&
        json.entry["for"]?.[$propertyId]
      ) {
        json.services["for"][$propertyId].entry =
          json.entry["for"][$propertyId];
      }

      if (json.services["for"]?.[$propertyId]) {
        for (const [id, item] of Object.entries(
          json.services["for"][$propertyId].items
        )) {
          item.subject = $propertyId;
        }
      } else if (json.entry["for"]?.[$propertyId]) {
        json.services.entry = json.entry["for"][$propertyId];
      }

      //console.log(json);

      set(
        Object.assign(json.services["for"]?.[$propertyId] || json.services, {
          items: Object.assign(
            json.services.items,
            json.services["for"]?.[$propertyId]?.items || {}
          ),
        })
      );
    });
  }
);

export const stripe = derived(
  [propertyId, onlineVisibleTime({ minutes: 30 })],
  ([$propertyId], set) => {
    if (!$propertyId) return set(null);

    //var json = await
    fetchServices($propertyId, "stripe").then((json) => set(json.stripe));

    //set(json.stripe);
  }
);

// only fetch on load?
export const propertyPaymentMetrics = derived(
  [propertyId, onlineVisibleTime({ minutes: 30 })],
  function updater([$propertyId], set) {
    if (!$propertyId) return set(null);

    var byInterval = range(-14, 1, 1)
      .map((i) => [
        addMonths(startOfMonth(new Date()), i),
        addMonths(startOfMonth(new Date()), i + 1),
      ])
      .map(
        ([min, max]) =>
          `${format(min, "yyyy-MM-dd'T'00:00:00")}/${format(
            max,
            "yyyy-MM-dd'T'00:00:00"
          )}`
      )
      .map((valid) =>
        fetchPaymentMetrics(valid, {
          property: $propertyId,
          //datetimes: ["P1D", "P1M"],
          metrics: ["total", "policy", "property"],
          payments: ["total", "net"],
        }).then(function (json) {
          json.metrics.items.forEach((item) => (item.datetimes = "P1M"));
          return json;
        })
      );

    const interval = `${format(
      addMonths(startOfMonth(new Date()), -14),
      "yyyy-MM-dd'T'00:00:00"
    )}/${format(
      addMonths(startOfMonth(new Date()), 1),
      "yyyy-MM-dd'T'00:00:00"
    )}`;

    Promise.all(byInterval)
      .then((jsons) =>
        jsons.reduce((json, json1) => {
          return Object.assign(json, json1, {
            metrics: {
              interval,
              items: (json.metrics?.items || []).concat(
                json1.metrics.items || []
              ),
            },
          });
        }, {})
      )
      .then(set);

    // var json = await Promise.all([
    //   fetchPaymentMetrics(
    //   `${format(addMonths(new Date(), -2), "yyyy-MM-01'T'00:00:00")}/${format(
    //     addDays(new Date(), 1),
    //     "yyyy-MM-dd'T'00:00:00"
    //   )}`,
    //   {
    //     property: $propertyId,
    //     datetimes: ["P1M"],
    //     metrics: ["total", "policy", "property"],
    //     payments: ["total", "net"],
    //   }
    // ),
    // fetchPaymentMetrics(
    //   `${format(addMonths(new Date(), -1), "yyyy-MM-01'T'00:00:00")}/${format(
    //     addDays(new Date(), 1),
    //     "yyyy-MM-dd'T'00:00:00"
    //   )}`,
    //   {
    //     property: $propertyId,
    //     datetimes: ["P1M"],
    //     metrics: ["total", "policy", "property"],
    //     payments: ["total", "net"],
    //   }
    // ),
    // ;

    //console.log("paymentmetrics=", json);

    //set(json);
  }
);

function resolve(state, key, fallback?) {
  return (state && key && (state.items || state)[key]) || fallback;
}

export const prices = derived([property, pricesData], ([property, state]) => {
  if (!property) return null;
  if (!state || !state.prices || !state.prices.items) return null;
  return Object.keys(state.prices.items).reduce((result, key) => {
    const item = resolve(state, key);
    if (!item) return result;

    if (item.scope !== property.id) return result;

    item.subject = resolve(state, item.subject) || item.subject;

    result.push(item);

    return result;
  }, []);
});

export const pricingFor = derived<
  [typeof property, typeof pricesData],
  Record<string, Prices>
>([property, pricesData], ([$property, $state]) => {
  const data: Record<string, Prices> = $state?.prices?.["for"];

  if (!data) return data;

  for (const item of Object.values(data)) {
    // expand any itms
    for (const [k1, v1] of Object.entries(item.items)) {
      item.items[k1] =
        $state.items[v1 as unknown as string] || $state.items[k1] || v1;
    }
  }

  return data;
});

// on property change, other refresh?
const unitsTenantsData = derived(
  [propertyId, unitsUpdated],
  ([$propertyId], set) => {
    if (!$propertyId) return set(null);

    fetchUnitsTenants($propertyId, "*/*").then(set);
  }
);

const _tenantsByUnit = writable({});
const _units = writable({});
const _tenants = writable({});
const _maxTenants = writable(null);

function itemsFromState(items, $state) {
  $state = $state.items || $state;
  return Object.entries(items).map(
    ([id, item]) => resolve($state, item) || resolve($state, id) || item
  );
}

unitsTenantsData.subscribe(($state) => {
  //console.log("state=", $state);

  if (
    !$state ||
    !$state.tenants ||
    !$state.tenants.items ||
    !$state.units ||
    !$state.units.items
  )
    return; // no changes

  const tenantItems = itemsFromState($state.tenants.items, $state);

  //console.log("tenantItems=", tenantItems);

  _units.update(($value) => {
    return itemsFromState($state.units.items, $state).reduce((map, item) => {
      item = resolve($state, item) || item;
      if (item.removed) return map;
      map[item.id] = item;
      return map;
    }, $value);
  });

  // run the update
  _tenantsByUnit.update(($value) => {
    return tenantItems.reduce(
      (map, item) => {
        if (!map[item.subject]) return map;

        const meta = map[item.subject];
        (meta.tenants ??= {})[item.id] = item;

        const [minutc, maxutc] = item.valid.interval.split("/");
        //.map((str) => str && parseISO(str));

        if (minutc > meta.latest) meta.latest = minutc;

        return map;
      },
      Object.keys($state.units.items).reduce((map, id) => {
        if (map[id]) return map;
        const item = resolve($state, id);
        if (!item) return map;
        if (item.removed) return map;
        map[id] = {
          type: "unitstatus",
          latest: item.created,
          subject: item,
          unit: item,
        };
        return map;
      }, $value)
    );
  });

  let max;

  _tenants.update(($value) => {
    return tenantItems.reduce((map, item) => {
      //console.log(map, item);

      map[item.id] = item;

      const [a] = item.valid.utc.split("/");

      if (null == max || a > max) max = a;

      return map;
    }, $value);
  });

  _maxTenants.update(($value) =>
    (null != $value) == null && $value > max ? $value : max
  );
});
export { _tenantsByUnit as tenantsByUnit, _tenants as tenants };

//export const units = derived(unitsData, ($data) => $data && $data.units); -- replaced above

//export const tenants = _tenants;

// change on any propertyid or max tenant (e.g. reset)
export const externalCRMStatusData = derived<
  [typeof propertyId, typeof _maxTenants, Readable<Date>],
  any
>(
  [propertyId, _maxTenants, onlineVisibleTime({ minutes: 10 })],
  ([$propertyId, $maxTenants], set) => {
    if (!$propertyId) return set(null);
    if (null == $maxTenants) return set(null);

    fetchUnitExternalStatus($propertyId)
      .then(function (data) {
        for (const details of Object.values(
          data.unitstatus?.["for"] ?? {}
        ) as any[]) {
          details.subject = data.items[details.subject] || details.subject;
        }
        return data;
      })
      .then(set);
  }
);
export const externalCRMOccupancyData = derived<
  [typeof propertyId, Readable<Date>],
  any
>([propertyId, onlineVisibleTime({ minutes: 10 })], ([$propertyId], set) => {
  if (null == $propertyId) return set(null); // no status

  const id = $propertyId;
  //console.log("data=", data, item);

  // if (!$crmStatus.connected) {
  //   return set({
  //     type: "occupancy",
  //     ...$crmStatus,
  //   });
  // }

  fetchUnitExternalOccupancy(id, null).then(function ($json: any) {
    // var occupied = json.occupancy.for[id];
    // console.log("occupancy=", occupied);
    // if (!occupied) return null;

    // const occupants = Object.values(
    //   occupied.current?.occupants ?? occupied.pending?.occupants ?? {}
    // )
    //   .map((value: any) => {
    //     value = occupied.items[value as string];
    //     const occupant = json.items[value.occupant] ?? value.occupant;
    //     return {
    //       ...value,
    //       ...occupant,
    //       vehicles: Object.values(
    //         value.current?.vehicles ?? value.pending?.vehicles ?? {}
    //       ).map((value) => json.items[value as string]),
    //     };
    //   })
    //   .sort(comparer("name"));

    // const rentals = Object.values(
    //   occupied.current?.rentals ?? occupied.pending?.rentals ?? {}
    // ).map((value: any) => {
    //   value = occupied.items[value as string];
    //   const rentable = json.items[value.rentable as string] ?? value.rentable;
    //   return {
    //     ...value,
    //     ...rentable,
    //     subject: json.items[rentable.subject],
    //   };
    // });

    //console.log("occupants=", occupants, "rented=", rentals);

    return set($json);
  });
});

export const externalCRMOccupancyPermits = derived<
  [typeof externalCRMOccupancyData],
  Permits
>([externalCRMOccupancyData], ([$occupancy]) => {
  return $occupancy.permits;
});

externalCRMOccupancyData.subscribe(($value) =>
  console.log("occupancy=", $value)
);

// export const externalCRMInfo = derived<
//   [typeof propertyId, typeof _maxTenants],
//   any
// >([propertyId, _maxTenants], ([$propertyId, $maxTenants], set) => {
//   if (!$propertyId) return set(null);
//   if (null == $maxTenants) return set(null);

//   fetchUnitExternalInfo($propertyId)
//     // .then(function (data) {
//     //   for (const details of Object.values(
//     //     data.unitstatus?.["for"] ?? {}
//     //   ) as any[]) {
//     //     details.subject = data.items[details.subject] || details.subject;
//     //   }
//     //   return data;
//     // })
//     .then(set);
// });

// export const externalCRMEnabled = derived(
//   unitExternalCRMStatus,
//   ($unitStatus, set) => {
//     return set($unitStatus?.unitstatus?.enabled);
//     // var res $unitStatus;
//     // set(res && res.unitstatus && res.unitstatus.enabled);
//   }
// );

export const externalCRM = derived<
  typeof property,
  {
    enabled: boolean;
    connected: boolean;
    service: Service;
  }
>(property, ($property, set) => {
  return set({
    enabled: !!$property?.crm,
    connected: !!$property?.crm,
    service: $property?.crm,
  });
  // var res $unitStatus;
  // set(res && res.unitstatus && res.unitstatus.enabled);
});

// export const externalCRMService = derived(
//   unitExternalCRMStatus,
//   ($unitStatus, set) => {
//     return set($unitStatus?.unitstatus?.service);
//   }
// );

export const externalCRMRentables = derived<
  typeof externalCRMStatusData,
  Record<string, unknown>
>(externalCRMStatusData, ($external) => {
  if (null == $external) return null;
  //console.log("$external=", $external);
  const rentables = ($external.rentables?.items ?? {}) as Record<
    string,
    string
  >;
  return Object.entries(rentables).reduce((result, [key, value]) => {
    const item = $external.items[value] ?? $external.items[key] ?? value;
    // if (item.subject)
    //   item.subject = $external.items[item.subject as string] ?? item.subject;
    // if (item.by) {
    //   if (typeof item.by == "string") item.by = [item.by];
    //   console.log("item.by", item.by);
    //   item.by = Object.values(item.by ?? []).map(
    //     (item) => $external.items[item as string] ?? item
    //   );
    // }
    result[key] = item;
    return result;
  }, {});
  // .map((id) => {
  //   const item = $external.items[id] ?? id;
  //   if (item.subject)
  //     item.subject = $external.items[item.subject as string] ?? item.subject;
  //   return item;
  // });
});

//externalCRMRentables.subscribe(($value) => console.log("rentables=", $value));

async function unitStatusItems(promise) {
  const data = await promise;
  return (
    (data &&
      data.unitstatus &&
      data.unitstatus["for"] &&
      sortBy(
        Object.values(data.unitstatus["for"]).map((details) => {
          details.subject = data.items[details.subject] || details.subject;
          return details;
        })
      )) ||
    []
  );
  //[ data.items[unit], details]), ([unit]) => unit.display) || [];
}

export const unitStatusAttention = derived(
  externalCRMStatusData,
  ($unitStatusData, set) => {
    //const items = await
    unitStatusItems($unitStatusData).then((items) =>
      set(
        items.filter(
          (details) => details?.check?.vacant || details?.check?.reoccupied
        )
      )
    );
    // set(
    //   items.filter(
    //     (details) => details?.check?.vacant || details?.check?.reoccupied
    //   )
    // );
  }
);

export const unitTypes = derived(_units, ($units) => {
  return Object.keys(
    Object.values($units || {}).reduce(
      (types, item) => (types[item.format] = true) && types,
      {}
    )
  ).sort();
});

export const unitId = param("unit");
export const tenantId = param("tenant");
export const vehicleId = param("vehicle");
export const mediaId = param("media");
export const spaceId = param("space");
export const violationId = param("violation");
export const permitId = param("permit");

//tenantId.subscribe($tenantId => console.log("tenantId=", $tenantId));

function resolveId(target, key, source) {
  if (!target || !key || !source) return;
  //console.log("resolving", target, key, source, target[key], source[key]);
  if (typeof target[key] == "string")
    target[key] = source[target[key]] || target[key];
}

export const tenantWithVersions = derived(
  [tenantId, _tenants, _units],
  ([$id, $tenants, $units]) => {
    if (!$id) return;

    const item = $tenants[$id];
    //console.log("tenant v=", item, $units);
    if (!item) return;

    resolveId(item, "subject", $units);

    return item;
  }
);

// export const vehicle = derived([vehicleId, state], ([$id, $state]) => {
//   if (!$id) return;

//   const item = $state[$id];

//   if (!item) return autocreateVehicle($id);

//   return item;
// });

// export const media = derived([mediaId, state], ([$id, $state]) => {
//   if (!$id) return;

//   const item = $state[$id];

//   return item;
// });

// export const space = derived([spaceId, state], ([$id, $state]) => {
//   if (!$id) return;

//   const item = $state[$id];

//   return item;
// });

//   const item = $state[$id];

//   return item;
// });

export const details = writable({});

// export const violationsUpdated = writable();
export const violationsUpdated = (function () {
  const state = writable<number>();

  return throttle(
    function (value?: Date | number | boolean) {
      if (true === value) state.set(Date.now());
      else if (value) state.set(value as number);
      return state;
    },
    3000,
    {
      trailing: true,
      leading: true,
    }
  ) as (value?: Date | number | boolean) => Readable<number>;
})();
//export const permitsUpdated = writable<number>();
export const permitsUpdated = (function () {
  const state = writable<number>();

  return throttle(
    function (value?: Date | number | boolean) {
      if (true === value) state.set(Date.now());
      else if (value) state.set(value as number);
      return state;
    },
    3000,
    {
      trailing: true,
      leading: true,
    }
  ) as (value?: Date | number | boolean) => Readable<number>;
})();
export const permittablesUpdated = (function () {
  const state = writable<number>();

  return throttle(
    function (value?: Date | number | boolean) {
      if (true === value) state.set(Date.now());
      else if (value) state.set(value as number);
      return state;
    },
    3000,
    {
      trailing: true,
      leading: true,
    }
  ) as (value?: Date | number | boolean) => Readable<number>;
})();
export const notesUpdated = writable();
//export const permittablesUpdated = writable();
export const authcodeUpdated = (function () {
  const state = writable<number>();

  return throttle(
    function (value?: Date | number | boolean) {
      if (true === value) state.set(Date.now());
      else if (value) state.set(value as number);
      return state;
    },
    3000,
    {
      trailing: true,
      leading: true,
    }
  ) as (value?: Date | number | boolean) => Readable<number>;
})();
//export const authcodeUpdated = writable();
export const authorizationsUpdated = writable();

// property
// every 30m - don't expect much change
// violations updated
export const donotpermit = derived(
  [propertyId, onlineVisibleTime({ minutes: 30 }), permittablesUpdated()],
  function ([$propertyId], set) {
    if (!$propertyId) return set(null);

    //const permittables = await
    fetchNegativePermittables(
      $propertyId,
      format(addHours(new Date(), 1), "yyyy-MM-dd'T'HH:mm:ssxxx") // can't use $now
    ).then(set);
    //console.log("exceptions=", exceptions);

    //set(permittables);
  }
);

export const violation = derived<typeof violationId, Violation>(
  violationId,
  ($id, set) => {
    // fetch!!
    if (null == $id) return;

    fetchViolation($id)?.then(($json) =>
      set(Object.values($json.items ?? {})[0] as Violation)
    );
  }
);

export const doNotPermitFor = derived(
  [propertyId, donotpermit],
  function ([$propertyId, $permittables], set) {
    const $id = $propertyId;
    // console.log(
    //   "permittablesfor=",
    //   $id,
    //   $permittables,
    //   $permittables?.["for"]?.[$id]
    // );
    if (!$id) return set(null);
    if (!$permittables) return set(null);

    return set($permittables["for"][$id] || { count: 0, items: {} });
  }
);

//doNotPermitFor.subscribe(($value) => console.log("property banned=", $value));

// property
// every 30m - don't expect much change
// authorizations updated
export const authorized = derived(
  [propertyId, onlineVisibleTime({ minutes: 30 }), authorizationsUpdated],
  function ([$propertyId], set) {
    if (!$propertyId) return set(null);

    fetchAuthorizations(
      $propertyId,
      format(addHours(new Date(), 1), "yyyy-MM-dd'T'HH:mm:ssxxx") + "/" // can't use $now because that only changes every 30m
    ).then(set);
    //console.log("exceptions=", exceptions);

    //set(data);
  }
);

const validNow = onlineVisibleTime({ minutes: 1 });

export const validOneCalendarDay = derived(
  validNow,
  ($now) =>
    format(startOfDay($now), "yyyy-MM-dd'T'HH:mm:ss") +
    "/" +
    format(endOfDay($now), "yyyy-MM-dd'T'HH:mm:ss")
);

export const validPast24H = derived(
  validNow,
  ($now) =>
    format(addHours(startOfHour($now), -24), "yyyy-MM-dd'T'HH:mm:ss") +
    "/" +
    format(endOfDay($now), "yyyy-MM-dd'T'HH:mm:ss")
);

export const validPastSevenDays = derived(
  validNow,
  ($now) =>
    format(addDays(startOfHour($now), -7), "yyyy-MM-dd'T'HH:mm:ss") +
    "/" +
    format(endOfDay($now), "yyyy-MM-dd'T'HH:mm:ss")
);

// this is the mostly-fixed valid...shouldn't change much
// will change when the day changes
export const valid13CalendarWeeks = derived(
  validNow,
  ($now) =>
    format(addWeeks(startOfWeek($now), -13), "yyyy-MM-dd'T'HH:mm:ss") +
    "/" +
    format(endOfWeek($now), "yyyy-MM-dd'T'HH:mm:ss")
);

// this is the mostly-fixed valid...shouldn't change much
// will change when the day changes
export const validFiveCalendarWeeks = derived(
  validNow,
  ($now) =>
    format(addWeeks(startOfWeek($now), -4), "yyyy-MM-dd'T'HH:mm:ss") +
    "/" +
    format(endOfWeek($now), "yyyy-MM-dd'T'HH:mm:ss")
);
// reprocess each minute
export const validSevenDays = derived(
  validNow,
  () =>
    `${Temporal.Now.plainDateTimeISO()
      .subtract({ days: 6 })
      .with({
        hour: 0,
        minute: 0,
        microsecond: 0,
        millisecond: 0,
        second: 0,
      })
      .toString()}/${Temporal.Now.plainDateTimeISO()
        .with({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 0,
          microsecond: 0,
        })
        .toString()}`
  // format(addDays(startOfDay($now), -6), "yyyy-MM-dd'T'HH:mm:ss") +
  // "/" +
  // format(endOfDay($now), "yyyy-MM-dd'T'HH:mm:ss")
);

//validSevenDays.subscribe(($value) => console.log("$validSevenDays=", $value));
// property
// days
// violation change
// every 5m
//export const enforcementFor = writable(null);
export const enforcementFor = derived(
  [
    propertyId,
    validFiveCalendarWeeks,
    violationsUpdated(),
    enforcementUpdated,
    onlineVisibleTime({ minutes: 5 }), // refresh every 5 minutes
  ],
  function ([$propertyId, $valid], set) {
    if (!$propertyId) {
      // violations.set(null);
      // accessed.set(null);
      return set(null);
    }

    fetchEnforcement($propertyId, $valid, "principal", "P1D", "P1W").then(
      (data) => {
        //enforcementFor.set(data.enforcement?.["for"]);

        set(data.enforcement?.["for"]);
      }
    );

    // violations.set(data.violations?.["for"]?.[$propertyId]);
    // accessed.set(data.accessed?.["for"]?.[$propertyId]);
  }
);

export const enforcement = derived(
  [propertyId, enforcementFor],
  ([$propertyId, $fordata]) => {
    if (!$propertyId || null == $fordata) return null;
    return $fordata[$propertyId];
  }
);

// property
// every 5m - moderatly fast change
// sessions updated
export const sessions = derived(
  [propertyId, validFiveCalendarWeeks, onlineVisibleTime({ minutes: 5 })],
  function ([$propertyId, $valid], set) {
    if (!$propertyId) return set(null);

    fetchSessions($propertyId, $valid).then(set);
    //console.log("exceptions=", exceptions);

    //set(data);
  }
);

export const permitsIssuedSevenDays = derived<
  [typeof property, typeof validSevenDays, Readable<number>],
  Permits
>(
  [property, validSevenDays, permitsUpdated()],
  function update([$property, $issued], set) {
    const $id = $property?.id;
    if (!$id) return set(null);

    fetchPermits($id, "/", {
      issued: $issued
        .split("/")
        .map((value) =>
          Temporal.PlainDateTime.from(value)
            .toZonedDateTime($property.timezone)
            .toString({
              timeZoneName: "never",
            })
        )
        .join("/"),
    }).then(function (data) {
      set(data.permits || data);
    });

    // return function clear() {
    //   set(null);
    // }
  }
);

export const permits = derived<
  [Readable<string>, Readable<string>, Readable<unknown>, Readable<unknown>],
  { generated: string; items: Record<string, Permit> }
>(
  [
    propertyId,
    param("valid"),
    permitsUpdated(),
    onlineVisibleTime({ minutes: 10 }),
  ], // update on vehicle change, day change, violations updated, every 10m
  function ([$propertyId, $valid], set) {
    const $id = $propertyId;
    if (!$id) return set(null);

    // do we clear this on any change?

    //console.log("updating enforcement init for=", subject, $valid);

    if ($valid && $valid.indexOf("/") < 0 && parseISO($valid as string)) {
      $valid = format(parseISO($valid as string), "yyyy-MM-dd'T'00:00:00'/'");
    }

    fetchPermits(
      $id,
      $valid || addMinutes(new Date(), -60).toISOString() + "/"
    ).then(function (data) {
      set(data.permits || data);
    });

    //enforcementFor.set(data.enforcement?.["for"]);

    // succeeded...there must be none
  }
);

// we want this to init load
permits.subscribe(($value) => $value);

export const spacesStatusData = derived<
  [typeof propertyId, Readable<string>, Readable<number>, Readable<Date>],
  any
>(
  [
    propertyId,
    param("valid"),
    permitsUpdated(),
    onlineVisibleTime({ minutes: 10 }),
  ],
  function updater([$propertyId, $valid], set) {
    if (!$propertyId) return set(null);
    // console.log(
    //   "$valid=",
    //   $valid,
    //   parseISO($valid),
    //   format(new Date($valid), "yyyy-MM-dd'T'00:00:00'/'")
    // );
    set(null);

    //if (!$valid) $valid = format(new Date(), "yyyy-MM-dd");

    fetchSpaceStatus(
      $propertyId,
      !$valid || isToday(parseISO($valid))
        ? null
        : format(parseISO($valid), "yyyy-MM-dd'T'00:00:00'/'")
    ).then(set);
  }
);

export const spacesAvailability = derived<
  typeof spacesStatusData,
  AvailabilityFor
>(spacesStatusData, ($spacesStatusData) => {
  return $spacesStatusData?.availability as AvailabilityFor;
});

export const spacesStatus: Readable<Record<string, Space>> = derived(
  [
    propertyId,
    param("valid"),
    permitsUpdated(),
    onlineVisibleTime({ minutes: 10 }),
  ],
  function updater([$propertyId, $valid], set) {
    if (!$propertyId) return set(null);
    // console.log(
    //   "$valid=",
    //   $valid,
    //   parseISO($valid),
    //   format(new Date($valid), "yyyy-MM-dd'T'00:00:00'/'")
    // );
    set(null);

    //if (!$valid) $valid = format(new Date(), "yyyy-MM-dd");

    fetchSpaceStatus(
      $propertyId,
      !$valid || isToday(parseISO($valid))
        ? null
        : format(parseISO($valid), "yyyy-MM-dd'T'00:00:00'/'")
    )
      .then(function ($status) {
        return Object.values($status.spaces.items).reduce(
          (result: Record<string, any>, item: string | any) => {
            item = $status.items[item as string] || item;

            for (const k1 of ["prices", "permitted"]) {
              item[k1] = item[k1] ?? $status[k1]?.["for"]?.[item.id];

              // expand any itms
              if (!item[k1]?.items) continue;
              for (const [k2, v2] of Object.entries(item[k1].items)) {
                item[k1].items[k2] =
                  $status.items[v2 as string] ||
                  $status.items[k2 as string] ||
                  v2;
              }
            }

            result[item.id] = item;

            return result;
          },
          {}
        );
      })
      .then(set);
  }
);

// const bounds = derived(property, function ($property, set) {
//   const area = $property?.address.area;
//   if (!area) return;

//   const bounds = scalebbox(squarebbox(bbox(area)), 1.2);
//   set(bounds);
// });

// export const streetmap = derived(
//   [property, bounds],
//   function ([$property, $bounds], set) {
//     if (!$bounds) return;

//     const url = `https://api.mapbox.com/styles/v1/mapbox/navigation-guidance-day-v4/static/[${$bounds.join(
//       ","
//     )}]/450x450@2x?access_token=${mapboxAccessToken}&logo=false&attribution=false`;
//     set(url);
//     // let response = await fetch(url);

//     // const blob = await response.blob(); // download as Blob object

//     // const loaded = URL.createObjectURL(blob);
//     // console.log(loaded);

//     // set(loaded);
//   }
// );

// export const satellitemap = derived(
//   [property, bounds],
//   function ([$property, $bounds], set) {
//     if (!$bounds) return;

//     const url = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static/[${$bounds.join(
//       ","
//     )}]/450x450@2x?access_token=${mapboxAccessToken}&logo=false&attribution=false`;
//     set(url);
//     // let response = await fetch(url);

//     // const blob = await response.blob(); // download as Blob object

//     // const loaded = URL.createObjectURL(blob);
//     // console.log(loaded);

//     // set(loaded);
//   }
// );

export const updated = writable({});
//let subscribedPropertyId = null;

let events = bosse();

events.connected.subscribe($state => console.log("bosse connected=", $state));
events.connecting.subscribe($state => console.log("bosse connecting=", $state));

// propertyId.subscribe(($propertyId) => {

//   if (!$propertyId) return events?.disconnect();

//   events.connect({
//     [$propertyId]: "property"
//   });

// });

// can we do in one joined store?
derived([propertyId, events.source], ([$propertyId, $source]) => {
  if (!$propertyId) return events?.disconnect();

  if (!$source)
    events.connect({
      [$propertyId]: "property",
    });

  $source?.addEventListener("message", (e: MessageEvent) => {
    console.log("EventSource message:", e);
    const json = JSON.parse(e.data);
    updated.set({
      scope: propertyId,
      type: Object.keys(json)[0],
      updated: json[Object.keys(json)[0]],
    });
  });
}).subscribe(() => { });

// events.source.subscribe(($source) => {
//     $source?.addEventListener("message", (e: MessageEvent) => {
//       console.log("EventSource message:", e);
//       const json = JSON.parse(e.data);
//       updated.set({
//         scope: propertyId,
//         type: Object.keys(json)[0],
//         updated: json[Object.keys(json)[0]],
//       });
// });

// const eventing = derived(propertyId, ($propertyId, set) => {

//   if (!$propertyId) return set(events.connect(null));

//   return

//   eventsource = new EventSource(
//     `https://bosse.communityboss.app/?property=${$propertyId}`
//   );
//   eventsource.addEventListener("error", (e: Event) =>
//     console.error("EventSource failed:", e)
//   );
//   eventsource.addEventListener("open", (e: Event) =>
//     console.log("EventSource connected", e)
//   );
//   eventsource.addEventListener("message", (e: MessageEvent) => {

//   });
//   return set(eventsource);
//   // evtSource.addEventListener("message", (e) => console.log("event=", e));
//   // evtSource.onerror = (err) => {
//   //   ;
//   // };
// });

// eventing.subscribe((bosse) => {

//   bosse.source?.addEventListener("message", (e: MessageEvent) => {
//     console.log("EventSource message:", e);
//     const json = JSON.parse(e.data);
//     updated.set({
//       scope: propertyId,
//       type: Object.keys(json)[0],
//       updated: json[Object.keys(json)[0]],
//     });
//   });

// });

// const mqtt = mqttclient(
//   "AKIAIUPPRVWKBYHY4UWQ",
//   "GQQeZRDLfbR9JpVeIuAJzcAOgSlaJXABCRsqR3M8",
//   (json) => {
//     //console.log("mqtt.message=", json);
//     //updated.set(json);
//     updated.set({
//       scope: subscribedPropertyId,
//       type: Object.keys(json)[0],
//       updated: json[Object.keys(json)[0]],
//     });
//   }
// );

// propertyId.subscribe((propertyId) => {
//   if (!propertyId) return;
//   if (subscribedPropertyId == propertyId) return;

//   // propertyId changed...

//   // unsubscribe
//   if (!!subscribedPropertyId)
//     mqtt.unsubscribe(`locations/${subscribedPropertyId}`);

//   // subscribe
//   //mqtt.subscribe("#");
//   mqtt.subscribe(
//     `locations/${(subscribedPropertyId = propertyId)}`,
//     function (err, granted) {
//       if (err) return console.error(err);
//       console.log("granted=", granted);
//     }
//   );
// });

updated.subscribe(({ type, updated }: { type: string; updated: string }) => {
  console.log("updated=", type);
  if ("permit.issued" === type) permitsUpdated(true);
  if ("violation.issued" === type) violationsUpdated(true);
  if ("permittable.issued" === type) permittablesUpdated(true);
});

const watchstores: Record<string, Readable<any>> = {};

// const updater = readable<{
//   //watch: (path: string) => Readable<any>;
//   status: string;
//   connecting?: boolean;
//   connected: boolean;
//   reconnecting?: boolean;
//   disconnected?: boolean;
//   error?: any;
//   offline?: boolean;
// }>(
//   {
//     //watch,
//     status: "connecting",
//     connecting: true,
//     connected: false,
//   },
//   function start(set) {
//     const base = {
//       //client: mqtt,
//       //watch,
//     };

//     set({
//       ...base,
//       status: "connecting",
//       connecting: true,
//       connected: mqtt.connected,
//       //reconnecting: mqtt.reconnecting, // this is undefined while connecting for first time?
//     });

//     // setup events
//     mqtt.on("connect", function (connack) {
//       console.log("mqtt.on=connect");
//       set({
//         ...base,
//         status: "connected",
//         connected: mqtt.connected,
//         reconnecting: mqtt.reconnecting,
//       });
//     });

//     mqtt.on("reconnect", function () {
//       console.log("mqtt.on=reconnect");
//       set({
//         ...base,
//         status: "reconnecting",
//         connected: mqtt.connected,
//         reconnecting: mqtt.reconnecting,
//       });
//     });

//     mqtt.on("close", function () {
//       console.log("mqtt.on=close");
//       set({
//         ...base,
//         status: "disconnected",
//         disconnected: true,
//         connected: mqtt.connected,
//         reconnecting: mqtt.reconnecting,
//       });
//     });

//     mqtt.on("offline", function () {
//       console.log("mqtt.on=offline");
//       set({
//         ...base,
//         status: "offline",
//         offline: true,
//         connected: mqtt.connected,
//         reconnecting: mqtt.reconnecting,
//       });
//     });

//     mqtt.on("error", function (error) {
//       console.log("mqtt.on=error");
//       set({
//         ...base,
//         status: "error",
//         error: error,
//         connected: mqtt.connected,
//         reconnecting: mqtt.reconnecting,
//       });
//     });

//     //   Event 'connect'
//     // function (connack) {}

//     // Emitted on successful (re)connection (i.e. connack rc=0).

//     // connack received connack packet. When clean connection option is false and server has a previous session for clientId connection option, then connack.sessionPresent flag is true. When that is the case, you may rely on stored session and prefer not to send subscribe commands for the client.
//     // Event 'reconnect'
//     // function () {}

//     // Emitted when a reconnect starts.

//     // Event 'close'
//     // function () {}

//     // Emitted after a disconnection.

//     // Event 'disconnect'
//     // function (packet) {}

//     // Emitted after receiving disconnect packet from broker. MQTT 5.0 feature.

//     // Event 'offline'
//     // function () {}

//     // Emitted when the client goes offline.

//     // Event 'error'
//     // function (error) {}

//     // Emitted when the client cannot connect (i.e. connack rc != 0) or when a parsing error occurs.

//     // The following TLS errors will be emitted as an error event:

//     // ECONNREFUSED
//     // ECONNRESET
//     // EADDRINUSE
//     // ENOTFOUND
//     // Event 'end'
//     // function () {}

//     // Emitted when mqtt.Client#end() is called. If a callback was passed to mqtt.Client#end(), this event is emitted once the callback returns.

//     return function stop() {
//       // end the mqtt session
//       // mqttConnection.end(function () {
//       //   set({
//       //     ...base,
//       //     status: "disconnected",
//       //     disconnected: true,
//       //     connected: mqtt.connected,
//       //     reconnecting: mqtt.reconnecting,
//       //   });
//       // });
//     };
//   }
// );

// updater.subscribe(($value) => console.log("updater=", $value));

// const rootupdater = watch("locations/#");

// rootupdater.subscribe(($value) => console.log($value));
