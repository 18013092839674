<script context="module">
</script>

<script>
  import TimeIntervalItem from "../TimeIntervalItem.svelte";

  export let item;
  export let record = null;
  export let interval = true;
  $: value = (item && item.interval && item[item.type]?.[item.interval]) || 0;
</script>

<aside class="{item.type} summary">
  <!-- <h1>
        <dfn>{item.title || "Checks"}</dfn>
        {#if interval}
        <TimeIntervalItem {item} />
        {/if}
    </h1>
    <time datetime="{item.interval}"><data class="count" value="{value}">{value === 0 ? "0" : value}</data></time> -->
  <dl class="data">
    <dt>{item.title || "Checks"}</dt>
    <dd>
      <time datetime={item.interval}
        ><data class="count" {value}>{value === 0 ? "0" : value}</data></time
      >
    </dd>
  </dl>
</aside>
