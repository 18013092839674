import { derived, readable, type Readable } from "svelte/store";
import { toMilliseconds } from "duration-fns";
import { pagevisible } from "./behaviorstores";
import { online } from "@parkingboss/svelte-utils";
import { isEqual } from "date-fns";

const stores = {};

export function time(interval): Readable<Date> {
  const ms = toMilliseconds(interval);
  return (
    stores[ms] ||
    (stores[ms] = readable(new Date(), (set) => {
      set(new Date());
      const i = setInterval(() => set(new Date()), ms);
      return () => clearInterval(i);
    }))
  );
}

const onlineVisible = {};

export function onlineVisibleTime(interval): Readable<Date> {
  var ms = toMilliseconds(interval);
  return (
    onlineVisible[ms] ||
    (onlineVisible[ms] = derived<
      [Readable<Date>, typeof pagevisible, typeof online],
      Date
    >(
      [time(ms), pagevisible, online],
      function updater([$now, $visible, $online], set) {
        //!!$online && $visible && set($now);

        if (!$online) return; // block updates while offline
        if (!$visible) return; // block updates while hidden

        // date equality sucks
        if ((updater as any).$last && isEqual($now, (updater as any).$last))
          return;

        (updater as any).$last = $now; // cache

        set($now);
      },
      new Date()
    ))
  );
}
