<script>
  import { externalCRMRentables } from "$utils/propertystores";
  import { sortBy } from "$utils/sort";
  import ItemsList from "./item/ItemsList.svelte";
  import Loading from "./Loading.svelte";
  import RentableItem from "$components/rentable/RentableItem.svelte";
  import RentableRecordItem from "$components/rentable/RentableRecordItem.svelte";
  import RentableUnlinkedItem from "$components/rentable/RentableUnlinkedItem.svelte";
  import UnitStatusItem from "./unit/UnitStatusItem.svelte";
  import orderBy from "lodash-es/orderBy";

  export let data;
  export let property;

  // const start = addDays(new Date(), -90);
  // const end = addDays(new Date(), 30);

  // const interval = `${start.toISOString()}/${end.toISOString()}`;

  // $: console.log("interval=", interval);

  // $: status = data && data.unitstatus && data.unitstatus["for"] && sortBy(Object.entries(data.unitstatus["for"]).map(([ unit, details]) => [ data.items[unit], details]), ([unit]) => unit.display) || [];

  let submitting = {};

  async function dataToItems(promise) {
    const data = await promise;
    return (
      (data &&
        data.unitstatus &&
        data.unitstatus["for"] &&
        sortBy(
          Object.entries(data.unitstatus["for"]).map(
            ([unit, details]) => details
          ),
          (details) => details.subject?.display
        )) ||
      []
    );
  }

  $: status = dataToItems(data).then((data) => {
    console.log(
      "unlinked units",
      data.filter((status) => !status.subject?.id)
    );
    return data;
  });

  $: linkedRentables =
    $externalCRMRentables &&
    Object.values($externalCRMRentables)?.filter(
      (rentable) => rentable.subject?.id
    );
  $: unlinkedRentables =
    $externalCRMRentables &&
    Object.values($externalCRMRentables)?.filter(
      (rentable) => !rentable.subject?.id
    );

  $: console.log("unlinked rentables=", unlinkedRentables);

  // $: console.log("status=", status);
  // $: status && status.then(console.log);

  // $: console.log("submitting=", submitting);
</script>

{#if status}
  {#await status}
    <figure>Loading&hellip;</figure>
  {:then status}
    <section>
      <header><h1>Needs attention</h1></header>
      <ItemsList
        class="activity highlight"
        items={status.filter(
          (status) =>
            (status.subject?.id && status?.check?.vacant) ||
            status?.check?.reoccupied
        )}
        types={{ unitstatus: UnitStatusItem }}
      >
        <aside class="empty units history">None right now</aside>
      </ItemsList>
    </section>
    <section>
      <header><h1>Vacant</h1></header>
      <ItemsList
        class="items"
        items={status.filter(
          (status) =>
            status.subject?.id && status?.vacant && !status?.check.vacant
        )}
        types={{ unitstatus: UnitStatusItem }}
        ><aside class="empty units history">None right now</aside></ItemsList
      >
    </section>
    <section>
      <header><h1>Occupied</h1></header>
      <ItemsList
        class="items"
        items={status.filter(
          (status) =>
            status.subject?.id && status?.occupied && !status?.check?.reoccupied
        )}
        types={{ unitstatus: UnitStatusItem }}
      />
    </section>
  {/await}
{:else}
  <figure>Loading&hellip;</figure>
{/if}
<section>
  <header><h1>Rentables</h1></header>
  {#if linkedRentables}
    <ItemsList
      class="rentable items"
      items={sortBy(linkedRentables, "subject.display")}
      types={{
        rentable: RentableRecordItem,
      }}
      highlight={{
        //rentable: true,
      }}
    />
    <!-- {#each sortBy(Object.entries(groupBy($externalRentables, "category.name")), 0) as [name, rentables]}
      <header><h1>{name} Rentables</h1></header>
      <ItemsList
        class="rentable activity"
        items={sortBy(rentables, "name")}
        types={{
          rentable: RentableItem,
        }}
        highlight={{
          rentable: true,
        }}
      />
    {/each} -->
  {:else}
    <Loading />
  {/if}
</section>
<section>
  <header>
    <h1>Unlinked</h1>
  </header>
  {#await status}
    <Loading message="Checking unlinked units" />
  {:then status}
    <ItemsList
      class="items"
      items={status.filter((status) => !status.subject?.id)}
      types={{ unitstatus: UnitStatusItem }}
    />
  {/await}

  {#if unlinkedRentables}
    <ItemsList
      class="rentable items"
      items={orderBy(unlinkedRentables, ["category.name", "name"])}
      loading="Checking unlinked rentables"
      types={{
        rentable: RentableUnlinkedItem,
      }}
      highlight={{
        //rentable: true,
      }}
    />
  {:else}
    <Loading />
  {/if}
</section>
