<script lang="ts">
  import PropertiesList from "$components/property/PropertiesList.svelte";

  export let properties: Properties;
</script>

<section>
  <!-- <header>
    <h1>Properties</h1>
  </header> -->
  <PropertiesList {properties} />
</section>
