<script lang="ts">
  import ItemsList from "$components/item/ItemsList.svelte";
  import Loading from "$components/Loading.svelte";
  import AmenitiesPromo from "$components/amenities/AmenitiesPromo.svelte";
  import PermitPolicySelector from "$components/permit/PermitPolicySelector.svelte";
  import Construction from "$components/promo/Construction.svelte";
  import AmenitiesFeatures from "$components/amenities/AmenitiesFeaturesPromo.svelte";
  import Copy from "$components/util/Copy.svelte";
  import { sign } from "$utils/auth";
  import { help, message } from "$utils/help";
  import { policies, permits as allPermits } from "$utils/propertystores";
  import { comparer, textDesc } from "$utils/sort";
  import { title } from "$utils/text";
  import TenantNotify from "$components/tenant/TenantNotify.svelte";
  export let property: Property;

  $: enabled = !!property?.amenities.enabled;

  // $: if (amenities)
  //   location.href = sign(
  //     `https://amenities.communityboss.app/properties/${property.id}`
  //   );

  //document.documentElement.setAttribute("data-records", "amenities");

  $: amenityPolicies = $policies
    ?.filter((item) => item.amenity !== "parking" && item.enabled)
    .sort(comparer("title"));
</script>

<section class="amenities">
  {#if enabled}
    <!-- sections -->
    <header>
      <h1>Amenities</h1>

      <Construction
        message="We're building an awesome new way to manage your amenities!"
      >
        <a
          target="_blank"
          href={sign(
            `https://amenities.communityboss.app/properties/${property.id}`
          )}>Open Legacy Amenity Manager</a
        >
      </Construction>

      <aside class="help">
        <p>
          <a
            on:click={help}
            href="mailto:help@communityboss.com?subject=Amenity Boss&body=I'd like to learn more about my Amenity Boss setup:"
            >I'd like to learn more</a
          >
        </p>
      </aside>
    </header>
  {:else if property}
    <header>
      <h1>Amenities</h1>
      <figure role="alert" class="upgrade">
        <p>Oh no! You don't have Amenity Boss setup.</p>
        <p>Want to run your amenities better?</p>
        <a
          on:click={message("I'm interested in Amenity Boss")}
          href="mailto:help@communityboss.com?subject=Amenity Boss&body=Interested in Amenity Boss:"
          >I'm Interested</a
        >
        <a
          href="https://communityboss.com/{property.format}/amenity-boss"
          target="_blank">Learn More</a
        >
      </figure>
    </header>
  {:else}
    <header>
      <h1><Loading /></h1>
    </header>
  {/if}
  {#if amenityPolicies}
    {#each amenityPolicies.filter((item) => item.audience.admin) as item}
      <section>
        <header>
          <h1>{title(item.title)}</h1>
          <nav>
            <ul>
              <li><PermitPolicySelector {property} policies={[item]} /></li>
            </ul>
          </nav>

          <h2>Last 20 {item.permit.continuous ? "assigned" : "issued"}:</h2>
        </header>

        <ItemsList
          loading="Loading latest"
          class="info items"
          items={$allPermits &&
            Object.values($allPermits.items)
              .filter(
                (permit) =>
                  (permit.issued.policy?.policy || permit.issued.policy) ===
                  item.policy
              )
              .sort(comparer("issued.datetime", textDesc))
              .slice(0, 20)}
        />
      </section>
      <!-- {:else} -->
    {/each}
    {#each amenityPolicies.filter((item) => false && item.audience.public) as item}
      <section>
        <header>
          <h1>{title(item.title)}</h1>
          <nav>
            <!-- <ul>
              <li><PermitPolicySelector {property} policies={[item]} /></li>
            </ul> -->
          </nav>

          <!-- <h2>Last 20 {item.permit.continuous ? "assigned" : "issued"}:</h2> -->
        </header>

        <ItemsList
          loading="Loading latest"
          class="info items"
          items={$allPermits &&
            Object.values($allPermits.items)
              .filter(
                (permit) =>
                  (permit.issued.policy?.policy || permit.issued.policy) ===
                  item.policy
              )
              .sort(comparer("issued.datetime", textDesc))
              .slice(0, 20)}
        />
      </section>
      <!-- {:else} -->
    {/each}
  {:else}
    <section>
      <header><h1><Loading message="Loading" /></h1></header>
    </section>
  {/if}
  {#if enabled}
    <section>
      <header><h1>Community Amenities</h1></header>
      <nav>
        <ul>
          <li>
            <TenantNotify variant="amenities" />
            <!-- <a
                  href="https://amenitypass.app/properties/{property.id}"
                  target="_blank">Amenity Pass App</a
                >
                <Copy
                  value="https://amenitypass.app/properties/{property.id}"
                /> -->
          </li>
        </ul>
      </nav>
      <ItemsList
        class="info"
        items={amenityPolicies?.filter((item) => item.audience.public)}
      />
    </section>
  {/if}
  {#if property}
    <section>
      <header>
        <h1>Setup an Amenity</h1>
        <nav>
          <ul>
            <li>
              <a
                on:click={message("I'd like to setup an amenity:")}
                href="mailto:help@communityboss.com?subject=Amenity Boss&body=I'd like to setup an amenity:"
                >Help Me Setup&hellip;</a
              >
            </li>
          </ul>
        </nav>
      </header>
      <AmenitiesFeatures {property} />
    </section>
  {/if}
</section>
