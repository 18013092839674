import words from "lodash-es/words";
import capitalize from "lodash-es/capitalize";

const allcaps = new Set(["BBQ", "HOA", "USA"]);

export function title(text: string) {
  if (!text) return text;
  return words(text)
    .map((word: string) => {
      if (allcaps.has(word)) return word;
      return capitalize(word);
    })
    .join(" ");
}
