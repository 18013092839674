<script lang="ts">
  import RecordSpaceCovered from "$components/record/RecordSpaceCovered.svelte";
  import SpaceAccessSharedItem from "./SpaceAccessSharedItem.svelte";
  import SpaceCapacityBicycleItem from "./SpaceCapacityBicycleItem.svelte";
  import SpaceCapacityChargingItem from "./SpaceCapacityChargingItem.svelte";
  import SpaceCapacityDisabledItem from "./SpaceCapacityDisabledItem.svelte";
  import SpaceSizeItem from "./SpaceParkingSizeItem.svelte";
  import upperFirst from "lodash-es/upperFirst";
  export let item: Space;
</script>

<article class="space info">
  <dl>
    {#if "parking" === item.format}
      <!-- <dt>Capacity</dt>
            <dd>{item.capacity}</dd> -->
      <SpaceSizeItem {item} />
      <dt>Covered</dt>
      <dd><RecordSpaceCovered {item} label={true} /></dd>
      <dt>Lift</dt>
      <dd>
        <data class="capacity-lift" value={item["capacity:lift"] || ""}
          >{upperFirst(item["capacity:lift"]) || "No"}</data
        >
      </dd>
      <SpaceAccessSharedItem {item} />
      <dt>Storage</dt>
      <dd>
        <data class="capacity-storage" value={item["capacity:storage"] || ""}
          >{upperFirst(item["capacity:storage"]) || "No"}</data
        >
      </dd>
      <SpaceCapacityDisabledItem {item} />
      <SpaceCapacityChargingItem {item} />
      <SpaceCapacityBicycleItem {item} />
    {/if}
    {#if "storage" === item.format}
      <!-- <dt>Capacity</dt>
            <dd>{item.capacity}</dd> -->
      <!-- <SpaceSizeItem {item} /> -->
      <dt>Covered</dt>
      <dd><RecordSpaceCovered {item} label={true} /></dd>
      <!-- <dt>Lift</dt>
      <dd>
        <data class="capacity-lift" value={item["capacity:lift"] || ""}
          >{upperFirst(item["capacity:lift"]) || "No"}</data
        >
      </dd> -->
      <!-- <dt>Storage</dt>
      <dd>
        <data class="capacity-storage" value={item["capacity:storage"] || ""}
          >{upperFirst(item["capacity:storage"]) || "No"}</data
        >
      </dd> -->
      <!-- <SpaceCapacityDisabledItem {item} />
      <SpaceCapacityChargingItem {item} /> -->
      <!-- <SpaceCapacityBicycleItem {item} /> -->
    {/if}
  </dl>
</article>
