<script context="module">
</script>

<script>
  import TimeIntervalItem from "./TimeIntervalItem.svelte";
  export let item;
  export let interval = true;
</script>

<aside class="{item.type} summary">
  <dl class="data">
    <dt>Suspicious</dt>
    <dd>
      <time datetime={item.interval}
        ><data class="count" value={item.count}
          >{item.count === 0 ? "0" : item.count}</data
        ></time
      >
    </dd>
  </dl>
  <!-- <h1>
        <dfn>Suspicious</dfn>
        {#if interval}
        <TimeIntervalItem {item} />
        {/if}
    </h1>
    <time datetime="{item.interval}"><data class="count" value="{item.count}">{item.count === 0 ? "0" : item.count}</data></time> -->
</aside>
