<script lang="ts">
  import RecordExtras from "./RecordExtras.svelte";
  import RecordLink from "./RecordLink.svelte";
  import RecordSpaceCovered from "./RecordSpaceCovered.svelte";
  import RecordUnit from "./RecordUnit.svelte";
  import defaults from "./defaults";

  export let addressed: boolean | null = defaults.addressed;

  export let url = true;
  export let item: any = null;
</script>

{#if item}
  <RecordLink {item} {url}>
    {#if "unit" === item.type}
      <RecordUnit {item} {addressed}
        ><slot><RecordExtras {item} /></slot></RecordUnit
      >
    {:else}
      <data
        class={["id", item.type, item.format].filter(Boolean).join(" ")}
        value={item.id || item.key}
      >
        <dfn
          ><abbr title={item.display}
            >{item.name || item.display}
            <slot
              ><RecordSpaceCovered {item} label={false} /><RecordExtras
                {item}
              /></slot
            ></abbr
          ></dfn
        ></data
      >
    {/if}
  </RecordLink>
{/if}
