<script lang="ts">
  import NotifyLink from "../NotifyLink.svelte";
  import { state as editing } from "$utils/item";
  export let item: Violation;
  export let removable = true;
  async function copyon(e: Event) {
    var target = e.target as HTMLButtonElement | HTMLAnchorElement;

    var value =
      (target as HTMLButtonElement).value || (target as HTMLAnchorElement).href;

    if (!value) return; // nothing to copy

    //if ((target as HTMLButtonElement).value) target.select();

    await navigator.clipboard.writeText(value);

    target.classList.add("copied");

    setTimeout((cl) => target.classList.remove(cl), 3000, "copied");
  }
</script>

<nav>
  <h1>Options</h1>
  <ul>
    <li>
      <NotifyLink {item} />
    </li>
    {#if removable}
      <li>
        <button
          type="button"
          class="revoke"
          on:click={(e) => $editing.start("revoke", item)}>Remove</button
        >
      </li>
    {/if}
    <li>
      <a
        class="copy"
        href={item.url}
        target="_blank"
        on:click|preventDefault={copyon}>Copy Link</a
      >
    </li>
  </ul>
</nav>
