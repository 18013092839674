<script lang="ts">
  import { createEventDispatcher } from "svelte";
  export let item: Typed = null;
  export let types: Record<string, any>;
  import { resolve } from "../components";
  export let context: Record<string, any>;
  const events = createEventDispatcher<{
    removed: Typed;
    remove: Typed;
  }>();
</script>

<svelte:component
  this={types?.[item.type] || resolve(item)}
  on:removed={() => events("removed", item)}
  on:remove={() => events("remove", item)}
  {item}
  {...context}
/>
