<script>
  import Record from "$components/record/RecordItem.svelte";
  import { format, toZonedTime } from "date-fns-tz";
  import { title as proper } from "$utils/text";
  import startCase from "lodash-es/startCase";
  import TimeIntervalItem from "./TimeIntervalItem.svelte";
  import ViolationExceptionChartItem from "./ViolationExceptionChartItem.svelte";
  import Time from "./Time.svelte";
  export let item = null;
  export let record = null;
  //$: console.log(item);
</script>

{#if item}
  <article
    class="violations details"
    class:exception={item.active}
    class:highlight={item.active}
    class:zero={item.count === 0}
    data-violations={item.count}
    data-id={item.id}
  >
    {#if item.active}
      <header>
        <h1>
          <data value={item.id}
            ><dfn>{startCase(item.title.toLowerCase())}</dfn></data
          >
          <!-- <TimeIntervalItem {item} /> -->
        </h1>
        <!-- <time datetime={item.interval}>
          <data class="violations count">{item.count}</data>
        </time> -->
      </header>

      <!-- <p>
        <data class="limit" value={item.limit.count}
          ><dfn>After</dfn>
          <time datetime={item.duration}>{item.limit.display}</time></data
        >
      </p> -->
      <dl>
        <dt>Status</dt>
        <dd>{item.display}</dd>
        {#if item.latest}
          <dt>Latest</dt>
          <dd>
            <Time
              datetime={item.latest}
              timezone={item.timezone}
              format={"h:mm a EEE MMM d yyyy zzz"}
            />
          </dd>
        {/if}
        <dt>Threshold</dt>
        <dd>{item.limit.display}</dd>
      </dl>
      <ul class="subjects">
        {#each [item.subject].filter((item) => !!item && item.id !== (record?.id || record)) as item}
          <li><Record {item} /></li>
        {/each}
      </ul>
      {#if item.violations}
        <ViolationExceptionChartItem {item} />
      {/if}

      <!-- <p><data class="count" value="{item.count}"><dfn>Has {item.display}</dfn>{#if item.latest} <time datetime="{item.latest}">, <dfn>most recently at</dfn> <abbr>{format(toZonedTime(item.latest, item.timezone), "h:mm a MMM d yyyy zzz", { timeZone: item.timezone })}</abbr></time>{/if}</data></p> -->
    {:else}
      <header>
        <ol class="strikes">
          {#each [...Array(Math.max(item.count, item.limit.count + 1)).keys()].map((i) => i + 1) as i}
            <li>
              <data
                class="count"
                class:active={i <= item.count}
                value={i}
                class:exception={i <= item.count && i > item.limit.count}
                >{i}</data
              >
            </li>
          {/each}
        </ol>
      </header>

      <ul class="subjects">
        {#each [item.subject].filter((item) => !!item && item.id !== (record?.id || record)) as item}
          <li><Record {item} /></li>
        {/each}
      </ul>
      <dl>
        <dt>Threshold</dt>
        <dd>
          <data value={item.id}
            ><dfn>{startCase(item.title.toLowerCase())}</dfn></data
          >
        </dd>
        <dd>{item.limit.display}</dd>
        <dt>Status</dt>
        <dd>{item.display}</dd>
        {#if item.latest}
          <dt>Latest</dt>
          <dd>
            <Time
              datetime={item.latest}
              timezone={item.timezone}
              format={"h:mm a EEE MMM d yyyy zzz"}
            />
          </dd>
        {/if}
      </dl>

      <!-- <p>
        <data class="count" value={item.count}>
          <dfn>Has {item.display}</dfn>{#if item.latest}
            <time datetime={item.latest}
              >, <dfn>most recently at</dfn>
              <abbr
                >{format(
                  toZonedTime(item.latest, item.timezone),
                  "h:mm a MMM d yyyy zzz",
                  { timeZone: item.timezone }
                )}</abbr
              ></time
            >{/if}
        </data>
        <data class="limit" value={item.limit.count}
          ><dfn>{proper(item.title)}</dfn> after
          <time datetime={item.duration}>{item.limit.display}</time></data
        >
      </p> -->
    {/if}
    <!-- <ul class="subjects">
    {#each [item.subject].filter(record) as item}
    <li><Record {item} /></li>
    {/each}
    </ul> -->
  </article>
{/if}
