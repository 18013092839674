<script lang="ts">
  import { permits } from "$utils/propertystores";

  export let subject: Typed;
  export let also: Typed | false;
  export let label = "Already in use";
  export let editing: Permit | string | null = null;

  //$: console.log("editing=", editing);

  function equaltype(a: Typed, b: Typed) {
    if (a.type === "tenant" && b.type === "unit") return true;
    if (a.type === "unit" && b.type === "tenant") return true;
    return a.type === b.type;
  }

  function equals(a: Typed, b: Typed) {
    if (!equaltype) return false;
    if (a.type === "vehicle" && b.type === "vehicle")
      return a.id === b.id || a.plate === b.plate;
    return (
      (b.id ?? b) === (a.subject?.id ?? a.subject) ||
      (a.id ?? a) === (b.subject?.id ?? b.subject) ||
      (a.id ?? a) === (b.id ?? b)
    );
    // if (a.type === "tenant" && b.type === "unit") {
    //   return b.id === (a.subject?.id ?? a.subject);
    // } else if (a.type === "unit" && b.type === "tenant") {
    //   return a.id === (b.subject?.id ?? b.subject);
    // }
    // return a.type === b.type && a.id === b.id;
  }

  function alertCheckSpace(item: Typed) {
    if (item?.type !== "space") return true;
    if (item.capacity > 1) return false;
    if (item.name?.match(/(open|guest)/i)) return false;
    return true;
  }

  $: inuse =
    $permits &&
    subject &&
    alertCheckSpace(subject) &&
    (false === also || null != also) &&
    !!Object.values($permits?.items).find((p) => {
      //console.log("permit=", p);
      if (p.expired || p.cancelled) return false;
      if (p.id === (editing as Permit)?.id || p.id === editing) return false;
      var subjects = Object.values(p.subjects);
      if (!Object.values(subjects).find((item) => equals(item, subject)))
        return false;
      //console.log("permit=", p);
      //if (!p.subjects?.[subject.id]) return false; // doesn't apply to queryied subject
      //console.log("subjects=", subjects);
      return (
        false === also ||
        !!Object.values(subjects).find(
          (item) =>
            equaltype(item, also as Typed) && !equals(item, also as Typed)
        )
      );
    });

  //$: console.log("checking in use", subject, also, inuse);
</script>

{#if true === inuse}
  <figure class="inuse">
    <a
      href="/property/{subject.scope.id ??
        subject.scope}/{subject.type}/{subject.id}">{label}</a
    >
  </figure>
{/if}
