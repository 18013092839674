<script lang="ts" context="module">
  const id = param("id");
</script>

<script lang="ts">
  import LprdItem from "$components/lprd/LprdItem.svelte";
  import { param } from "$utils/params";
  import { devices, now } from "$components/lprd";
  import NavTo from "$components/NavTo.svelte";
  import { instant } from "$utils/temporal";

  $: ids = new Set(
    [$id as string]
      .concat(new URL(location.href).searchParams.getAll("id"))
      .filter(Boolean)
  );

  $: console.log("ids=", ids);

  function ago(timestamp: string, now: Temporal.ZonedDateTime) {
    if (!timestamp) return "--";
    const duration = Temporal.Instant.from(timestamp).until(now.toInstant());
    if (duration.total("seconds") < 3) return "live";
    if (duration.total("days") > 1)
      return duration.total("days").toFixed(0) + "d";
    if (duration.total("hours") > 1)
      return duration.total("hours").toFixed(0) + "h";
    if (duration.total("minutes") > 1)
      return duration.total("minutes").toFixed(0) + "m";
    return duration.total("seconds").toFixed(0) + "s";
  }
</script>

<header>
  <h1>LPRD</h1>
  <nav class="app lprd">
    <ul class="primary lprd">
      {#each Object.entries($devices) as [itemid, item]}
        <li>
          <NavTo url="/lprd/{itemid}/stat" selected={ids.has(itemid)}
            >{item.name}
            <time datetime={item.valid ?? ""}>{ago(item.valid, $now)}</time>
          </NavTo>
        </li>
      {/each}
    </ul>
  </nav>
</header>
<main class="lprd">
  {#each ids as lprd}
    <LprdItem id={lprd} />
  {/each}
</main>
