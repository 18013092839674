<script>
  import { parseISO } from "date-fns";
  import { sortBy } from "$utils/sort";
  import ItemsList from "./item/ItemsList.svelte";
  import PaymentMetricsItem from "./PaymentMetricsItem.svelte";

  export let data;
  export let group = "property";

  //$: console.log("payments=", data);

  $: propertyMonthsNet =
    data &&
    Object.values(data?.metrics.items || {}).filter(
      (i) =>
        i.datetimes == "P1M" &&
        i.payments == "net" &&
        (i.group == group ||
          (i.group == "property" && i.property == group) ||
          (i.group == "property+policy" && i.policy == group))
    );
  //$: console.log("payments=", propertyMonthsNet);
  $: propertyDaysNet =
    data &&
    Object.values(data?.metrics.items || {}).filter(
      (i) =>
        i.datetimes == "P1D" &&
        i.payments == "net" &&
        (i.group == group ||
          (i.group == "property" && i.property == group) ||
          (i.group == "property+policy" && i.policy == group))
    );

  $: propertyMonthsNetSummary =
    propertyMonthsNet &&
    propertyMonthsNet.reduce(function summarize(summary, item) {
      var dates = item.interval
        .split("/")
        .concat(summary.interval?.split("/") || []);

      return Object.assign(summary, {
        ...item,
        interval:
          data.metrics.interval ??
          `${dates.reduce((min, item) =>
            parseISO(item) < parseISO(min) ? item : min
          )}/${dates.reduce((max, item) =>
            parseISO(item) > parseISO(max) ? item : max
          )}`,
        values: Object.assign({}, item.values, summary.values || {}),
      });
    }, {});
</script>

<ItemsList
  class="items"
  items={data && [
    //...Object.values(items).filter(i => i.datetimes == "P1M")
    propertyMonthsNetSummary,
    ...sortBy(
      [...propertyMonthsNet, ...propertyDaysNet],
      "interval",
      undefined,
      true
    ),
  ]}
  types={{
    paymentmetrics: PaymentMetricsItem,
    metric: PaymentMetricsItem,
  }}
/>
