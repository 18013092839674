<script lang="ts">
  import Loading from "$components/Loading.svelte";
  import ComingSoon from "$components/promo/ComingSoon.svelte";
  import Construction from "$components/promo/Construction.svelte";
  import Copy from "$components/util/Copy.svelte";
  import WelcomeProperty from "$components/welcome/WelcomeProperty.svelte";
  import WelcomeUnit from "$components/welcome/NotifyTenant.svelte";
  import { sign } from "$utils/auth";
  import { smscreen } from "$utils/behaviorstores";
  import { help } from "$utils/help";
  import svgqr from "svgqr.js";
  import TenantNotify from "$components/tenant/TenantNotify.svelte";

  export let property: Property;
  $: enabled = !!property?.tenants?.tour?.enabled;
</script>

<section class="welcome">
  {#if enabled}
    <!-- sections -->
    <header>
      <h1>Welcome Boss</h1>
      <h2>Manage hospitality like a boss.</h2>
      <p>Make it easy to move in, use, visit, and deliver to your community.</p>
      <!-- <ul>
        <li>Community Directory</li>
        <li>Resident Guide</li>
      </ul> -->
      <aside class="help">
        <p>
          <a
            on:click={help}
            href="mailto:help@communityboss.com?subject=Welcome Boss&body=I'd like to learn more about my Welcome Boss setup:"
            >I'd like to learn more</a
          >
        </p>
      </aside>
    </header>
    <section>
      <header>
        <h1>Community Directory</h1>
        <!-- <nav>
          <ul>
            <li>
              <a
                href="https://my.communityhq.app?property={property.id}"
                target="_blank">Directory Map & Guide</a
              >
              <Copy value="https://my.communityhq.app?property={property.id}" />
            </li>
            <li>
              <a
                href="https://api.qrserver.com/v1/create-qr-code/?ecc=M&format=eps&data=HTTPS%3A%2F%2FSCANME.IO%2F1%2F{property.id.toUpperCase()}%2F0%2FDIR"
                download="directory-qr.eps">Download QR Barcode</a
              >
            </li>
          </ul>
        </nav> -->
      </header>

      <ol class="activity">
        <!-- <li class="full">
          <figure class="map">
            <img
              src="https://static.carto.communityboss.app/cdn-cgi/image/w=600,h=400,format=auto/?property={property.id}&size=900x600"
            />
          </figure>
        </li> -->
        <li>
          <WelcomeProperty {property} />
        </li>
        <!-- <li>
          <figure class="qr">
            {@html svgqr(
              `HTTPS%3A%2F%2FSCANME.IO%2F1%2F${property.id.toUpperCase()}%2F0%2FDIR`,
              {
                correction: "L",
                //mode:"Alphanumeric"
              }
            )}
            <figcaption>
              <a
                href="https://api.qrserver.com/v1/create-qr-code/?ecc=M&format=eps&data=HTTPS%3A%2F%2FSCANME.IO%2F1%2F{property.id.toUpperCase()}%2F0%2FDIR"
                download="directory-qr.eps">Download QR Barcode</a
              >
            </figcaption>
          </figure>
        </li> -->
      </ol>

      <ol class="promo">
        <li>
          <article class="promo">
            <h1>Welcome Signage</h1>
            <img src="/directory-sign.png" />
            <p>
              Professional, branded signage for residents, guests, and delivery.
            </p>
            <!-- <a target="_blank" href=""></a> -->
          </article>
        </li>
        <li>
          <article class="promo">
            <h1>Wayfinding Signage</h1>
            <img src="/wayfinding-sign.png" />
            <p>
              Small directory signs for elevators, stairwells, or amenity
              spaces.
            </p>
            <!-- <a target="_blank" href=""></a> -->
          </article>
        </li>
      </ol>
      <!-- {#if !$smscreen}
        <iframe
          class="app"
          title="App Preview"
          src="https://communityhq.app/properties/{property.id}"
        />
      {/if} -->
    </section>
    <section>
      <header>
        <h1>Resident Guide</h1>
        <nav>
          <ul>
            <li><TenantNotify variant="welcome" /></li>
          </ul>
        </nav>
      </header>
      <ol class="promo">
        <li>
          <article class="promo">
            <h1>Resident Magnet</h1>
            <img src="/directory-magnet.png" />
            <p>
              Refrigerator magnets provide quick access right from the
              resident's home.
            </p>
            <!-- <a target="_blank" href=""></a> -->
          </article>
        </li>
      </ol>
      <!-- <WelcomeUnit {property} /> -->
    </section>
    <!-- <section>
      <header>
        <h1>Visitor Passes</h1>
        <ComingSoon />
      </header>
    </section> -->
  {:else if property}
    <header>
      <h1><h1>Welcome</h1></h1>
    </header>
    <section>
      <header>
        <h1>Key Features</h1>
      </header>
    </section>
  {:else}
    <header>
      <h1><Loading /></h1>
    </header>
  {/if}
</section>
