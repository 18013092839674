<script lang="ts">
  import { fetchDeleteAuthorization } from "$utils/api";
  import { authorizationsUpdated } from "$utils/propertystores";
  export let item: Authorization;
  let submitting = false;

  async function submit(e) {
    if (submitting) return;

    if (!item) return;

    if (
      !window.confirm(`Are you sure you want to remove ${item.principal.name}?`)
    )
      return;

    await fetchDeleteAuthorization(item.scope, item.principal.id);

    // set teh store
    authorizationsUpdated.set(Date.now());

    submitting = false;
  }
</script>

<nav>
  <h1>Options</h1>
  <ul>
    <li>
      <button
        type="button"
        disabled={submitting}
        class="remove"
        on:click={submit}>Remove</button
      >
    </li>
  </ul>
</nav>
