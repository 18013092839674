<script context="module">
  import { help } from "$utils/help";
  import { comparer, dateDesc } from "$utils/sort";
</script>

<script>
  import orderBy from "lodash-es/orderBy";
  import AuthorizationItem from "$components/authorization/AuthorizationItem.svelte";
  import AuthorizeForItem from "$components/authorization/AuthorizeForItem.svelte";
  import ItemsList from "$components/item/ItemsList.svelte";
  import Time from "$components/Time.svelte";
  import UserSessionItem from "$components/UserSessionItem.svelte";
  import Loading from "$components/Loading.svelte";

  export let property;
  export let authorized;
  export let sessions;

  let show_full_access_form = false;
  let show_enforcement_access_form = false;

  // $: console.log("authorized=", authorized);
  // $: console.log("sessions=", sessions);

  // $: items = Object.values(authorized?.items || {}).reduce((result, item) => {
  //     if(item.principal.email.includes("@parkingboss.com")) return result;
  //     result[item.principal.id] = item;
  //     return result;
  // }, {});

  $: fullaccess = Object.values(authorized?.items || {})
    .filter((auth) => auth.roles.admin)
    .sort(comparer("valid.min.datetime", dateDesc));
  $: enforcementonly = Object.values(authorized?.items || {})
    .filter((auth) => !auth.roles.admin && auth.roles.patrol)
    .sort(comparer("valid.min.datetime", dateDesc));

  $: propertySessions = orderBy(
    Object.values(
      Object.values(sessions?.items || {}).reduce(
        (result, item) => {
          if (!result[item.principal.id]) return result;
          Object.assign(result[item.principal.id], item);
          return result;
        },
        Object.values((sessions && authorized?.items) || {}).reduce(
          (result, item) => {
            result[item.principal.id] = {
              type: "sessions",
              principal: item.principal,
              values: [],
              seconds: 0,
              latest: "",
              generated: sessions.generated,
              valid: sessions.valid.utc,
              roles: item.roles,
            };
            return result;
          },
          {}
        )
      )
    ),
    ["latest"],
    ["desc"]
  );

  //$: console.log("sessions=", propertySessions);
</script>

<section class="users">
  <header>
    {#if property}
      <h1>Team</h1>
      <dl>
        <dt>Types</dt>
        <dd>Full Access enabled</dd>
        <dd>
          Enforcement Only {property.users.patrollers?.enabled
            ? "enabled"
            : "disabled"}
        </dd>
      </dl>
      <!-- <dl>
            <dt>Enforcement Only Users</dt>
            <dd>Can only use Field Agent app</dd>
            <dt>Enforcement Only Limits</dt>
            <dd>{property.users.patrollers?.continuous ? "Can see " : "Cannot see "}contact details for assigned permits</dd>
            <dd>{property.users.patrollers?.temporary ? "Can see " : "Cannot see "}contact details for temporary permits</dd>
            
        </dl> -->
      <dl>
        <dt>Synced</dt>
        <dd><Time datetime={authorized?.generated} /></dd>
      </dl>
      <aside class="help">
        <p>
          <a
            on:click={help}
            href="mailto:help@communityboss.com?subject=Enforcement&body=I'd like to learn more about enforcement setup:"
            >I'd like to learn more</a
          >
        </p>
      </aside>
    {:else}
      <h1><Loading /></h1>
    {/if}
  </header>
  {#if property}
    <section>
      <header>
        <h1>Full Access</h1>
        <p>These users have unlimited access to all apps and contact info</p>
        <nav>
          <ul>
            <li>
              {#if show_full_access_form}
                <AuthorizeForItem
                  bind:active={show_full_access_form}
                  item={property}
                  role="admin"
                  title="Full Access"
                />
              {:else}
                <button
                  type="button"
                  class="user"
                  on:click={() => (show_full_access_form = true)}
                  >Add&hellip;</button
                >
              {/if}
            </li>
          </ul>
        </nav>
      </header>
      <ItemsList
        class="info"
        items={fullaccess}
        types={{
          authorization: AuthorizationItem,
        }}
      />
    </section>

    <section>
      <header>
        <h1>Enforcement Only Access</h1>
        <p>
          These users are limited to Field Agent
          {#if property.users.patrollers?.continuous && property.users.patrollers?.temporary}
            with full access to contact info
          {:else if property.users.patrollers?.temporary}
            with temporary permit contact info
          {:else if property.users.patrollers?.continuous}
            with assigned permit contact info
          {:else}
            with no access to contact info
          {/if}
        </p>
        {#if property?.users.patrollers?.enabled}
          <nav>
            <ul>
              <li>
                {#if show_enforcement_access_form}
                  <AuthorizeForItem
                    bind:active={show_enforcement_access_form}
                    item={property}
                    role="patrol"
                    title="Enforcement Only"
                  />
                {:else}
                  <button
                    type="button"
                    class="user"
                    on:click={() => (show_enforcement_access_form = true)}
                    >Add&hellip;</button
                  >
                {/if}
              </li>
            </ul>
          </nav>
        {/if}
      </header>
      {#if property?.users.patrollers?.enabled}
        <ItemsList
          class="info"
          items={enforcementonly}
          types={{
            authorization: AuthorizationItem,
          }}
        >
          <aside class="empty history" slot="empty">
            No Enforcement Only users right now
          </aside>
        </ItemsList>
      {:else}
        <aside class="empty history">
          Enforcement Only users are not enabled
        </aside>
      {/if}
    </section>

    <section>
      <header>
        <h1>Activity Overview</h1>
      </header>
      <ItemsList
        class="items"
        items={propertySessions}
        types={{
          sessions: UserSessionItem,
        }}
      />
    </section>
  {/if}
</section>
