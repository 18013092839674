<script lang="ts">
  import RecordAddress from "$components/record/RecordAddress.svelte";
  import { unitPrefixed } from "$utils/unit";

  export let item: Unit;
</script>

<article class="unit info">
  <dl>
    <dt>Address</dt>
    <dd>
      {#if [item?.["addr:housenumber"], item?.["addr:street"], item?.["addr:unit"]].find(Boolean)}
        <RecordAddress {item} />
      {:else}
        Not set
      {/if}
    </dd>
    <dt>Mailbox</dt>
    <dd>{item?.["post:box"] || "Not set"}</dd>
    <dt>Floorplan</dt>
    <dd>{item?.style ?? "Not set"}</dd>
    <dt>Bedrooms</dt>
    <dd>{item?.bedrooms || "Not set"}</dd>
  </dl>
</article>
