<script lang="ts">
  import { confirmedOffsite } from "$utils/propertystores";

  export let property: Property;
  //export let onsite: Boolean;
  export let alert: Property;
  export let position: GeolocationPositionStatus;
</script>

{#if position}
  <!-- <pre>{JSON.stringify(property.address.area ?? {})}</pre>
    <pre>{JSON.stringify(position)}</pre> -->
  {#if alert}
    <nav class="offsite">
      <!-- <h1>{alert.name}</h1> -->
      <h1>You appear to be offsite</h1>

      <ul>
        <li>
          <button
            type="button"
            value={alert.id}
            on:click={({ currentTarget: target }) =>
              confirmedOffsite.set(target.value)}>Ignore & Keep Working</button
          >
        </li>
        <li>
          <a
            href="/properties"
            on:click|preventDefault={({ currentTarget: target }) =>
              (location.href = target.href)}>I'm Done Here</a
          >
        </li>
      </ul>
    </nav>
  {:else if position?.status === "unstarted"}
    <!-- Your location is turned off -->
  {:else if position?.status === "unpermitted"}
    <!-- Your location is blocked -->
  {:else if position?.status === "requesting" || position?.status === "loading"}
    <!-- Finding your location -->
  {/if}
{/if}
