<script lang="ts">
  export let value: string = null;
  export let label: string = "copy";
  async function click(e: Event) {
    var target = e.target as HTMLButtonElement | HTMLAnchorElement;

    var value =
      (target as HTMLButtonElement).value || (target as HTMLAnchorElement).href;

    if (!value) return; // nothing to copy

    //if ((target as HTMLButtonElement).value) target.select();

    await navigator.clipboard.writeText(value);

    target.classList.add("copied");

    setTimeout((cl) => target.classList.remove(cl), 3000, "copied");
  }
</script>

<button class="copy" type="button" {value} on:click={click}>{label}</button>
