<script context="module">
  import SelectUnit from "$components/SelectUnit.svelte";
  import Record from "$components/record/RecordItem.svelte";
  import identity from "lodash-es/identity";
  import { help } from "$utils/help";
</script>

<script>
  export let property;
  export let units;
  export let tenants;

  let tenant;
  let account;

  let url;

  // on unit change we need to select current account

  $: parking =
    property?.public?.parking && new URL(property.public.parking).toString();
  $: amenities =
    property?.public?.amenities &&
    new URL(property.public.amenities).toString();

  //$: if(account) url = `https://my.communityhq.app/?account=${account.id}`;
  $: directory =
    property?.tenants.tour?.enabled &&
    new URL(`https://my.communityhq.app/?property=${property.id}`).toString();

  // on change generate and set account url, defaulting
  $: account = url =
    tenant &&
    new URL(`https://my.communityhq.app/?account=${tenant.id}`).toString();

  // set default url
  $: if (!url && parking) url = parking;

  //$: console.log(url, parking, directory, parking == url, directory == url);

  function preview(e) {
    console.log(e);

    if (e.metaKey || e.ctrlKey || e.shiftKey) return;
    if (e.defaultPrevented) return;

    var link = e.target.closest("a");

    if (!link?.href) return;

    if (url != link.href) {
      //account = null;
      url = link.href;
    }

    e.preventDefault();
  }

  async function copy(e) {
    if (!e.target.value || e.target.href) return; // nothing to copy

    var target = e.target;

    if (target.value) target.select();

    await navigator.clipboard.writeText(target.value ?? target.href);

    target.classList.add("copied");

    setTimeout((cl) => target.classList.remove(cl), 3000, "copied");
  }

  function unit(e) {
    console.log("unit=", e, tenants);
    const selected = e.detail;

    if (!selected) return;

    // find the tenant and set
    tenant = Object.values(tenants || {}).find(
      (item) =>
        item.subject == selected.id &&
        item.valid.utc.split("/").filter(identity).length == 1
    );
  }
</script>

<section class="apps">
  <nav on:click={preview}>
    <header><h1>Self-Guided</h1></header>
    <ul>
      {#if parking}
        <li><a class:selected={url == parking} href={parking}>Parking</a></li>
      {/if}
      {#if amenities}
        <li>
          <a class:selected={url == amenities} href={amenities}>Amenities</a>
        </li>
      {/if}
      {#if directory}
        <li>
          <a class:selected={url == directory} href={directory}>Directory</a>
        </li>
      {/if}
    </ul>
    {#if property?.tenants.tour?.enabled}
      <h1>Self-Guided Move-In</h1>
      <form>
        <fieldset>
          <SelectUnit items={units} on:unit={unit} autoclear={true} />
          <!-- <SelectTenant items={tenants} bind:selected={tenant} /> -->
        </fieldset>
      </form>
      <ul>
        {#if tenant}
          <li>
            <a class:selected={url == account} href={account}
              ><Record item={tenant} url={false} /></a
            >
          </li>
        {/if}
      </ul>
    {/if}
    <aside class="help">
      <p>
        <a
          on:click={help}
          href="mailto:help@communityboss.com?subject=Self-Guided&body=I'd like to learn more about self-guided setup"
          >I'd like to learn more</a
        >
      </p>
    </aside>
  </nav>
  <figure class="app">
    {#if url}
      <iframe src={url || parking} />
      <figcaption>
        <input type="text" value={url} readonly on:click={copy} />
        <!-- <button class="copy" value="{url}" on:click={copy}>Copy</button>-->
      </figcaption>
    {/if}
  </figure>
</section>
