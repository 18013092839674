<script context="module">
</script>

<script>
  import TimeIntervalItem from "./TimeIntervalItem.svelte";
  export let item;
  export let interval = true;
</script>

<aside class="{item.type} summary">
  <dl class="data">
    <dt>Blocks</dt>
    <dd>
      <time datetime={item.interval}
        ><data class="count" value={item.count}
          >{item.count === 0 ? "0" : item.count}</data
        ></time
      >
    </dd>
  </dl>
</aside>
