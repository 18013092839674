import each from "lodash-es/each";
import debounce from "lodash-es/debounce";
import unset from "lodash-es/unset";
import bbox from "geojson-bbox";
import mapbox from "mapbox-gl";

const accessToken = "pk.eyJ1IjoicGFya2luZ2Jvc3MiLCJhIjoiY2swY3VheHQyMDE1ejNtbjV4M3RoeTQ5cyJ9.toumXl_aMY5GgH45lZyiuA"

mapbox.accessToken =
  accessToken;

export { mapbox, accessToken, bbox };

const layers = [
  // {
  //     "id": "selected-circle",
  //     "type": "circle",
  //     "source": "property",
  //     "filter": [
  //         "all",
  //         ["==", ["geometry-type"], "Point" ],
  //         [ "has", "selected" ],
  //     ],
  //     "minzoom":18,
  //     "paint": {
  //       "circle-radius": {
  //         stops: [
  //           [5, 1],
  //           [15, 17]
  //         ],
  //         base: 2
  //       },
  //       "circle-opacity": 0,
  //         "circle-stroke-width": 2,
  //         "circle-stroke-color": "red",
  //     },
  // },
  {
    id: "selectable",
    type: "fill",
    source: "property",
    filter: [
      "all",
      ["has", "ref"],
      //["==", ["get", "permittable"], "true" ],
      ["==", ["geometry-type"], "Polygon"],
      ["==", ["get", "amenity"], "parking_space"], // match parking spaces
    ],
    minzoom: 19,
    // "layout":{
    //     "visibility": "none",
    // },
    paint: {
      "fill-color": "red",
      "fill-opacity": 0,
    },
  },
  {
    id: "charged",
    type: "fill",
    source: "property",
    filter: [
      "all",
      ["has", "ref"],
      //["==", ["get", "permittable"], "true" ],
      ["==", ["geometry-type"], "Polygon"],
      ["==", ["get", "amenity"], "parking_space"], // match parking spaces
    ],
    minzoom: 19,
    // "layout":{
    //     "visibility": "none",
    // },
    paint: {
      "fill-color": "red",
      "fill-opacity": 0,
    },
  },
  {
    id: "charge-highlight",
    type: "line",
    source: "property",
    filter: [
      "all",
      ["==", ["geometry-type"], "Polygon"],
      ["has", "charge"],
      ["==", ["get", "amenity"], "parking_space"],
    ],
    minzoom: 19,
    paint: {
      "line-color": "black",
      // "line-dasharray": [
      //     1,
      //     2
      // ],
      "line-width": 3,
      "line-offset": -4,
      "line-opacity": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        1,
        0.1,
      ],
    },
  },
  // {
  //     "id": "selected-outline",
  //     "type": "line",
  //     "source": "property",
  //     "filter": [
  //         "all",
  //         ["==", ["geometry-type"], "Polygon" ],
  //         [ "has", "selected" ],
  //     ],
  //     "minzoom":18,
  //     "paint": {
  //         "line-color": "red",
  //         // "line-dasharray": [
  //         //     1,
  //         //     2
  //         // ],
  //         "line-width": 2,
  //         "line-offset":-2,
  //     }
  // }
];

export async function rewriteStyle(loadStyle, loadPropertyGeoJSON) {
  var style = await loadStyle;

  //console.log("style=", style);

  // check to see if mapbox styles loaded
  let mapbox = false;
  each(style.sources, function (source, id) {
    if (!!source.url && source.url.includes("mapbox.")) mapbox = true;
    if (mapbox && !!source.url)
      source.url = source.url.replace(/parkingboss\.[a-z0-9_-]+,?/gi, ""); //.replace(/,$/, "");
  });

  if (!mapbox) style.sources = {};

  each(style.layers, function (layer, i) {
    if (
      layer["source-layer"] !== "arterra" &&
      layer["source-layer"] !== "property"
    )
      return;
    layer.source = "property";
    unset(layer, "source-layer");

    if (layer.id === "community-logo") layer.layout.visibility = "none";

    //console.log(layer);
  });

  each(layers, function (layer, i) {
    layer.source = "property";
    style.layers.push(layer);
  });

  style.sources["property"] = {
    type: "geojson",
    buffer: 256,
    attribution: "Parking Boss",
    data: await loadPropertyGeoJSON,
  };
  return style;
}

export function boundsFromGeoJSON(map, geojson, padding, max) {
  if (!geojson || !geojson.features || !geojson.features.length) return;

  // console.log("bounds=", geojson.bbox);
  var bounds = geojson.bbox || bbox(geojson);
  map.fitBounds(
    [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ],
    {
      padding: padding || 0,
      animate: false,
    }
  );
  if (max) map.setMaxBounds(map.getBounds());
  //var scaled = bbox(transformScale(bboxPolygon(bounds), 3));
  //console.log("scaled", scaled);
  //map.setMaxBounds([[ scaled[0], scaled[1] ], [ scaled[2], scaled[3] ]])
}

export function wireEvents(mapUI, clicked, hoverin, hoverout) {
  function feature(mapUI, point) {
    const radius = 5;
    const x = radius;
    const y = radius;

    if (mapUI.getZoom() < 18.75) return;

    const featuresAtPoint = mapUI.queryRenderedFeatures(point, {
      filter: [
        "all",
        //[ "has", "id" ],
        ["==", ["get", "permittable"], "true"],
        ["==", ["geometry-type"], "Polygon"],
        ["==", ["get", "amenity"], "parking_space"], // match parking spaces
      ],
      //layers:[...],
    });

    //console.log(featuresAtPoint);

    if (featuresAtPoint.length < 1) return;
    return featuresAtPoint[0];
  }

  const click = debounce(
    function (e) {
      //console.log("click=",e);
      if (!!e.features) {
      }
      var item =
        !!e.features && e.features.length > 0
          ? e.features[0]
          : feature(e.target, e.point);
      if (!!item && "function" === typeof clicked) clicked(item.properties);
    },
    100,
    {
      leading: true,
      trailing: true,
    }
  );

  mapUI.on("click", "selectable", click);

  const mouseenter = function (e) {
    //console.log(e);
    let item = null;
    if ("function" === typeof hoverin) {
      item =
        !!e.features && e.features.length > 0
          ? e.features[0]
          : feature(e.target, e.point);
      //console.log("feature=", JSON.stringify(item));
      if (!!item)
        hoverin(northpoint(item).geometry.coordinates, item.properties);
    }
    //const item = (!!e.features && e.features.length > 0) ? e.features[0] : feature(e.target, e.point)
    e.target.getCanvasContainer().style.cursor =
      !!item ||
      (!!e.features && e.features.length > 0) ||
      !!feature(e.target, e.point)
        ? "pointer"
        : "";
  }; /*throttle(, 100, {
        leading:true,
        trailing:true,
    });*/

  const mouseleave = function (e) {
    //console.log(e);
    e.target.getCanvasContainer().style.cursor = "";
    if ("function" === typeof hoverout) {
      hoverout();
    }
  }; /*throttle(, 100, {
        leading:true,
        trailing:true,
    });*/

  mapUI.on("mouseenter", "selectable", mouseenter);
  //mapUI.on("mouseover", "selectable", mouseenter);
  //mapUI.on("mousemove", "selectable", mousemove);

  //mapUI.on("mouseout", "selectable", mouseleave);
  mapUI.on("mouseleave", "selectable", mouseleave);

  let id = 0;

  mapUI.on("mouseenter", "charged", function (e) {
    var item =
      !!e.features && e.features.length > 0
        ? e.features[0]
        : feature(e.target, e.point);
    if (!item || !item.id) return;
    mapUI.setFeatureState(
      { source: "property", id: (id = item.id) },
      { hover: true }
    );
  });
  mapUI.on("mouseleave", "charged", function (e) {
    if (!!id) {
      mapUI.setFeatureState({ source: "property", id: id }, { hover: false });
    }
    id = 0;
  });
}

export function sources(map, data) {
  //console.log("sources=", data);

  var ref = sources;
  var STATE = (ref.__data = ref.__data || {});

  if (!data) return;

  Object.assign(STATE, data || {});

  each(STATE, function (value, id) {
    var source = map.getSource(id);
    if (!!source) source.setData(value);
    else
      map.addSource(id, {
        type: "geojson",
        data: value,
      });
    source = map.getSource(id);
    //boundsFromGeoJSON(map, value);
  });
}

export async function style(id) {
  return fetch(
    `https://api.mapbox.com/styles/v1/${id.replace(".", "/")}?access_token=${
      mapboxgl.accessToken
    }`
  ).then((res) => res.json());
}

export function mapBBox(mapUI) {
  return mapUI.getBounds().toArray().flat();
}
