<script>
  import EnforcementFeesChartDatesItem from "./EnforcementFeesChartDatesItem.svelte";
  import EnforcementFeesSummaryItem from "./EnforcementFeesSummaryItem.svelte";

  export let item;
</script>

<article class={item.type}>
  <EnforcementFeesSummaryItem {item} interval={false} />
  <EnforcementFeesChartDatesItem {item} />
</article>
