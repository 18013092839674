<script context="module">
  import { derived } from "svelte/store";
  import { propertyId } from "$utils/propertystores";
  import { sign } from "$utils/auth";
  import { fetchDocuments } from "$utils/api";
  import { help } from "$utils/help";
  import { thumbnail } from "$utils/filepreview";

  const documents = derived(propertyId, async function ($propertyId, set) {
    if (!$propertyId) return set(null);

    const attachments = await fetchDocuments($propertyId);

    set(attachments);
  });
</script>

<script>
  import ItemsList from "$components/item/ItemsList.svelte";
  import DocumentItem from "$components/DocumentItem.svelte";
</script>

{#if $propertyId}
  <section class="notices">
    <header>
      <h1>Documents</h1>
      <ItemsList
        class="items"
        items={$documents && Object.values($documents.items || {})}
        types={{ file: DocumentItem }}
      />
      <aside class="help">
        <p>
          <a
            on:click={help}
            href="mailto:help@communityboss.com?subject=Documents&body=I'd like to learn more about documents & notices:"
            >I'd like to learn more</a
          >
        </p>
      </aside>
    </header>

    <figure class="notices">
      <iframe
        src={sign("https://docs.parkingboss.app/properties/" + $propertyId)}
      />
    </figure>
  </section>
{/if}
