export function status(permit: Permit, viewpoint: Date = null) {
  if (!permit) return "";
  viewpoint = viewpoint || new Date();
  if (permit.cancelled) return "canceled";
  if (permit.expired) return "expired";
  if (
    permit.valid.max?.datetime &&
    viewpoint > new Date(permit.valid.max.datetime)
  )
    return "expired";
  if (viewpoint < new Date(permit.valid.min.datetime)) return "pending";
  return "active";
}
