<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import TextField from "./TextField.svelte";
  export let value: string = "";
  export let name: string = "q";
  export let title: string | null | undefined = null;
  export let placeholder: string | null | undefined = null;
  export let searching: boolean = false;
  export let autocomplete: string | null | undefined = "off";
  export let autocorrect: string | null | undefined = null;
  export let spellcheck: boolean | "true" | "false" | null | undefined = null;
  export let autocapitalize: string | null | undefined = null;
  export let resettable: boolean = true;
  const events = createEventDispatcher<{
    reset: undefined;
    submit: {
      name: string;
      value: string;
    };
    change: {
      name: string;
      value: string;
    };
  }>();
  let className: string = "";
  export { className as class };

  function change(name: string, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    if (name)
      events("change", {
        name,
        value,
      });
  }
  function submit() {
    events("submit", {
      name,
      value,
    });
  }
  function reset() {
    change(name, "");
    events("reset");
  }
</script>

<form
  class={["search", className].filter(Boolean).join(" ")}
  class:searching={true === searching}
  on:submit|preventDefault={submit}
  on:reset={reset}
>
  <TextField
    type="search"
    {name}
    {title}
    {placeholder}
    {value}
    required
    {autocapitalize}
    {autocomplete}
    {autocorrect}
    {spellcheck}
    on:change={({ detail }) => change(detail.name, detail.value)}
  />{#if true === searching}
    <figure class="activity" />
  {:else if resettable}
    <button disabled={!value} type="reset">clear</button>
  {/if}
  <slot />
</form>
