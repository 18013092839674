<script lang="ts" context="module">
  function duration(value: Temporal.Duration | Temporal.DurationLike | string) {
    //if(typeof value === "string") return duration(Temporal.Duration.from(value));
    if (!(value instanceof Temporal.Duration))
      return duration(Temporal.Duration.from(value));
    //const duration = Temporal.Instant.from(timestamp).until(now.toInstant());
    if (value.total("seconds") < 3) return "live";
    if (value.total("days") > 1) return value.total("days").toFixed(0) + "d";
    if (value.total("hours") > 1) return value.total("hours").toFixed(0) + "h";
    if (value.total("minutes") > 1)
      return value.total("minutes").toFixed(0) + "m";
    return value.total("seconds").toFixed(0) + "s";
  }
</script>

<script lang="ts">
  import { presence, enforce, interval } from "$components/lprd";
  import VehiclesPresenceSections from "$components/lprd/VehiclesPresenceSections.svelte";
  import VehiclesPresentInfo from "$components/lprd/VehiclesPresentInfo.svelte";
  import { query } from "$utils/router";
  import { comparer } from "$utils/sort";
  import { datetime } from "$utils/temporal";
  export let property: Property;
  let partitioned: [any[], any[], any[], any[]] | null = null;
  $: sessions = Object.values($presence?.intervals?.items ?? {}).sort(
    comparer("interval", undefined, true)
  );

  $: if (!$interval && sessions.length) {
    query({ interval: sessions[0].interval });
  }
  $: if (
    $interval &&
    sessions.length &&
    !$presence.intervals.items[$interval]
  ) {
    query({ interval: sessions[0].interval });
  }

  $: session = $presence?.intervals?.items?.[$interval];

  $: console.log("session=", session);
  $: console.log("presence=", $presence);
  $: console.log("enforce=", $enforce);

  $: escalate =
    $enforce &&
    Object.values($enforce.items).filter(
      (item) => item.vehicle && (item.remove || item.immobilize)
    );
  $: violation =
    $enforce &&
    Object.values($enforce.items).filter(
      (item) =>
        item.vehicle && item.warn?.required && !item.remove && !item.immobilize
    );

  $: console.log("escalate=", escalate);
</script>

<section class="parking vehicles presence">
  <section>
    <header>
      <h1>Auto Intelligence <dfn class="preview">Preview</dfn></h1>
    </header>
    <VehiclesPresentInfo {property} title="" />
  </section>
  <VehiclesPresenceSections
    present={$presence}
    enforce={$enforce}
    bind:partitioned
  />
</section>
