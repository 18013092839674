<script lang="ts">
  import range from "lodash-es/range";
  import { fetchUpdateProperty as update } from "$utils/api";
  import { enforcementUpdated as updated } from "$utils/propertystores";
  export let item;
  $: value = item?.enforcement.target.minimum;
  $: submitting = !item;

  async function submit(e) {
    if (!item) return;

    submitting = true;

    await update(item, new FormData(e.target.form));

    // anything else?
    updated.set(Date.now());
  }
</script>

<form class="v2" on:change={submit}>
  {#if submitting}
    Saving
  {:else}
    <label for="property-enforcement-checks-week">Change</label>
    <select
      id="property-enforcement-checks-week"
      name="enforcement.target.minimum"
      value={(value || "0") + ""}
    >
      {#each range(5, 1000, 5) as count}
        <option value={count + ""}>{count} per week</option>
      {/each}
    </select>
  {/if}
</form>
