<script lang="ts">
  import NotifyTenant from "$components/welcome/NotifyTenant.svelte";

  export let tenant: Tenant = null;
  export let variant: string | string[] = null;
  let active = false;
</script>

{#if active}
  <NotifyTenant
    {tenant}
    {variant}
    on:complete={(e) => (active = false)}
    on:cancel={(e) => (active = false)}
  />
{:else}
  <!-- <nav>
    <ul>
      <li> -->
  <button type="button" class="notify" on:click={(e) => (active = true)}
    >Notify…</button
  >
  <!-- </li>
    </ul>
  </nav> -->
{/if}
