<script lang="ts" context="module">
  let i = 0;
</script>

<script lang="ts">
  import upload from "$utils/upload";
  import { propertyId } from "$utils/propertystores";
  import { createEventDispatcher } from "svelte";
  import FileInput from "./FileInput.svelte";

  export let name = "image";
  export let label = "Image";
  export let accept = "image/*";
  export let required = false;
  export let value = "";
  const id = `${name}-${i++}`;
  let input: HTMLInputElement;
  let submitting = false;

  const events = createEventDispatcher<{
    change: { name: string; value: string };
  }>();

  async function filevalue(name: string, file?: File) {
    value = "";

    if (!file || submitting) return;

    submitting = true;

    const record = await upload($propertyId, file);

    value = record.url;

    submitting = false;

    // change
    events("change", { name, value });
  }

  async function filesvalue(files: FileList) {
    if (!files?.length) return;
    const file = files[0];
    if (file) filevalue(name, file);
  }

  //   async function onfilechange(e: Event) {
  //     const file = (e.target as HTMLInputElement)?.files?.[0] as File;

  //     if (file) filevalue(file);
  //   }
</script>

<fieldset>
  <figure class="image preview" class:generating={submitting}>
    <figcaption>
      {#if label}
        <label for={id}>{label}</label>
      {/if}
      {#if value}
        <input type="hidden" {name} {value} />
      {/if}
      <FileInput
        {id}
        {accept}
        disabled={submitting}
        on:change={(e) => filesvalue(e.detail.value)}
      />
    </figcaption>
    <img src={value} alt={label} />
  </figure>

  <!-- {#if submitting}
    <figure>Submitting</figure>
  {:else if value}
    <figure class="image">
      <a href={value} target="_blank">
        <img
          src={value}
          alt={label}
          style="width:2.75rem;height:2.25rem;object-fit:contain;margin:0.25rem;"
        /></a
      >
    </figure>
    
  {/if} -->

  <!-- <input
    bind:this={input}
    type="file"
    {id}
    {accept}
    on:change={onfilechange}
    disabled={submitting}
  /> -->
</fieldset>
