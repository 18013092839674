<script lang="ts" context="module">
  import { authHeader } from "$utils/api";
  import { onDestroy } from "svelte";
  import { writable } from "svelte/store";

  const occupancy = writable<Record<string, any> | null>(null);

  // setup events...
  let events: EventSource;

  function eventing(id?: string) {
    if (events) events.close();

    // make more solid?

    occupancy.set(null);

    console.log("eventing, data cleared");

    if (!id) return;

    events = new EventSource(
      `https://placepod.communityboss.workers.dev/?for=${id}&${authHeader}`
    );
    events.addEventListener("message", (e) => {
      // console.log("message", e);
      const data = JSON.parse(e.data);
      console.log("data=", data);
      if (!data) return;

      occupancy.update((state) => {
        const id = data.space?.id ?? data.subject;
        if (!id) return state;

        if (!state) state = {};

        state[id] = data;

        console.log("updated state=", state);

        return state;
      });
    });
  }
</script>

<script lang="ts">
  import { level } from "$components/space";
  import { propertyId, spaces } from "$utils/propertystores";

  import MapFeatureState from "$components/map/MapFeatureState.svelte";
  import PropertyMap from "$components/PropertyMap.svelte";
  import { query } from "$utils/router";

  export let property: Property;

  let levels: Record<string, any>;

  $: eventing($propertyId);

  $: statemap = (($spaces, $occupancy) => {
    //console.log("crossref=", $crossref, $spaces);

    //if (!$occupancy || !$spaces) return null;

    const statusmap = Object.entries($occupancy ?? {}).reduce(
      (map, [key, value]) => {
        //console.log("map=", map, key, map[key]);

        const itemstate = map[key];
        if (!itemstate) return map;

        if (true === value.full) {
          itemstate["fill-color-hex"] = "ff0000";
        } else if (true === value.empty) {
          itemstate["fill-color-hex"] = "00ff00";
        } else {
          itemstate["fill-color-hex"] = undefined;
        }

        return map;
      },
      Object.values($spaces?.items ?? {}).reduce(
        (map: Record<string, any>, item: Space, i) => {
          // handle permitted state

          const key = item.id;

          const itemstate = (map[key] ??= {
            selectable: true, // this is the fallback
            selected: false,
          });

          // console.log(
          //   "space policies = ",
          //   key,
          //   Object.values($policies?.[key] ?? {})
          // );

          // set the permitted state

          return map;
        },
        {
          "*": {
            selectable: true, // this is the fallback
            selected: false,
          } as Record<string, any>,
        }
        //)
      ) as Record<string, any>
    );

    console.log("status=", statusmap);

    return statusmap;
  })($spaces, $occupancy) as Record<string, { status: string }>;

  onDestroy(() => {
    eventing(); // cleanup events
  });

  function onlevelchange(e: CustomEvent<string>) {
    console.log("level change=", e.detail);
    query("level", e.detail);
  }
</script>

<section class="spaces">
  {#if $propertyId}
    <PropertyMap
      property={property?.id}
      level={$level}
      on:level={onlevelchange}
      bind:levels
      minzoom={17}
    >
      <MapFeatureState
        state={(feature) =>
          statemap?.[feature.properties["ref:boss:subject"]] ?? statemap?.["*"]}
        filter={[
          "all",
          ["==", ["geometry-type"], "Polygon"],
          ["has", "ref:boss:subject"],
        ]}
      />
    </PropertyMap>
  {/if}
</section>
