<script lang="ts">
  import ChartAvailabilityTimeline from "$components/chart/ChartAvailabilityTimeline.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import { title } from "$utils/text";

  export let item: Space & AvailabilityItem;

  // $: if (item.availability && item.availability.interval.endsWith("/"))
  //   item.availability.interval =
  //     item.availability.interval.split("/")[0] +
  //     "/" +
  //     Temporal.Now.zonedDateTimeISO(item.availability.timezone)
  //       .add({ days: 30 })
  //       .toString({ timeZoneName: "never" });
</script>

<article class="{item.type} details">
  <dl>
    <dt>{title(item.format)}</dt>
    <dd><RecordItem {item} /></dd>
  </dl>
  <!-- <ChartAvailabilityTimeline availability={item.availability} /> -->
</article>
