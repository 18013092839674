import { derived, type Readable, writable } from "svelte/store";
import storage from "store/dist/store.modern";

const key = "params";

let _defaults = storage.get(key) ?? {};
const defaults = writable<Record<string, string>>(_defaults);
// defaults.subscribe(($defaults) =>
//   storage.set("params", (_defaults = $defaults))
// );

// another window changed the defaults
window.addEventListener("storage", (e) => {
  if (e.key === key)
    defaults.set((_defaults = (e.newValue && JSON.parse(e.newValue)) || {}));
});

// call when we udpate on this page
// function updateDefaults($defaults: Record<string, string>) {
//   defaults.set((_defaults = $defaults));
// }

export const params = writable<Record<string, string>>({});

function paramStore(
  selector,
  onlyUpdateForRealValues = false,
  defaultValue: string | null = null,
  persist: string | null = null
): Readable<string> {
  var value: string | null = null;
  return derived<[typeof params, typeof defaults], string>(
    [params, defaults],
    ([params, $defaults], set) => {
      //if (!params) return;
      var newValue = selector(params);
      // we have a new value set in params
      if (persist && newValue != value) {
        defaults.update(($defaults) => {
          $defaults[persist] = newValue; // update value
          storage.set(key, $defaults); // write to storage
          return (_defaults = $defaults); // return update
        });
      }
      if (!newValue && persist && $defaults) newValue = $defaults[persist];
      if (!newValue && null != defaultValue) newValue = defaultValue;
      if (!newValue && !value) return;
      if (!newValue && onlyUpdateForRealValues) return;
      //console.log("setting new paramstore value", newValue);
      if (newValue != value) set((value = newValue));
    }
  );
}

const stores: Record<string, Readable<string>> = {};

export const param = (
  property: string,
  onlyUpdateWhenSet = false,
  defaultValue: string | null = null,
  persistent: boolean = false
): Readable<string> => {
  return (stores[property] ??= paramStore(
    ($params: Record<string, string>) => $params?.[property],
    onlyUpdateWhenSet,
    defaultValue,
    persistent ? property : null
  ));
};
