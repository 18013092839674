<script context="module">
  import { time } from "$utils/timestores";
  const nowutc = time({ minutes: 30 });
</script>

<script>
  import WeekDayChart from "../svg/WeeksDaysChart.svelte";
  export let item;
</script>

<figure class="chart">
  <WeekDayChart
    interval={item.interval}
    timezone={item.timezone}
    intervals={item?.[item.type]}
    target={item.target}
    class={item.type}
    now={$nowutc.toISOString()}
  />
</figure>
