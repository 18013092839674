import PermitDetailsItem from "./permit/PermitDetailsItem.svelte";
import ScanItem from "./ScanItem.svelte";
import ViolationItem from "./violation/ViolationDetailsItem.svelte";
import PermitConflictItem from "./PermitConflictItem.svelte";
import ViolationExceptionItem from "./ViolationExceptionItem.svelte";
import AccessedItem from "./AccessedItem.svelte";
import PolicyUsageItem from "./SubjectPolicyUsageItem.svelte";
import SuspectedPermitItem from "./SuspectedPermitItem.svelte";
import NoteItem from "$components/note/NoteItem.svelte";
import TimeItem from "./TimeItem.svelte";
import identity from "lodash-es/identity";
import { toZonedTime } from "date-fns-tz";
import { parseISO } from "date-fns";
import PermittableDetailsItem from "./permittable/PermittableDetailsItem.svelte";
import PermittableRemove from "./permittable/PermittableRemove.svelte";
import ViolationRemove from "./violation/ViolationRemove.svelte";
import ItemSend from "./item/ItemSend.svelte";
import PermitRevoke from "./permit/PermitRevoke.svelte";
import PermitDuplicate from "./permit/PermitDuplicate.svelte";
import PermitEdit from "./permit/PermitEdit.svelte";
import PolicyDetailsItem from "./policy/PolicyDetailsItem.svelte";
import ContactDetailsItem from "./contact/ContactDetailsItem.svelte";
import VehicleInfoPermit from "./VehicleInfoPermit.svelte";

export const types = {
  contact: ContactDetailsItem,
  permit: PermitDetailsItem,
  violation: ViolationItem,
  detection: ScanItem,
  observation: ScanItem,
  conflict: PermitConflictItem,
  exception: ViolationExceptionItem,
  violationexception: ViolationExceptionItem,
  accessed: AccessedItem,
  policyusage: PolicyUsageItem,
  fraud: SuspectedPermitItem,
  note: NoteItem,
  time: TimeItem,
  date: TimeItem,
  datetime: TimeItem,
  permittable: PermittableDetailsItem,
  policy: PolicyDetailsItem,
};

export const editors = {
  permittable: {
    revoke: PermittableRemove,
  },
  violation: {
    revoke: ViolationRemove,
    notify: ItemSend,
  },
  permit: {
    revoke: PermitRevoke,
    duplicate: PermitDuplicate,
    edit: PermitEdit,
    notify: ItemSend,
  },
  vehicleinfo: {
    permit: VehicleInfoPermit,
  },
};

export const highlighters = {
  //violationexception: (item) => item.active,
  //permit: true,
  violation: true,
  //enforcement: true,
  //policyusage: true,
};
export function resolve(item) {
  return item && types[item.type];
}

export default resolve;

export function highlight(item, additional) {
  if (!item) return false;
  const highlighter =
    (additional && additional[item.type]) || highlighters[item.type];
  if (!highlighter) return false;
  if (highlighter === true) return true;
  if (typeof highlighter == "function") return highlighter(item);
  return false;
}

export function sortByCreatedDate(item) {
  const tz = item.timezone;
  return (
    [
      //item.issued?.utc,
      item.issued?.datetime,
      item.created?.utc,
      item.datetime,
      item.recorded,
      item.latest,
      item.utc,
    ].reduce((date, item) => {
      if (date) return date;
      if (!item) return date;
      return toZonedTime(item, tz);
    }, null) || item
  );
}
