<script lang="ts">
  import CopyButton from "./CopyButton.svelte";

  export let type: string = "text";
  export let value: string;
  let copied: boolean = false;
  export let label: string | false = false;

  async function copy() {
    await navigator.clipboard.writeText(value);

    copied = true;

    setTimeout((v: boolean) => (copied = v), 3000, false);
  }
</script>

<aside class="copy" on:click={copy} class:copied>
  {#if label}
    <h1>{label}</h1>
  {:else}
    <input
      {type}
      {value}
      readonly
      on:focus={(e) => (e.currentTarget.select(), copy())}
    />
  {/if}
  <CopyButton {value} bind:copied />
  <slot></slot>
</aside>
