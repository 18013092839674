<script lang="ts">
  import RecordItem from "$components/record/RecordItem.svelte";
  import { createEventDispatcher } from "svelte";

  export let label: string | null | undefined = "Space";
  export let value: Space;
  export let name = "space";
  export let readonly = false;
  export let scrollIntoView: boolean = false;

  const evented = createEventDispatcher<{
    change: Space | null;
  }>();

  let element: HTMLElement;
  $: if (element && scrollIntoView)
    element.scrollIntoView({ behavior: "instant", block: "center" });

  function change(updated: Space | null) {
    evented("change", updated);
  }
</script>

<figure class="value record" bind:this={element}>
  {#if label}
    <figcaption>{label}</figcaption>
  {/if}
  <input type="hidden" {name} value={value.id} />
  <RecordItem item={value} url={false} />
  {#if !readonly}
    <button class="clear" type="button" on:click={(e) => change(null)}
      >change</button
    >
  {/if}
</figure>
<slot />
