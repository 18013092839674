<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let name: string | null | undefined = null;
  export let id: string | null | undefined = null;
  export let required = false;
  export let disabled = false;
  export let accept: string | null | undefined = null;
  export let multiple = false;

  const events = createEventDispatcher<{
    change: {
      name: string;
      value: FileList;
    };
  }>();

  function change(name: string, newvalue: FileList) {
    //if (name)
    events("change", {
      name,
      value: newvalue,
    });
  }

  function onchange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target) return;
    change(target.name, target.files ?? new FileList());
  }
</script>

<input
  type="file"
  {name}
  {id}
  {accept}
  on:change={onchange}
  {disabled}
  {multiple}
/>
