<script lang="ts" context="module">
  var scan = param("scan");
</script>

<script lang="ts">
  import MediaScanner from "$components/media/MediaScanner.svelte";
  import InfoTagScanner from "$components/scanner/InfoTagScanner.svelte";
  import VehicleScanner from "$components/vehicle/VehicleScanner.svelte";
  import { param, params } from "$utils/params";
  import router from "$utils/router";
  import { event } from "$utils/track";
  import { tick } from "svelte";

  export let property: Property;
  $: mode = typeof $scan == "string" ? $scan || "media" : "media";

  function submit(e) {
    e.preventDefault();

    const value = e.detail;

    if (!value?.type) return;

    blur();

    event("scanned", "Scanner", value.type);

    return router(
      `/properties/${property.id}/${value.type}/${value.id || value.key}`
    );

    if (e.detail && e.detail.type == "vehicle") {
      let vehicle = e.detail;
      blur();
      return router(
        `/properties/${property.id}/${vehicle.type}/${vehicle.key}`
      );
    }

    if (e.detail && e.detail.type == "media") {
      let media = e.detail;
      console.log("have media=", media);
      blur();
      return router(`/properties/${property.id}/media/${media.id}`);
    }
  }

  async function setScanMode(value) {
    //console.log("setting scan mode = ", mode);
    await tick();
    params.update(($values) => {
      $values["scan"] = value;
      return $values;
    });
  }
</script>

{#if property}
  <!-- <ul>
      <li>
        <input
          id="scan-media"
          type="radio"
          name="scan"
          value="media"
          bind:group={mode}
          on:change={({ currentTarget: target }) =>
            target.checked && setScanMode(target.value)}
        /><label for="scan-media">Smart Decal</label>
      </li>
      <li>
        <input
          id="scan-vehicle"
          type="radio"
          name="scan"
          value="vehicle"
          bind:group={mode}
          on:change={({ currentTarget: target }) =>
            target.checked && setScanMode(target.value)}
        /><label for="scan-vehicle">Plate/Tag</label>
      </li>
    </ul> -->
  {#if mode == "vehicle"}
    <!-- <VehicleScanner {property} on:vehicle={submit} />
      <button type="button" value="media" on:click={(e) => setScanMode("media")}
        >Switch to scan Smart Decal</button
      > -->
  {:else if mode == "media"}
    <!-- <InfoTagScanner on:infotag={submit} /> -->
    <MediaScanner on:media={submit} />
    <!-- <button
        type="button"
        value="vehicle"
        on:click={(e) => setScanMode("vehicle")}
        >Switch to scan Plate/Tag</button
      > -->
  {/if}
{/if}
