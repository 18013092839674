<script context="module" lang="ts">
  import { fetchProperties } from "$utils/api";
  import { derived, readable, type Readable } from "svelte/store";

  const properties = derived<Readable<string>, Properties>(
    param(
      "principal",
      true,
      new URLSearchParams(location.search).get("principal")
    ),
    ($principal, set) => {
      fetchProperties(null, $principal).then((json) => set(json.properties));

      return function stop() {
        set(null);
      };
    }
  );

  // const properties = readable(null, function start(set) {
  //   fetchProperties().then((json) => set(json.properties));

  //   return function stop() {
  //     set(null);
  //   };
  // });
</script>

<script lang="ts">
  import { view } from "$utils/uistores";
  import PortfolioHeader from "./PortfolioHeader.svelte";
  import { onMount } from "svelte";
  import PortfolioPropertiesSection from "./PortfolioPropertiesSection.svelte";
  import PortfolioUsersSection from "./PortfolioUsersSection.svelte";
  import PortfolioPaymentsSection from "./PortfolioPaymentsSection.svelte";
  import { param } from "$utils/params";

  onMount(function () {
    document.body.classList.add("portfolio");
    return function () {
      document.body.classList.remove("portfolio");
    };
  });
</script>

<PortfolioHeader />
<main class="portfolio">
  {#if $view == "properties"}
    <PortfolioPropertiesSection properties={$properties} />
  {:else if $view == "propertiesusers"}
    <PortfolioUsersSection properties={$properties} />
  {:else if $view == "propertiespayments"}
    <PortfolioPaymentsSection properties={$properties} />
  {/if}
</main>
