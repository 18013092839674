<script>
  import { createEventDispatcher } from "svelte";
  import { upload } from "$utils/upload";
  import { fetchCreateNote } from "$utils/api";

  const dispatch = createEventDispatcher();

  export let item = null;
  export let accept =
    ".pdf,application/pdf,.png,image/png,.jpeg,.jpg,image/jpeg,.heic,image/heic,.heif,image/heif";

  async function add(file) {
    // kick off
    var url = URL.createObjectURL(file);
    const temp = {
      type: "file",
      local: true,
      id: url,
      url,
      created: new Date().toISOString(),
      format: file.type,
    };
    dispatch("creating", temp);

    const record = await upload(item.scope || item.location, file, {
      attach: item.id,
    });

    dispatch("created", temp);
    dispatch("created", record);

    //await tick();
    URL.revokeObjectURL(url);
  }

  function addFile(e) {
    if (!e || !e.target)
      if (!e.target.files || e.target.files.length <= 0) return;
    for (const file of e.target.files) {
      add(file);
    }
    e.target.value = "";
  }

  let submittable = false;
  let submitting = false;

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable) return;

    submitting = true;

    const form = e.target;

    const formData = new FormData(form);

    var json = await fetchCreateNote(item.scope, formData);

    dispatch("created", json.notes.item);

    submitting = submittable = false;

    form.reset();
  }
</script>

<form class="v2 notes" on:change={change} on:input={change} on:submit={submit}>
  <input type="hidden" name="subject" value={item.id} />
  <fieldset>
    <ul>
      <li>
        <input id="file-for-item" type="file" {accept} on:change={addFile} />
        <label for="file-for-item">Add an image or file&#x2026</label>
      </li>
      <li>
        <textarea required name="notes" placeholder="Add a note&#x2026" />
      </li>
    </ul>
  </fieldset>

  {#if submittable}
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Adding" : "Add Note"}</button
    >
  {/if}
</form>
