<script lang="ts">
  import PropertyMap from "$components/PropertyMap.svelte";
  import VehiclesPresentHeatmap from "./VehiclesPresentHeatmap.svelte";

  export let present: any;

  export let theme = "cool";

  let level: string = "outside";
  let levels: { [key: string]: any } = {};
  let usesatellite = false;
</script>

{#if present}
  <PropertyMap
    theme={usesatellite ? "satellite" : theme || "cool"}
    property={present.scope}
    {level}
    on:level={(e) => (level = e.detail)}
    bind:levels
    interactive={true}
    overlay
  >
    <VehiclesPresentHeatmap {present} />
    <slot></slot>
  </PropertyMap>
{/if}
