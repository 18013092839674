<script>
  import { format, toZonedTime } from "date-fns-tz";

  export let item;
  //$: console.log("unit status=", item);
</script>

{#if item}
  <slot />
  <ol>
    {#if item.pending.occupancy}
      <li>
        <time class="occupancy" datetime="">Pending move in</time>
      </li>
    {/if}
    {#if item.pending.vacancy}
      <li>
        <time class="vacating" datetime="">Pending move out</time>
      </li>
    {/if}
    {#if item.vacant && !item.check.vacant && item.check.asof}
      <li>
        <time class="reset" datetime={item.check.asof}
          ><dfn>Completed</dfn>
          <abbr
            >{format(
              toZonedTime(item.check.asof, item.timezone),
              "EEE MMM d yyyy h:mm a",
              { timeZone: item.timezone }
            )}</abbr
          ></time
        >
      </li>
    {/if}
    {#if item.vacated}
      <li>
        <time class="vacated" datetime={item.vacated}
          ><dfn>Moved out</dfn>
          <abbr
            >{format(
              toZonedTime(item.vacated, item.timezone),
              "EEE MMM d yyyy",
              { timeZone: item.timezone }
            )}</abbr
          ></time
        >
      </li>
    {/if}
    {#if item.occupied === true}
      <li>
        <time class="occupied" datetime=""
          ><dfn>Moved in</dfn>
          <!-- <abbr
            >{format(
              toZonedTime(item.occupied, item.timezone),
              "EEE MMM d yyyy",
              { timeZone: item.timezone }
            )}</abbr
          > -->
        </time>
      </li>
    {:else if item.occupied}
      <li>
        <time class="occupied" datetime={item.occupied}
          ><dfn>Moved in</dfn>
          <abbr
            >{format(
              toZonedTime(item.occupied, item.timezone),
              "EEE MMM d yyyy",
              { timeZone: item.timezone }
            )}</abbr
          ></time
        >
      </li>
    {/if}
  </ol>
{/if}
