<script context="module" lang="ts">
  import { readable } from "svelte/store";
  import { fetchProperties } from "$utils/api";

  const properties = readable<{ items: Record<string, Property> }>(
    null,
    function start(set) {
      //set(null);

      const now = new Date();

      fetchProperties("*").then((json) => set(json.properties));

      return function stop() {};
    }
  );
</script>

<script lang="ts">
  import { propertyId } from "$utils/propertystores";
  import { comparer } from "$utils/sort";
</script>

<select
  title="Choose an account&hellip;"
  value={$propertyId}
  on:change={(e) => (location.href = `/properties/${e.currentTarget.value}`)}
>
  {#each Object.values($properties?.items ?? {}).sort(comparer("name")) as { id, name, address }}
    <option value={id}>{name} - {address.city}, {address.state}</option>
  {/each}
</select>
