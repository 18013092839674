<script>
  import { fetchCreateUser } from "$utils/api";
  import { authorizationsUpdated } from "$utils/propertystores";
  import EmailField from "$components/form/EmailField.svelte";

  export let item;
  export let role = "";
  export let title = "User";
  export let cancel = true;
  export let active = false;

  let submittable = false;
  let submitting = false;

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!item) return;
    if (!role) return;

    if (submitting || !submittable) return;

    submitting = true;

    const form = e.target;

    var json = await fetchCreateUser(
      item.id,
      form.elements["email"].value,
      role
    );

    authorizationsUpdated.set(Date.now());

    submitting = submittable = false;
    active = false;

    form.reset();
  }
</script>

<form class="user" on:change={change} on:input={change} on:submit={submit}>
  <input type="hidden" name="type" value={role} />
  <ul>
    <li>
      <figure class="data">
        <figcaption>Access</figcaption>
        <data class="role" value={role}>{title}</data>
      </figure>
    </li>
    <li>
      <EmailField required={true} label="Email" />
    </li>
    <li>
      <p>We'll send an welcome message with login info</p>
    </li>
  </ul>
  <footer>
    <fieldset>
      <button disabled={!submittable || submitting} type="submit">
        {submitting ? "Saving…" : `Grant Access`}
      </button>
      {#if cancel && !submitting}
        <button type="reset" on:click={() => (active = false)}>Cancel</button>
      {/if}
    </fieldset>
  </footer>
</form>
