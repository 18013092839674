<script>
  export let item;
  import { thumbnail } from "$utils/filepreview";
</script>

<aside class="{item.type} document">
  <a href={item.url} target="_blank" type={item.format}>
    <data class="document file" value={item.id}>
      <figure class="document">
        <img alt="preview" src={thumbnail(item.url, 200)} />
        <figcaption>
          <h1>{item.name || item.title || item.name}</h1>
        </figcaption>
      </figure>
    </data>
  </a>
</aside>
