<script lang="ts">
  import { property } from "$utils/propertystores";
  import Fullscreen from "../Fullscreen.svelte";
  import MapLevelSelect from "../MapLevelSelect.svelte";
  import PropertyMap from "../PropertyMap.svelte";
  import EnforcementSummaryMapHeatmap from "./EnforcementSummaryMapHeatmap.svelte";
  //import { streetmap, satellitemap } from "$utils/propertystores";

  export let item: any = null;
  export let record: any = null; // let's use a different style if a single record
  export let theme = "cool";
  let level: string = "outside";
  let levels: { [key: string]: any } = {};
  let usesatellite = false;
  let fullscreen = false;
</script>

<article class="{item.type} map">
  <Fullscreen bind:active={fullscreen}>
    <PropertyMap
      theme={usesatellite ? "satellite" : theme || "cool"}
      property={$property}
      {level}
      on:level={(e) => (level = e.detail)}
      bind:levels
      overlay={!usesatellite}
      interactive={fullscreen}
    >
      <EnforcementSummaryMapHeatmap {item} {record} />
      <form>
        <fieldset>
          <input
            type="checkbox"
            name="style"
            value="satellite"
            on:change={({ currentTarget: target }) =>
              (usesatellite = target.checked)}
          />
          <MapLevelSelect {levels} bind:level />
        </fieldset>
        <fieldset>
          <input
            type="checkbox"
            name="fullscreen"
            value="fullscreen"
            bind:checked={fullscreen}
          />
        </fieldset>
      </form>
      <slot />
    </PropertyMap>
  </Fullscreen>
</article>
