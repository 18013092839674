<script context="module">
  const calendar = {
    P1D: "day",
    P1W: "week",
    P1M: "month",
    P1Y: "year",
  };
  const nowutc = time({ minutes: 30 });
</script>

<script>
  import { scaleTime, scaleLinear } from "d3-scale";
  import {
    addMonths,
    addWeeks,
    differenceInDays,
    differenceInMonths,
    differenceInWeeks,
    eachDayOfInterval,
    eachHourOfInterval,
    eachWeekOfInterval,
    endOfDay,
    endOfWeek,
    isEqual,
    isSameDay,
    isWithinInterval,
    startOfDay,
    startOfWeek,
  } from "date-fns";
  import { format, toZonedTime } from "date-fns-tz";
  import addDays from "date-fns/addDays";
  import range from "lodash-es/range";
  import { time } from "$utils/timestores";
  import { stringToDateInterval } from "../item/ItemsList.svelte";
  import TimeNow from "../svg/SVGTimeNow.svelte";
  export let item;

  //const tickCount = 5;

  $: nowlocal = toZonedTime($nowutc, item?.timezone);

  //$: console.log("item=", item, nowlocal);

  $: interval =
    item && item.interval && stringToDateInterval(item.interval, item.timezone);

  //$: intervals = Object.entries(item?.checks || {}).map(([ interval, count ]) => ({ interval, count, ...stringToDateInterval(interval, item.timezone) }));

  $: sessions = Object.values(item?.values || {}).map((interval) => ({
    interval,
    ...stringToDateInterval(interval, item.timezone),
  }));

  // $: [ dates, weeks ] = intervals.reduce((results, i) => {

  //     if(i.interval == item.interval) return results; // overall value;
  //     if(isEqual(addDays(i.start, 1), i.end)) {
  //         i.per = "P1D";
  //         results[0].push(i);
  //     } else if(isEqual(addWeeks(i.start, 1),i.end)) {
  //         i.per = "P1W";
  //         results[1].push(i);
  //     }
  //     return results;
  // }, [
  //     [],
  //     []
  // ]);

  // $: average = Math.round(weeks.reduce((sum,  i ) => isWithinInterval(nowlocal, i) ? sum : sum + i.count, 0) / (weeks.filter(w => !isWithinInterval(nowlocal, w)).length));
  // $: max = Math.max((target?.minimum || 0) * 1.5, average * 1.5, ...weeks.map(i => i.count));

  $: singleDay =
    isEqual(interval.end, addDays(interval.start, 1)) ||
    isEqual(interval.end, endOfDay(interval.start));
  $: weekish = differenceInDays(interval.end, interval.start) < 8;
  $: tickFormat = singleDay ? "h a" : weekish ? "eee" : "MMM d";
  $: months = differenceInMonths(interval.end, interval.start);
  $: start = interval.start;

  $: end = singleDay
    ? endOfDay(start)
    : months > 6 && isWithinInterval(nowlocal, interval)
      ? addMonths(interval.end, 1)
      : differenceInDays(interval.end, interval.start) < 8
        ? interval.end
        : endOfWeek(interval.end);

  $: tickCount = singleDay ? 6 : 5;

  //$: console.log("start=",start,"end=",end);

  $: timescale = scaleTime().domain([start, end]).range([0, 100]);

  $: containsNow = isWithinInterval(nowlocal, { start, end });

  $: ticks = singleDay
    ? eachHourOfInterval(interval).filter((i) => i.getHours() % 4 == 0)
    : weekish
      ? eachDayOfInterval(interval)
      : months < 2 && isEqual(start, startOfWeek(start))
        ? eachWeekOfInterval(interval)
        : range(start, end, (end - start) / tickCount);

  // $: if (!singleDay && differenceInDays(nowlocal, start) < 8) {
  //   tickFormat = "eee";
  //   ticks = eachDayOfInterval(interval);
  // }
</script>

<figure class="chart">
  <svg height="52" width="100%">
    <svg class="values" height="32" width="100%">
      <rect width="100%" height="100%" />
      {#if nowlocal}
        <rect
          class="today"
          data-datetime={item.interval}
          x="{timescale(startOfDay(nowlocal))}%"
          y="0%"
          height="100%"
          width="{timescale(endOfDay(nowlocal)) -
            timescale(startOfDay(nowlocal))}%"
        />
      {/if}
      {#each sessions as item}
        <rect
          class="online"
          x="{timescale(item.start)}%"
          y="0%"
          height="100%"
          width="{timescale(item.end) - timescale(item.start)}%"
        />
      {/each}
      {#each Object.values(item.items)
        .filter((item) => item.type == "accessed")
        .map( (item) => [toZonedTime(item.datetime, item.timezone), item.type] ) as [date, type]}
        <line
          class={type}
          stroke-width="1"
          y1="50%"
          y2="100%"
          x1="{timescale(date)}%"
          x2="{timescale(date)}%"
        />
      {/each}
      {#each Object.values(item.items)
        .filter((item) => item.type == "violation")
        .map( (item) => [toZonedTime(item.issued.datetime, item.timezone), item.type] ) as [date, type]}
        <!-- <circle class="{type}" cx="{timescale(date)}%" cy="50%" r="3" /> -->
        <line
          class={type}
          stroke-width="1"
          y1="0%"
          y2="50%"
          x1="{timescale(date)}%"
          x2="{timescale(date)}%"
        />
      {/each}
    </svg>
    <g class="legend">
      <!-- <line class="date min" x1="0%" x2="0%" y1="0%" y2="100%" /> -->
      {#each ticks as tick}
        <line
          class="date"
          class:min={timescale(tick) == 0}
          x1="{timescale(tick)}%"
          x2="{timescale(tick)}%"
          y1="0%"
          y2="100%"
        />
        <text
          class="date min"
          class:today={weekish && !singleDay && isSameDay(tick, nowlocal)}
          x="{timescale(tick)}%"
          y="100%"
          >{weekish && !singleDay && isSameDay(tick, nowlocal)
            ? "Today"
            : format(tick, tickFormat)}</text
        >
      {/each}
      <line class="date max" x1="100%" x2="100%" y1="0%" y2="100%" />
    </g>

    <svg class="context" height="32" width="100%">
      {#if containsNow}
        <TimeNow x="{timescale(nowlocal)}%" />
      {/if}
    </svg>
  </svg>
</figure>
