<script lang="ts">
  import { params } from "$utils/params";
  import { query } from "$utils/router";
  import type { MapGeoJSONFeature } from "./map";

  export let highlight: (feature: MapGeoJSONFeature) => boolean;
  export let policies: Record<string, any>;
  export let spaces: any;

  function covered(feature: MapGeoJSONFeature) {
    return (
      "yes" === feature.properties["covered"] ||
      "carport" === feature.properties["covered"] ||
      "carports" === feature.properties["parking"] ||
      "carport" === feature.properties["parking_space"]
    );
  }

  $: policiesById = Object.values(policies ?? []).reduce(
    (policies: Record<string, any>, policy: any) => {
      policies[policy.policy] = policy;
      return policies;
    },
    {}
  );

  $: highlighters = [
    {
      param: "parking",
      data: ["format", "parking"],
      name: "All Spaces",
      eval: (feature: MapGeoJSONFeature) =>
        "parking_space" === feature.properties["amenity"],
    },
    {
      param: "charging",
      name: "Charging",
      data: ["capacity-charging", "yes"],
      eval: (feature: MapGeoJSONFeature) =>
        "yes" === feature.properties["capacity:charging"] ||
        "charging" === feature.properties["parking_space"] ||
        (null != feature.properties["capacity:charging"] &&
          feature.properties["capacity:charging"] > 0),
    },
    {
      param: "storage",
      name: "Storage",
      data: ["capacity-storage", "yes"],
      eval: (feature: MapGeoJSONFeature) =>
        "yes" === feature.properties["capacity:storage"] ||
        feature.properties["amenity"] == "self_storage" ||
        feature.properties.format == "storage",
    },
    {
      param: "ada",
      name: "ADA",
      data: ["capacity-disabled", "yes"],
      eval: (feature: MapGeoJSONFeature) =>
        "yes" === feature.properties["capacity:disabled"] ||
        "disabled" === feature.properties["parking_space"] ||
        (null != feature.properties["capacity:disabled"] &&
          feature.properties["capacity:disabled"] > 0),
    },

    // {
    //   param: "compact",
    //   name: "Compact",
    //   data: ["size", "compact"],
    //   eval: (feature: MapGeoJSONFeature) =>
    //     "compact" == feature.properties["size"],
    // },
    // {
    //   param: "tandem",
    //   name: "Tandem",
    //   data: ["size", "tandem"],
    //   eval: (feature: MapGeoJSONFeature) =>
    //     "tandem" == feature.properties["size"] ||
    //     "mutiple" == feature.properties["size"],
    // },
    {
      param: "bicycle",
      name: "Bicycle",
      data: ["capacity-bicycle", "yes"],
      eval: (feature: MapGeoJSONFeature) =>
        "bicycle" === feature.properties["parking_space"] ||
        "yes" === feature.properties["capacity:bicycle"] ||
        (null != feature.properties["capacity:bicycle"] &&
          feature.properties["capacity:bicycle"] > 0),
    },
    // {
    //   param: "motorcycle",
    //   name: "Motorcycle",
    //   data: ["parking_space", "motorcycle"],
    //   eval: (feature: MapGeoJSONFeature) =>
    //     "motorcycle" === feature.properties["parking_space"],
    // },

    {
      param: "outdoor",
      name: "Uncovered",
      data: ["outdoor", "uncovered"],
      eval: (feature: MapGeoJSONFeature) =>
        null == feature.properties["indoor"] &&
        (null == feature.properties["outdoor"] ||
          "yes" === feature.properties["outdoor"]) &&
        !covered(feature),
    },
    {
      param: "covered",
      name: "Covered",
      data: ["covered", "yes"],
      eval: covered,
    },
    {
      param: "indoor",
      name: "Indoor",
      data: ["indoor", "yes"],
      eval: (feature: MapGeoJSONFeature) =>
        null != feature.properties["indoor"] &&
        "no" !== feature.properties["indoor"],
    },
    {
      param: "garage",
      name: "Private Garage",
      data: ["indoor", "garage"],
      eval: (feature: MapGeoJSONFeature) =>
        "garage" === feature.properties["indoor"],
    },
    {
      param: "leasing",
      name: "Future Resident",
      data: ["access", "prospect"],
      eval: (feature: MapGeoJSONFeature) =>
        "prospect" == feature.properties["access"],
    },
    {
      param: "retail",
      name: "Retail",
      data: ["access", "customer"],
      eval: (feature: MapGeoJSONFeature) =>
        "customer" == feature.properties["access"],
    },
    {
      param: "size",
      name: "Size",
      values: {
        standard: "standard",
        compact: "compact",
        ultracompact: "ultracompact",
        motorcycle: "motorcycle",
        multiple: "tandem",
        van: "van",
        oversize: "oversize",
      },
      data: ["size", ""],
      eval: (feature: MapGeoJSONFeature, value: string) =>
        value == feature.properties["size"] ||
        ("standard" === value && null == feature.properties["size"]),
    },
    ...Object.keys(
      Object.values(spaces ?? {}).reduce(
        (colors: Record<string, string>, { color }: { color?: string }) => {
          if (color) colors[color] = color;
          return colors;
        },
        {}
      ) as Record<string, string>
    ).reduce((items, item, i, colors) => {
      if (colors.length)
        return [
          {
            param: "color",
            values: colors
              .sort()
              .reduce((map: Record<string, string>, color) => {
                map[color] = color;
                return map;
              }, {}),
            name: "Color",
            data: ["color", ""],
            eval: (feature: MapGeoJSONFeature, value) =>
              value === feature.properties?.color,
          },
        ];
      return [];
    }, []),
    ...Object.entries(
      Object.values(policies ?? {}).reduce(
        (
          policies: Record<string, string>,
          {
            policy,
            title,
          }: { policy?: string; title?: string; spaces?: Spaces }
        ) => {
          console.log("policy=", policy, title, spaces);
          if (!spaces) return policies;
          if (policy && title) policies[policy] = title;
          return policies;
        },
        {}
      ) as Record<string, string>
    ).reduce((items, [id, title], i, policies) => {
      if (policies.length)
        return [
          {
            param: "policy",
            values: policies
              //.sort()
              .reduce((map: Record<string, string>, [key, value]) => {
                map[key] = value;
                return map;
              }, {}),
            name: "Policy",
            data: ["policy", ""],
            eval: (feature: MapGeoJSONFeature, value: string) => {
              const policy = policiesById[value];
              if (!policy) return false;
              if (!feature.properties?.id) return false;
              if (!policy.spaces) return false;
              //console.log("policy feature?=", feature, policy);
              return !!(
                policy.spaces.items?.[feature.properties.id] ??
                policy.spaces[feature.properties.id]
              );

              return value === feature.properties?.color;
            },
          },
        ];
      return [];
    }, []),
    // ...Object.values(
    //   Object.values(spaces ?? {}).reduce(
    //     (colors: Record<string, any>, { color }: { color?: string }) => {
    //       if (!color) return colors;
    //       const value = color;
    //       if (!colors[color.toLowerCase()])
    //         colors[color.toLowerCase()] = {
    //           param: color,
    //           name: proper(color),
    //           data: ["color", color.toLowerCase()],
    //           eval: (feature: MapGeoJSONFeature) =>
    //             value === feature.properties?.color,
    //         };
    //       return colors;
    //     },
    //     {} as Record<string, any>
    //   )
    //).sort(comparer("name")),
  ] as any[];

  $: highlighterstatus = (($params) =>
    highlighters.map((item) => {
      item.value = $params[item.param];
      item.active = !!item.value;
      //if (item.values && item.data) item.data[1] = item.value || "";
      return item;
    }))($params);

  // process active based on params and reduce to a function that evals on active params
  $: highlight = highlighterstatus
    .filter((item) => item.active)
    .reduce(
      (func, item, i, items) => {
        // only call once, return a function that evaluates against active items
        return function (feature: MapGeoJSONFeature) {
          return !!items.find(
            (item) =>
              null != feature?.properties && item.eval(feature, item.value)
          );
        };
      },
      () => false
    ) as (feature: MapGeoJSONFeature) => boolean;

  const onchange = ({ target, currentTarget }) =>
    currentTarget instanceof HTMLSelectElement
      ? query(currentTarget.name, currentTarget.value)
      : currentTarget instanceof HTMLInputElement
        ? query(
            currentTarget.name,
            currentTarget.checked ? currentTarget.value : null
          )
        : target instanceof HTMLSelectElement
          ? query(target.name, target.value)
          : target instanceof HTMLInputElement
            ? query(target.name, target.checked ? target.value : null)
            : null;
</script>

<ul on:change={onchange}>
  {#each highlighterstatus as item}
    <li>
      {#if null == item.values}
        <input
          id="highlight-{item.param}"
          class="highlight"
          type="checkbox"
          name={item.param}
          value="true"
          checked={item.active}
        />
      {:else if null != item.values}
        <select
          class="highlight"
          name={item.param}
          value={item.value ?? ""}
          required
        >
          <option value="">none</option>
          {#each Object.entries(item.values) as [value, label]}
            <option {value}>{label}</option>
          {/each}
        </select>
      {/if}
      <label for="highlight-{item.param}">
        {#if item.data}
          <data class={item.data[0]} value={item.data[1]}>{item.name}</data>
        {:else}
          {item.name}
        {/if}</label
      >
    </li>
  {/each}
</ul>
