<script lang="ts">
  export let item = null;
  export let property = null;
  //$: console.log("service=", item);
</script>

{#if item}
  <article class={item.type}>
    <header>
      <h1>{item.name}</h1>
      <h2>{item.format}</h2>
    </header>
    {#if item.new}
      <data class="status" value="new">New</data>
    {:else if !item.enabled && item.development}
      <data class="status" value="development"
        >{item.development === true ? "Coming Soon" : item.development}</data
      >
      <!-- {:else if item.enabled && item.development}
    <data class="status" value="beta">Beta</data> -->
    {:else if item.beta}
      <data class="status" value="beta">Early Preview</data>
    {/if}
    {#if item.suite}
      <ul>
        <li>Available with Community Boss Suite</li>
      </ul>
    {/if}
    <!-- 
    {#if item.oauth && property}
      <a
        href="https://services.communityboss.app/{item.service}/authorize?state={property.id}"
        target="_blank">Connect Account</a
      >
    {/if} -->

    {#if item.description}<p>{item.description}</p>{/if}

    <img
      alt="{item.name} logo"
      class="service logo"
      src="/{item.service}.svg"
    />
    <!-- <h2>
        {#if item.service == "stripe"}
          {#if item.account}
            Connected Stripe Account
          {:else}
            <a
              href="https://services.communityboss.app/{item.service}/authorize?state={$propertyId}"
              target="_blank">Connect {item.name} Account</a
            >
          {/if}
        {:else if item.oauth}
          {#if item.subject}
            <data class="oauth" value="true">OAuth Connected</data>
          {:else if !item.subject && $propertyId}
            <a
              href="https://services.communityboss.app/{item.service}/authorize?state={$propertyId}"
              target="_blank">Connect {item.name} Account</a
            >
          {/if}
        {:else}
          <data class="oauth" value="false">No OAuth Support</data>
        {/if}
      </h2> -->
  </article>
{/if}
