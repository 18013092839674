<script>
  import TimeIntervalItem from "../TimeIntervalItem.svelte";

  import EnforcementChartItem from "./EnforcementChartItem.svelte";
  import EnforcementSummaryItem from "./EnforcementSummaryItem.svelte";

  export let item;
  export let record = null;
</script>

<article class="{item.type} details">
  <EnforcementSummaryItem {item} />
  <!-- <EnforcementChartItem {item} /> -->
</article>
