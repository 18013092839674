<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import NewPolicyPermit from "$components/form/NewPolicyPermit.svelte";

  export let item: any;

  export let values: Record<string, any> = {};
  export let policy: PermitIssuePolicy;
  export let policies: PermitIssuePolicy[];
  export let vehicle: Vehicle;
  export let tenant: Tenant;
  export let property: Property;

  //$: console.log("vehicleinfopermit=", property, policy);

  const events = createEventDispatcher();
</script>

<NewPolicyPermit
  {property}
  {policy}
  {policies}
  {vehicle}
  {tenant}
  on:cancel={(e) => events("cancel")}
  on:create={(e) => events("complete")}
  on:change={(e) => events("change", e.detail)}
  {values}><header><h1>{policy.title}:</h1></header></NewPolicyPermit
>
