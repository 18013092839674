import { readable } from "svelte/store";

var smscreenquery = window.matchMedia("only screen and (max-width: 979px)");
export const smscreen = readable(smscreenquery.matches, (set) => {
  set(smscreenquery.matches);
  const f = (e) => set(e.matches);
  smscreenquery.addEventListener
    ? smscreenquery.addEventListener("change", f)
    : smscreenquery.addListener(f);
  return () =>
    smscreenquery.addEventListener
      ? smscreenquery.removeEventListener("change", f)
      : smscreenquery.removeListener(f);
});

const nohoverquery = window.matchMedia("(hover: none)");
export const hover = readable(nohoverquery.matches, (set) => {
  set(!nohoverquery.matches);
  const f = (e) => set(!e.matches);
  if (nohoverquery.addEventListener) nohoverquery.addEventListener("change", f);
  return () => {
    if (nohoverquery.addEventListener)
      nohoverquery.removeEventListener("change", f);
  };
});

export const pagevisible = readable(!document.hidden, (set) => {
  set(!document.hidden);
  const f = () => set(!document.hidden);
  document.addEventListener("visibilitychange", f);
  return () => document.removeEventListener("visibilitychange", f);
});

export const online = readable(navigator.onLine, (set) => {
  set(navigator.onLine);
  const f = () => set(navigator.onLine);
  window.addEventListener("online", f);
  window.addEventListener("offline", f);

  return () => {
    window.removeEventListener("online", f);
    window.removeEventListener("offline", f);
  };
});

export const touch = readable(!document.hidden, (set) => {
  set(false);
  const events: [string, EventListener][] = [
    ["touchstart", (e: Event) => set(true)],
    ["touchend", (e: Event) => set(false)],
  ];
  const o: AddEventListenerOptions = { passive: true };

  for (const [e, f] of events) document.addEventListener(e, f, o);

  return () => {
    for (const [e, f] of events) document.removeEventListener(e, f, o);
  };
});

export const embedded = readable(top != self);

export const standalone = readable(!!(navigator as any).standalone);

export const hash = readable(
  window.location.hash?.substring(1) || null,
  function start(set) {
    function f() {
      set(window.location.hash?.substring(1) || null);
    }

    window.addEventListener("hashchange", f);

    return function stop() {
      window.removeEventListener("hashchange", f);
    };
  }
);

export const localhost = readable(location.hostname === "localhost");
