<script lang="ts">
  import Fullscreen from "$components/Fullscreen.svelte";
  import MapLevelSelect from "$components/MapLevelSelect.svelte";
  import PropertyMap from "$components/PropertyMap.svelte";
  import { autocreate } from "$utils/vehicle";
  import VehicleDetectionsHeatmap from "./VehicleDetectionsHeatmap.svelte";

  export let item: any;

  export let theme = "cool";
  let level: string = "outside";
  let levels: { [key: string]: any } = {};
  let usesatellite = false;
  let fullscreen = false;
  //console.log("item=", item);
  const vehicle =
    typeof item?.vehicle === "string"
      ? autocreate(item.vehicle, item.scope)
      : item?.vehicle;
  //if (!vehicle) console.log("item=", item);
  // const files = Object.values(item?.items ?? [])
  //   .map((i: any) => i.file)
  //   .sort(comparer("created", Temporal.Instant.compare, true));
</script>

<Fullscreen bind:active={fullscreen}>
  <PropertyMap
    theme={usesatellite ? "satellite" : theme || "cool"}
    property={item.scope}
    {level}
    on:level={(e) => (level = e.detail)}
    bind:levels
    overlay={!usesatellite}
    interactive={fullscreen}
  >
    <VehicleDetectionsHeatmap {item} record={vehicle} />
    <!-- <VehicleDetectionsOffenderHeatmap {item} record={vehicle} /> -->
    <form>
      <fieldset>
        <input
          type="checkbox"
          name="style"
          value="satellite"
          on:change={({ currentTarget: target }) =>
            (usesatellite = target.checked)}
        />
        <MapLevelSelect {levels} bind:level />
      </fieldset>
      <fieldset>
        <input
          type="checkbox"
          name="fullscreen"
          value="fullscreen"
          bind:checked={fullscreen}
        />
      </fieldset>
    </form>
    <slot />
  </PropertyMap>
</Fullscreen>
