<script context="module">
</script>

<script>
  import { format, toZonedTime } from "date-fns-tz";
  export let item;

  //"item=", item);
  //$: interval = item && item.interval && stringToDateInterval(item.interval, item.timezone);
  $: date = item && item.valid && toZonedTime(item.valid, item.timezone);
</script>

<aside class="{item.type} summary">
  <!-- <h1>
    <dfn>Escalations</dfn>
  </h1>
  <time datetime={item.interval}
    ><data class="count" value={item.count}
      >{item.count === 0 ? "0" : item.count}</data
    ></time
  > -->
  <dl class="data">
    <dt>Escalations</dt>
    <dd>
      <time datetime={item.interval}
        ><data class="count" value={item.count}
          >{item.count === 0 ? "0" : item.count}</data
        ></time
      >
    </dd>
  </dl>
</aside>
