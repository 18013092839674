<script context="module">
  const now = time({ seconds: 12 });
</script>

<script>
  import NavTo from "$components/NavTo.svelte";
  import SearchResults from "./SearchResults.svelte";
  import { smscreen } from "$utils/behaviorstores";
  import { externalCRM, unitStatusAttention } from "$utils/propertystores";
  import { view } from "$utils/uistores";
  import { help } from "$utils/help";
  import { time } from "$utils/timestores";
  import { format, toZonedTime } from "date-fns-tz";

  export let active = false;
  export let property;

  $: timezone = property?.timezone;

  $: base = property ? `/properties/${property.id}` : "";
</script>

<nav class="app" class:active={true === active}>
  <SearchResults />
  <ul class="primary">
    {#if property}
      {#if $view == "live"}
        <li>
          <NavTo selected={$view == "live"} url="{base}/live"
            >Live {format(toZonedTime($now, timezone), "h:mm a zzz", {
              timeZone: timezone,
            })}</NavTo
          >
        </li>
      {/if}
      <li>
        <NavTo
          selected={$view == "activity" || $view == "dashboard"}
          url="{base}/activity">Activity</NavTo
        >
      </li>
      <li>
        <NavTo
          selected={$view == "parking" || $view == "map"}
          url="{base}/parking">Parking</NavTo
        >
      </li>
      <!-- {#if !$smscreen}
        <li>
          <NavTo selected={$view == "map"} url="{base}/map">Parking Map</NavTo>
        </li>
      {/if} -->
      <li>
        <!-- {#if property.amenities.enabled}
          <a
            target="_blank"
            href={sign(
              `https://amenities.communityboss.app/properties/${property.id}`
            )}>Amenities</a
          >
        {:else} -->
        <NavTo
          selected={$view == "amenities"}
          updated="2023-06-20"
          url="{base}/amenities">Amenities</NavTo
        >
        <!-- {/if} -->
      </li>
      {#if property.tenants?.tour?.enabled}
        <li>
          <NavTo selected={$view == "welcome"} url="{base}/welcome"
            >Welcome</NavTo
          >
        </li>
      {/if}
      {#if !$smscreen}
        <li>
          <NavTo selected={$view == "payments"} url="{base}/payments"
            >Payments</NavTo
          >
        </li>
      {/if}
      <li>
        <NavTo
          selected={$view &&
            ($view == "enforcement" || $view.startsWith("enforcement."))}
          url="{base}/enforcement">Enforcement</NavTo
        >
      </li>
      <li>
        <NavTo selected={$view && $view == "insights"} url="{base}/insights"
          >Smart Guard</NavTo
        >
      </li>

      {#if property.units?.enabled}
        <li>
          <NavTo
            selected={$view == "units"}
            url="{base}/units"
            attention={$unitStatusAttention &&
              $externalCRM?.enabled &&
              $unitStatusAttention.length}>Units</NavTo
          >
        </li>
      {/if}
      {#if property.spaces?.enabled}
        <li>
          <NavTo selected={$view == "spaces"} url="{base}/spaces">Spaces</NavTo>
        </li>
      {/if}
      <li>
        <NavTo selected={$view == "notices"} url="{base}/notices"
          >Documents</NavTo
        >
      </li>

      <li>
        <NavTo selected={$view == "users"} url="{base}/users">Team</NavTo>
      </li>
      {#if property.reports?.enabled}
        <li>
          <a href="{base}/reports">Reports</a>
        </li>
      {/if}
      <li>
        <NavTo selected={$view == "integrations"} url="{base}/integrations"
          >Integrations</NavTo
        >
      </li>
    {/if}
  </ul>
  <ul class="system ancillary">
    <li>
      <a href="/help" on:click={help}>Get Help</a>
    </li>
    <li>
      <a href="https://my.communityboss.app/user">Account</a>
    </li>
    <!-- <li>
        <a href="https://updates.parkingboss.com" target="_blank">Updates</a>
    </li> -->
    <li>
      <a href="/logout">Log out</a>
    </li>
    <!-- <li>
        <a href="mailto:help@communityboss.com" rel="rt" data-unread="0"></a>
    </li> -->
  </ul>
</nav>
