<script lang="ts" context="module">
  const menu = param("menu");
  const lookup = param("lookup");
  const scan = param("scan");
  const id = param("id");
  const mode = param("mode");
</script>

<script lang="ts">
  import Live from "$components/Live.svelte";
  import AccessControlSection from "$sections/AccessControlSection.svelte";
  import AllocationSection from "$sections/AllocationSection.svelte";
  import Amenities from "$sections/AmenitiesSection.svelte";
  import Apps from "$sections/Apps.svelte";
  import DashboardSection from "$sections/DashboardSection.svelte";
  import DebugSection from "$sections/DebugSection.svelte";
  import Enforcement from "$sections/EnforcementSection.svelte";
  import Header from "$sections/Header.svelte";
  import Insights from "$sections/InsightsSection.svelte";
  import IntegrationSection from "$sections/IntegrationSection.svelte";
  import MediaDetails from "$sections/MediaDetailsSection.svelte";
  import Nav from "$sections/Nav.svelte";
  import ParkingLegacySection from "$sections/ParkingLegacySection.svelte";
  import ParkingMapSection from "$sections/ParkingMapSection.svelte";
  import Payments from "$sections/Payments.svelte";
  import PolicySection from "$sections/PolicySection.svelte";
  import PortfolioSection from "$sections/PortfolioSection.svelte";
  import Notices from "$sections/PropertyNotices.svelte";
  import SelfGuided from "$sections/SelfGuided.svelte";
  import SpaceDetails from "$sections/SpaceDetailsSection.svelte";
  import SpacesSection from "$sections/SpacesSection.svelte";
  import TenantDetails from "$sections/TenantDetailsSection.svelte";
  import UnitDetailsSection from "$sections/UnitDetailsSection.svelte";
  import Units from "$sections/UnitsSection.svelte";
  import Users from "$sections/UsersSection.svelte";
  import VehicleDetails from "$sections/VehicleDetailsSection.svelte";
  import WelcomeSection from "$sections/WelcomeSection.svelte";
  import {
    authorized,
    enforcement,
    mediaId,
    permits,
    permitsIssuedSevenDays,
    policy,
    policyId,
    propertyId,
    property,
    sessions,
    spaceId,
    spaces,
    tenantId,
    tenants,
    unitId,
    units,
    vehicleId,
    violationId,
    violation,
    suspend,
    spacesAvailability,
    isSystemAdmin,
  } from "$utils/propertystores";
  import {
    mediaStatus,
    spaceStatus,
    tenantStatus,
    vehicleStatus,
  } from "$utils/recordstores";
  import { minimal, view } from "$utils/uistores";
  import { smscreen } from "$utils/behaviorstores";
  import { param } from "$utils/params";
  import { auth } from "$utils/auth";
  import Scanner from "$sections/Scanner.svelte";
  import ViolationSection from "$sections/ViolationSection.svelte";
  import SuspendItem from "$components/suspend/SuspendItem.svelte";
  import ParkingSection from "$sections/ParkingSection.svelte";
  import EnforcementMapSection from "$sections/EnforcementMapSection.svelte";
  import PropertySignageSection from "$sections/PropertySignageSection.svelte";
  import Lprd from "$sections/Lprd.svelte";
  import SpaceOccupancyMapSection from "$sections/SpacesOccupancyMapSection.svelte";
  import PropertyLprdSection from "$sections/PropertyLprdSection.svelte";
  import PropertyVehiclesPresenceSection from "$sections/PropertyVehiclesPresenceSection.svelte";

  //$: console.log("view:", $view);

  $: active = !$smscreen ? undefined : !!$menu || !!$lookup;
</script>

<svelte:head>
  <title
    >{[$property?.name, "Community Boss"].filter(Boolean).join(" - ")}</title
  >
</svelte:head>
{#if null == $propertyId && $view == "lprd"}
  <Lprd id={$id} />
{:else if $view == "debug"}
  <DebugSection property={$property} />
{:else if null == $propertyId}
  <PortfolioSection />
{:else}
  <header class:active={active || $scan}>
    <Header property={$property} user={$auth}>
      {#if $suspend?.pending || ($suspend?.active && $isSystemAdmin)}
        <SuspendItem item={$suspend} />
      {/if}
      {#if $scan}<Scanner property={$property} />{/if}
      {#if active || !$smscreen}
        <Nav property={$property} />
      {/if}
    </Header>
  </header>
  <main>
    {#if $suspend?.active && !$isSystemAdmin}
      <SuspendItem item={$suspend} />
    {:else}
      {#if $view == "dashboard" || $view == "activity"}
        <DashboardSection
          property={$property}
          authorized={$authorized}
          sessions={$sessions}
          enforcement={$enforcement}
          permits={$permitsIssuedSevenDays}
        />
      {/if}
      {#if $view == "lprd" && $property}
        <!-- <Map property={$property} /> -->
        <PropertyLprdSection property={$property} />
      {/if}
      {#if $view == "map" && $property}
        <!-- <Map property={$property} /> -->
        <ParkingMapSection property={$property} />
      {/if}
      {#if $view == "amenities" && $property}
        <Amenities property={$property} />
      {/if}
      {#if $view == "payments" && $property}
        <Payments property={$property} />
      {/if}
      <!-- {#if $view == "parking" && $property}
  <ParkingSection property={$property} />
{/if} -->
      {#if $view == "integrations" && $property}
        <IntegrationSection property={$property} />
      {/if}
      {#if $view == "accesscontrol" && $property}
        <AccessControlSection property={$property} />
      {/if}
      {#if $view == "debug"}
        <DebugSection property={$property} />
      {/if}
      {#if $view == "units"}
        <Units property={$property} units={$units} tenants={$tenants} />
      {/if}
      {#if $view == "vehiclespresence"}
        <PropertyVehiclesPresenceSection property={$property} />
      {/if}

      {#if $view == "notices"}
        <Notices />
      {/if}

      {#if $view == "parking" && $propertyId == "5zcktyynzd4410ench1vpjtkcr"}
        <ParkingSection property={$property} />
      {:else if $view == "parking"}
        <ParkingLegacySection property={$property} />
      {/if}

      {#if $view == "allocation" && $property}
        <AllocationSection permits={$permits} spaces={$spaces} />
      {/if}

      {#if $view == "apps"}
        <Apps property={$property} units={$units} tenants={$tenants} />
      {/if}
      {#if $view == "selfguided"}
        <SelfGuided property={$property} units={$units} tenants={$tenants} />
      {/if}
      {#if $view == "signage"}
        <PropertySignageSection property={$property} mode={$mode} />
      {/if}
      {#if $view == "users"}
        <Users
          property={$property}
          authorized={$authorized}
          sessions={$sessions}
        />
      {/if}
      {#if $view == "spaces"}
        <SpacesSection
          property={$property}
          spaces={$spaces}
          availability={$spacesAvailability}
        />
      {/if}
      {#if $view == "welcome"}
        <WelcomeSection property={$property} />
      {/if}
      {#if $view && ($view == "enforcement" || $view.startsWith("enforcement."))}
        <Enforcement
          property={$property}
          authorized={$authorized}
          sessions={$sessions}
        />
      {/if}
      {#if $view && ($view == "enforcementmap" || $view.startsWith("enforcement."))}
        <EnforcementMapSection property={$property} />
      {/if}
      {#if $view && $view == "insights"}
        <Insights property={$property} />
      {/if}
      {#if $view == "spacesoccupancy"}
        <SpaceOccupancyMapSection property={$property} />
      {/if}
      {#if $vehicleId && !$view}
        <VehicleDetails
          id={$vehicleId}
          property={$property}
          item={$vehicleStatus?.vehicles.item}
          data={$vehicleStatus}
          minimal={$minimal || $smscreen}
        />
      {/if}
      {#if $unitId && !$view}
        <UnitDetailsSection
          id={$unitId}
          property={$property}
          item={$tenantStatus?.tenants.item.subject}
          data={$tenantStatus}
          minimal={$minimal || $smscreen}
        />
      {/if}
      {#if $tenantId && !$view}
        <TenantDetails
          id={$tenantId}
          property={$property}
          item={$tenantStatus?.tenants.item}
          data={$tenantStatus}
          minimal={$minimal || $smscreen}
        />
      {/if}
      {#if $spaceId && !$view}
        <SpaceDetails
          id={$spaceId}
          property={$property}
          item={$spaceStatus?.spaces.item}
          data={$spaceStatus}
          minimal={$minimal || $smscreen}
        />
      {/if}
      {#if $mediaId && !$view}
        <MediaDetails
          id={$mediaId}
          property={$property}
          item={$mediaStatus?.media.item}
          data={$mediaStatus}
          minimal={$minimal || $smscreen}
        />
      {/if}
      {#if $violationId && !$view}
        <ViolationSection property={$property} violation={$violation} />
      {/if}
      {#if $view == "live"}
        <Live view={$view} property={$property} />
      {/if}
      {#if $policyId}
        <PolicySection policy={$policy} />
      {/if}
    {/if}
  </main>
{/if}
