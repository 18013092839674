<script context="module" lang="ts">
  const formatAsOfDate = Temporal.PlainDate.from({
    year: 2000,
    day: 1,
    month: 1,
  });

  const timeFormat = new Intl.DateTimeFormat("en-US", {
    timeStyle: "short",
  });
  export function format(
    value: string | Temporal.PlainTime | Temporal.PlainTimeLike
  ) {
    if (!value) return "";

    const pdt = formatAsOfDate.toPlainDateTime(value);

    const formattable = parseISO(pdt.toString());

    return timeFormat.format(formattable);
  }
</script>

<script lang="ts">
  import { parseISO } from "date-fns";
  import { createEventDispatcher } from "svelte";

  import { midnight } from "$utils/temporal";

  export let id: string = null;
  export let name: string = null;
  export let value: string = "";
  //   export let min: string = null;
  //   export let max: string = null;
  export let required: boolean = false;
  export let disabled: boolean = false;

  const events = createEventDispatcher();

  function change(newvalue: string) {
    if (value === newvalue) return; // nothing to change
    events("change", (value = newvalue));
  }

  //const midnight = new Temporal.PlainTime(0, 0, 0, 0, 0, 0);
  const values = [midnight.toString()];
  let minutes = 30;
  let time = midnight.add({ minutes });
  while (!time.equals(midnight)) {
    //console.log("time=", time.toString(), max.toString());
    values.push(time.toString());
    time = time.add({ minutes });
  }
  values.push("23:59:59");
  //values.push(midnight.toString());
</script>

<select
  {required}
  {id}
  {name}
  {value}
  {disabled}
  on:change={({ currentTarget: target }) => change(target.value)}
>
  {#each values as value}
    <option {value}>{format(value)}</option>
  {/each}
</select>
