import { writable } from "svelte/store";

// TODO: throttle/smoother

const store = writable({
    status: "uninitialized",
});

let status = "uninitialized";
let heading;
let headingAccuracy;
let permission = false;
let listening = false;

// function onDeviceOrientation(e) {
//     console.debug("deviceorientation=", e);
//     if(!has(e, "webkitCompassHeading") && !has(e, "webkitCompassAccuracy")) return;

//     store.set({
//         status: "oriented",
//         orientation: {
//             heading: e.webkitCompassHeading,
//             headingAccuracy: e.webkitCompassAccuracy
//         }
//     });
// }

// listen for all unsubscribers and stop?
window.addEventListener("deviceorientation", onDeviceOrientation, false);

/**
     * Compute compass heading.
     *
     * @param {number} alpha
     * @param {number} beta
     * @param {number} gamma
     *
     * @returns {number} compass heading
     */
    function _computeCompassHeading(alpha, beta, gamma) {

        // Convert degrees to radians
        var alphaRad = alpha * (Math.PI / 180);
        var betaRad = beta * (Math.PI / 180);
        var gammaRad = gamma * (Math.PI / 180);

        // Calculate equation components
        var cA = Math.cos(alphaRad);
        var sA = Math.sin(alphaRad);
        var sB = Math.sin(betaRad);
        var cG = Math.cos(gammaRad);
        var sG = Math.sin(gammaRad);

        // Calculate A, B, C rotation components
        var rA = - cA * sG - sA * sB * cG;
        var rB = - sA * sG + cA * sB * cG;

        // Calculate compass heading
        var compassHeading = Math.atan(rA / rB);

        // Convert from half unit circle to whole unit circle
        if (rB < 0) {
            compassHeading += Math.PI;
        } else if (rA < 0) {
            compassHeading += 2 * Math.PI;
        }

        // Convert radians to degrees
        compassHeading *= 180 / Math.PI;

        return compassHeading;
    }

    /**
     * Handler for device orientation event.
     *
     * @param {Event} event
     * @returns {void}
     */
    function onDeviceOrientation(event) {
        if (event.webkitCompassHeading !== undefined) {
            if (event.webkitCompassAccuracy < 50) {
                heading = event.webkitCompassHeading;
                headingAccuracy = event.webkitCompassAccuracy;
                status = "oriented";
                // store.set({
                //     status: "oriented",
                //     orientation: {
                //         heading,
                //         headingAccuracy
                //     }
                // });
            } else {
                console.warn('webkitCompassAccuracy is event.webkitCompassAccuracy');
            }
        } else if (event.alpha !== null) {
            if (event.absolute === true || event.absolute === undefined) {
                heading = _computeCompassHeading(event.alpha, event.beta, event.gamma);
                headingAccuracy = 10;
                status = "oriented";
                // store.set({
                //     status: "oriented",
                //     orientation: {
                //         heading,
                //         headingAccuracy
                //     }
                // });
            } else {
                console.warn('event.absolute === false');
            }
        } else {
            console.warn('event.alpha === null');
        }
    }


// this should be called from inside an interaction event
export const start = function() {

    // setup
    if(!permission) {

        store.set({
            status: "requesting",
            requesting: true,
        });

        // request orientation permission if that's a thing...
        if (window.DeviceOrientationEvent && typeof DeviceOrientationEvent.requestPermission === 'function') {
            DeviceOrientationEvent.requestPermission()
            .then(permissionState => {
                if (permissionState === 'granted') {
                    permission = true;
                    console.log("orienation permission=", permissionState);

                    store.set({
                        status: "permitted",
                        requesting: true,
                    });

                    // if(!listening) {
                    //     window.addEventListener("deviceorientation", onDeviceOrientation, false);
                    //     listening = true;
                    // }

                }
            })
            .catch(console.error);
        } else {
        // handle regular non iOS 13+ devices
        }

    }

    // setup?
    return true;
}

export const current = function() {

    return {
        status,
        orientation: {
            heading,
            headingAccuracy
        }
    };
}