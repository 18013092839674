<script context="module">
  import { property } from "$utils/propertystores";
  import transformScale from "@turf/transform-scale";
  import square from "@turf/square";
  import { onMount } from "svelte";

  import { simpleheat } from "$utils/simpleheat";
  import { mapbox, boundsFromGeoJSON, accessToken, bbox } from "$utils/mapbox";
  import { derived } from "svelte/store";
  // import { contourDensity } from "d3-contour";
  import { geoMercator, geoPath } from "d3-geo";
  import { hexbin } from "d3-hexbin";
  const projection = geoMercator();
  // const path = geoPath(projection);

  const bounds = derived(property, function ($property, set) {
    const area = $property?.address.area;
    if (!area) return;

    const bounds = square(bbox(transformScale(area, 1.2)));
    set(bounds);
  });

  const streetmap = derived(
    [property, bounds],
    function ([$property, $bounds], set) {
      if (!$bounds) return;

      const url = `https://api.mapbox.com/styles/v1/mapbox/navigation-guidance-day-v4/static/[${$bounds.join(
        ","
      )}]/450x450@2x?access_token=${accessToken}&logo=false&attribution=false`;
      set(url);
      // let response = await fetch(url);

      // const blob = await response.blob(); // download as Blob object

      // const loaded = URL.createObjectURL(blob);
      // console.log(loaded);

      // set(loaded);
    }
  );

  const satellitemap = derived(
    [property, bounds],
    function ([$property, $bounds], set) {
      if (!$bounds) return;

      const url = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static/[${$bounds.join(
        ","
      )}]/450x450@2x?access_token=${accessToken}&logo=false&attribution=false`;
      set(url);
      // let response = await fetch(url);

      // const blob = await response.blob(); // download as Blob object

      // const loaded = URL.createObjectURL(blob);
      // console.log(loaded);

      // set(loaded);
    }
  );

  function buildStaticMapUrl(area) {
    if (!area) return null;
    const feature = {
      type: "Feature",
      geometry: area,
      properties: {
        stroke: "red",
        "stroke-width": 2,
        "fill-opacity": 0,
        "stroke-dasharray": [1.5, 0.5],
      },
    };

    return `https://api.mapbox.com/styles/v1/mapbox/dark-v10/static/[${bounds.join(
      ","
    )}]/450x450@2x?access_token=${accessToken}`;
    // return `https://api.mapbox.com/styles/v1/mapbox/dark-v10/static/geojson(${encodeURIComponent(
    //   JSON.stringify(feature)
    // )})/[${bounds.join(",")}]/450x450@2x?access_token=${accessToken}`;

    //path-{strokeWidth}+{strokeColor}-{strokeOpacity}+{fillColor}-{fillOpacity}({polyline})
  }
</script>

<script>
  export let item;
  export let record = null; // let's use a different style if a single record
  let checkheatmapcanvas;
  let checkheatmap;
  let vioheatmapcanvas;
  let vioheatmap;
  let usesatellite;
  //x$: console.log("maps data =", item);

  let width = 1000;
  let height = 1000;

  let features;

  let area;
  export let defaultStyle = [
    "mapbox://styles/mapbox/dark-v10",
    "mapbox://styles/mapbox/navigation-guidance-night-v4",
  ][0];
  export let style = defaultStyle;

  $: if (!area) area = item?.subject?.address?.area || $property?.address?.area;

  $: if ($bounds)
    projection.fitExtent(
      [
        [0, 0],
        [width, height],
      ],
      {
        type: "Feature",
        //bbox: $bounds,
        geometry: {
          type: "LineString",
          bbox: $bounds,
          coordinates: [
            [$bounds[0], $bounds[1]],
            [$bounds[2], $bounds[3]],
          ],
        },
      }
    );

  //$: console.log("map=", $streetmap);

  const layers = [
    {
      id: "property-outline",
      type: "line",
      source: "property",
      filter: ["==", ["geometry-type"], "Polygon"],
      paint: {
        "line-color": "red",
        "line-width": 2,
        //"line-opacity":0.6,
        "line-dasharray": [1.5, 0.5],
      },
      layout: {
        //"line-cap":"round",
        "line-join": "round",
      },
    },
    {
      id: "accessed-heat",
      type: "heatmap",
      source: "property",
      filter: [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["get", "type"], "check"],
      ],
      //maxzoom: 20,
      paint: record
        ? {
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.2,
              "#A1D2FF",
              0.4,
              "#73BCFF",
              0.6,
              "#44A5FF",
              0.8,
              "#158FFF",
              1,
              "#1177D4",
            ],
            "heatmap-weight": 1,
            //"heatmap-opacity":0.5,
            "heatmap-radius": 10,
          }
        : {
            // increase intensity as zoom level increases
            //   "heatmap-intensity": {
            //     stops: [
            //       [11, 1],
            //       [18, 3],
            //     ],
            //   },
            // assign color values be applied to points depending on their density
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.2,
              "#A1D2FF",
              0.4,
              "#73BCFF",
              0.6,
              "#44A5FF",
              0.8,
              "#158FFF",
              1,
              "#1177D4",
            ],
            // purple
            // "heatmap-color": [
            //   "interpolate",
            //   ["linear"],
            //   ["heatmap-density"],
            //   0,
            //   "rgba(0, 0, 255, 0)",
            //   0.2,
            //   "#D9C9F3",
            //   0.4,
            //   "#C6AEEE",
            //   0.6,
            //   "#B393E8",
            //   0.8,
            //   "#A078E2",
            //   1,
            //   "#7F48D8"
            // ],
            // increase radius as zoom increases
            "heatmap-weight": 0.2,
            // "heatmap-intensity":0.5,
            "heatmap-opacity": 0.5,
            "heatmap-radius": 10,
            // "heatmap-weight": [
            //     "interpolate",
            //     ["linear"],
            //     ["zoom"],
            //     16,
            //     0.5,
            //     17,
            //     1
            // ],
            // "heatmap-opacity": [
            //     "interpolate",
            //     ["linear"],
            //     ["zoom"],
            //     16,
            //     0.5,
            //     17,
            //     1
            // ],
            //   "heatmap-radius": [
            //     "interpolate",
            //     ["linear"],
            //     ["zoom"],
            //     15,
            //     5,
            //     17,
            //     10
            // ],
            // decrease opacity to transition into the circle layer
            //   "heatmap-opacity": {
            //     default: 1,
            //     stops: [
            //       [14, 1],
            //       [18, 0],
            //     ],
            //   },
          },
    },
    {
      id: "violations-heat",
      type: "heatmap",
      source: "property",
      filter: [
        "all",
        ["==", ["geometry-type"], "Point"],
        ["==", ["get", "type"], "violation"],
      ],
      //maxzoom: 20,
      paint: record
        ? {
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.1,
              "#FEF8E8",
              0.2,
              "#FAD674",
              0.3,
              "#F7BB18",
              0.4,
              "#FC9647",
              0.5,
              "#EB6409",
              0.7,
              "#DF3A25",
              0.8,
              "#B22E1E",
              0.9,
              "#862316",
              1,
              "#2D0C07",
            ],
            "heatmap-radius": 10,
          }
        : {
            // increase intensity as zoom level increases
            //   "heatmap-intensity": {
            //     stops: [
            //       [11, 1],
            //       [18, 3],
            //     ],
            //   },
            // assign color values be applied to points depending on their density
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(0, 0, 255, 0)",
              0.1,
              "#FEF8E8",
              0.2,
              "#FAD674",
              0.3,
              "#F7BB18",
              0.4,
              "#FC9647",
              0.5,
              "#EB6409",
              0.7,
              "#DF3A25",
              0.8,
              "#B22E1E",
              0.9,
              "#862316",
              1,
              "#2D0C07",
            ],
            // increase radius as zoom increases
            "heatmap-radius": 5,
            //   "heatmap-radius": [
            //     "interpolate",
            //     ["linear"],
            //     ["zoom"],
            //     18,
            //     10,
            //     22,
            //     15
            // ],
            //   "heatmap-radius": {
            //     stops: [
            //       [11, 15],
            //       [18, 20],
            //     ],
            //   },
            // decrease opacity to transition into the circle layer
            //   "heatmap-opacity": {
            //     default: 1,
            //     stops: [
            //       [14, 1],
            //       [18, 0],
            //     ],
            //   },
          },
    },
  ];

  const dataStyle = {
    version: 8,
    sources: {
      property: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      },
    },
    layers,
  };

  function build(item) {
    const geojson = {
      type: "FeatureCollection",
      features: [],
    };
    geojson.features = item.geo.features || geojson.features;
    if (area)
      geojson.features.push({
        type: "Feature",
        geometry: area,
      });
    return geojson;
  }

  // setup features
  $: features = build(item);

  // setup bounds

  // $: if (map && area)
  //   boundsFromGeoJSON(
  //     map,
  //     {
  //       type: "FeatureCollection",
  //       features: [
  //         {
  //           type: "Feature",
  //           geometry: area,
  //         },
  //       ],
  //     },
  //     30,
  //     true
  //   );

  $: if (checkheatmapcanvas) checkheatmap = simpleheat(checkheatmapcanvas);

  $: if ($bounds && checkheatmap && features) {
    // clear data
    checkheatmap.clear();

    for (const feature of features.features) {
      if (feature.geometry.type != "Point") continue;
      if (feature.properties.type != "check") continue;

      const point = projection(feature.geometry.coordinates);
      checkheatmap.add([...point, 0.2]);
    }
    checkheatmap.gradient({
      0.2: "#D6EBFF",
      0.4: "#C2E2FF",
      0.6: "#A1D2FF",
      0.8: "#73BCFF",
      1: "#158FFF",
    });
    checkheatmap.radius(8, 1);
    checkheatmap.draw(0.2);
  }

  $: if (vioheatmapcanvas) vioheatmap = simpleheat(vioheatmapcanvas);

  $: if ($bounds && vioheatmap && features) {
    // clear data
    vioheatmap.clear();

    for (const feature of features.features) {
      if (feature.geometry.type != "Point") continue;
      if (feature.properties.type != "violation") continue;

      const point = projection(feature.geometry.coordinates);
      vioheatmap.add([...point, 0.5]);
    }
    vioheatmap.gradient({
      0.2: "#F9C946",
      0.4: "#F7BB18",
      0.6: "#FC9647",
      0.8: "#F47317",
      1.0: "#C93421",
    });
    vioheatmap.radius(10, 1);
    vioheatmap.draw(0.2);
  }

  const grid = 9;

  $: hexbinner = hexbin()
    .radius(grid)
    .size([width, height]) // extent of projected data (displayed)
    .x(function (d) {
      return projection(d.geometry.coordinates)[0];
    })
    .y(function (d) {
      return projection(d.geometry.coordinates)[1];
    });

  $: hexbins =
    features &&
    hexbinner(
      features.features.filter(
        (feature) =>
          feature.geometry.type == "Point" &&
          (feature.properties.type == "check" ||
            feature.properties.type == "violation")
      )
      // .map((feature) =>
      //   Object.assign(
      //     [
      //       ...feature.geometry.coordinates,
      //       //feature.properties.type,
      //     ],
      //     {
      //       properties: feature.properties,
      //     }
      //   )
      // )
    );

  // $: checkshexbins =
  //   features &&
  //   hexbinner(
  //     features.features
  //       .filter(
  //         (feature) =>
  //           feature.geometry.type == "Point" &&
  //           feature.properties.type == "check"
  //       )
  //       .map((feature) => [
  //         ...feature.geometry.coordinates,
  //         feature.properties.type,
  //       ])
  //   );

  // $: vioshexbins =
  //   features &&
  //   hexbinner(
  //     features.features
  //       .filter(
  //         (feature) =>
  //           feature.geometry.type == "Point" &&
  //           feature.properties.type == "violation"
  //       )
  //       .map((feature) => [
  //         ...feature.geometry.coordinates,
  //         feature.properties.type,
  //       ])
  //   );

  //$: if (hexbins) console.log("hexbins=", hexbins);

  // $: if (area)
  //   console.log(
  //     "area=",
  //     area,
  //     area.coordinates[0]
  //       .map(projection)
  //       .map(([x, y]) => `${x},${y}`)
  //       .slice(0, -1)
  //       .join(" ")
  //   );

  // $: contourer = contourDensity()
  //   .x(function (d) {
  //     return projection(d.geometry.coordinates)[0];
  //   })
  //   .y(function (d) {
  //     return projection(d.geometry.coordinates)[1];
  //   })
  //   .size([width, height])
  //   .weight(1)
  //   .thresholds(20);

  // $: checkscontours = contourer(
  //   features.features.filter(
  //     (feature) =>
  //       feature.geometry.type == "Point" && feature.properties.type == "check"
  //   )
  // );

  //$: console.log("checkscontours=", JSON.stringify(checkscontours));

  function classifier(items) {
    //const classes = [];
    const violations = items.filter(
      (i) => i.properties.type == "violation"
    ).length;
    if (violations) {
      if (violations > 3) return "violations high";
      if (violations > 1) return "violations mid";
      return "violations low";
    }
    const checks = items.filter((i) => i.properties.type == "check").length;
    if (checks) {
      if (checks > 10) return "checks high";
      if (checks > 3) return "checks mid";
      return "checks low";
    }
    return "";
  }
</script>

<article class="{item.type} map">
  <figure class="map">
    <figcaption>
      <form>
        <input
          type="checkbox"
          name="style"
          value="mapbox://styles/mapbox/satellite-streets-v11"
          on:change={(e) => (usesatellite = e.target.checked && e.target.value)}
          style="background-image:url({usesatellite
            ? $streetmap
            : $satellitemap});"
        />
      </form>
    </figcaption>
    <img src={usesatellite ? $satellitemap : $streetmap} />
    {#if $bounds}
      <svg class:satellite={usesatellite} viewBox="0 0 {width} {height}">
        <!-- <path
          class="property"
          d={path(area)}
          stroke="red"
          stroke-width="5"
          stroke-dasharray="20 20"
          fill="none"
        /> -->

        <!-- {#if features}
          {#each features.features
            .filter((feature) => feature.geometry.type == "Point" && feature.properties.type == "check")
            .map( (feature) => [...projection(feature.geometry.coordinates), feature.properties.type] ) as [x, y, type]}
            <circle cx={x} cy={y} r="6" class={type} />
          {/each}
        {/if}
        {#if features}
          {#each features.features
            .filter((feature) => feature.geometry.type == "Point" && feature.properties.type == "violation")
            .map( (feature) => [...projection(feature.geometry.coordinates), feature.properties.type] ) as [x, y, type]}
            <circle cx={x} cy={y} r="8" class={type} />
          {/each}
        {/if} -->
        {#if hexbins}
          <g class="hex">
            <path
              d={hexbinner.mesh()}
              class="enforcement-grid"
              clip-path="url(#property)"
            />
            {#each hexbins as hex}
              <!-- <circle
              cx={hex.x}
              cy={hex.y}
              r={grid - 2}
              fill="black"
              class="enforcement {classifier(hex)}"
            /> -->
              <path
                d={hexbinner.hexagon(grid - 0) ||
                  hexbinner.hexagon(Math.min(hex.length * 0.5, grid))}
                transform="translate({hex.x},{hex.y})"
                class="enforcement {classifier(hex)}"
              />
            {/each}
          </g>
        {/if}
        {#each [area.coordinates[0]
            .map(projection)
            .map(([x, y]) => `${x},${y}`)
            .slice(0, -1)
            .join(" ")] as pointstr}
          <clipPath id="property">
            <polygon points={pointstr} />
          </clipPath>
          <polygon class="property" points={pointstr} />
        {/each}
        <!-- {#each checkscontours as contour}
          {#each contour.coordinates as polygon}
            <polygon
              points={polygon[0]
                //.map(projection)
                .map(([x, y]) => `${x},${y}`)
                .slice(0, -1)
                .join(" ")}
              stroke="black"
              stroke-width="1"
              fill="none"
              fill-opacity="0.1"
            />
          {/each}
          <path class="checks" fill-opacity="0.2" d={path(contour)} />
        {/each} -->
        <!-- {#if checkshexbins}
          {#each checkshexbins as hex}
            <path
              d={hexbinner.hexagon(Math.min(hex.length * 2, grid))}
              transform="translate({hex.x},{hex.y})"
              style="fill: var(--boss-check);opacity:{Math.min(
                hex.length * 0.2,
                0.8
              )}"
            />
          {/each}
        {/if}
        {#if vioshexbins}
          {#each vioshexbins as hex}
            <path
              d={hexbinner.hexagon(Math.min(hex.length * 3, grid))}
              transform="translate({hex.x},{hex.y})"
              style="fill: var(--boss-violation);opacity:{Math.min(
                hex.length * 1,
                1
              )}"
            />
          {/each}
        {/if} -->
      </svg>
    {/if}
    <!-- <canvas bind:this={checkheatmapcanvas} {width} {height} />
    <canvas bind:this={vioheatmapcanvas} {width} {height} /> -->
  </figure>
</article>
