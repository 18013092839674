<script lang="ts">
  import Info from "../Info.svelte";
  export let title = "Stay Tuned";
  export let message = "Awesome new features are on the way!";
  let classname = "info";
  export { classname as class };
</script>

<Info class="promo {classname}" {title} {message}>
  <slot /></Info
>
