<script lang="ts">
  import RecordItem from "$components/record/RecordItem.svelte";
  import { title as proper } from "$utils/text";
  import Time from "../Time.svelte";
  export let item: Tenant;

  let unit: Unit = item.unit ?? item.subject;
  let [start, end] = item.valid.interval.split("/");
</script>

<article class="details">
  <header>
    <h1>Move Out Completed</h1>
    <a href="/properties/{unit.scope}/{unit.type}/{unit.id}">Details</a>
  </header>
  <dl>
    <dt>{unit.title ?? unit.format}</dt>
    <dd><ul class="subjects"><li><RecordItem item={unit} /></li></ul></dd>
    <dt>Reset</dt>
    <dd><Time datetime={start} timezone={item.timezone} /></dd>
  </dl>
</article>
