<script lang="ts">
  import { help } from "$utils/help";

  export let subject: string;
  export let body: string;
</script>

<aside class="help">
  <p>
    <a
      on:click={help}
      href="mailto:help@communityboss.com?subject={subject}&body={body}:"
      ><slot /></a
    >
  </p>
</aside>
