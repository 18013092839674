import { derived, type Readable } from "svelte/store";
import MiniSearch from "minisearch";
import uniqBy from "lodash-es/uniqBy";
import orderBy from "lodash-es/orderBy";

export function store(properties: Readable<Properties>, query: Readable<string>) {

    const miniSearch = new MiniSearch<Property>({
        fields: ["name", "address.city", "address.state", "management", "format"],
        searchOptions: {
          prefix: true,
          boost: { name: 2 },
        },
        storeFields: ["name"],
        extractField: (document, fieldName) =>
          fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
      });

      // when items change, update the index
      properties.subscribe($value => $value?.items && miniSearch.addAll(Object.values($value?.items)));
    

      return derived<[typeof query, typeof properties], Properties>([query, properties], ([$query, $properties]) => {

        if(!$query) return {
          type: "properties",
          count: 0,
          items: [],
      }; 
        
        const results = uniqBy(
          orderBy(miniSearch.search($query).slice(0, 10), ["score", "name"], ["desc", "asc"]),
          "id"
        ).map((result) => $properties?.items?.[result.id]);
        return {
            type: "properties",
            count: results.length,
            items: results,
        }
      });

}