<script lang="ts">
  import NewPolicyPermit from "$components/form/NewPolicyPermit.svelte";

  export let property: any = null;
  export let policies: PermitIssuePolicy[] = [];
  let policy: any = null;
</script>

{#if policies.length}
  {#if policy}
    <NewPolicyPermit
      {property}
      {policy}
      {policies}
      on:cancel={(e) => (policy = null)}
      on:create={(e) => (policy = null)}
    />
  {:else}
    <nav>
      <ul>
        <li>
          <select
            class="new permit policies"
            on:change={({ currentTarget: target }) =>
              (policy = policies.find(
                (item) =>
                  item.subject === target.value ||
                  (!item.subject && item.title === target.value)
              ))}
          >
            <option value="">Add&#x2026</option>
            <!-- <optgroup label="Types"> -->
            {#each policies as item}
              <option value={item.subject ?? item.title}>{item.title}</option>
            {/each}
            <!-- </optgroup> -->
          </select>
        </li>
      </ul>
    </nav>
  {/if}
{:else}
  <nav><slot /></nav>
{/if}
