<script lang="ts">
  export let url = true;
  export let item;
</script>

{#if url}
  <a
    href={true === url
      ? `/properties/${item.scope?.id ?? item.scope}/${item.type}/${item.id}`
      : url}><slot /></a
  >
{:else}
  <slot />
{/if}
