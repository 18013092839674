<script>
  import PaymentMetricsDaysItem from "./PaymentMetricsDaysItem.svelte";
  import PaymentMetricsMonthItem from "./PaymentMetricsMonthItem.svelte";
  import PaymentMetricsMonthsChartItem from "./PaymentMetricsMonthsChartItem.svelte";

  export let item;
  //$: console.log("item=", item);
</script>

{#if item.datetimes == "P1M" && Object.values(item.values).length > 1}
  <PaymentMetricsMonthsChartItem {item} />
{:else if item.datetimes == "P1M" && Object.values(item.values).length == 1}
  <PaymentMetricsMonthItem {item} />
{/if}
{#if item.datetimes == "P1D"}
  <PaymentMetricsDaysItem {item} />
{/if}
