<script lang="ts">
  import { autocreate } from "$utils/vehicle";
  import Record from "$components/record/RecordItem.svelte";
  import { state as editing } from "$utils/item";
  import SelectPolicy from "./form/SelectPolicy.svelte";
  export let item: Typed;
  export let occupant: Typed | null = null;
  export let tenant: Tenant | null = null;
  export let policies: PermitIssuePolicy[];
  export let property: Property;
  const vehicle = autocreate(item.plate, property?.id ?? item.scope);
  console.log("vehicleitem=", item, vehicle, tenant, occupant, policies);

  //on:click={(e) => $editing.start("edit", item)}>Edit</button

  $: applypolicies = policies?.filter(
    (p) => p.amenity === "parking" && p.audience.admin && p.vehicle?.request
  );

  $: console.log("vehicle info policies = ", policies);

  function permit({ detail: { name, value } }) {
    //console.log("permit=", name, value);
    $editing.start(
      "permit",
      {
        type: "vehicleinfo",
        id: item.id,
      },
      {
        description: [item.color, item.make, item.model, item.year]
          .filter(Boolean)
          .join(" "),
        name: occupant?.name,
        email: Object.values(occupant?.email ?? []).join(", "),
        tel: Object.values(occupant?.mobile ?? []).join(", "),
      },
      {
        property,
        //policies,
        policy: value,
        vehicle,
        tenant,
      }
    );
  }
</script>

<article class="vehicle details">
  <!-- {#if vehicle}
    <header>
      <h1><Record item={vehicle} /></h1>
    </header>
  {/if} -->
  <dl>
    <!-- <dt>Resident</dt>
    <dd>
      <data class="resident" value={item.resident?.id || "false"}
        >{item.resident?.name || "--"}</data
      >
    </dd> -->
    {#if vehicle}
      <dt>Vehicle</dt>
      <dd><Record item={vehicle} /></dd>
      <dd>
        <figure class="add">
          <figcaption>Add parking…</figcaption>
          <SelectPolicy
            label=""
            blank="Add…"
            policies={applypolicies}
            on:change={permit}
          />
        </figure>
      </dd>
    {/if}
    {#if !vehicle}
      <dt>Plate</dt>
      <dd>{item.plate || "--"}</dd>
    {/if}

    <dt>Year</dt>
    <dd>{item.year || "--"}</dd>
    <dt>Color</dt>
    <dd>{item.color || "--"}</dd>
    <dt>Make</dt>
    <dd>{item.make || "--"}</dd>
    <dt>Model</dt>
    <dd>{item.model || "--"}</dd>
    <dt>State</dt>
    <dd>{item.state || "--"}</dd>
    <!-- <dt>Parking</dt> -->

    <!-- <dt>Source</dt>
    <dd>{item.service.name}</dd> -->
  </dl>
</article>
