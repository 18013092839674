<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import TextInputField from "./TextField.svelte";

  export let name = "name";
  export let label: string = null;
  export let placeholder: string = null;
  export let required = false;
  export let value: string = "";
  export let autocomplete: string = "off";
  const events = createEventDispatcher<{
    change: {
      name: string;
      value: string;
    };
  }>();
</script>

<TextInputField
  {name}
  {label}
  {placeholder}
  {required}
  {autocomplete}
  bind:value
  on:change={(e) => events("change", e.detail)}
/>
