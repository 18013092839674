<script lang="ts" context="module">
  const menu = param("menu");
  const lookup = param("lookup");
  const scan = param("scan");
</script>

<script lang="ts">
  import type { User } from "$utils/auth";

  import { smscreen } from "$utils/behaviorstores";
  import { minimal } from "$utils/uistores";
  import { param, params } from "$utils/params";
  import Search from "./Search.svelte";
  import UserPropertySelect from "$components/UserPropertySelect.svelte";
  import PropertyPositionStatus from "$components/property/PropertyPositionStatus.svelte";
  import { alertOffsite } from "$utils/propertystores";
  import AuthenticationStatus from "$components/authentication/AuthenticationStatus.svelte";
  import { event } from "$utils/track";

  export let user: User;
  export let property: Property;
  export let onsite: Boolean = null;
  export let position: GeolocationPositionStatus = null;

  $: active = !$smscreen ? undefined : !!$menu || !!$lookup;

  $: if ($lookup || $menu) offScan();

  function onClose(e) {
    // query({
    //    menu:false,
    //    lookup:false
    // });
    params.update((values) => {
      delete values["menu"];
      delete values["lookup"];
      //values["menu"] = values["lookup"] = false;
      return values;
    });
  }

  function onMenu(e) {
    // query({
    //    menu:true
    // });
    params.update((values) => {
      values["menu"] = true;
      return values;
    });
  }
  function onScan() {
    params.update((values) => {
      values["scan"] = true;
      return values;
    });
    event("scanner", "header", "open");
  }

  function offScan() {
    params.update((values) => {
      delete values["scan"];
      return values;
    });
  }
</script>

{#if property}
  <h1>
    {#if true === onsite}
      <data class="proximity" value="onsite">onsite</data>
    {:else if false === onsite}
      <data class="proximity" value="offsite">offsite</data>
    {:else if position?.status === "unpermitted"}
      <data class="proximity" value="unpermitted">blocked</data>
    {:else if position?.error}
      <data class="proximity" value="error">error</data>
    {:else if position}
      <data class="proximity" value="">finding</data>
    {/if}
    {property.name}
    <address>
      <span class="street">{property.address.street}</span>
      <span class="city">{property.address.city}</span>
      <span class="state">{property.address.state}</span>
      <span class="postal">{property.address.postal}</span>
    </address>
    <UserPropertySelect />
  </h1>
  <nav class="dynamic">
    <!-- <header /> -->
    <Search>
      {#if true === active || !!$lookup}
        <button type="button" class="menu deactivate" on:click={onClose}
          >Close</button
        >
      {:else if false === active && !$minimal}
        <button type="button" class="menu activate" on:click={onMenu}
          >Menu</button
        >
      {/if}
      {#if !!$scan}
        <button
          type="button"
          class="scan deactivate"
          class:active={!!$scan}
          on:click={offScan}>Scan</button
        >
      {:else}
        <button
          type="button"
          class="scan activate"
          class:active={!!$scan}
          on:click={onScan}>Scan</button
        >
      {/if}
    </Search>
  </nav>
  <PropertyPositionStatus
    {position}
    {property}
    onsite={onsite ? !!property : false}
    alert={$alertOffsite}
  />
  <AuthenticationStatus {user} />
  <slot />
{:else}
  <h1>Loading&hellip;</h1>
{/if}
