<script lang="ts">
  import type { FeatureCollection } from "geojson";
  import type { Readable } from "svelte/store";

  export let property: Property;
  export let features: FeatureCollection;
</script>

<section class="signage docs">
  <slot />
  <section>
    {#each features.features as feature}
      <article class="doc page landscape">
        <header>
          <h1>{feature.properties?.inscription || ""}</h1>
          {#if feature.properties?.image}
            <figure class="image">
              <img
                src={feature.properties?.image}
                alt={feature.properties?.inscription || ""}
              />
            </figure>
          {/if}
        </header>
        <figure>
          <img
            alt="Map preview"
            src={`https://static.carto.communityboss.app/cdn-cgi/image/w=900,h=900,format=auto/?property=${
              property.id
            }&level=${feature.properties?.level ?? ""}&size=900x900&zoom=20&center=${feature.geometry.coordinates.join(
              ","
            )}&feature=${feature.id}`}
          />
        </figure>
        <!-- <dl>
          {#each Object.entries(feature.properties ?? {}) as [key, value]}
            <dt>{key}</dt>
            <dd>{value}</dd>
          {/each}
        </dl> -->
      </article>
    {/each}
  </section>
</section>
