<script>
  import { record as isRecord } from "$utils/filter";
  import Record from "$components/record/RecordItem.svelte";
  import { toHours, toMinutes, toDays, toMilliseconds } from "duration-fns";
  import { scaleLinear } from "d3-scale";
  import { format, toZonedTime } from "date-fns-tz";
  import { stringToDateInterval } from "./item/ItemsList.svelte";
  import MeterDescription from "./MeterDescription.svelte";
  import { title as proper } from "$utils/text";
  import TimeIntervalItem from "./TimeIntervalItem.svelte";
  import upperFirst from "lodash-es/upperFirst";

  export let item;
  export let record = null;
  export let header = true;

  //$: console.log("usage item =", item);

  $: meter = item.meter;
  $: subject = item.subject;
  $: minimum = item.minimum;
  $: used = item.count || toMilliseconds(item.time);

  $: interval =
    item && item.interval && stringToDateInterval(item.interval, item.timezone);

  $: numeric = !!meter.count || !!meter.concurrent; // numeric vs time
  $: threshold = numeric
    ? meter.count ?? meter.concurrent
    : toMilliseconds(meter.time); //(item.meter.minimum);

  $: percentage = scaleLinear().domain([0, threshold]).range([0, 100]);

  $: scale = scaleLinear()
    .domain([
      0,
      Math.max(
        threshold * 1,
        meter.minimum && meter.time && meter.per
          ? toMilliseconds(meter.per)
          : 0,
        used
      ), // max number
      //: Math.max(threshold * 1.5, ...Object.values(item.items).map(i => toMilliseconds(i.time))) + 4 // max time
      //: toMilliseconds(item.meter.period) + 4 // max time
    ])
    .range([0, 100]);
</script>

<article
  class="{item.type} details"
  class:minimum={meter.minimum}
  class:maximum={meter.maximum}
>
  {#if header}
    <header>
      <h1>
        <dfn
          >{upperFirst(meter.title) ||
            `${proper(meter.group?.replace("tenant", "unit")) || ""} ${
              meter.minimum ? "Monitoring" : "Limit"
            }`}
        </dfn>
      </h1>
      {#if !meter.ratio}
        <TimeIntervalItem {item} />
      {:else}
        <time datetime={item.interval}>
          <time datetime=""><abbr>{format(interval.start, "MMM d")}</abbr></time
          >
          -
          {#if !meter.ratio}
            <time datetime=""><abbr>{format(interval.end, "MMM d")}</abbr></time
            >
          {:else}
            <time datetime=""
              ><abbr
                >{format(
                  toZonedTime(item.generated, item.timezone),
                  "MMM d"
                )}</abbr
              ></time
            >
          {/if}
        </time>
      {/if}
    </header>
  {/if}
  <dl>
    {#each [subject].filter(isRecord) as item}
      <dt>{proper(item.title || item.format || item.type)}</dt>
      <dd><Record {item} /></dd>
    {/each}
    {#if header}
      <dt>Policy</dt>
      <dd>
        {#each Object.values(item.meter.subjects) as item, i}
          {#if i > 0}&nbsp;+
          {/if}<data value={item.id}>{item.title}</data>
        {/each}
      </dd>
      <dt>{meter.minimum ? "Threshold" : "Limit"}</dt>
      <dd><MeterDescription item={item.meter} title={!item.meter.title} /></dd>
    {/if}
    <dt>Used</dt>
    <dd>
      <figure class={item.type}>
        {#if meter.maximum}
          <figcaption>
            <time class="used" datetime={item.time}
              ><abbr>{numeric ? used : percentage(used).toFixed(1) + "%"}</abbr
              ></time
            >
          </figcaption>
        {:else if meter.minimum}
          <figcaption>
            <time class="used" datetime={item.time}
              ><abbr
                >{numeric
                  ? used
                  : meter.minimum
                    ? toHours(used).toFixed(1).replace(".0", "") + " hours"
                    : percentage(used).toFixed(1) + "%"}</abbr
              ></time
            >
          </figcaption>
        {/if}
        <svg width="100%" height="32">
          <rect
            class:minimum={meter.minimum}
            class:maximum={minimum < 1.0}
            class:full={minimum < 1.0 && percentage(used) > 99.0}
            class="used"
            x="0%"
            y="20%"
            height="60%"
            rx="3"
            ry="3"
            width="{scale(used)}%"
          />
          <!-- <line class="used" x1="0" x2="{scale(used).toFixed(3)}%" y1="50%" y2="50%" stroke-width="10" stroke-linecap="round" /> -->
          {#if meter.minimum}
            <line
              class="minimum"
              x1="{scale(threshold)}%"
              x2="{scale(threshold)}%"
              y1="0"
              y2="100%"
              stroke-width="2"
            />
          {/if}
          <!-- <text x="0" y="100%" class="used">{Math.round(toHours(usedMs))}</text> -->
        </svg>
      </figure>
    </dd>
  </dl>

  <!-- <dl>
    <dt>
      {#each [subject].filter(isRecord) as item}
        <Record {item} />
      {/each}
    </dt>
    <dd />
    {#if meter.minimum}
      <dd>
        
      </dd>
    {/if}
  </dl> -->
</article>
