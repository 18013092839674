<script>
  import { createEventDispatcher } from "svelte";

  import { upload } from "$utils/upload";

  const dispatch = createEventDispatcher();

  export let item = null;
  export let accept =
    ".pdf,application/pdf,.png,image/png,.jpeg,.jpg,image/jpeg,.heic,image/heic,.heif,image/heif";

  async function add(file) {
    // kick off
    var url = URL.createObjectURL(file);
    const temp = {
      type: "file",
      local: true,
      id: url,
      url,
      created: new Date().toISOString(),
      format: file.type,
    };
    dispatch("creating", temp);

    const record = await upload(item.scope || item.location, file, {
      attach: item.id,
    });

    dispatch("created", temp);
    dispatch("created", record);

    //await tick();
    URL.revokeObjectURL(url);
  }

  function addFile(e) {
    if (!e || !e.target)
      if (!e.target.files || e.target.files.length <= 0) return;
    for (const file of e.target.files) {
      add(file);
    }
    e.target.value = "";
  }
</script>

{#if item}
  <form>
    <input type="file" multiple {accept} on:change={addFile} />
  </form>
{/if}
