<script lang="ts">
  export let value: string = "";
  export let copied: boolean = false;
  async function copy(e: Event) {
    const text = (e.currentTarget as HTMLButtonElement).value ?? value;
    await navigator.clipboard.writeText(text);

    copied = true;

    setTimeout((v: boolean) => (copied = v), 3000, false);
  }
</script>

<button
  type="button"
  class="copy"
  class:success={copied}
  class:copied
  {value}
  on:click={copy}>Copy</button
>
