<script>
  import { format, toZonedTime } from "date-fns-tz";
  import Record from "$components/record/RecordItem.svelte";
  import { thumbnail } from "$utils/filepreview";
  import { record } from "$utils/filter";
  export let item = null;
  //$: console.log("scan=", item);
</script>

{#if item}
  {#each item.files as file}
    <figure class="scan {item.type}" data-id={item.id}>
      <a href={file.url} target="_blank"
        ><img alt="scan" loading="lazy" src={thumbnail(file.url, 500)} /></a
      >
      <figcaption>
        <h1>
          <dfn>Scanned </dfn>
          <time datetime={item.created.utc}
            ><abbr
              >{format(
                toZonedTime(item.created.utc, item.timezone),
                "h:mm a MMM d yyyy zzz",
                { timeZone: item.timezone }
              )}</abbr
            ></time
          >
          {#each [item.created.by]
            .filter((item) => item && item.id)
            .slice(0, 1) as user}
            <a href="mailto:{user.email}"
              ><data class="user {user.type}" value={user.id}
                ><dfn>by</dfn> {user.name}</data
              ></a
            >
          {/each}
          <!-- <data
            class="position"
            value={(file.geometry?.coordinates || []).join(",")}
            >{file.geometry ? "Located" : "Unlocated"}</data
          > -->
          <!-- <a href={file.url} target="_blank"> - enlarge</a> -->
        </h1>
        <ul class="subjects">
          {#each item.subjects.filter(record) as record}
            <li>
              <Record item={record} />
              {#if item.direction}<data class="direction" value={item.direction}
                  >{item.direction}</data
                >{/if}
            </li>
          {/each}
        </ul>
      </figcaption>
    </figure>
  {/each}
{/if}
