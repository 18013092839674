<script lang="ts" context="module">
  import { fetchStatus } from "$components/lprd/api";
  import { derived, writable, type Readable } from "svelte/store";

  const vehicle = param("vehicle");

  function single(id: Readable<string>, data: Readable<any>): Readable<any> {
    return derived([id, data], ([$id, $data]) => {
      if (!$id || !$data) return null;
      return $data.for[$id];
    });
  }
</script>

<script lang="ts">
  import Loading from "$components/Loading.svelte";
  import ItemsList from "$components/item/ItemsList.svelte";
  import VehicleDetectionsStatusItem from "$components/lprd/VehicleDetectionsStatusSummaryItem.svelte";
  import { comparer } from "$utils/sort";
  import { param } from "$utils/params";
  import VehicleDetectionsCoverageMapItem from "$components/lprd/VehicleDetectionsCoverageMapItem.svelte";
  import { load } from "$components/lprd";

  export let property: Property;
  const sortby = comparer("latest", undefined, true);

  const scope = writable<string | null>(null);
  const data = load(scope);
  const selected = single(vehicle, data);
  $: scope.set(property?.id ?? null);
  function filterpermitted(item: any) {
    return !item.permit && Object.values(item.items).length > 1;
  }

  $: console.log("$data=", $data);
</script>

<section class="">
  <header>
    <h1>Overwatch</h1>
  </header>

  <section class="vehicles">
    {#if !$data}
      <Loading />
    {:else}
      <header>
        <h1>Hotlist</h1>
      </header>
      <ItemsList
        class="info"
        items={Object.values($data.for).filter(filterpermitted).sort(sortby)}
        types={{ detections: VehicleDetectionsStatusItem }}
      ></ItemsList>
    {/if}
  </section>
  {#if $data}
    <VehicleDetectionsCoverageMapItem item={$data} />
  {/if}
</section>
