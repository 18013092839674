<script context="module">
  import { time } from "$utils/timestores";
  import { parseISO } from "date-fns";
  import { format, toZonedTime } from "date-fns-tz";
  import identity from "lodash-es/identity";
  import Contact from "../Contact.svelte";
  import FeesItem from "../FeesItem.svelte";
  const now30s = time({ seconds: 30 });
</script>

<script>
  import ItemExpandContent from "$components/item/ItemExpandContent.svelte";
  import ItemImages from "$components/item/ItemFiles.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import EntryItem from "../EntryItem.svelte";
  import Time from "../Time.svelte";
  import PermitNavItem from "./PermitNavItem.svelte";
  import PermitStatus from "./PermitStatus.svelte";

  export let item;

  //$: console.log("permit=", item);
  //$: policy = item && item.policy;

  $: timezone = item && item.timezone;

  //$: fees = item && item.fees && item.fees.items && Object.values(item.fees.items);

  $: validations = Object.values(
    item?.validations?.items || item?.validations || {}
  );

  $: issuedutc = item && parseISO(item.issued.utc);

  $: [startstr, endstr] = item?.valid.interval.split("/") ?? ["", ""];
  $: startutc =
    startstr &&
    new Date(Math.max(parseISO(startstr), Math.min(issuedutc, Date.now())));

  $: startlocal = startutc && toZonedTime(startutc, timezone);
  $: endutc = endstr && parseISO(endstr);
  $: endlocal = endutc && toZonedTime(endutc, timezone);

  $: nowutc = item && new Date(Math.max(Date.now(), $now30s));

  $: cancelled = item && item.cancelled;
  $: pending = item && !cancelled && nowutc < startutc;
  $: expired = item && (item.expired || (endutc && nowutc > endutc));
  $: active = item && !expired && !pending && !cancelled;

  //$: console.log("now", nowutc);

  //$: pending = item && !cancelled && nowutc < startutc;
  //$: expired = item && (item.expired || (endutc && nowutc > endutc));
  //$: active = item && !expired && !pending && !cancelled;

  // $: repermit = item && !pending && policy && !item.assigned && (!item.media || !policy.media || !policy.media.request || (policy.media.request && policy.media.repermit && item.expired));

  // $: expirable = item
  //     && ((item.cancelable && pending) // may be cancelled pre-start and not cancelled
  //     || (item.reclaimable && active)); // may be ended early and still running

  //$: files = Object.values(item?.files || []);
  $: contacts = [item?.contact].filter(identity);

  $: subjects = [
    item?.media,
    item?.vehicle,
    ...Object.values(item?.spaces ?? []),
    item?.tenant,
  ].filter((item) => !!item);

  //$: if (item) console.log(item.id, "entry=", item.entry);

  // $: notify = "notify" === $editing.action && $editing.id === item.id;
  // $: revoke =
  //   !item.expired && "revoke" === $editing.action && $editing.id === item.id;
  // $: edit =
  //   item.continuous &&
  //   !item.expired &&
  //   "edit" === $editing.action &&
  //   $editing.id === item.id;
  // $: duplicate = "duplicate" === $editing.action && $editing.id === item.id;
  let permitDuration;
  if (item.valid.duration) {
    permitDuration = Temporal.Duration.from(item.valid.duration)
      .total({
        unit: "hours",
        relativeTo: item.valid.min.datetime,
      })
      .toLocaleString("en-US", { maximumFractionDigits: 2 });
  }
</script>

<article class="{item.type} details" data-record="permits/{item?.id}">
  {#if item}
    <header>
      <h1>{item.issued.policy?.title ?? item.title}</h1>
    </header>
    <PermitNavItem {item} />
    <!-- <PermitSummaryItem {item}>

    </PermitSummaryItem> -->
    <dl>
      {#if active}
        <!-- running -->
        <dt>Status</dt>
        <dd><PermitStatus {item} /></dd>
        {#if item.revoked}
          <!--running but revoked ?-->
          <dt>{item.revoked.title}</dt>
          <dd>
            <Time
              class="status revoked"
              datetime={item.revoked.datetime}
              timezone={item.timezone}
              format="h:mm a EEE MMM d yyyy zzz"
            />
          </dd>
          <dd>
            {#each [item.revoked.by, item.revoked.user]
              .filter((item) => item && item.id)
              .slice(0, 1) as user}
              <a href="mailto:{user.email}"
                ><data class="user {user.type}" value={user.id}
                  ><dfn>by</dfn> {user.name}</data
                ></a
              >
            {:else}
              <dfn>by</dfn> Self Service Registration
            {/each}
          </dd>
          {#if item.revoked.reason}
            <!-- <dt>Reason</dt> -->
            <dd>{item.revoked.reason}</dd>
          {/if}
        {:else}
          <dt>Ends</dt>
          <dd>
            <time datetime={endstr}
              ><abbr
                >{cancelled
                  ? "cancelled"
                  : endlocal
                    ? format(endlocal, "h:mm a EEE MMM d yyyy zzz", {
                        timeZone: item.timezone,
                      })
                    : "when revoked"}</abbr
              ></time
            >
          </dd>
        {/if}
      {:else if pending}
        <!-- upcoming -->
        <dt>Status</dt>
        <dd><PermitStatus {item} /></dd>
        <dt>Starts</dt>
        <dd>
          <Time
            datetime={startstr}
            timezone={item.timezone}
            format="h:mm a EEE MMM d yyyy zzz"
          />
        </dd>
      {:else if item.revoked}
        <dt>Status</dt>
        <dd><PermitStatus {item} /></dd>
        <dt>{item.revoked.title}</dt>
        <dd>
          <Time
            class="status revoked"
            datetime={item.revoked.datetime}
            timezone={item.timezone}
            format="h:mm a EEE MMM d yyyy zzz"
          />
        </dd>
        <dd>
          {#each [item.revoked.by, item.revoked.user]
            .filter((item) => item && item.id)
            .slice(0, 1) as user}
            <a href="mailto:{user.email}"
              ><data class="user {user.type}" value={user.id}
                ><dfn>by</dfn> {user.name}</data
              ></a
            >
          {:else}
            <dfn>by</dfn> Self Service Registration
          {/each}
        </dd>
        {#if item.revoked.reason && item.revoked.reason != item.revoked.title}
          <!-- <dt>Reason</dt> -->
          <dd>{item.revoked.reason}</dd>
        {/if}
      {:else if cancelled}
        <dt>Status</dt>
        <dd><PermitStatus {item} /></dd>
        <!-- cancelled before start -->
        <dt>Cancelled</dt>
        <dd>Before start</dd>
      {:else}
        <!-- naturally ended -->
        <dt>Status</dt>
        <dd><PermitStatus {item} /></dd>
        <dt>Expired</dt>
        <dd><Time datetime={endstr} {timezone} /></dd>
      {/if}
      <dt>Type</dt>
      <dd>
        {item.pass
          ? "printed pass"
          : item.media
            ? "physical permit"
            : "digital permit"}
      </dd>
      {#if item.source}
        <dt>Source</dt>
        <dd>{item.source.name}</dd>
      {/if}
      <dt>For</dt>
      <dd>
        <ul class="subjects">
          {#each subjects as item}<li>
              <RecordItem {item} />
            </li>{/each}
        </ul>
      </dd>

      <!-- {#each [item.media, item.vehicle, item.space, item.tenant].filter((item) => !!item) as item}
        <dd><RecordItem {item} /></dd>
      {/each} -->
      {#if item.visiting}
        <dt>Reason</dt>
        <dd>{item.visiting}</dd>
      {/if}
      <!-- <ul class="subjects">
      {#each [item.media, item.vehicle, item.space, item.tenant].filter((item) => !!item) as record}<li
        >
          <Record item={record} />
        </li>{/each}
    </ul> -->
      {#if item.description}
        <dt>Info</dt>
        <dd>{item.description}</dd>
      {/if}
    </dl>
    <ul class="contacts">
      {#each contacts as item}
        <li class="contact"><Contact contact={item} /></li>
      {/each}
    </ul>
    <ItemExpandContent {item}>
      <dl>
        <dt>Recorded</dt>
        <dd>
          <Time
            datetime={item.issued.utc}
            timezone={item.timezone}
            format="h:mm a EEE MMM d yyyy zzz"
          />
          <!-- <time datetime={item.issued.utc}
            ><abbr
              >{format(
                toZonedTime(item.issued.datetime, item.timezone),
                "h:mm a EEE MMM d yyyy zzz",
                {
                  timeZone: item.timezone,
                }
              )}</abbr
            ></time
          > -->
        </dd>
        <dd>
          {#each [item.issued.by, item.issued.user]
            .filter((item) => item && item.id)
            .slice(0, 1) as user}
            <a href="mailto:{user.email}"
              ><data class="user {user.type}" value={user.id}
                ><dfn>by</dfn> {user.name}</data
              ></a
            >
          {:else}
            <dfn>by</dfn> Self Service Registration
          {/each}
        </dd>
        {#if validations.length}
          <dd>Validated by Smart Guard</dd>
        {/if}
        <dt>Start</dt>
        <dd>
          <Time
            datetime={startstr}
            timezone={item.timezone}
            format="h:mm a EEE MMM d yyyy zzz"
          />
        </dd>
        {#if !cancelled && endutc}
          <dt>Duration</dt>
          <dd>
            {permitDuration} hours
          </dd>
        {/if}
        {#if item.revoked}
          <dt>{item.revoked.title}</dt>
          <dd>
            <Time
              class="status revoked"
              datetime={item.revoked.datetime}
              timezone={item.timezone}
              format="h:mm a EEE MMM d yyyy zzz"
            />
          </dd>
          <dd>
            {#each [item.revoked.by, item.revoked.user]
              .filter((item) => item && item.id)
              .slice(0, 1) as user}
              <a href="mailto:{user.email}"
                ><data class="user {user.type}" value={user.id}
                  ><dfn>by</dfn> {user.name}</data
                ></a
              >
            {:else}
              <dfn>by</dfn> Self Service Registration
            {/each}
          </dd>
          {#if item.revoked.reason}
            <!-- <dt>Reason</dt> -->
            <dd>{item.revoked.reason}</dd>
          {/if}
        {:else}
          <dt>End</dt>
          <dd>
            <time datetime={endstr}
              ><abbr
                >{cancelled
                  ? "cancelled"
                  : endlocal
                    ? format(endlocal, "h:mm a EEE MMM d yyyy zzz", {
                        timeZone: item.timezone,
                      })
                    : "when revoked"}</abbr
              ></time
            >
          </dd>
        {/if}

        {#if !cancelled}
          <dt>Usage</dt>
          <dd>
            {item.exempt
              ? "Not counted against limits"
              : "Counted against limits"}
          </dd>
        {/if}

        <dt>Access</dt>
        {#each Object.values(item.entry?.items || {}) as item}
          <dd>
            <EntryItem {item} />
          </dd>
        {:else}
          <dd>No linked entry access</dd>
        {/each}
        <dt>Record #</dt>
        <dd><data value={item.number || ""}>{item.number || "--"}</data></dd>
      </dl>
      <FeesItem {item} />

      <ItemImages {item} create={true} />
    </ItemExpandContent>
  {/if}
</article>
