<script lang="ts">
  import { createEventDispatcher, onDestroy } from "svelte";
  import { type VectorGLMap, type Marker, type LngLatLike } from ".";
  import { getSvelteContext, engine } from ".";
  import { LngLat } from "maplibre-gl";

  export let position: LngLatLike;
  export let accuracy: number;
  export let grade: string = "";

  let classname: string = "";
  export { classname as class };

  const { map, pixelsPerMeter } = getSvelteContext();

  // const events = createEventDispatcher<{
  //   click: typeof feature;
  // }>();

  $: point = position; // ?? position;

  let element: HTMLElement;
  let marker: Marker;

  $: marker =
    element &&
    new engine.Marker({
      element: element,
      pitchAlignment: "map",
      className: ["map-user-location-accuracy-radius", classname]
        .filter(Boolean)
        .join(" "),
    });
  //$: console.log("$marker=", marker, element);

  function init(
    $map: VectorGLMap,
    $marker: Marker,
    $position: LngLatLike,
    $pixelsPerMeter: number | null,
    $center: Boolean = false
  ) {
    //console.log("init popup", $position, $content);
    if (!$map || !$position) {
      $marker?.remove();
      return;
    }
    if (!$position || !$marker) return;

    const diameter = Math.ceil(2 * (accuracy ?? 50) * ($pixelsPerMeter ?? 0));
    element.style.width = `${diameter}px`;
    element.style.height = `${diameter}px`;

    $marker.setLngLat($position);

    $marker.addTo($map);

    if ($center)
      $map.flyTo({
        center: $position,
      });
  }

  $: init($map, marker, point, $pixelsPerMeter);

  onDestroy(function () {
    marker?.remove();
  });
</script>

<data bind:this={element} value={grade || accuracy}></data>
<!-- <aside class="{classname} map-marker" class:selected bind:this={element}>
  <button on:click={(e) => events("click", feature)}>
    <slot />
  </button>
</aside> -->
