<script lang="ts" context="module">
  const lastTwoWeeksIntervalZoned = derived(
    [time("PT1H") as Readable<Date>, timezone],
    ([$now, $timezone]) => {
      const $nowlocal = Temporal.Instant.from(
        $now.toISOString()
      ).toZonedDateTimeISO($timezone);
      return `${$nowlocal
        .subtract({ days: 14 + ($nowlocal.dayOfWeek % 7) })
        .startOfDay()
        .toString({
          timeZoneName: "never",
        })}/${$nowlocal
        .subtract({ days: 0 + ($nowlocal.dayOfWeek % 7) })
        .startOfDay()
        .toString({
          timeZoneName: "never",
        })}`;
    }
  );

  export const propertyLastWeekPaymentMetrics: Readable<any> = derived(
    [propertyId, lastTwoWeeksIntervalZoned],
    function updater([$propertyId, $valid], set) {
      if (!$propertyId) return set(null);

      const startToday = Temporal.Now.plainDateTimeISO().round({
        smallestUnit: "day",
        roundingMode: "floor",
      });

      // var byInterval = range(-1, 0, 1)
      //   .map((i) => [
      //     startToday.add({ days: i * 7 - (startToday.dayOfWeek % 7) }),
      //     startToday
      //       .add({ days: (i + 1) * 7 - (startToday.dayOfWeek % 7) })
      //       .round({ smallestUnit: "day", roundingMode: "floor" }),
      //   ])
      //   .map(([min, max]) => `${min.toString()}/${max.toString()}`)
      const byInterval = [$valid].map((valid) =>
        fetchPaymentMetrics(valid, {
          property: $propertyId,
          datetimes: ["P1D", "P1W"],
          metrics: ["total", "policy", "property"],
          payments: ["total", "net"],
        }).then(function (json) {
          //json.metrics.items.forEach((item) => (item.datetimes = "P1W"));
          return json;
        })
      );

      Promise.all(byInterval)
        .then((jsons) =>
          jsons.reduce((json, json1) => {
            return Object.assign(json, json1, {
              metrics: {
                //interval,
                items: (json.metrics?.items || []).concat(
                  json1.metrics.items || []
                ),
              },
            });
          }, {})
        )
        .then((json) => {
          console.log(json);
          return json;
        })
        .then(set);

      // var json = await Promise.all([
      //   fetchPaymentMetrics(
      //   `${format(addMonths(new Date(), -2), "yyyy-MM-01'T'00:00:00")}/${format(
      //     addDays(new Date(), 1),
      //     "yyyy-MM-dd'T'00:00:00"
      //   )}`,
      //   {
      //     property: $propertyId,
      //     datetimes: ["P1M"],
      //     metrics: ["total", "policy", "property"],
      //     payments: ["total", "net"],
      //   }
      // ),
      // fetchPaymentMetrics(
      //   `${format(addMonths(new Date(), -1), "yyyy-MM-01'T'00:00:00")}/${format(
      //     addDays(new Date(), 1),
      //     "yyyy-MM-dd'T'00:00:00"
      //   )}`,
      //   {
      //     property: $propertyId,
      //     datetimes: ["P1M"],
      //     metrics: ["total", "policy", "property"],
      //     payments: ["total", "net"],
      //   }
      // ),
      // ;

      //console.log("paymentmetrics=", json);

      //set(json);
    }
  );
  const propertyLastWeekEnforcement: Readable<any> = derived(
    [propertyId as Readable<string | null>, lastTwoWeeksIntervalZoned],
    function ([$propertyId, $valid], set) {
      if (!$propertyId) {
        // violations.set(null);
        // accessed.set(null);
        return set(null);
      }

      fetchEnforcement($propertyId, $valid, "principal", "P1D", "P1W").then(
        (data) => {
          set(data.enforcement?.["for"]?.[$propertyId]);
        }
      );

      // violations.set(data.violations?.["for"]?.[$propertyId]);
      // accessed.set(data.accessed?.["for"]?.[$propertyId]);
    }
  );
</script>

<script lang="ts">
  import { time } from "$utils/timestores";

  import DaysChart from "./svg/DaysChart.svelte";
  import { derived, type Readable } from "svelte/store";
  import { propertyId, timezone } from "$utils/propertystores";
  import { fetchEnforcement, fetchPaymentMetrics } from "$utils/api";
  import { currency } from "$utils/format";
  import PropertyMap from "./PropertyMap.svelte";
  import MetricsSummary from "./metrics/MetricsSummary.svelte";
  export let property: { id: string; timezone: string } = null;

  $: now = time("PT1H");
  $: nowlocal =
    property &&
    Temporal.Instant.from($now.toISOString()).toZonedDateTimeISO(
      property.timezone
    );
  $: interval = nowlocal && {
    start: nowlocal
      .subtract({ days: 7 + (nowlocal.dayOfWeek % 7) })
      .startOfDay(),
    end: nowlocal.subtract({ days: 0 + (nowlocal.dayOfWeek % 7) }).startOfDay(),
  };
  $: previnterval = nowlocal && {
    start: nowlocal
      .subtract({ days: 14 + (nowlocal.dayOfWeek % 7) })
      .startOfDay(),
    end: nowlocal.subtract({ days: 7 + (nowlocal.dayOfWeek % 7) }).startOfDay(),
  };
  // $: console.log(
  //   "interval=",
  //   `${interval.start.toString({
  //     timeZoneName: "never",
  //   })}/${interval.end.toString({
  //     timeZoneName: "never",
  //   })}`
  // );
  // $: console.log(
  //   "propertyLastWeekPaymentMetrics=",
  //   $propertyLastWeekPaymentMetrics
  // );
  $: paymentsByDay =
    $propertyLastWeekPaymentMetrics &&
    Object.values($propertyLastWeekPaymentMetrics?.metrics.items || {}).filter(
      (i: any) =>
        i.datetimes == "P1D" && i.payments == "net" && i.group == "property"
    );
  $: paymentsByWeek =
    $propertyLastWeekPaymentMetrics &&
    Object.values($propertyLastWeekPaymentMetrics?.metrics.items || {}).filter(
      (i: any) =>
        i.datetimes == "P1W" && i.payments == "net" && i.group == "property"
    );
  // $: console.log(
  //   "paymentsbyday=",
  //   paymentsByDay,
  //   "paymentsbyweek=",
  //   paymentsByWeek
  // );
</script>

<article class="map">
  <PropertyMap
    theme="cool"
    property={property.id}
    enforcement={`${interval.start.toString({
      timeZoneName: "never",
    })}/${interval.end.toString({
      timeZoneName: "never",
    })}`}
  />
</article>
<MetricsSummary
  interval={`${interval.start.toString({
    timeZoneName: "never",
  })}/${interval.end.toString({
    timeZoneName: "never",
  })}`}
  timezone={property.timezone}
  values={[
    {
      type: "checks",
      title: "Checks",
      value:
        $propertyLastWeekEnforcement?.checks[
          `${interval.start.toString({
            //offset: "never",
            fractionalSecondDigits: 3,
            timeZoneName: "never",
          })}/${interval.end.toString({
            //offset: "never",
            fractionalSecondDigits: 3,
            timeZoneName: "never",
          })}`
        ],
    },
    {
      type: "permitted",
      title: "Permitted",
      value: `${(
        ($propertyLastWeekEnforcement?.permitted[
          `${interval.start.toString({
            //offset: "never",
            fractionalSecondDigits: 3,
            timeZoneName: "never",
          })}/${interval.end.toString({
            //offset: "never",
            fractionalSecondDigits: 3,
            timeZoneName: "never",
          })}`
        ] /
          $propertyLastWeekEnforcement?.checks[
            `${interval.start.toString({
              //offset: "never",
              fractionalSecondDigits: 3,
              timeZoneName: "never",
            })}/${interval.end.toString({
              //offset: "never",
              fractionalSecondDigits: 3,
              timeZoneName: "never",
            })}`
          ]) *
        100
      ).toFixed(1)}%`,
    },
  ]}
/>
<figure class="chart">
  <DaysChart
    class="checks"
    intervals={$propertyLastWeekEnforcement?.checks}
    interval={`${interval.start.toString({
      timeZoneName: "never",
    })}/${interval.end.toString({
      timeZoneName: "never",
    })}`}
    timezone={property.timezone}
  />
</figure>
<MetricsSummary
  interval={`${interval.start.toString({
    timeZoneName: "never",
  })}/${interval.end.toString({
    timeZoneName: "never",
  })}`}
  timezone={property.timezone}
  values={[
    {
      type: "violations",
      title: "Violations",
      value:
        $propertyLastWeekEnforcement?.violations[
          `${interval.start.toString({
            //offset: "never",
            fractionalSecondDigits: 3,
            timeZoneName: "never",
          })}/${interval.end.toString({
            //offset: "never",
            fractionalSecondDigits: 3,
            timeZoneName: "never",
          })}`
        ],
    },
  ]}
/>
<figure class="chart">
  <DaysChart
    class="violations"
    intervals={$propertyLastWeekEnforcement?.violations}
    interval={`${interval.start.toString({
      timeZoneName: "never",
    })}/${interval.end.toString({
      timeZoneName: "never",
    })}`}
    timezone={property.timezone}
  />
</figure>
<MetricsSummary
  interval={`${interval.start.toString({
    timeZoneName: "never",
  })}/${interval.end.toString({
    timeZoneName: "never",
  })}`}
  timezone={property.timezone}
  values={[
    {
      type: "payments",
      title: "Revenue",
      value: currency(
        paymentsByWeek?.[0]?.values[
          `${interval.start.toString({
            //offset: "never",
            fractionalSecondDigits: 3,
            timeZoneName: "never",
          })}/${interval.end.toString({
            //offset: "never",
            fractionalSecondDigits: 3,
            timeZoneName: "never",
          })}`
        ] / 100
      ),
    },
  ]}
/>
<figure class="chart">
  <DaysChart
    class="cents"
    intervals={paymentsByDay?.[0]?.values}
    interval={`${interval.start.toString({
      timeZoneName: "never",
    })}/${interval.end.toString({
      timeZoneName: "never",
    })}`}
    timezone={property.timezone}
    format={(val) => currency(val / 100)}
  />
</figure>
