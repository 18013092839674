<script>
  import { format, toZonedTime } from "date-fns-tz";

  import { route } from "$utils/router";

  import { comparer, textAsc } from "$utils/sort";

  export let item = null;

  $: versions =
    item &&
    item.versions &&
    Object.values(item.versions).sort(
      comparer("valid.interval", textAsc, true)
    );
</script>

{#if versions && versions.length > 0}
  <dt>History</dt>
  <dd>
    <time datetime={item.valid.interval}
      >{item.valid.interval
        .split("/")
        .map((d) => d && toZonedTime(d, item.timezone))
        .map((d) => (d && format(d, "MMM d yyyy")) || "current")
        .join(" - ")}</time
    >
    {#if versions.length > 1}
      <form>
        <label for="record-versions">Switch</label>
        <select
          id="record-versions"
          disabled={versions.length < 2}
          value={item && item.id}
          on:change={(e) =>
            route(`/properties/${item.scope}/${item.type}/${e.target.value}`)}
        >
          {#each versions as item, i}
            <option value={item.id}
              >{item.valid.interval
                .split("/")
                .map((d) => d && toZonedTime(d, item.timezone))
                .map((d) => (d && format(d, "MMM d yyyy")) || "current")
                .join(" - ")}</option
            >
          {/each}
        </select>
      </form>
    {/if}
  </dd>
{/if}
