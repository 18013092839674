<header>
  <h1>System Checks</h1>
</header>
<main class="debug">
  <section>
    <!-- <header /> -->
    <section>
      <header>
        <h1>Checking Data Access</h1>
      </header>
      <iframe
        src="https://api.propertyboss.io/v2/hello"
        width="100%"
        height="200"
        frameborder="0"
      />
      <p>
        You should see "Hello from Community Boss!" above, if not, please
        contact us with the error so we can help sort out the issue!
      </p>
    </section>
  </section>
</main>
