<script lang="ts">
  import isString from "lodash-es/isString";
  import debounce from "lodash-es/debounce";
  import { propertyId } from "$utils/propertystores";
  import { touch } from "$utils/behaviorstores";
  import { autocreate } from "$utils/vehicle";

  import { route } from "$utils/router";
  import { param, params } from "$utils/params";
  import SearchForm from "$components/form/SearchForm.svelte";
  import { createEventDispatcher } from "svelte";

  const lookup = param("lookup");

  //let scan = false;
  var query: string | null | undefined = "";

  function change(e: CustomEvent) {
    query = e?.detail?.value;
    //scan = false;
    search(query);
  }

  // let input = null;
  // let form = null;

  // don't let this change too fast
  const search = debounce(
    function (query: string) {
      //const value = e?.detail?.value;
      params.update((values) => {
        values["lookup"] = query;
        return values;
      });

      // if(e && e.target) //query("lookup", e.target.value);
      // else params
      //scan = false;
      // showResults = !!query;
      // if(showResults) scan = false;
    },
    100,
    {
      leading: true,
      trailing: true,
    }
  );

  function blur() {
    //if (input) input.blur();
    window.blur();
  }

  async function clear() {
    //scan = false;
    //form.reset();
    search();
    //blur();
  }

  function reset() {
    clear();
    events("reset");
  }

  const events = createEventDispatcher<{
    reset: undefined;
  }>();

  // function onInputBlur(e) {
  //   console.log("oninputblur=", e, e.target.value, "touch=", $touch);
  //   if (!e.target.value) clear();
  // }

  function submit(e) {
    if (!e?.detail?.value) return;
    var vehicle = autocreate(e.detail.value, $propertyId);
    if (!vehicle) return;
    route(`/properties/${$propertyId}/${vehicle.type}/${vehicle.key}`);
    blur();
    clear();
  }

  $: if (!$lookup) query = "";
</script>

<SearchForm
  class="lookup"
  placeholder="Lookup"
  value={query || ""}
  on:change={change}
  on:submit={submit}
  on:reset={reset}
  resettable={false}
  autocomplete="off"
  autocapitalize="off"
  autocorrect="off"
  spellcheck="false"><slot /></SearchForm
>
<!-- <form class="lookup" bind:this={form} on:submit={submit}>
  <input
    bind:this={input}
    value={$lookup || ""}
    type="search"
    placeholder="Lookup"
    required
    on:input={search}
    on:focus={search}
    on:blur={onInputBlur}
    autocomplete="off"
    autocapitalize="off"
    autocorrect="off"
    spellcheck="false"
  />
  <slot />
</form> -->
