<script lang="ts">
  import ImageUrlField from "$components/form/ImageUrlField.svelte";
  import Option from "$components/form/Option.svelte";
  import SelectField from "$components/form/SelectField.svelte";
  import TextareaField from "$components/form/TextareaField.svelte";
  import type { Feature } from "geojson";
  import { createEventDispatcher } from "svelte";

  export let feature: Feature | null | undefined;

  const events = createEventDispatcher<{
    change: Feature;
  }>();

  function propertychange(name: string, value: string) {
    if (feature && name) {
      feature.properties = {
        ...feature.properties,
        [name]: value,
      };
      events("change", feature);
    }
  }

  //   function onpropertieschange(e: Event) {
  //     const target = e.target as HTMLInputElement;
  //     propertychange(target.name, target.value);
  //   }
</script>

{#if feature}
  <form class="editing">
    <ul>
      <li>
        <TextareaField
          placeholder="Text"
          required
          name="inscription"
          value={feature.properties?.inscription || ""}
          on:change={(e) => propertychange(e.detail.name, e.detail.value)}
        />
      </li>
      <li>
        <ImageUrlField
          name="image"
          value={feature.properties?.image || ""}
          on:change={(e) => propertychange(e.detail.name, e.detail.value)}
        />
      </li>
      <li>
        <SelectField
          label="Parking"
          name="amenity"
          value={feature.properties?.amenity || ""}
          on:change={(e) => propertychange(e.detail.name, e.detail.value)}
        >
          <Option value="">n/a</Option>
          <Option value="parking">yes</Option>
        </SelectField>
      </li>
      <li>
        <SelectField
          label="Installed"
          name="support"
          value={feature.properties?.support || "post"}
          on:change={(e) => propertychange(e.detail.name, e.detail.value)}
        >
          <Option value="no">no</Option>
          <Option value="wall">wall</Option>
          <Option value="post">post</Option>
          <Option value="pole">pole</Option>
          <Option value="fence">fence</Option>
        </SelectField>
      </li>
    </ul>
    <slot />
  </form>
{/if}
