<script>
  import { toMilliseconds, parse, toHours, toDays } from "duration-fns";

  export let item;
  export let title = true;

  $: numeric = !!item.count || !!item.concurrent;
  $: time = !!item.time;
  $: value = numeric
    ? item.count ?? item.concurrent
    : toMilliseconds(item.time);
  $: days =
    parse(item.time).days > 0 &&
    !(value % (24 * 60 * 60 * 1000)) &&
    value / (24 * 60 * 60 * 1000);

  $: units = item.count ? "times" : days ? "days" : "hours";
</script>

<!-- <p>
  {#each Object.values(item.subjects) as item, i}
    {#if i > 0}&nbsp;+ {/if}<data value={item.id}>{item.title}</data>
  {/each}
  • -->
<!-- {#if item.maximum}
    up to -->
<!-- {:else}
    for -->
<!-- {/if} -->
{#if item.minimum}
  At least {!title ? item.description : item.display}
  <!-- {numeric ? value : days || toHours(value)} {units} {#if item.minimum}or more{/if} in {toDays(item.per || item.period)} days -->
{:else}
  {!title ? item.description : item.display}
{/if}
<!-- </p> -->
