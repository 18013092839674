<script>
  export let item = null;
</script>

{#if item}
  <aside class="{item.type} summary">
    <h1>{item.title}</h1>
    <h2>{item.parking ? "Parking" : "Amenity"}</h2>
  </aside>
{/if}
