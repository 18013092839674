<script lang="ts">
  import { property } from "$utils/propertystores";
  import EnforcementSummaryPropertyMapItem from "./EnforcementSummaryPropertyMapItem.svelte";
  import EnforcementSummaryWorldMapItem from "./EnforcementSummaryWorldMapItem.svelte";
  export let item;
  export let record = null; // let's use a different style if a single record
  //   $: if (item) item.subject = record || item.subject;
  //   $: console.log("item=", item);
</script>

{#if $property}
  <EnforcementSummaryPropertyMapItem
    theme={$property.map ? undefined : "streets"}
    {item}
    {record}
  />
{/if}
<!-- 
{#if $property?.map}
  <EnforcementSummaryPropertyMapItem style={$property} {item} {record} />
{:else if $property && !$property?.map}
  <EnforcementSummaryWorldMapItem {item} {record} />
{/if} -->
