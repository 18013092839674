<script lang="ts">
  import PropertyPhoto from "./PropertyPhoto.svelte";
  import PropertyDataItem from "./PropertyDataItem.svelte";

  export let item: Property;
</script>

<PropertyPhoto property={item}>
  <h1><PropertyDataItem property={item} /></h1>
</PropertyPhoto>
