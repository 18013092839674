<script lang="ts">
  import { route } from "$utils/router";
  import Reset from "../tenant/TenantReset.svelte";
  import UnitStatusTimelineItem from "./UnitStatusTimelineItem.svelte";

  export let item: any = null;
</script>

<article class={item.type}>
  <UnitStatusTimelineItem {item} />
  {#if item.check?.vacant && item.vacated && item.subject}
    <Reset
      label="Complete Move Out"
      {item}
      on:change={(e) => {
        const id = e.detail;
        setTimeout(function () {
          route(`/properties/${item.scope}/tenant/${id}`);
        }, 1000);
      }}
    />
  {:else if item.check.reoccupied && item.occupied && item.subject}
    <Reset
      label="Complete Move In"
      {item}
      on:change={(e) => {
        const id = e.detail;
        setTimeout(function () {
          route(`/properties/${item.scope}/tenant/${id}`);
        }, 1000);
      }}
    />
  {/if}
</article>
