<script lang="ts">
  import { policyCanPermitSpace } from "$utils/policy";
  import { spaces } from "$utils/propertystores";
  import RecordInUseAlert from "$components/record/RecordInUseAlert.svelte";
  import SpaceValue from "./SpaceValue.svelte";
  import SelectSpace from "$components/record/SelectSpace.svelte";
  import { createEventDispatcher, tick } from "svelte";

  const eventing = createEventDispatcher<{
    change: {
      spaces: Record<string, Space>;
    };
  }>();

  export let label: string | null | undefined = "Space";
  export let values: Record<string, any> = {};
  export let policy: PermitIssuePolicy;
  export let editing: string | Permit | null | undefined;

  let request = !!policy?.space?.request;
  let required = !!policy?.space?.required;
  let valued: Record<string, Space> = valuemap(values.spaces);

  let added: string | null | undefined;

  $: request = !!policy?.space?.request;

  $: valued = valuemap(values.spaces);

  $: console.log("valued=", valued);

  $: required = !!policy?.space?.required && !Object.values(valued).length;

  $: items = (
    (values.policy.spaces?.items &&
      Object.values(values.policy.spaces.items)) ??
    Object.values($spaces?.items ?? {})
  ).filter((item: Space) => policyCanPermitSpace(values.policy, item));

  async function change(init: Space | null, updated?: Space | null) {
    if (init && !updated) {
      delete valued[init.id];
    }

    if (!init && updated) {
      valued[updated.id] = updated;
      added = updated.id;
    }

    // wait for state to update before passing up change
    await tick();

    eventing("change", { spaces: valued });
  }

  function valuemap(values: Record<string, Space> | Space[]) {
    if (!values) return {};
    return Object.values(values).reduce(
      (map, value) => {
        return (map[value.id] = value) && map;
      },
      {} as Record<string, Space>
    );
  }

  // iterate over the spaces
</script>

{#each Object.values(valued) as value}
  <li>
    <SpaceValue
      {value}
      {label}
      name="space"
      readonly={false}
      scrollIntoView={value.id === added}
      on:change={(e) => change(value)}
    >
      {#if value.capacity == 1}<RecordInUseAlert
          subject={value}
          also={values.tenant}
          {editing}
          label="Limited capacity & already in use"
        />{/if}
    </SpaceValue>
  </li>
{/each}
<li>
  <SelectSpace
    placeholder={required ? "select…" : "select…"}
    {label}
    {required}
    {items}
    on:space={(e) => change(null, e.detail)}
  />
  <slot />
</li>
