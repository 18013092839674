<script>
  import { isWithinInterval, parseISO } from "date-fns";
  import { stringToDateInterval } from "../item/ItemsList.svelte";
  import EnforcementChartItem from "./EnforcementChartItem.svelte";

  export let item;
  //$: console.log("item=", item);

  $: interval = stringToDateInterval(item.interval);

  $: checks =
    item.checks[item.interval] ||
    Object.entries(item.checks).reduce((total, [partial, count]) => {
      if (
        partial
          .split("/")
          .map(parseISO)
          .every((date) => isWithinInterval(date, interval))
      )
        return total + count;
      return total;
    }, 0);
  $: violations =
    item.violations[item.interval] ??
    Object.entries(item.violations).reduce((total, [partial, count]) => {
      if (
        partial
          .split("/")
          .map(parseISO)
          .every((date) => isWithinInterval(date, interval))
      )
        return total + count;
      return total;
    }, 0);
</script>

<aside class="{item.type} summary">
  <dl class="data">
    <dt class="checks">Checks</dt>
    <dd>
      <data class="count checks" value={checks}
        >{checks === 0 ? "0" : checks}</data
      >
    </dd>
    <dt class="violations">Violations</dt>
    <dd class="violations">
      <data class="count violations" value={violations}
        >{violations === 0 ? "0" : violations}</data
      >
    </dd>
  </dl>
  <EnforcementChartItem {item} />
</aside>
