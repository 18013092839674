<script context="module">

</script>
<script>
import ItemsChartItem from "./ItemsChartItem.svelte";

import SuspectedPermitsSummaryItem from "./SuspectedPermitsSummaryItem.svelte";
export let item;

</script>
<article class="{item.type} details">
    <SuspectedPermitsSummaryItem {item} interval={false} />
    <ItemsChartItem {item} />
</article>