<script lang="ts" context="module">
  function duration(value: Temporal.Duration | Temporal.DurationLike | string) {
    //if(typeof value === "string") return duration(Temporal.Duration.from(value));
    if (!(value instanceof Temporal.Duration))
      return duration(Temporal.Duration.from(value));
    //const duration = Temporal.Instant.from(timestamp).until(now.toInstant());
    if (value.total("seconds") < 3) return "live";
    if (value.total("days") > 1) return value.total("days").toFixed(0) + "d";
    if (value.total("hours") > 1) return value.total("hours").toFixed(0) + "h";
    if (value.total("minutes") > 1)
      return value.total("minutes").toFixed(0) + "m";
    return value.total("seconds").toFixed(0) + "s";
  }
  function intervalstr(value: string, timezone: Temporal.TimeZoneLike) {
    const [min, max] = value
      .split("/")
      .map((str) => (str && Temporal.Instant.from(str)) || maxInstant)
      .map((instant) => instant.toZonedDateTimeISO(timezone));
    if (max.since(min).total("hours") < 24)
      return `${datetime(
        min,
        undefined,
        ({ day, weekday, month, hour, minute, dayPeriod }) =>
          `${weekday} ${month} ${day} ${hour}:${minute} ${dayPeriod}`
      )}–${datetime(
        max,
        undefined,
        ({ day, weekday, month, hour, minute, dayPeriod }) =>
          `${hour}:${minute} ${dayPeriod}`
      )}`;
    return `${datetime(min)}–${datetime(max)}`;
    //   datetime(
    //   value,
    //   timezone,
    //   ({ day, weekday, month, hour, minute, dayPeriod }) =>
    //     `${weekday} ${month} ${day} ${hour}:${minute} ${dayPeriod}`
    // );
  }
</script>

<script lang="ts">
  import Loading from "$components/Loading.svelte";
  import { comparer } from "$utils/sort";
  import { presence, enforce, interval } from "$components/lprd";
  import { query } from "$utils/router";
  import { datetime, indefinite, maxInstant } from "$utils/temporal";
  import VehiclesPresentSession from "./VehiclesPresentSession.svelte";
  import VehiclesPresentSummaryMap from "./VehiclesPresentSummaryMap.svelte";

  export let title: string | null | undefined = "Auto Intelligence";
  export let property: Property;
  export let details: string | null | undefined = null;
  export let coverage: boolean = false;
  let partitioned: [any[], any[], any[], any[]] | null = null;
  $: sessions = Object.values($presence?.intervals?.items ?? {})
    .filter((session) => {
      return session.count > 10;
    })
    .sort(comparer("interval", undefined, true));

  $: if (!$interval && sessions.length) {
    query({ present: sessions[0].interval });
  }
  $: if (
    $interval &&
    sessions.length &&
    !$presence.intervals.items[$interval]
  ) {
    query({ present: sessions[0].interval });
  }

  $: session = $presence?.intervals?.items?.[$interval];

  $: console.log("session=", session);
  $: console.log("presence=", $presence);
  $: console.log("enforce=", $enforce);

  $: escalate =
    $enforce &&
    Object.values($enforce.items).filter(
      (item) => item.vehicle && (item.remove || item.immobilize)
    );
  $: violation =
    $enforce &&
    Object.values($enforce.items).filter(
      (item) =>
        item.vehicle && item.warn?.required && !item.remove && !item.immobilize
    );

  $: console.log("escalate=", escalate);
</script>

{#if !property || !$presence}
  {#if title}
    <h1><Loading /></h1>
  {:else}
    <Loading />
  {/if}
{:else if title}
  <h1>{title}</h1>
{/if}
{#if coverage && $presence}
  <aside class="present presence">
    <VehiclesPresentSummaryMap present={$presence} />
  </aside>
{/if}
<select
  name="present"
  value={$interval}
  on:change={({ currentTarget: target }) =>
    query(
      {
        present: target.value,
      },
      { history: false }
    )}
>
  {#each sessions as session}
    <option value={session.interval}
      >{intervalstr(session.interval, session.timezone)}</option
    >
  {/each}
</select>
{#if session}
  <aside class="present presence session">
    <dl class="stats">
      <dt class="escalate">Escalate</dt>
      <dd class="escalate">
        <data class="count escalate" value={partitioned?.[3].length ?? ""}
          >{partitioned?.[3].length ?? "…"}</data
        >
      </dd>
      <dt class="rewarn">Warn Again</dt>
      <dd class="rewarn">
        <data class="count rewarn" value={partitioned?.[2].length ?? ""}
          >{partitioned?.[2].length ?? "…"}</data
        >
      </dd>
      <dt class="warn">Warn First</dt>
      <dd class="warn">
        <data class="count warn" value={partitioned?.[1].length ?? ""}
          >{partitioned?.[1].length ?? "…"}</data
        >
      </dd>

      <dt>Scanned</dt>
      <dd>
        <data class="count" value={session.count}>{session.count}</data>
      </dd>
    </dl>

    <VehiclesPresentSession
      present={session}
      enforce={$enforce}
      bind:partitioned
      >{#if details}
        <nav>
          <a class="details" href="{details}?present={$interval}"
            >Open Details</a
          >
        </nav>
      {/if}
    </VehiclesPresentSession>
  </aside>
{/if}
