<script context="module">
  import { addMinutes, endOfMinute, format, startOfMinute } from "date-fns";
  import identity from "lodash-es/identity";
  import { derived } from "svelte/store";
  import { fetchEnforcement } from "$utils/api";
  import { propertyId, violationsUpdated } from "$utils/propertystores";
  import { comparer, dateDesc } from "$utils/sort";
  import { onlineVisibleTime } from "$utils/timestores";
  import { sortByCreatedDate } from "./components";
  import EnforcementChecksSummaryItem from "./enforcement/EnforcementChecksSummaryItem.svelte";
  import EnforcementViolationsSummaryItem from "./enforcement/EnforcementViolationsSummaryItem.svelte";
  import ItemsList from "./item/ItemsList.svelte";

  const validNow = onlineVisibleTime({ minutes: 1 });

  const valid = derived(
    validNow,
    ($now) =>
      format(addMinutes(startOfMinute($now), -4), "yyyy-MM-dd'T'HH:mm:ssxxx") +
      "/"
  );

  const validMinutes = derived(validNow, ($now) => {
    const minutes = [];

    for (var i = 0; i >= -5; i--) {
      minutes.push(endOfMinute(addMinutes($now, i)));
    }

    return minutes;
  });

  const enforcement = derived(
    [propertyId, valid, violationsUpdated()],
    async function ([$propertyId, $valid], set) {
      if (!$propertyId) return set(null);

      const data = await fetchEnforcement($propertyId, $valid);

      set(data.enforcement?.["for"]?.[$propertyId]);
    }
  );
</script>

<script>
  export let property;
</script>

<section class="live">
  <header>
    <h1>Live</h1>
  </header>
  <section class="enforcement">
    <header><h1>Enforcement Activity</h1></header>
    <!-- <ItemsList class="history" items={$enforcement && [ { ...$enforcement, title:"Checks", type:"checks" }, { ...$enforcement, title:"Violations", type:"violations" }, $enforcement ].filter(identity)} types={{
            "violations":EnforcementViolationsSummaryItem,
            "checks":EnforcementChecksSummaryItem,
            //"enforcement":EnforcementSummaryMapItem,
        }} full={{ enforcement:true }} /> -->
    <ItemsList
      class="history"
      items={$validMinutes
        .map((i) => ({
          type: "time",
          datetime: i,
          timezone: property?.timezone,
        }))
        .concat(Object.values($enforcement?.items || {}))
        .sort(comparer(sortByCreatedDate, dateDesc))}
      full={{ detection: true }}
    />
  </section>
</section>
