<script context="module" lang="ts">
  const calendar = {
    P1D: "day",
    P1W: "week",
    P1M: "month",
    P1Y: "year",
  };
</script>

<script lang="ts">
  import { parseISO } from "date-fns";
  import { format, toZonedTime } from "date-fns-tz";
  import { toDays, toMilliseconds, toMinutes } from "duration-fns";
  import identity from "lodash-es/identity";
  import EnforcementChecksTargetItem from "./EnforcementChecksTargetItem.svelte";
  import Loading from "$components/Loading.svelte";

  export let property: Property;
</script>

{#if property}
  <dt>Vehicle plate/tag scanning</dt>
  <dd>
    {property.vehicles.recognition.enabled
      ? "Active & enabled"
      : "Setup required"}
  </dd>
  <dt>Smart Decal scanning</dt>
  <dd>
    {property.media?.format == "smart-decal"
      ? "Active & enabled"
      : "Smart Decals aren't enabled"}
  </dd>
  <EnforcementChecksTargetItem item={property} />
  <dt>Auto notify on violation</dt>
  <dd>
    {[
      property.violations.notify.email && "Email",
      property.violations.notify.sms && "SMS",
      toMilliseconds(property.violations.notify.delay)
        ? toMinutes(property.violations.notify.delay) + " minute delay"
        : "immediately",
    ]
      .filter(identity)
      .join(", ")}
  </dd>

  <dt>Link unit to violation</dt>
  {#if property.violations.tenant?.required}
    <dd>Select when recording</dd>
  {:else}
    {#if property.violations.tenant?.request}
      <dd>Select when recording (optional)</dd>
    {/if}
    {#if property.violations.vehicle.infer.tenant?.enabled}
      <dd>Automatically when vehicle history is for only one unit and:</dd>
      <dd>
        <ol>
          {#if property.violations.vehicle.infer.tenant.permits.minimum > 1}
            <li>has an active permit for unit</li>
          {/if}
          {#if property.violations.vehicle.infer.tenant?.continuous && property.violations.vehicle.infer.tenant.permits.minimum > 1}
            <li>
              has an assigned permit for unit in the past {toDays(
                property.violations.vehicle.infer.tenant.permits.period
              )}
              days
            </li>
          {/if}
          <li>
            has any
            {#if property.violations.vehicle.infer.tenant.permits.minimum > 1}
              {property.violations.vehicle.infer.tenant.permits.minimum}+
            {/if}
            permits for unit in the past {toDays(
              property.violations.vehicle.infer.tenant.permits.period
            )}
            days
          </li>
        </ol>
      </dd>
    {:else}
      <dd>Auto-link is disabled</dd>
    {/if}
  {/if}
  {#if property.violations.valid && parseISO(property.violations.valid.split("/")[0]) > parseISO(property.created)}
    <dt>Ignore violations before</dt>
    <dd>
      <time datetime={property.violations.valid}
        >{format(
          toZonedTime(
            property.violations.valid.split("/")[0],
            property.timezone
          ),
          "MMM d yyyy h:mm a zzz",
          {
            timeZone: property.timezone,
          }
        )}</time
      >
    </dd>
  {/if}
{/if}
