<script lang="ts">
  import NewPolicyPermit from "$components/form/NewPolicyPermit.svelte";

  let active = false;
  export let property: Property;
  export let policy: PermitIssuePolicy;
</script>

{#if active}
  <NewPolicyPermit {policy} {property} on:cancel={() => (active = false)} />
{:else}
  <button type="button" class="create" on:click={(e) => (active = true)}
    >Add…</button
  >
{/if}
