import get from "lodash-es/get";
import merge from "lodash-es/merge";
import pick from "lodash-es/pick";
import map from "lodash-es/map";
import set from "lodash-es/set";
import invoke from "lodash-es/invoke";
import { metersFromPolicy } from "./policy";
import { toZoneISOString } from "./datetime";
import { api, auth, client } from "./auth";
import throttle from "lodash-es/throttle";
import { image } from "./imagetools";
import { stringify as toWKT } from "wellknown";
import type { Writable } from "svelte/store";
import type { Feature, FeatureCollection } from "geojson";

const postal = window.postal;

export { api };

// update auth header as auth changes
export let user = "self";
export let authHeader = "";

auth.subscribe(function ($auth) {
  authHeader = $auth && `&Authorization=${$auth.type} ${$auth.token}`;
  user = $auth?.sub || "self";
});

export function authorize(url: URL | string) {


  if (url instanceof URL) {
    url.searchParams.set("Authorization", authHeader.split("=")[1] || "");
    return url;
  }
  if (typeof url == "string") return authorize(new URL(url));

  return "&Authorization=" + (authHeader || "");
}

export function viewpoint(offset?: number) {
  const query = new URLSearchParams(location.search);
  if (query.has("viewpoint")) return encodeURIComponent(query.get("viewpoint"));

  return encodeURIComponent(
    toZoneISOString(new Date().getTime() + (offset || 0))
  );
}

const apiVersion = "v2";

export function base() {
  return api.settings.apiBase + apiVersion;
}

auth.subscribe((auth) => auth && invoke(window.ParkIQ, "API.Auth.set", auth));
//api.user.subscribe(auth => console.log("auth=", auth));

// lets tap into the old api requests...

// export const auth = writable(store.get("auth"));
// // write to backing store
// auth.subscribe($auth => $auth && store.set("auth", $auth));

function formDataToURLSearchParams(formData) {
  return new URLSearchParams([...formData.entries()]); // what edge does this support?
}

export function coordsToURLSearchParams(coords) {
  return new URLSearchParams(
    Object.entries(
      pick(coords || {}, [
        "latitude",
        "longitude",
        "accuracy",
        "altitude",
        "altitudeAccuracy",
        "speed",
        "heading",
        "headingAccuracy",
      ])
    )
      .filter(([a, b]) => !!b)
      .map(([a, b]) => [a, b + ""])
  );
}

function processLegacyItems(json) {
  if (!json) return json;
  for (const [key, value] of Object.entries(json)) {
    //console.log("legacy", key, value);
    //if(has(value, "items")) json.items[key] = value; //.items;
    if (value.items) set(json, ["items", key], value);
  }
  return json;
}

export async function fetchProperties(scope, principal) {
  const url = new URL(
    `${base()}/properties?viewpoint=${viewpoint()}&principal=${principal || user
    }&${authHeader}`
  );
  if (scope) url.searchParams.append("scope", scope);

  const json = await Promise.all([fetch(url)])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  for (const [k, v] of Object.entries(json.properties.items)) {
    const item = (json.properties.items[k] =
      json.items[v] || json.items[k] || v);

    for (const k2 of ["address"]) {
      if (item[k2]) item[k2] = json.items[item[k2]] || item[k2];
    }
  }

  return json;
}

export async function responseJson(response) {
  if (!response) return {};
  return response
    .text()
    .then(function (text) {
      if (!text)
        return {
          status: response.status,
        };

      return Promise.resolve(text)
        .then(JSON.parse)
        .catch(function (error) {
          return {
            status: response.status,
            message: text,
          };
        });
    })
    .catch(function (error) {
      return {
        status: response.status,
      };
    });
}

function resolveAddress(item, state) {
  if (!item) return item;
  const items = state.items || state;
  item = items[item] || item;
  //console.log("address state=", state);
  item.address = items[item.address] || item.address;
  return item;
}

export function resolvePolicy(item, state) {
  if (!item) return item;

  const items = state.items || state;

  item = items[item] || item;

  for (const key of [
    "statistics",
    "pricing",
    "metered",
    "entry",
    "spaces",
    "units",
    "media",
  ]) {
    //const value =
    item[key] =
      state[key]?.["for"]?.[item.id] ??
      state[key]?.["for"]?.[item.subject] ??
      item[key];
    // if (value?.items)
    //   for (const [k2, v2] of Object.entries(value.items))
    //     value.items[k2] = state[v2] ?? state[k2] ?? v2;
  }

  // need to convert to old style
  for (const key of ["units", "spaces"]) {
    if (item[key]) {
      let count = 0;
      item[key] = Object.entries(item[key].items ?? item[key]).reduce(
        (result, [k, v]) => {
          if (!v) return result; // admin doesn't need listed false options
          result.items[k] = items[v] ?? items[k] ?? v;

          count++; // increment the count

          // check first true and not one passed in
          if (count <= 1 && !result.item) {
            result.item = result.items[k];
          } else if (count >= 2 && result.item)
            delete result.item; // never allow more than one
          else if (result.item) {
            result.item = items[result.item] ?? result.item;
          }

          return result;
        },
        item[key].items ? item[key] : { items: {}, item: item[key].item }
      );
    }
  }

  // much more simple build
  for (const key of ["media"]) {
    const meta = item[key];
    //console.log("meta=", meta);
    if (meta) {
      let count = 0;
      item[key] = Object.entries(meta.items ?? {}).reduce((result, [k, v]) => {
        if (!v) return result; // admin doesn't need listed false options
        result.items[k] = items[v] ?? items[k] ?? v;

        // check first true and not one passed in
        if (0 === count && !result.item) {
          result.item = result.items[k];
          count++;
        } else if (count > 1 && result.item)
          delete result.item; // never allow more than one
        else if (result.item) result.item = items[result.item] ?? result.item;
        return result;
      }, meta);
    }
  }

  // item.statistics =
  //   get(state, ["statistics", "for", item.id]) ||
  //   get(state, ["statistics", "for", item.subject]);
  // item.pricing =
  //   get(state, ["pricing", "for", item.id]) ||
  //   get(state, ["pricing", "for", item.subject]);
  // item.metered =
  //   get(state, ["metered", "for", item.id]) ||
  //   get(state, ["metered", "for", item.subject]);
  // item.metered =
  //   get(state, ["metered", "for", item.id]) ||
  //   get(state, ["metered", "for", item.subject]);
  for (let [id, v] of Object.entries(metersFromPolicy(item) || {})) {
    v = items[v] || v;
    if (!v || !v.principals) continue;
    for (const [id2, v2] of Object.entries(v.principals)) {
      v.principals[id2] = items[v2] || v2;
    }
  }

  //item.property = resolveProperty(item.location, state);

  //console.log("policy=", item);

  return item;
}

export function resolveProperty(item, state) {
  if (!item) return item;
  const items = state.items || state;
  item = items[item] || item;
  return resolveAddress(item, state);
}

export const fetchPropertyPermitPolicies = throttle(
  async function (property) {
    const json = await Promise.all([
      fetch(
        `${base()}/permits/policies/issue?scope=${property}&viewpoint=${viewpoint()}&entry=true&public=true&admin=true&disabled=false&pricing=policy&${authHeader}`
      ),
    ])
      .then((values) => Promise.all(values.map(responseJson)))
      //.then(values => (values.map(json => pick(json, "items"))))
      .then((values) => merge({}, ...values));

    //console.log(json.policies.items);

    for (const [key, value] of Object.entries(json.policies.items)) {
      // console.log("policy=", key, value, json.items[key]);
      json.policies.items[key] = resolvePolicy(key, json);
    }

    return json;
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

export const fetchPropertyViolationPolicies = throttle(
  async function (property) {
    const json = await Promise.all([
      fetch(
        `${base()}/violations/policies/issued?scope=${property}&viewpoint=${viewpoint()}&${authHeader}`
      ),
    ])
      .then((values) => Promise.all(values.map(responseJson)))
      //.then(values => (values.map(json => pick(json, "items"))))
      .then((values) => merge({}, ...values));

    //console.log(json.policies.items);

    for (const [key, value] of Object.entries(json.policies.items)) {
      // console.log("policy=", key, value, json.items[key]);
      json.policies.items[key] = json.items[value] ?? json.items[key] ?? value;
    }

    return json;
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

export const fetchProperty = throttle(
  async function (property) {
    const json = await Promise.all([
      fetch(
        `${base()}/properties?viewpoint=${viewpoint()}&property=${property}`
      ),
    ])
      .then((values) => Promise.all(values.map(responseJson)))
      //.then(values => (values.map(json => pick(json, "items"))))
      .then((values) => merge({}, ...values));

    return json;
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

export async function fetchSessions(scope, valid) {
  if (!scope) return null;

  var res = await fetch(
    `${base()}/sessions?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
      valid
    )}&${authHeader}`
  );

  var json = await responseJson(res);

  for (const [k, v] of Object.entries(json.sessions.items || {})) {
    // const item = (json.authorizations.items[k] =
    //   json.items[v] || json.items[k] || v);
    const item = v;

    for (const k2 of ["principal"]) {
      if (item[k2]) item[k2] = json.items[item[k2]] || item[k2];
    }
  }

  return json.sessions;
}

export async function fetchPrincipalAuthorizations(
  principal: string,
  valid?: string
) {
  if (!principal) return {};

  var res = await fetch(
    `${base()}/authorizations?principal=${principal}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
      valid || toZoneISOString(Date.now() + 5 * 60 * 1000) + "/"
    )}&client=${client}&${authHeader}`
  );

  var json = await responseJson(res);

  for (const [k, v] of Object.entries(json.authorizations.items || {})) {
    const item = (json.authorizations.items[k] =
      json.items[v] || json.items[k] || v);

    for (const k2 of ["principal"]) {
      if (item[k2]) item[k2] = json.items[item[k2]] || item[k2];
    }
    item.valid.min.by = json.items[item.valid.min.by] || item.valid.min.by;
    if (item.valid.max)
      item.valid.max.by = json.items[item.valid.max.by] || item.valid.max.by;
  }

  return json.authorizations;
}

export async function fetchAuthorizations(scope, valid) {
  if (!scope) return {};

  var res = await fetch(
    `${base()}/authorizations?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
      valid || toZoneISOString(Date.now() + 5 * 60 * 1000) + "/"
    )}&${authHeader}`
  );

  var json = await responseJson(res);

  for (const [k, v] of Object.entries(json.authorizations.items || {})) {
    const item = (json.authorizations.items[k] =
      json.items[v] || json.items[k] || v);

    for (const k2 of ["principal"]) {
      if (item[k2]) item[k2] = json.items[item[k2]] || item[k2];
    }
    item.valid.min.by = json.items[item.valid.min.by] || item.valid.min.by;
    if (item.valid.max)
      item.valid.max.by = json.items[item.valid.max.by] || item.valid.max.by;
  }

  return json.authorizations;
}

export async function fetchDeleteAuthorization(scope, subject) {
  scope = scope.id || scope;

  const res = await fetch(
    `${base()}/authorizations?viewpoint=${viewpoint()}&scope=${scope}&subject=${subject}&${authHeader}`,
    {
      method: "DELETE",
      //body: formData,
    }
  );
  const json = await responseJson(res);
  return json;
}

export async function fetchCreateUser(scope, email, role) {
  scope = scope.id || scope;

  const res = await fetch(
    `${base()}/locations/${scope}/users?viewpoint=${viewpoint()}&email=${encodeURIComponent(
      email
    )}&type=${role}&role=${role}&${authHeader}`,
    {
      method: "POST",
      //body: formData,
    }
  );
  return await responseJson(res);
}

export async function fetchVehicles(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/vehicles?scope=${scope}&viewpoint=${viewpoint()}${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  for (const [k, v] of Object.entries(json.vehicles.items || {})) {
    json.vehicles.items[k] = json.items[v] || v;
  }

  if (postal)
    postal.publish({
      topic: "vehicles.items.updated",
      data: {
        generated: json.generated,
        items: Object.values(json.vehicles.items),
      },
    });

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchUpdateSpaces(...items) {
  //console.log(items);

  for (const item of items) {
    var qs = new URLSearchParams(item);

    let res = await fetch(
      `${base()}/locations/${item.scope.id || item.scope
      }/spaces?viewpoint=${viewpoint()}&${qs.toString()}&${authHeader}`,
      {
        method: "POST",
      }
    );
  }
}

export async function fetchUpdateUnits(...items) {
  //console.log(items);

  for (const item of items) {
    var qs = new URLSearchParams(item);

    let res = await fetch(
      `${base()}/locations/${item.scope.id || item.scope
      }/tenants?viewpoint=${viewpoint()}&${qs.toString()}&${authHeader}`,
      {
        method: "POST",
      }
    );
  }
}

export async function fetchUpdatePermit(permit, json, formData) {
  if (permit.id) permit = permit.id;

  const res = await fetch(
    `${base()}/permits/${permit}?viewpoint=${viewpoint()}&${new URLSearchParams(
      json || {}
    ).toString()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );

  return await responseJson(res);
}

export async function fetchUpdateProperty(property, data) {
  const res = await fetch(
    `${base()}/properties/${property.id
    }?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "PATCH",
      body: data,
    }
  );

  // update property?
  const json = await responseJson(res);

  return json;
}

export async function fetchSpaces(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/spaces?scope=${scope}&viewpoint=${viewpoint()}${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  for (const [k, v] of Object.entries(json.spaces?.items || {})) {
    json.spaces.items[k] = json.items[v] || v;
  }

  if (postal)
    postal.publish({
      topic: "spaces.items.updated",
      data: {
        generated: json.generated,
        items: Object.values(json.spaces?.items || {}),
        predefined: true,
      },
    });

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchMedias(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/media?scope=${scope}&viewpoint=${viewpoint()}${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  for (const [k, v] of Object.entries(json.media?.items || {})) {
    json.media.items[k] = json.items[v] || v;
  }

  if (postal)
    postal.publish({
      topic: "media.items.updated",
      data: {
        generated: json.generated,
        items: Object.values(json.media?.items || {}),
        predefined: true,
      },
    });

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchUnits(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/units?scope=${scope}&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  //processLegacyItems(json); why??

  for (const [k, v] of Object.entries(json.units?.items || {})) {
    json.units.items[k] = json.items[v] || v;
  }

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchUnitsTenants(scope, valid, sent) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/units/tenants?scope=${scope}&sent=${sent === true
      }&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid || toZoneISOString(Date.now() - 30 * 24 * 60 * 60 * 1000) + "/"
      )}${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  processLegacyItems(json);

  for (const [k, v] of Object.entries(json.units?.items || {})) {
    json.units.items[k] = json.items[v] || v;
  }
  for (const [k, v] of Object.entries(json.tenants?.items || {})) {
    const item = (json.tenants.items[k] = json.items[v] || v);
    if (item?.subject) item.unit = json.items[item.subject] ?? item.subject;
  }

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchPrices(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/prices?scope=${scope.id || scope
      }&viewpoint=${viewpoint()}&valid=${viewpoint(1000 * 60)}/&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchPaymentMetrics(interval, options) {
  let url = `${base()}/payments/collected/metrics?viewpoint=${viewpoint()}&interval=${interval}&${authHeader}`;
  if (options.property) {
    url += `&scope=${options.property}`;
  }
  if (options["for"]) {
    url += `&for=${options["for"]}`;
  }
  if (options.policies) {
    for (const policy of options.policies) {
      url += `&policy=${policy}`;
    }
  }
  if (options.payments && typeof options.payments == "string") {
    url += `&payments=${options.payments}`;
  } else if (options.payments && Array.isArray(options.payments)) {
    for (const payments of options.payments) {
      url += `&payments=${payments}`;
    }
  }
  if (options.metrics && typeof options.metrics == "string") {
    url += `&metric=${options.metrics}`;
  } else if (options.metrics && Array.isArray(options.metrics)) {
    for (const metric of options.metrics) {
      url += `&metric=${metric}`;
    }
  }
  if (options.times) {
    url += `&times=${options.times}`;
  }
  if (options.datetimes && typeof options.datetimes == "string") {
    url += `&datetimes=${options.datetimes}`;
  } else if (options.datetimes && Array.isArray(options.datetimes)) {
    for (const datetime of options.datetimes) {
      url += `&datetimes=${datetime}`;
    }
  }
  if (options.daytimes && typeof options.daytimes == "string") {
    url += `&daytimes=${options.daytimes}`;
  } else if (options.daytimes && Array.isArray(options.daytimes)) {
    for (const daytime of options.daytimes) {
      url += `&daytimes=${daytime}`;
    }
  }
  if (options.days) {
    url += `&days`;
  }
  if (options.tenants) {
    url += `&tenants=${options.tenants}`;
  }
  if (options.count) {
    url += `&metric=count&count=${options.count}`;
  }
  //console.log(`PAYMENT METRICS URL = `, url);
  const response = await fetch(url, { signal: options.signal });
  const data = await response.json();
  return data;
}

export async function fetchResetUnit(tenantOrUnit, notes, formData?) {
  const scope = tenantOrUnit.scope;
  const id =
    (tenantOrUnit.subject && tenantOrUnit.subject.id) ||
    tenantOrUnit.subject ||
    tenantOrUnit.id ||
    tenantOrUnit;

  const res = await fetch(
    `${base()}/units/${id}/tenants?revoke=true&notes=${notes || ""
    }&viewpoint=${viewpoint()}${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );

  return await responseJson(res);
}

export async function fetchUnitExternalStatus(scope, valid) {
  if (!scope) return null;
  const vp = viewpoint();
  if (!valid) valid = `${toZoneISOString(new Date().getTime())}/`;
  console.log("valid=", valid);
  const json = await Promise.all([
    //fetch(`${base()}/units?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(valid)}&${authHeader}`),
    fetch(
      `${base()}/units/status?scope=${scope}&viewpoint=${vp}&valid=${encodeURIComponent(
        valid
      )}&client=${client}&${authHeader}`
    ),
    // fetch(
    //   `${base()}/units/residents?valid=${encodeURIComponent(
    //     valid
    //   )}&viewpoint=${vp}&client=${client}&scope=${scope}&${authHeader}`
    // ),
    // fetch(
    //   `${base()}/units/vehicles?valid=${encodeURIComponent(
    //     valid
    //   )}&viewpoint=${vp}&client=${client}&scope=${scope}&${authHeader}`
    // ),
    // fetch(
    //   `${base()}/rentables?valid=${encodeURIComponent(
    //     valid
    //   )}&viewpoint=${vp}&client=${client}&scope=${scope}&${authHeader}`
    // ),
    //fetch(`${base()}/units/status?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(valid)}&${authHeader}`),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  if (json.rentables) {
    for (const [k, v] of Object.entries(json.rentables.items ?? {})) {
      const item = (json.rentables.items[k] =
        json.items[v as string] ?? json.items[k] ?? v);
      if (item.subject) item.subject = json.items[item.subject] ?? item.subject;
      if (item.by) {
        if (typeof item.by == "string") item.by = [item.by];
        //console.log("item.by", item.by);
        item.by = Object.values(item.by ?? []).map(
          (item) => json.items[item as string] ?? item
        );
        //item.by = json.items[item.by] ?? item.by;
      }
    }
  }

  return json;

  //state.update(prev => merge(prev, json.items));
}

// async function fetchUnitExternalInfo(scope, valid) {
//   if (!scope) return null;
//   const vp = viewpoint();
//   if (!valid) valid = `${toZoneISOString(new Date().getTime())}/`;
//   console.log("valid=", valid);
//   const json = await Promise.all([
//     //fetch(`${base()}/units?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(valid)}&${authHeader}`),
//     // fetch(
//     //   `${base()}/units/status?scope=${scope}&viewpoint=${vp}&valid=${encodeURIComponent(
//     //     valid
//     //   )}&client=${client}&${authHeader}`
//     // ),
//     fetch(
//       `${base()}/units/residents?valid=${encodeURIComponent(
//         valid
//       )}&viewpoint=${vp}&client=${client}&scope=${scope}&${authHeader}`
//     ),
//     fetch(
//       `${base()}/units/vehicles?valid=${encodeURIComponent(
//         valid
//       )}&viewpoint=${vp}&client=${client}&scope=${scope}&${authHeader}`
//     ),
//     fetch(
//       `${base()}/rentables?valid=${encodeURIComponent(
//         valid
//       )}&viewpoint=${vp}&client=${client}&scope=${scope}&${authHeader}`
//     ),
//     //fetch(`${base()}/units/status?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(valid)}&${authHeader}`),
//   ])
//     .then((values) => Promise.all(values.map((res) => res.json())))
//     //.then(values => (values.map(json => pick(json, "items"))))
//     .then((values) => merge({}, ...values));

//   if (json.rentables) {
//     for (const [k, v] of Object.entries(json.rentables.items ?? {})) {
//       const item = (json.rentables.items[k] =
//         json.items[v as string] ?? json.items[k] ?? v);
//       if (item.subject) item.subject = json.items[item.subject] ?? item.subject;
//     }
//   }

//   return json;

//   //state.update(prev => merge(prev, json.items));
// }

export async function fetchUnitExternalOccupancy(subject, valid) {
  if (!subject) return null;
  const vp = viewpoint();
  if (!valid) valid = `${toZoneISOString(new Date().getTime())}/`;
  const json = await fetch(
    `${base()}/units/occupancy?valid=${encodeURIComponent(
      valid
    )}&viewpoint=${vp}&client=${client}&for=${subject}&${authHeader}`
  ).then((res) => res.json());

  if (json.items) {
    for (const [k, v] of Object.entries(json.items ?? {})) {
      const item = v;
      if (item.subject) item.subject = json.items[item.subject] ?? item.subject;
      if (item.by) {
        if (typeof item.by == "string") item.by = [item.by];
        //console.log("item.by", item.by);
        item.by = Object.values(item.by ?? []).map(
          (item) => json.items[item as string] ?? item
        );
        //item.by = json.items[item.by] ?? item.by;
      }
    }
  }

  if (json.permits) {
    // for (const [k, v] of Object.entries(json.permits.items ?? {})) {
    //   const item = json.permits.items[k] = json.items[v as string] ?? json.items[k] ?? v;
    // }
    resolvePermits(json.permits.items, json);
    for (const [id, meta] of Object.entries(json.permits.for ?? {})) {
      for (const [k, v] of Object.entries(meta.items ?? {})) {
        meta.items[k] = json.items[v as string] ?? json.items[k] ?? v;
      }
    }
  }

  return json;
}

export async function fetchVehicleDetections(vehicle, valid) {
  if (!vehicle || !vehicle.scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/detections/vehicles?scope=${vehicle.scope}&plate=${vehicle.key
      }&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
    // fetch(
    //   `${base()}/observations?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
    //     valid
    //   )}&${authHeader}`
    // ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  return json;
}

export async function fetchDetectionsAndObservations(scope, valid) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/detections/vehicles?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
    fetch(
      `${base()}/observations?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  return json;
}

export async function fetchObservations(scope, valid) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/observations?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  return json;
}

export async function fetchMinUsage(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/permits/policies/usage?scope=${scope}&minimum=true&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  for (let item of Object.values(json.usage?.["for"] || {})) {
    // update properties...
    for (const k of ["subject", "meter"]) {
      if (item[k]) item[k] = json.items[item[k]] || item[k];
    }

    // nested...
    for (let nested of Object.values(item.policies || {}).concat(
      Object.values(item.meters || {}),
      Object.values(item.items || {})
    )) {
      for (const k1 of ["subject", "policy", "meter"]) {
        if (!nested[k1]) continue;
        nested[k1] = json.items[nested[k1]] || nested[k1]; // lookup or use existing value
        // expand nested subjects
        for (const [k2, v2] of Object.entries(nested[k1]?.subjects || {})) {
          nested[k1].subjects[k2] = json.items[v2] || json.items[k2] || v2;
        }
      }

      if (nested.meters)
        nested.meters = Object.values(nested.meters).map(
          (v3) => item.meters[v3] || v3
        );
    }
  }

  return json;
}
export async function fetchMinMaxUsage(scope, minimum, maximum, prices) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/permits/policies/usage?scope=${scope}&minimum=${!!minimum + ""
      }&maximum=${!!maximum + ""}&prices=${!!prices + ""
      }&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  for (let item of Object.values(json.usage?.["for"] || {})) {
    // update properties...
    for (const k of ["subject", "meter"]) {
      if (item[k]) item[k] = json.items[item[k]] || item[k];

      for (const [k2, v2] of Object.entries(item[k]?.subjects || {})) {
        item[k].subjects[k2] = json.items[v2] || json.items[k2] || v2;
      }
    }

    // nested...
    for (let nested of Object.values(item.policies || {}).concat(
      Object.values(item.meters || {}),
      Object.values(item.items || {})
    )) {
      for (const k1 of ["subject", "policy", "meter"]) {
        if (!nested[k1]) continue;
        nested[k1] = json.items[nested[k1]] || nested[k1]; // lookup or use existing value
        // expand nested subjects
        for (const [k2, v2] of Object.entries(nested[k1]?.subjects || {})) {
          nested[k1].subjects[k2] = json.items[v2] || json.items[k2] || v2;
        }
      }

      if (nested.meters)
        nested.meters = Object.values(nested.meters).map(
          (v3) => item.meters[v3] || v3
        );
    }
  }

  return json;
}
export async function fetchUsage(subject: string, asof?: string) {
  if (!subject) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/permits/policies/usage?subject=${subject}&viewpoint=${asof || viewpoint()
      }&prices=true&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  for (let item of Object.values(json.usage?.["for"] || {})) {
    // update properties...
    for (const k of ["subject"]) {
      if (item[k]) item[k] = json.items[item[k]] || item[k];
    }

    // nested...
    for (let nested of Object.values(item.policies || {}).concat(
      Object.values(item.meters || {})
    )) {
      for (const k1 of ["subject", "policy", "meter"]) {
        if (!nested[k1]) continue;
        nested[k1] = json.items[nested[k1]] || nested[k1]; // lookup or use existing value
        // expand nested subjects
        for (const [k2, v2] of Object.entries(nested[k1]?.subjects || {})) {
          nested[k1].subjects[k2] = json.items[v2] || json.items[k2] || v2;
        }
      }

      if (nested.meters)
        nested.meters = Object.values(nested.meters).map(
          (v3) => item.meters[v3] || v3
        );
    }
  }

  return json;
}

export async function fetchVehicle(scope, subject, valid) {
  if (!scope) return null;
  if (!subject) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/locations/${scope}/vehicles/${subject}?valid=${encodeURIComponent(
        valid
      )}&viewpoint=${viewpoint()}&client=${client}&sent=true&files=true&violations.status=true&similar=true&payments=true&validations=true&entry=true${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  for (const [k, v] of Object.entries(json.vehicles.versions || {})) {
    const item = (json.vehicles.versions[k] =
      json.items[v] || json.items[k] || v);

    for (const k2 of ["subject"]) {
      if (item[k2]) item[k2] = json.items[item[k2]] || item[k2];
    }
  }

  for (const item of Object.values(json.notes[json.vehicles.item] || {})) {
    resolveIssued(item.issued, json);
  }

  json.vehicles.item = json.items[json.vehicles.item] || json.vehicles.item;
  json.vehicles.latest =
    json.items[json.vehicles.latest] || json.vehicles.latest;
  json.permittables.item =
    json.items[json.permittables.item] || json.permittables.item;

  resolvePermits(json.permits.items, json);
  resolvePermittables(json.permittables.items, json);
  resolveViolationExceptions(json.violations.exceptions.items, json);
  json.violations.items = resolveViolations(json.violations.items, json);

  for (const [key, value] of Object.entries(json.attachments?.["for"])) {
    resolveAttachments(key, json);
  }

  if (json.vehicles.similar)
    for (const [k, v] of Object.entries(json.vehicles.similar)) {
      json.vehicles.similar[k] = json.items[v] || json.items[k] || v;
    }

  // for (let [k, v] of Object.entries(json.vehicles.items || {})) {
  //   var nested = (item.items[k] = json.items[v] || json.items[k] || v);

  //   // expand properties
  //   for (const k1 of ["subject", "principal"]) {
  //     if (!nested[k1]) continue;
  //     nested[k1] = json.items[nested[k1]] || nested[k1]; // lookup or use existing value
  //   }
  // }

  // for (let item of Object.values(json.usage?.["for"] || {})) {
  //   // update properties...
  //   for (const k of ["subject"]) {
  //     if (item[k]) item[k] = json.items[item[k]] || item[k];
  //   }

  //   // nested...
  //   for (let nested of Object.values(item.policies || {}).concat(
  //     Object.values(item.meters || {})
  //   )) {
  //     for (const k1 of ["subject", "policy", "meter"]) {
  //       if (!nested[k1]) continue;
  //       nested[k1] = json.items[nested[k1]] || nested[k1]; // lookup or use existing value
  //       // expand nested subjects
  //       for (const [k2, v2] of Object.entries(nested[k1]?.subjects || {})) {
  //         nested[k1].subjects[k2] = json.items[v2] || json.items[k2] || v2;
  //       }
  //     }

  //     if (nested.meters)
  //       nested.meters = Object.values(nested.meters).map(
  //         (v3) => item.meters[v3] || v3
  //       );
  //   }
  // }

  if (postal) {
    for (const item of Object.values(json.permits.items)) {
      if (item.type != "permit") continue;
      postal.publish({
        topic: "permit.updated",
        data: {
          generated: item.generated,
          item: item,
        },
      });
    }
    for (const item of Object.values(json.permittables.items)) {
      //if (item.type != "permittable") continue;
      postal.publish({
        topic: "permittable.updated",
        data: {
          generated: item.generated,
          item: item,
        },
      });
    }
    for (let item of Object.values((json.sent && json.sent.items) || {})) {
      item = json.items[item] || item;
      //console.log("sent item=", item);
      if (!item.id) continue;
      postal.publish({
        topic: "item.sent.updated",
        data: {
          generated: item.generated,
          item: item.container,
          items: [item],
        },
      });
    }
  }

  return json;
}

export async function fetchTenant(scope, subject, valid) {
  if (!scope) return null;
  if (!subject) return null;
  const vp = viewpoint();
  const json = await Promise.all([
    fetch(
      `${base()}/locations/${scope}/tenants/${subject}?valid=${encodeURIComponent(
        valid
      )}&viewpoint=${vp}&client=${client}&sent=true&files=true&violations.status=true&auth=true&payments=true&validations=true&entry=true&${authHeader}`
    ),
    // fetch(
    //   `${base()}/units/residents?valid=${encodeURIComponent(
    //     valid
    //   )}&viewpoint=${vp}&client=${client}&scope=${scope}&${authHeader}`
    // ),
    // fetch(
    //   `${base()}/units/vehicles?valid=${encodeURIComponent(
    //     valid
    //   )}&viewpoint=${vp}&client=${client}&scope=${scope}&${authHeader}`
    // ),
    // fetch(
    //   `${base()}/rentables?valid=${encodeURIComponent(
    //     valid
    //   )}&viewpoint=${vp}&client=${client}&scope=${scope}&${authHeader}`
    // ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  for (const item of Object.values(json.notes[json.tenants.item] || {})) {
    resolveIssued(item.issued, json);
  }

  json.tenants.item = json.items[json.tenants.item] || json.tenants.item;
  json.tenants.latest = json.items[json.tenants.latest] || json.tenants.latest;
  json.permittables.item =
    json.items[json.permittables.item] || json.permittables.item;

  for (const [k, v] of Object.entries(json.tenants.versions || {})) {
    const item = (json.tenants.versions[k] =
      json.items[v] || json.items[k] || v);

    for (const k2 of ["subject"]) {
      if (item[k2]) item[k2] = json.items[item[k2]] || item[k2];
    }
  }

  resolvePermits(json.permits.items, json);
  resolvePermittables(json.permittables.items, json);
  resolveViolationExceptions(json.violations.exceptions.items, json);

  for (const [key, value] of Object.entries(json.attachments?.["for"])) {
    resolveAttachments(key, json);
  }

  for (const [key, value] of Object.entries(json.contacts?.["for"] ?? {})) {
    resolveContacts(key, json, value);
  }

  if (postal) {
    for (const item of Object.values(json.permits.items)) {
      if (item.type != "permit") continue;
      postal.publish({
        topic: "permit.updated",
        data: {
          generated: item.generated,
          item: item,
        },
      });
    }
    for (const item of Object.values(json.permittables.items)) {
      //if (item.type != "permittable") continue;
      postal.publish({
        topic: "permittable.updated",
        data: {
          generated: item.generated,
          item: item,
        },
      });
    }
    for (let item of Object.values((json.sent && json.sent.items) || {})) {
      item = json.items[item] || item;
      //console.log("sent item=", item);
      if (!item.id) continue;
      postal.publish({
        topic: "item.sent.updated",
        data: {
          generated: item.generated,
          item: item.container,
          items: [item],
        },
      });
    }
  }

  return json;
}

export async function fetchSpace(scope, subject, valid) {
  if (!scope) return null;
  if (!subject) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/locations/${scope}/spaces/${subject}?valid=${encodeURIComponent(
        valid
      )}&viewpoint=${viewpoint()}&client=${client}&sent=true&files=true&payments=true&validations=true&entry=true&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  for (const item of Object.values(json.notes[json.spaces.item] || {})) {
    resolveIssued(item.issued, json);
  }

  json.spaces.item = json.items[json.spaces.item] || json.spaces.item;
  // json.permittables.item =
  //   json.items[json.permittables.item] || json.permittables.item;

  resolvePermits(json.permits.items, json);
  //resolvePermittables(json.permittables.items, json);
  //resolveViolationExceptions(json.violations.exceptions.items, json);

  for (const [key, value] of Object.entries(json.attachments?.["for"])) {
    resolveAttachments(key, json);
  }

  if (postal) {
    for (const item of Object.values(json.permits.items)) {
      if (item.type != "permit") continue;
      postal.publish({
        topic: "permit.updated",
        data: {
          generated: item.generated,
          item: item,
        },
      });
    }
    // for (const item of Object.values(json.permittables.items)) {
    //   //if (item.type != "permittable") continue;
    //   postal.publish({
    //     topic: "permittable.updated",
    //     data: {
    //       generated: item.generated,
    //       item: item,
    //     },
    //   });
    // }
    for (let item of Object.values((json.sent && json.sent.items) || {})) {
      item = json.items[item] || item;
      //console.log("sent item=", item);
      if (!item.id) continue;
      postal.publish({
        topic: "item.sent.updated",
        data: {
          generated: item.generated,
          item: item.container,
          items: [item],
        },
      });
    }
  }

  return json;
}

export async function fetchMedia(scope, subject, valid) {
  if (!scope) return null;
  if (!subject) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/locations/${scope}/media/${subject}?valid=${encodeURIComponent(
        valid
      )}&viewpoint=${viewpoint()}&client=${client}&sent=true&files=true&payments=true&validations=true&entry=true&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  for (const item of Object.values(json.notes[json.media.item] || {})) {
    resolveIssued(item.issued, json);
  }

  json.media.item = json.items[json.media.item] || json.media.item;
  // json.permittables.item =
  //   json.items[json.permittables.item] || json.permittables.item;

  json.permittables.item =
    json.items[json.permittables.item] || json.permittables.item;

  resolvePermits(json.permits.items, json);
  resolvePermittables(json.permittables.items, json);
  //resolveViolationExceptions(json.violations.exceptions.items, json);

  for (const [key, value] of Object.entries(json.attachments?.["for"])) {
    resolveAttachments(key, json);
  }

  if (postal) {
    for (const item of Object.values(json.permits.items)) {
      if (item.type != "permit") continue;
      postal.publish({
        topic: "permit.updated",
        data: {
          generated: item.generated,
          item: item,
        },
      });
    }
    // for (const item of Object.values(json.permittables.items)) {
    //   //if (item.type != "permittable") continue;
    //   postal.publish({
    //     topic: "permittable.updated",
    //     data: {
    //       generated: item.generated,
    //       item: item,
    //     },
    //   });
    // }
    for (let item of Object.values((json.sent && json.sent.items) || {})) {
      item = json.items[item] || item;
      //console.log("sent item=", item);
      if (!item.id) continue;
      postal.publish({
        topic: "item.sent.updated",
        data: {
          generated: item.generated,
          item: item.container,
          items: [item],
        },
      });
    }
  }

  return json;
}

// export async function fetchAccessRecords(subject, valid, ...summarize) {
//   if (!subject) return null;
//   const json = await Promise.all([
//     fetch(
//       `${base()}/sessions/records?subject=${subject}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
//         valid
//       )}${summarize.reduce(
//         (qs, i) => qs + "&summarize=" + i,
//         ""
//       )}&${authHeader}`
//     ),
//   ])
//     .then((values) => Promise.all(values.map((res) => res.json())))
//     .then((values) => merge({}, ...values));

//   for (let item of Object.values(json.accessed?.["for"] || {})) {
//     // expand properties...
//     for (const k of ["subject"]) {
//       if (item[k]) item[k] = json.items[item[k]] || item[k];
//     }

//     // nested...
//     for (let [k, v] of Object.entries(item.items || {})) {
//       var nested = (item.items[k] = json.items[v] || json.items[k] || v);

//       // expand properties
//       for (const k1 of ["subject", "principal"]) {
//         if (!nested[k1]) continue;
//         nested[k1] = json.items[nested[k1]] || nested[k1]; // lookup or use existing value
//       }
//     }
//   }

//   return json;
// }

export async function fetchConflicts(subject, valid) {
  if (!subject) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/permits/policies/conflicts?subject=${subject}&viewpoint=${viewpoint()}&issued=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  for (let [k1, v1] of Object.entries(json.conflicts?.items || {})) {
    v1 = json.conflicts.items[k1] = json.items[v1] || v1;

    for (let [k2, v2] of Object.entries(v1)) {
      if (k2 == "id") continue;
      if (!json.items[v2]) continue;
      v1[k2] = json.items[v2] || v2;
    }
  }

  return json;
}

export async function fetchServices(scope, service) {
  // const res = await fetch(
  //   authorize(`${base()}/services?service=${service || ""}&for=${
  //     scope || ""
  //   }&viewpoint=${viewpoint()}`),
  //   {
  //     //method: !!code ? "post" : "get"
  //   }
  // );

  const json = await Promise.all([
    fetch(
      authorize(
        `${base()}/services?service=${service || ""}&for=${scope || ""
        }&viewpoint=${viewpoint()}`
      )
    ),
    fetch(
      authorize(
        `${base()}/entry/permitted?for=${scope || ""}&viewpoint=${viewpoint()}`
      )
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  // lol
  for (const v1 of [
    ...Object.values(json.entry?.items ?? {}),
    ...Object.values(json.entry?.["for"] || {}).flatMap((v) =>
      Object.values(v.items)
    ),
  ]) {
    const item = json.items[v1] ?? v1;
    for (const [k2, v2] of Object.entries(item)) {
      item[k2] = json.items[v2] ?? json.items[k2] ?? v2;
    }
  }

  //const json = await res.json();

  return json;
}

// export async function fetchConflicts(subject, valid) {
//   if (!subject) return null;
//   const json = await Promise.all([
//     fetch(
//       `${base()}/notes?for=${subject}&viewpoint=${viewpoint()}&issued=${encodeURIComponent(
//         valid
//       )}&${authHeader}`
//     ),
//   ])
//     .then((values) => Promise.all(values.map((res) => res.json())))
//     .then((values) => merge({}, ...values));

//   for (let [k1, v1] of Object.entries(json.conflicts?.items || {})) {
//     v1 = json.conflicts.items[k1] = json.items[v1] || v1;

//     for (let [k2, v2] of Object.entries(v1)) {
//       if (k2 == "id") continue;
//       if (!json.items[v2]) continue;
//       v1[k2] = json.items[v2] || v2;
//     }
//   }

//   return json;
// }

export async function fetchEnforcement(
  scope,
  interval,
  principal,
  ...summarize
) {
  scope = scope.id || scope;

  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const json = await Promise.all([
    fetch(
      `${base()}/enforcement?scope=${scope}${summarize.reduce(
        (qs, i) => qs + "&summarize=" + i,
        ""
      )}${principal ? "&for=principal" : ""
      }&items=true&files=true&sent=true&payments=true&viewpoint=${viewpoint()}&interval=${encodeURIComponent(
        interval
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  //
  //
  //
  //

  // process fee items
  // for (const [id, value] of Object.entries(json.fees?.items || {})) {
  //   const item = json.items[value] || json.items[id] || value;
  //   item.payments = json.payments?.["for"][item.id] || item.payments;
  //   for (const [k2, v2] of Object.entries(item.payments || {})) {
  //     item.payments[k2] = json.items[v2] || json.items[k2] || v2;
  //   }
  // }

  // process fee for
  // for (const [id, value] of Object.entries(json.fees?.["for"] || {})) {
  //   for (const [k2, v2] of Object.entries(value || {})) {
  //     console.log(k2, v2);
  //     value[k2] = json.items[v2] || json.items[k2] || v2;
  //   }
  // }

  for (let item of Object.values(json.enforcement?.["for"] || {})) {
    // expand properties...
    for (const k of ["subject"]) {
      if (!item[k]) continue;
      if (item[k]) item[k] = json.items[item[k]] || item[k];

      for (const k1 of ["address"]) {
        if (!item[k][k1]) continue;
        item[k][k1] = json.items[item[k][k1]] || item[k][k1]; // lookup or use existing value
      }
    }

    // nested...
    for (let [k, v] of Object.entries(item.items || {})) {
      var nested = (item.items[k] = json.items[v] || json.items[k] || v);

      // expand properties
      for (const k1 of ["subject", "principal", "vehicle", "tenant"]) {
        if (!nested[k1]) continue;
        nested[k1] = json.items[nested[k1]] || nested[k1]; // lookup or use existing value
      }
      nested.fees = json.fees?.["for"][nested.id];

      if (nested.permitted && Array.isArray(nested.permitted))
        for (var i = 0; i < nested.permitted.length; i++)
          nested.permitted[i] =
            json.items[nested.permitted[i]] ?? nested.permitted[i];

      nested.attached = resolveAttachments(nested, json);
      resolveIssued(nested.issued, json);
    }
  }

  for (let item of Object.values(json.accessed?.["for"] || {})) {
    // expand properties...
    for (const k of ["subject"]) {
      if (!item[k]) continue;
      if (item[k]) item[k] = json.items[item[k]] || item[k];

      for (const k1 of ["address"]) {
        if (!item[k][k1]) continue;
        item[k][k1] = json.items[item[k][k1]] || item[k][k1]; // lookup or use existing value
      }
    }

    // nested...
    for (let [k, v] of Object.entries(item.items || {})) {
      var nested = (item.items[k] = json.items[v] || json.items[k] || v);

      // expand properties
      for (const k1 of ["subject", "principal"]) {
        if (!nested[k1]) continue;
        nested[k1] = json.items[nested[k1]] || nested[k1]; // lookup or use existing value
      }
    }
  }

  for (let item of Object.values(json.violations?.["for"] || {})) {
    // expand properties...
    for (const k of ["subject"]) {
      if (!item[k]) continue;
      if (item[k]) item[k] = json.items[item[k]] || item[k];

      for (const k1 of ["address"]) {
        if (!item[k][k1]) continue;
        item[k][k1] = json.items[item[k][k1]] || item[k][k1]; // lookup or use existing value
      }
    }

    // nested...
    for (let [k, v] of Object.entries(item.items || {})) {
      var nested = (item.items[k] = json.items[v] || json.items[k] || v);

      // expand properties
      for (const k1 of ["subject", "principal", "vehicle", "tenant", "space"]) {
        if (!nested[k1]) continue;
        nested[k1] = json.items[nested[k1]] || nested[k1]; // lookup or use existing value
      }

      nested.fees = json.fees?.["for"][item.id];

      nested.attached = resolveAttachments(nested, json);
      resolveIssued(nested.issued, json);
    }
  }

  if (postal) {
    for (const item of Object.values(json.items)) {
      if (item.type != "violation") continue;
      postal.publish({
        topic: "violation.updated",
        data: {
          generated: item.generated,
          item: item,
        },
      });
    }
    for (let item of Object.values((json.sent && json.sent.items) || {})) {
      item = json.items[item] || item;
      //console.log("sent item=", item);
      if (!item.id) continue;
      postal.publish({
        topic: "item.sent.updated",
        data: {
          generated: item.generated,
          item: item.container,
          items: [item],
        },
      });
    }
  }

  //json.violations.items = resolveViolations(json.violations.items, json);
  //console.log(json.violations);
  return json;
}

async function fetchViolations(property, valid, ...summarize) {
  property = property.id || property;

  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const json = await Promise.all([
    fetch(
      `${base()}/violations?scope=${property}${summarize.reduce(
        (qs, i) => qs + "&summarize=" + i,
        ""
      )}&files=true&viewpoint=${viewpoint()}&issued=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  //
  //
  //
  //

  for (let item of Object.values(json.violations?.["for"] || {})) {
    // expand properties...
    for (const k of ["subject"]) {
      if (item[k]) item[k] = json.items[item[k]] || item[k];
    }

    // nested...
    for (let [k, v] of Object.entries(item.items || {})) {
      var nested = (item.items[k] = json.items[v] || json.items[k] || v);

      // expand properties
      for (const k1 of ["subject", "principal"]) {
        if (!nested[k1]) continue;
        nested[k1] = json.items[nested[k1]] || nested[k1]; // lookup or use existing value
      }
    }
  }

  json.violations.items = resolveViolations(json.violations.items, json);
  //console.log(json.violations);
  return json.violations;
}

export async function fetchViolationExceptions(property, valid) {
  property = property.id || property;

  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const state = await Promise.all([
    fetch(
      `${base()}/locations/${property}/violations/exceptions?scope=${property}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  //
  //
  //
  //

  //json.violations.items = resolveViolations(json.violations.items, json);
  //console.log(json.permits.items);

  for (let item of Object.values(state.violations?.exceptions?.["for"] || {})) {
    // expand properties...
    for (const k of ["subject"]) {
      if (!item[k]) continue;
      if (item[k]) item[k] = state.items[item[k]] || item[k];

      for (const k1 of ["address"]) {
        if (!item[k][k1]) continue;
        item[k][k1] = state.items[item[k][k1]] || item[k][k1]; // lookup or use existing value
      }
    }

    // nested...
    for (let [k, v] of Object.entries(item.items || {})) {
      var nested = (item.items[k] = state.items[v] || state.items[k] || v);

      // expand properties
      for (const k1 of ["subject"]) {
        if (!nested[k1]) continue;
        nested[k1] = state.items[nested[k1]] || nested[k1]; // lookup or use existing value
      }
    }
  }

  state.violations.exceptions.items?.map(function (item) {
    item.subject = state.items[item.subject] || item.subject;
    return item;
  }); //.violations;
  return state.violations.exceptions;
}

export async function fetchNegativePermittables(scope, valid) {
  scope = scope.id || scope;

  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const state = await Promise.all([
    fetch(
      `${base()}/locations/${scope}/permittables/negative?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  for (let item of Object.values(state.permittables?.["for"] || {})) {
    // expand properties...
    for (const k of ["subject", "latest"]) {
      if (!item[k]) continue;
      if (item[k]) item[k] = state.items[item[k]] || item[k];
    }

    // nested...
    for (let [k, v] of Object.entries(item.items || {})) {
      var nested = (item.items[k] = state.items[v] || state.items[k] || v);

      // expand properties
      for (const k1 of ["subject"]) {
        if (!nested[k1]) continue;
        nested[k1] = state.items[nested[k1]] || nested[k1]; // lookup or use existing value
      }

      resolveIssued(nested.issued, state);
    }
  }

  return state.permittables;
}

export async function fetchSuspicious(subjectOrScope, valid) {
  subjectOrScope = subjectOrScope.id || subjectOrScope;

  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const state = await Promise.all([
    fetch(
      `${base()}/suspicious/permits?for=${subjectOrScope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  //
  //
  //
  //

  Object.values(state.suspected.items).map((item) => {
    //item.tenant = state.items[item.tenant] || item.tenant;
    if (item.permit) item.permit = state.items[item.permit] || item.permit;
    if (item.permit && item.permit.tenant)
      item.permit.tenant =
        state.items[item.permit.tenant] || item.permit.tenant;
    if (item.permit && item.permit.vehicle)
      item.permit.vehicle =
        state.items[item.permit.vehicle] || item.permit.vehicle;
    if (item.permit && item.permit.issued.policy)
      item.permit.policy =
        state.items[item.permit.issued.policy] || item.permit.issued.policy;

    Object.values(item.evaluated).map((item) => {
      if (item.vehicle)
        item.vehicle = state.items[item.vehicle] || item.vehicle;
      if (item.subject)
        item.subject = state.items[item.subject] || item.subject;
    });

    //console.log("item=", item);
  });
  return state.suspected;

  var attendant = _.get(json, [
    "attendants",
    "items",
    _.get(json, "attendants.item"),
  ]);

  var items = _.chain(_.get(json, "suspected.items", {}))
    .map(function (item, id) {
      _.set(item, "attendant", attendant);
      _.set(
        item,
        "permit",
        _.get(json, ["permits", "items", _.get(item, "permit")])
      );
      _.set(
        item,
        "tenant",
        _.get(json, ["tenants", "items", _.get(item, "tenant")])
      );
      if (!!_.get(item, "tenant"))
        _.set(item, "tenant.type", _.get(json, "tenants.type"));

      _.set(
        item,
        "evaluated",
        _.chain(_.get(item, "evaluated") || _.get(item, "vehicles", {}))
          .map(function (item, id) {
            //console.log(item);

            _.set(
              item,
              "vehicle",
              _.get(json, ["vehicles", "items", _.get(item, "vehicle")])
            );

            //console.log(item);

            //return _.get(json, [ "vehicles", "items", id || item ]);

            return item;
          })
          .filter()
          .sortBy(["display "])
          .value()
      );

      _.set(item, "analyzed", _.get(item, "evaluated[0].analyzed"));

      return item;
    })
    .orderBy(["analyzed"], ["desc"])
    .value();

  return {
    generated: json.generated,
    requested: json.requested,
    location: _.get(json, [
      "locations",
      "items",
      _.get(json, "locations.item"),
    ]),
    attendant: attendant,
    permits: _.pick(_.get(json, "permits"), ["count", "issued", "valid"]),
    count: _.get(json, "suspected.count", 0),
    enabled: _.get(json, "suspected.enabled", false),
    items: items,
  };
}

function resolveAttachments(itemOrID, state) {
  if (!itemOrID) return itemOrID;
  if (itemOrID.id) itemOrID = itemOrID.id;
  const attached = get(state, ["attachments", "for", itemOrID], {});
  if (attached && attached.items)
    Object.entries(attached.items).reduce(function (result, [id, value]) {
      result[id] = state.items[id] || value;
      if (result[id] && result[id].issued && result[id].issued.by)
        result[id].issued.by =
          state.items[result[id].issued.by] || result[id].issued.by;
      return result;
    }, attached.items);
  return attached;
}

export function resolveContacts(itemOrID, state, meta) {
  if (!itemOrID) return itemOrID;
  if (itemOrID.id) itemOrID = itemOrID.id;
  const attached = get(state, ["contacts", "for", itemOrID], {
    type: "contacts",
    subject: itemOrID,
    count: 0,
    items: {},
  });
  if (attached && attached.items)
    Object.entries(attached.items).reduce(function (result, [id, value]) {
      result[id] = state.items[value] || state.items[id] || value;
      // if (result[id] && result[id].issued && result[id].issued.by)
      //   result[id].issued.by =
      //     state.items[result[id].issued.by] || result[id].issued.by;
      return result;
    }, attached.items);
  return attached;
}

function resolveIssued(item, state) {
  state = (state && state.items) || state;
  if (!item || !state) return item;

  for (const [key, value] of Object.entries(item)) {
    item[key] = state[value] || value;
  }

  return item;
}

export function resolveViolations(values, state) {
  // values is the list of permits, items is the overall state
  let items = state.items || state;

  if (!values || !items) return null;
  values = map(
    values?.items ?? values,
    (value, key) => items[key] || items[value] || value || key
  );
  if (!values.every((item) => !!item && !!item.id)) return null; // not done loading

  return values
    .filter((item) => item)
    .map((item) =>
      !item
        ? item
        : merge(item, {
          property: resolveProperty(
            items[item.location] || item.location,
            items
          ),
          address: items[item.address] || item.address,
          vehicle: items[item.vehicle] || item.vehicle,
          space: items[item.space] || item.space,
          tenant: items[item.tenant] || item.tenant,
          attached: resolveAttachments(item, state),

          //attachments: get(state, [ "attachments", "for", item.id ], {}),
          //contact: get(items, [ "contacts", "items", permit.id ], permit.contact),
          //subjects: Object.values(permit.subjects || {}).map(i => items[i] || i),
          // attachments: Object.entries(get(items, [ "attachments", "items", permit.id ], {})).map(([ id, item]) => items[id] || item),
          // validations: Object.values(get(items, [ "validations", "for", permit.id ]) || permit.validations || {}).map(item => {
          //     item = items[item] || item;
          //     if(item.meter) item.meter = items[item.meter] || item.meter;
          //     return item;
          // })
          issued: resolveIssued(item.issued, state),
        })
    );
}

export async function fetchDocuments(property) {
  property = property.id || property;

  const state = await Promise.all([
    fetch(
      `${base()}/files?for=${property}&type=application/pdf&issued=/&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  var attached = resolveAttachments(property, state);

  return attached;

  return json;
}

export async function fetchPermits(scope, valid, options) {
  scope = scope.id || scope;
  const qs = new URLSearchParams(
    `for=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
      valid
    )}&files=true&sent=true&payments=true&${authHeader}`
  );

  if (options)
    for (const [k, v] of Object.entries(options)) {
      if (null != v) qs.set(k, v);
      else qs.delete(k);
    }

  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const json = await Promise.all([fetch(`${base()}/permits?${qs.toString()}`)])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  resolvePermits(json.permits.items, json);
  //console.log(json.permits.items);

  if (postal) {
    for (const item of Object.values(json.permits.items)) {
      if (item.type != "permit") continue;
      postal.publish({
        topic: "permit.updated",
        data: {
          generated: item.generated,
          item: item,
        },
      });
    }
    for (let item of Object.values((json.sent && json.sent.items) || {})) {
      item = json.items[item] || item;
      //console.log("sent item=", item);
      if (!item.id) continue;
      postal.publish({
        topic: "item.sent.updated",
        data: {
          generated: item.generated,
          item: item.container,
          items: [item],
        },
      });
    }
  }

  return json.permits;
}

export function resolveViolationExceptions(values, items) {
  // values is the list of permits, items is the overall state
  items = (items && items.items) || items;

  if (!values || !items) return values;

  // run expansion
  for (const [k, v] of Object.entries(values)) {
    const item = (values[k] = items[v] || items[k] || v);

    Object.assign(item, {});

    for (const k1 of ["subject", "vehicle", "tenant", "media", "space"]) {
      if (!item[k1]) continue;
      item[k1] = items[item[k1]] || item[k1]; // lookup or use existing value
    }

    //resolveIssued(item.issued, items);
  }

  return values;
}

export function resolvePermittables(values, items) {
  // values is the list of permits, items is the overall state
  items = (items && items.items) || items;

  if (!values || !items) return values;

  // run expansion
  for (const [k, v] of Object.entries(values)) {
    const item = (values[k] = items[v] || items[k] || v);

    //Object.assign(item, {});

    for (const k1 of ["subject", "vehicle", "tenant", "media", "space"]) {
      if (!item[k1]) continue;
      item[k1] = items[item[k1]] || item[k1]; // lookup or use existing value
    }

    resolveIssued(item.issued, items);
  }

  return values;
}

export function resolvePermits(values, json) {
  // values is the list of permits, items is the overall state
  const items = (json && json.items) || json;

  if (!values || !items) return values;

  // run expansion
  for (const [k, v] of Object.entries(values)) {
    const item = (values[k] = items[v] || items[k] || v);

    Object.assign(item, {
      property: resolveProperty(items[item.location] || item.location, items),
      address: items[item.address] || item.address,
      policy:
        items[item.issued.policy] ||
        items[item.issued.issuer] ||
        item.issued.policy ||
        item.issued.issuer,
      contact: get(json, ["contacts", "items", item.id], item.contact),
      fees: resolveFees(get(json, ["fees", "for", item.id]), json),
      // Object.entries(get(json, ["fees", "for", item.id], {})).map(
      //   ([id, item]) => items[id] || item
      // ),
      entry: json.entry?.["for"]?.[item.id], // ||json.entry?.["for"]?.[item.issued.policy],
      attachments: Object.entries(
        get(json, ["attachments", "items", item.id], {})
      )
        .map(([id, item]) => items[id] || item)
        .concat(
          Object.entries(get(json, ["attachments", "for", item.id], {})).map(
            ([id, item]) => items[id] || item
          )
        ),
      attached: resolveAttachments(item, json),
      validations: Object.values(
        get(json, ["validations", "for", item.id]) || item.validations || {}
      ).map((item) => {
        item = items[item] || item;
        if (item.meter) item.meter = items[item.meter] || item.meter;
        return item;
      }),
    });

    if (item.subjects)
      for (const [k1, v1] of Object.entries(item.subjects || {})) {
        item.subjects[k1] = items[v1 as string] || items[k1] || v1;
      }

    if (item.spaces)
      for (const [k1, v1] of Object.entries(item.spaces || {})) {
        item.spaces[k1] = items[v1 as string] || items[k1] || v1;
      }

    for (const k1 of ["vehicle", "tenant", "media", "space", "source"]) {
      if (!item[k1]) continue;
      item[k1] = items[item[k1]] || item[k1]; // lookup or use existing value
    }

    resolveIssued(item.issued, items);
    resolveIssued(item.revoked, items);
  }

  for (const [id, value] of Object.entries(json.fees?.items || {})) {
    const item = json.items[value] || json.items[id] || value;
    // item.payments = json.payments["for"][item.id];
    // for (const [k2, v2] of Object.entries(item.payments || {})) {
    //   item.payments[k2] = json.items[v2] || json.items[k2] || v2;
    // }
  }

  for (const [id, value] of Object.entries(json.validations?.items || {})) {
    const item = json.items[value] || json.items[id] || value;
  }

  return values;



  values = map(
    values,
    (value, key) => items[key] || item[value] || value || key
  );
  if (!values.every((item) => !!item && !!item.id)) return null; // not done loading

  return values
    .filter((permit) => permit)
    .map((permit) =>
      !permit
        ? permit
        : merge(permit, {
          property: resolveProperty(
            items[permit.location] || permit.location,
            items
          ),
          address: items[permit.address] || permit.address,
          policy:
            items[permit.issued.policy] ||
            items[permit.issued.issuer] ||
            permit.issued.issuer,
          vehicle: items[permit.vehicle] || permit.vehicle,
          media: items[permit.media] || permit.media,
          spaces: (permit.spaces || []).map((i) => items[i] || i),
          tenant: items[permit.tenant] || permit.tenant,
          contact: get(
            items,
            ["contacts", "items", permit.id],
            permit.contact
          ),
          subjects: Object.values(permit.subjects || {}).map(
            (i) => items[i] || i
          ),
          attachments: Object.entries(
            get(items, ["attachments", "items", permit.id], {})
          ).map(([id, item]) => items[id] || item),
          validations: Object.values(
            get(items, ["validations", "for", permit.id]) ||
            permit.validations ||
            {}
          ).map((item) => {
            item = items[item] || item;
            if (item.meter) item.meter = items[item.meter] || item.meter;
            return item;
          }),
        })
    );
}

function resolveFees(values: {
  type: string,
  items: Record<string, string>;
} | Record<string, string>, json: any): {
  type: string,
  items: Record<string, any>;
} | null | undefined {
  // values is the list of permits, items is the overall state
  const items = (json && json.items) || json;

  //logger("resolveFees", values, json);

  if (!values || !items) return;

  if (!values.type) {
    return resolveFees({
      type: "fees",
      items: Object.keys(values).reduce((result, id) => {
        result[id] = id;
        return result;
      }, {} as Record<string, string>)
    }, json);
  }

  // run expansion
  for (const [k, v] of Object.entries(values.items)) {
    const item = values.items[k] = items[v as string] || items[k] || v;
  }


  return values as { type: string, items: Record<string, any> };
}

export async function fetchCreateFile(scope, file, data, attempt) {
  //var requested = new Date().toISOString();

  // return Promise.join(api.base(), location, file, function(base, location, file) {
  //     var url = base + "v1/locations/" + location + "/files?ts=" + viewpoint();
  //     // + "&name=" + file.name + "&type=" + file.type + "&length=" + file.size + "&created=" + requested + "&latitude=" + latitude + "&longitude=" + longitude;

  var formData = new FormData();
  formData.append("name", file.name);
  formData.append("type", file.type);
  formData.append("length", file.size);
  formData.append("created", viewpoint());
  formData.append("modified", new Date(file.lastModified).toISOString());
  if (!!data)
    for (const [key, value] of Object.entries(data)) {
      if (!!key && !!value) formData.append(key, value);
    }

  const res = await fetch(
    `${base()}/locations/${scope.scope || scope.id || scope
    }/files?ts=${viewpoint()}&viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );

  return await res.json();
  // })
  // .catch(function(error) {
  //     if(!attempt) attempt = 1;
  //     if(attempt <= retry) return Promise.delay(1000 * attempt).then(function() {
  //         return createFile(location, file, data, attempt + 1);
  //     });
  //     return Promise.reject(error); // out of retries
  // })
}

export async function fetchCreateNote(scope, data) {
  scope = scope.scope || scope.id || scope;

  const res = await fetch(
    `${base()}/locations/${scope}/notes?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: data,
    }
  );
  const json = await res.json();

  if (postal) {
    var item = json.notes.item;
    resolveIssued(item.issued, json);
    postal.publish({
      topic: "note.created",
      data: {
        generated: json.generated,
        item: item,
      },
    });
  }

  return json;
}

export async function fetchSpaceStatus(scope, valid) {
  if (!valid) valid = viewpoint(1000 * 60 * 5) + "/";
  else valid = encodeURIComponent(valid);

  var url = `${base()}/locations/${scope}/permits/spaces/summary?prices=true&viewpoint=${viewpoint()}&valid=${valid}`;
  //console.log("spaces=", url);

  const res = await fetch(url);
  //console.log("spaces res", res);
  const json = await res.json();

  // for (const k1 of ["prices", "permitted"]) {
  //   for (const [k2, v2] of Object.entries(json[k1]?.["for"] ?? {})) {
  //     const item = json.items[k2];
  //     console.log(k1, k2, v2, item);
  //     if (item) item[k1] = v2;
  //   }
  // }

  // copy prices to set by subject
  // if (json.prices) {
  //   for (const [id, v] of Object.entries(json.prices.items)) {
  //     if (typeof v !== "string") continue;
  //     const price = (json.prices.items[id] =
  //       json.items[v] || json.items[id] || v);

  //     if (price.subjects) {
  //       for (const subject of Object.values(price.subjects)) {
  //         json.prices["for"] = json.prices["for"] || {};
  //         json.prices["for"][subject] = json.prices["for"][subject] || [];
  //         json.prices["for"][subject].push(price);
  //       }
  //     }
  //   }
  //   //console.log("prices=", json.prices);
  // }

  return json;
}

export async function fetchPermitCreate(data: FormData) {
  const url = new URL(
    `${base()}/permits?viewpoint=${viewpoint()}&${authHeader}`
  );
  // url.searchParams.set("viewpoint", viewpoint());
  // url.searchParams.set("Authorization", authHeader);

  for (const key of data.keys()) {
    const values = data.getAll(key);
    if (key === "notes") continue; // omit from query
    for (const value of values) {
      if (null == value) continue;
      if ("" === value) continue;
      if (value.length > 100) continue;
      if (typeof value !== "string") continue;
      url.searchParams.append(key, value);
    }
  }

  // for (const [key, value] of data.entries()) {
  //   if(value.length > 100) continue;
  //   if (null != value) url.searchParams.set(key, value);
  // }

  return fetch(url, {
    method: "POST",
    body: data,
  }).then(async function (res) {
    const json = await responseJson(res);
    if (!res.ok) return json;
    resolvePermits(json.permits.items, json);
    if (json.permits?.item)
      json.permits.item = json.items[json.permits.item] ?? json.permits.item;
    return json;
  });
  // const json = await responseJson(res);
  // resolvePermits(json.permits.items, json);
  // if (json.permits?.item)
  //   json.permits.item = json.items[json.permits.item] ?? json.permits.item;
  // return json;
}

export async function fetchPermitRevoke(permit, data) {
  permit = permit?.id || permit;
  if (!permit)
    return {
      status: 404,
      message: "No item set",
    };
  return fetch(
    `${base()}/permits/${permit}?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
      body: data,
    }
  ).then(async function (res) {
    const json = await responseJson(res);
    // if (!res.ok) return json;
    // resolvePermits(json.permits.items, json);
    // if (json.permits?.item)
    //   json.permits.item = json.items[json.permits.item] ?? json.permits.item;
    return json;
  });
}

export async function fetchPermittableCreate(data) {
  return fetch(
    `${base()}/permittables?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: data,
    }
  ).then(async function (res) {
    const json = await responseJson(res);
    if (!res.ok) return json;
    resolvePermittables(json.permittables.items, json);
    if (json.permittables.item)
      json.permittables.item =
        json.items?.[json.permittables.item] ??
        json.permittables.items[json.permittables.item] ??
        json.permittables.item;
    return json;
  });
}

export async function fetchPermittableRevoke(permittable, data) {
  permittable = permittable?.id || permittable;
  if (!permittable)
    return {
      status: 404,
      message: "No item set",
    };
  return fetch(
    `${base()}/permittables/${permittable}?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
      body: data,
    }
  ).then(async function (res) {
    const json = await responseJson(res);
    // if (!res.ok) return json;
    // resolvePermits(json.permits.items, json);
    // if (json.permits?.item)
    //   json.permits.item = json.items[json.permits.item] ?? json.permits.item;
    return json;
  });
}

export async function fetchContactCreate(data) {
  return fetch(`${base()}/contacts?viewpoint=${viewpoint()}&${authHeader}`, {
    method: "POST",
    body: data,
  }).then(async function (res) {
    const json = await responseJson(res);
    if (!res.ok) return json;
    // resolvePermittables(json.permittables.items, json);
    // if (json.permittables.item)
    //   json.permittables.item =
    //     json.items?.[json.permittables.item] ??
    //     json.permittables.items[json.permittables.item] ??
    //     json.permittables.item;
    if (json.contacts?.item)
      json.contacts.item = json.items[json.contacts.item] ?? json.contacts.item;
    for (const [key, value] of Object.entries(json.contacts?.["for"] ?? {})) {
      resolveContacts(key, json, value);
    }

    return json;
  });
}

export async function fetchViolationRevoke(violation, data) {
  violation = violation?.id || violation;
  if (!violation)
    return {
      status: 404,
      message: "No item set",
    };
  return fetch(
    `${base()}/violations/${violation}?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
      body: data,
    }
  ).then(async function (res) {
    const json = await responseJson(res);
    // if (!res.ok) return json;
    // resolvePermits(json.permits.items, json);
    // if (json.permits?.item)
    //   json.permits.item = json.items[json.permits.item] ?? json.permits.item;
    return json;
  });
}

export async function fetchSend(item, data) {
  item = item?.id || item;
  if (!item)
    return {
      status: 404,
      message: "No item set",
    };
  return fetch(`${base()}/send?viewpoint=${viewpoint()}&${authHeader}`, {
    method: "POST",
    body: data,
  }).then(async function (res) {
    const json = await responseJson(res);
    // if (!res.ok) return json;
    // resolvePermits(json.permits.items, json);
    // if (json.permits?.item)
    //   json.permits.item = json.items[json.permits.item] ?? json.permits.item;
    return json;
  });
}

export async function fetchResetTenantCode(item, formData) {
  const id = item.id || item;

  return fetch(
    `${base()}/accounts/${id}/code?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  ).then(async function (res) {
    const json = await responseJson(res);
    return json;
  });
}

export async function fetchPlateObservation(property, blob, coords) {
  const photo = await image.resize(
    blob,
    {
      width: 1280,
      height: 1280,
    },
    "image/jpeg",
    0.45
  );

  //console.log(URL.createObjectURL(photo));

  const data = new FormData();
  data.append("image", photo, `image${Date.now()}.jpg`);

  const url = new URL(
    `${base()}/observations?viewpoint=${viewpoint()}&scope=${property.id || property
    }&method=scanner&alpr=&${coordsToURLSearchParams(
      coords
    ).toString()}${authHeader}`
  );
  if (property?.vehicles?.recognition?.engine)
    url.searchParams.set("alpr", property.vehicles.recognition.engine);

  let res = await fetch(url, {
    method: "POST",
    body: data,
  });
  let json = await responseJson(res);
  return json;
}

export async function fetchMediaObservation(property, blob, media, coords) {
  const photo = await image.resize(
    blob,
    {
      width: 1280,
      height: 1280,
    },
    "image/jpeg",
    0.45
  );

  //console.log(URL.createObjectURL(photo));

  const data = new FormData();
  data.append("image", photo, `image${Date.now()}.jpg`);

  let res = await fetch(
    `${base()}/observations?viewpoint=${viewpoint()}&scope=${property.id || property
    }&media=${(media && media.id) || media || ""
    }&method=scanner&${coordsToURLSearchParams(
      coords
    ).toString()}${authHeader}`,
    {
      method: "POST",
      body: data,
    }
  );
  let json = await responseJson(res);
  return json;
}

export const fetchViolation = throttle(
  async function fetchViolation(id: string): Promise<Violations> {
    var url = `${base()}/violations/${id}?viewpoint=${viewpoint()}&files=true`;
    //console.log("permits=", url);

    const res = await fetch(url);
    //console.log("spaces res", res);

    const json = await res.json();

    json.violations.items = resolveViolations(json.violations.items, json);
    //console.log(json.violations);
    return json.violations;
  },
  3 * 1000,
  {
    leading: true,
    trailing: true,
  }
);

export async function fetchRemoveGeoFeature(
  property: any,
  features: FeatureCollection | Feature[] | string[] | string,
  state?: Writable<FeatureCollection>
) {
  const ids = [];
  if (typeof features == "string")
    ids.push(feature as string); // single string
  else if (features && features.id)
    ids.push(features.id as string); // single feature
  else if (features && features.length)
    ids.push(...features.map((i) => i.id || i)); // array of features or ids
  else if (features && (features as FeatureCollection).features)
    ids.push(...features.features.map((i) => i.id)); // feature collection

  if (!ids.length)
    return {
      type: "FeatureCollection",
      features: [],
    };

  const doRemove = fetch(
    `${base()}/geo?viewpoint=${new Date().toISOString()}${ids.reduce((str, id) => str + `&id=${id}`, "")}&${authHeader}`,
    {
      method: "DELETE",
    }
  );

  const [removeJson] = await Promise.all([doRemove]).then((values) =>
    Promise.all(values.map((res) => res.json()))
  );

  if (state)
    state.update((state) => {
      const features = state.features;
      for (const feature of removeJson.features) {
        let targetIndex = null;
        for (let i = 0; i < features.length; i++) {
          if (feature.id === features[i].id) targetIndex = i;
        }
        if (null != targetIndex) features.splice(targetIndex, 1);
      }
      return state;
    });

  // if (state && state.features) state = state.features;

  // if (state) {
  //   // update this to use json?
  //   for (const feature of removeJson.features) {
  //     let targetIndex = null;
  //     for (let i = 0; i < state.length; i++) {
  //       if (feature.id === state[i].id) targetIndex = i;
  //     }
  //     if (null != targetIndex) state.splice(targetIndex, 1);
  //   }
  // }

  return removeJson;
}

export async function fetchUpdateGeoFeature(
  property: any,
  feature: Feature,
  state?: Writable<FeatureCollection>
) {
  const doRemove = fetch(
    `${base()}/geo?viewpoint=${new Date().toISOString()}&id=${feature.id || ""}&${authHeader}`,
    {
      method: "DELETE",
    }
  );

  const form = new FormData();
  form.append("geometry", toWKT(feature.geometry)); // feature.geometry
  for (const [name, value] of Object.entries(feature.properties))
    form.append(name, value);

  const doCreate = fetch(
    `${base()}/geo?viewpoint=${new Date().toISOString()}&scope=${property.id || property}${authHeader}`,
    {
      method: "POST",
      body: form,
    }
  );

  const [removeJson, createJson] = await Promise.all([doRemove, doCreate]).then(
    (values) => Promise.all(values.map((res) => res.json()))
  );

  if (state)
    state.update((state) => {
      const features = state.features;
      let targetIndex = null;
      if (feature.id) {
        for (let i = 0; i < features.length; i++) {
          if (feature.id === features[i].id) targetIndex = i;
        }
        if (null != targetIndex) features.splice(targetIndex, 1);
      }
      features.push(...createJson.features);
      return state;
    });

  // if (features && features.features) features = features.features;

  // if (features) {
  //   if (feature.id) {
  //     for (let i = 0; i < features.length; i++) {
  //       if (feature.id === features[i].id) targetIndex = i;
  //     }
  //     if (null != targetIndex) features.splice(targetIndex, 1);
  //   }
  //   features.push(...createJson.features);
  // }

  return merge(removeJson, createJson);
}
