<script>
  import startCase from "lodash-es/startCase";
  import { fetchUpdateUnits as update } from "$utils/api";
  import { unitsUpdated as updated } from "$utils/propertystores";

  export let item;

  $: value = item?.["format"];
  $: submitting = !item;

  async function submit(e) {
    if (!item) return;

    submitting = true;

    await update({
      type: item.type,
      scope: item.scope,
      id: item.id,
      [e.target.name]: e.target.value,
    });

    // anything else?
    updated.set(Date.now());
  }
</script>

<dt>Type</dt>
<dd>
  <data class="unit format" value={value || ""}>{startCase(value)}</data>
  <form class="v2" on:change={submit}>
    {#if submitting}
      Saving
    {:else}
      <label for="unit-format">Change</label>
      <select id="unit-format" name="format" value={value + ""}>
        <option value="apartment">apartment</option>
        <option value="townhome">townhome</option>
        <option value="condo">condo</option>
        <option value="home">home</option>
        <option value="office">office</option>
        <option value="suite">suite</option>
        <option value="retail">retail</option>
      </select>
    {/if}
  </form>
</dd>
