<script lang="ts" context="module">
  const now30s = time({ seconds: 30 });
</script>

<script lang="ts">
  import EntryItem from "../EntryItem.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import ItemExpandContent from "$components/item/ItemExpandContent.svelte";
  import ItemImages from "$components/item/ItemFiles.svelte";
  import FeesItem from "../FeesItem.svelte";
  import Time from "../Time.svelte";
  import { parseISO } from "date-fns";
  import { time } from "$utils/timestores";
  import { format, toZonedTime } from "date-fns-tz";

  export let item: Permit;
  $: subjects = [
    item?.media,
    item?.vehicle,
    ...Object.values(item?.spaces ?? []),
    item?.tenant,
  ].filter((item) => !!item);

  $: timezone = item && item.timezone;

  $: issuedutc = item && parseISO(item.issued.utc);

  $: [startstr, endstr] = item?.valid.interval.split("/") ?? ["", ""];
  $: startutc =
    startstr &&
    new Date(
      Math.max(
        parseISO(startstr).getTime(),
        Math.min(issuedutc?.getTime() ?? Date.now(), Date.now())
      )
    );

  $: endutc = endstr && parseISO(endstr);
  $: endlocal = endutc && toZonedTime(endutc, timezone);
  $: nowutc = item && new Date(Math.max(Date.now(), $now30s.getTime()));

  $: cancelled = item && item.cancelled;
  $: pending = item && !cancelled && nowutc < startutc;
  $: expired = item && (item.expired || (endutc && nowutc > endutc));
  $: active = item && !expired && !pending && !cancelled;

  $: pass = item && item.pass;
</script>

{#if item}
  <article class="{item.type} details">
    <header><h1>{item.title}</h1></header>
    <dl>
      <dt>For</dt>
      <dd>
        <ul class="subjects">
          {#each subjects as item}<li>
              <RecordItem {item} />
            </li>{/each}
        </ul>
      </dd>
      <dt>Issued</dt>
      <dd>
        <Time
          datetime={item.issued.utc}
          timezone={item.timezone}
          format="h:mm a EEE MMM d yyyy zzz"
        />
      </dd>
      <dd>
        {#each [item.issued.by, item.issued.user]
          .filter((item) => item && item.id)
          .slice(0, 1) as user}
          <a href="mailto:{user.email}"
            ><data class="user {user.type}" value={user.id}
              ><dfn>by</dfn> {user.name}</data
            ></a
          >
        {:else}
          <dfn>by</dfn> Self Service Registration
        {/each}
      </dd>
    </dl>
    <ItemExpandContent {item}>
      <dl>
        <!-- {#if validations.length}
          <dd>Validated by Smart Guard</dd>
        {/if} -->
        <dt>Start</dt>
        <dd>
          <Time
            datetime={startstr}
            timezone={item.timezone}
            format="h:mm a EEE MMM d yyyy zzz"
          />
        </dd>
        {#if !cancelled && endutc}
          <dt>Duration</dt>
          <dd>
            <time datetime={item.valid.duration.iso}
              ><abbr />{item.valid.duration.display}</time
            >
          </dd>
        {/if}
        {#if item.revoked}
          <dt>{item.revoked.title}</dt>
          <dd>
            <Time
              class="status revoked"
              datetime={item.revoked.datetime}
              timezone={item.timezone}
              format="h:mm a EEE MMM d yyyy zzz"
            />
          </dd>
          <dd>
            {#each [item.revoked.by, item.revoked.user]
              .filter((item) => item && item.id)
              .slice(0, 1) as user}
              <a href="mailto:{user.email}"
                ><data class="user {user.type}" value={user.id}
                  ><dfn>by</dfn> {user.name}</data
                ></a
              >
            {:else}
              <dfn>by</dfn> Self Service Registration
            {/each}
          </dd>
          {#if item.revoked.reason}
            <!-- <dt>Reason</dt> -->
            <dd>{item.revoked.reason}</dd>
          {/if}
        {:else}
          <dt>End</dt>
          <dd>
            <time datetime={endstr}
              ><abbr
                >{cancelled
                  ? "cancelled"
                  : endlocal
                    ? format(endlocal, "h:mm a EEE MMM d yyyy zzz", {
                        timeZone: item.timezone,
                      })
                    : "when revoked"}</abbr
              ></time
            >
          </dd>
        {/if}

        {#if !cancelled}
          <dt>Usage</dt>
          <dd>
            {item.exempt
              ? "Not counted against limits"
              : "Counted against limits"}
          </dd>
        {/if}

        <dt>Access</dt>
        {#each Object.values(item.entry?.items || {}) as item}
          <dd>
            <EntryItem {item} />
          </dd>
        {:else}
          <dd>No linked entry access</dd>
        {/each}
      </dl>
      <FeesItem {item} />
      <ItemImages {item} create={true} />
    </ItemExpandContent>
  </article>
{/if}
