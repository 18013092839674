<script context="module" lang="ts">
  import { time } from "$utils/timestores";
  import { currency } from "$utils/format";
  import MonthsChart from "./svg/MonthsChart.svelte";

  const nowutc = time({ minutes: 30 });
</script>

<script lang="ts">
  export let item;
  //$: console.log("item=", item);
</script>

<figure class="chart">
  <MonthsChart
    interval={item.interval}
    timezone={item.timezone}
    intervals={item?.values}
    class="{item.type} {item.metric}"
    now={$nowutc.toISOString()}
    format={(val) => currency(val / 100)}
  />
</figure>
