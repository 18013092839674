<script context="module">
  import { time } from "$utils/timestores";
  import {
    permits,
    enforcement,
    policies,
    conflicts,
  } from "$utils/recordstores";

  const now = time({ seconds: 60 });
</script>

<script>
  import Record from "$components/record/RecordItem.svelte";
  import { sortByCreatedDate } from "$components/components";
  import { comparer, dateAsc, dateDesc } from "$utils/sort";
  import { addYears, parseISO } from "date-fns";
  import identity from "lodash-es/identity";
  import ItemsList from "$components/item/ItemsList.svelte";
  import { onDestroy, onMount } from "svelte";
  import CountSummaryItem from "$components/CountSummaryItem.svelte";
  import Time from "$components/Time.svelte";
  import DataColor from "$components/util/DataColor.svelte";
  import RecordDetailsNotesFiles from "$components/RecordDetailsNotesFiles.svelte";
  import EnforcementSummaryMapItem from "$components/enforcement/EnforcementSummaryMapItem.svelte";
  import EnforcementDetailsItem from "$components/enforcement/EnforcementDetailsItem.svelte";
  import RecordPermitPolicies from "$components/permit/RecordPermitPolicies.svelte";
  import Warning from "$components/Warning.svelte";
  import RecordDoNotPermit from "$components/permittable/RecordDoNotPermit.svelte";
  import PermitConflictsDetailstem from "$components/PermitConflictsDetailstem.svelte";
  import TimelineX from "$components/TimelineX.svelte";
  import CopyOpenUrl from "$components/copy/CopyOpenUrl.svelte";
  import CopyShareUrl from "$components/copy/CopyShareUrl.svelte";

  // this data we get externally
  export let id = null;
  export let property = null;
  export let item = null;
  export let data = null; // this is the fast/frequent loaded now data
  export let minimal = false;

  let attachments = {};
  $: if (id && !data) attachments = {}; // clear on change

  $: if (data?.attachments?.["for"][item?.id])
    attachments = data?.attachments?.["for"][item?.id];

  $: donotpermit = [data?.permittables.item].find(
    (item) => item && item.permittable === false
  ); // loads latest permittable item

  $: timezone = property && property.timezone;

  $: nowutc = $now;

  $: parking = "parking" === item?.amenity;

  // $: console.log("media=", item);
  // $: console.log("details=", data);

  $: activeRecentUpcomingPermits = data?.permits; // loads active + future
  $: permitHistory = $permits; // loads the last year

  //$: console.log("policies=", $policies);

  //$: console.log("donotpermit=", donotpermit);

  $: active = Object.values(activeRecentUpcomingPermits?.items || {})
    .filter(
      (p) =>
        !p.cancelled &&
        !p.expired &&
        Math.max(nowutc, Date.now()) > parseISO(p.valid.min.datetime) &&
        !(p.valid.max && parseISO(p.valid.max.datetime) < nowutc)
    )
    .sort((a, b) =>
      dateAsc(
        a.valid.max?.datetime || addYears(new Date(), 100),
        b.valid.max?.datetime || addYears(new Date(), 100)
      )
    );

  $: upcoming = Object.values(activeRecentUpcomingPermits?.items || {})
    .filter(
      (p) =>
        !p.cancelled &&
        !p.expired &&
        Math.max(nowutc, Date.now()) < parseISO(p.valid.min.datetime)
    )
    .sort(comparer("valid.min.datetime", dateAsc));

  // aggregate fast and long past permits
  $: past = Object.values(
    Object.assign(
      {},
      permitHistory?.items || {},
      activeRecentUpcomingPermits?.items || {}
    )
  )
    .filter(
      (p) =>
        p.cancelled ||
        p.expired ||
        (p.valid.max &&
          Math.max(nowutc, Date.now()) > parseISO(p.valid.max.datetime))
    )
    .sort(comparer("valid.max.datetime", dateDesc));

  onMount(function () {
    window.scrollTo(0, 0);
  });

  onDestroy(function () {});
</script>

<section class="media detail record" data-record="media">
  <header>
    <h1>
      {#if item}
        <Record
          url={false}
          item={[item.subject, item].find((item) => item && item.id)}
        />
        <slot />
      {:else}
        Loading&hellip;
      {/if}
    </h1>
    {#if item}
      <!-- <nav>
            <ul>
              {#if property?.media.permits}
              <li><a href="permits/media/new">Assign {property.media.title}</a></li>
              {/if}
            </ul>
          </nav> -->

      <!-- <time data-valid="PT5M"></time>	 -->
      {#if item.removed}
        <Warning message={item.removed.reason || "Removed"} />
      {/if}

      <dl>
        {#if item.description}
          <dt>Type</dt>
          <dd>{item.description}</dd>
        {/if}
        {#if item.label}
          <dt>Label</dt>
          <dd>{item.label}</dd>
        {/if}
        {#if item.color}
          <dt>Color</dt>
          <dd>
            <DataColor value={item.color} />
          </dd>
        {/if}
        <dt>Synced</dt>
        <dd>
          <Time datetime={data.generated} />
          <!-- <time datetime={data.generated}
            >{format(parseISO(data.generated), "MMM d yyyy h:mm a zzz")}</time
          > -->
        </dd>
      </dl>
      <CopyShareUrl
        label="Public View"
        url={item.url ?? `https://smartpermit.app/${item.type}/${item.id}`}
      />
    {/if}
  </header>
  {#if item}
    <section class="permits">
      <header>
        <h1>Permit Activity</h1>
        <nav>
          <ul>
            <li>
              <RecordPermitPolicies
                record={item}
                {property}
                policies={$policies?.filter(
                  (policy) => !!policy?.audience?.admin
                )}
              />
            </li>
          </ul>
        </nav>

        <!-- {#each $policies.filter((p) => p.amenity !== "parking" && p.audience.admin && p.tenant.request) as policy}
              <data value={policy.policy}>{policy.title}</data>
            {/each} -->
      </header>
      <nav />
      <ItemsList
        class="items"
        items={[
          active?.length === 0 && {
            type: "permits",
            title: "Current Status",
            state: "active",
            count: active.length,
            zero: "No Active Permits",
            datetime: activeRecentUpcomingPermits?.generated,
            timezone: activeRecentUpcomingPermits?.timezone,
          },
          ...active,
        ].filter(identity)}
        types={{
          permits: CountSummaryItem,
          //"permit":PermitSummaryItem
        }}
        highlight={{
          permit: true,
        }}
      />
      <ItemsList
        class="info"
        items={upcoming}
        types={{
          //"permit":PermitSummaryItem
        }}
      >
        <!-- <figure class="empty parking permits">No upcoming parking</figure> -->
      </ItemsList>
      <aside class="timeline">
        <TimelineX
          interval={item?.valid.interval}
          timezone={property?.timezone}
          permits={Object.assign(
            {},
            permitHistory?.items,
            activeRecentUpcomingPermits?.items
          )}
          enforcement={$enforcement}
          conflicts={$conflicts}
        />
      </aside>
      <ItemsList
        class="info"
        items={[
          permitHistory && {
            type: "permits",
            title: "History",
            state: "history",
            count: permitHistory && past.length,
            //zero: "No Permits",
            interval: permitHistory?.valid,
            timezone: timezone,
          },
          ...past,
        ]}
        types={{
          permits: CountSummaryItem,
        }}
        full={{
          permits: true,
        }}
      />
    </section>
    <section class="notes">
      <header>
        <h1>Notes & Info</h1>
      </header>
      <RecordDetailsNotesFiles {item} {attachments} />
    </section>
    <section class="enforcement">
      <header><h1>Enforcement</h1></header>
      <ItemsList
        class="items"
        items={$enforcement && [$enforcement].filter(identity)}
        types={{
          enforcement: EnforcementDetailsItem,
        }}
        full={{
          enforcement: true,
        }}
        context={{
          record: id,
        }}
      />
      {#if !minimal}
        <ItemsList
          class="items"
          items={$enforcement && [$enforcement].filter(identity)}
          types={{
            enforcement: EnforcementSummaryMapItem,
          }}
          full={{
            enforcement: true,
          }}
          context={{
            record: id,
          }}
        />
      {/if}
      <ItemsList
        class="info"
        items={Object.values($enforcement?.items || {}).sort(
          comparer(sortByCreatedDate, dateDesc)
        )}
        full={{
          detection: true,
        }}
        context={{
          //record: item.id,
        }}
      />
    </section>
    <section class="insights">
      <header>
        <h1>Smart Guard</h1>

        {#if !donotpermit}
          <nav>
            <ul>
              <li><RecordDoNotPermit record={item} /></li>
            </ul>
          </nav>
        {/if}
      </header>
      <ItemsList
        class="info"
        items={[...[donotpermit].filter(identity)]}
        context={{
          record: item.id,
        }}
        types={{}}
        highlight={{
          permittable: (item) => item.permittable === false,
        }}
      />

      <ItemsList
        class="info"
        items={[].concat(
          $conflicts || [],
          Object.values($conflicts?.items || {}).sort(
            comparer(sortByCreatedDate, dateDesc)
          )
        )}
        types={{
          conflicts: PermitConflictsDetailstem,
        }}
        full={{
          conflicts: true,
        }}
      />
    </section>
  {/if}
</section>
