<script context="module" lang="ts">
  import {
    eachDayOfInterval,
    addDays,
    parseISO,
    isWithinInterval,
    endOfDay,
  } from "date-fns";

  import { toZonedTime, format } from "date-fns-tz";
  import { state as editing } from "$utils/item";
  import {
    resolve,
    sortByCreatedDate,
    highlight as highlighter,
  } from "../components";
  import Loading from "../Loading.svelte";
  import ItemDisplay from "./ItemDisplay.svelte";
  function reduceToInterval(interval, date, i) {
    if (i === 0 && date) interval.start = date;
    else if (i === 1 && date) interval.end = date;
    return interval;
  }

  export function stringToDateInterval(input, timezone) {
    if (!input) return input;
    return input
      .split("/")
      .map(
        (str) => str && (!timezone ? parseISO(str) : toZonedTime(str, timezone))
      )
      .reduce(reduceToInterval, {
        start: timezone ? toZonedTime(new Date(), timezone) : new Date(),
        end: timezone ? toZonedTime(new Date(), timezone) : new Date(),
      });
  }

  export async function filterItemsAsync(
    items,
    sortByDate,
    interval,
    timezone
  ) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          items.filter((item) =>
            isWithinInterval(toZonedTime(sortByDate(item), timezone), interval)
          )
        );
      }, 0);
    });
  }
  export function filterItems(items, sortByDate, interval, timezone) {
    //console.log("filterItems=",...arguments);
    return items.filter((item) =>
      isWithinInterval(toZonedTime(sortByDate(item), timezone), interval)
    );
  }
</script>

<script lang="ts">
  import ItemEditor from "./ItemEditor.svelte";
  import { createEventDispatcher } from "svelte";

  export let items = null;
  export let title = null;

  export let context = {};

  export let types = {};
  export let full = {};
  export let highlight = {};

  export let loading: boolean | string = true;

  let className = "";
  export { className as class };
  export let highlighting = true;

  const events = createEventDispatcher<{
    removed: unknown;
    remove: unknown;
  }>();

  //$: console.log("itemslist=", items);
</script>

{#if !items}
  {#if loading}
    <slot name="loading">
      <Loading message={typeof loading == "string" ? loading : "Loading"} />
    </slot>
  {/if}
{:else if !Object.values(items).length}
  <slot name="empty" />
  <slot />
  <slot name="help" />
{:else}
  {#if title}
    <h1>{title}</h1>
  {/if}
  {#if $editing}
    <ol class={className}>
      {#each Object.values(items) as item}
        <!-- {#if types?.[item?.type] || resolve(item)} -->
        {#if item}
          <li
            class={item.type}
            class:full={!!full?.[item.type] ||
              ($editing?.id && $editing?.id === item?.id)}
            class:highlight={highlighting && highlighter(item, highlight)}
          >
            {#if $editing?.id && $editing?.id === item?.id}
              <ol class="item">
                <li>
                  <ItemDisplay
                    on:remove={(e) => events("remove", item)}
                    on:removed={(e) => events("removed", item)}
                    {types}
                    {item}
                    {context}
                  />
                </li>
                <li>
                  <ItemEditor
                    item={item ?? $editing?.source}
                    state={$editing}
                  />
                </li>
              </ol>
            {:else}
              <ItemDisplay
                on:remove={(e) => events("remove", item)}
                on:removed={(e) => events("removed", item)}
                {types}
                {item}
                {context}
              />
            {/if}
          </li>
        {/if}
      {/each}
    </ol>
  {/if}
{/if}
