<script lang="ts">
  import PropertySummaryItem from "./PropertySummaryItem.svelte";

  export let properties: Properties;

  let className: string = "";
  export { className as class }

  $: console.log("properties=", properties);
</script>

<ul
  class={["properties", className].filter(Boolean).join(" ")}
  data-count={properties?.count ?? ""}
>
  {#each Object.values(properties?.items ?? {}) as item}
    {#if !!item && item.id}
      <li>
        <a href="/{item.type}/{item.id}">
          <PropertySummaryItem {item} />
        </a>
      </li>
    {/if}
  {/each}
</ul>
