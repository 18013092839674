import { authHeader, base, responseJson, viewpoint } from "$utils/api";

export async function fetchRemoveContact(id: string) {
  const response = await fetch(
    `${base()}/contacts/${id}?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
    }
  );
  return await responseJson(response);
}
