<script lang="ts" context="module">
  async function fetchPropertiesAuthorizations(
    properties: Properties
  ): Promise<Authorizations> {
    const url = new URL(
      `${base()}/authorizations?viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        new Date(Date.now() + 5 * 60 * 1000).toISOString() + "/"
      )}&client=${client}&${authHeader}`
    );

    for (const k of Object.keys(properties.items))
      url.searchParams.append("scope", k);

    return fetch(url)
      .then(responseJson)
      .then((json) => {
        for (const [k, v] of Object.entries(json.authorizations.items || {})) {
          const item = (json.authorizations.items[k] =
            json.items[v as string] || json.items[k as string] || v);

          for (const k2 of ["principal"]) {
            if (item[k2]) item[k2] = json.items[item[k2]] || item[k2];
          }
          item.valid.min.by =
            json.items[item.valid.min.by] || item.valid.min.by;
          if (item.valid.max)
            item.valid.max.by =
              json.items[item.valid.max.by] || item.valid.max.by;
        }

        console.log("authorizations=", json.authorizations);

        return json.authorizations;
      });
  }
</script>

<script lang="ts">
  import PropertyDataItem from "$components/property/PropertyDataItem.svelte";
  import { authHeader, base, responseJson, viewpoint } from "$utils/api";
  import { client } from "$utils/auth";
  import { comparer } from "$utils/sort";
  import groupBy from "lodash-es/groupBy";

  export let properties: Properties;

  $: sorted = Object.values(properties?.items ?? []).sort(comparer("name"));

  $: authorizations = properties && fetchPropertiesAuthorizations(properties);

  const roles = {
    admin: "Full Access",
    patrol: "Enforcement",
    user: "View Only",
  };
</script>

<section>
  <!-- <header>
    <h1>Teams</h1>
  </header> -->
  <figure>
    <figcaption />
    {#if authorizations}
      {#await authorizations then authorizations}
        <table>
          <thead>
            <tr>
              <th scope="col row">&nbsp;</th>
              {#each sorted as property}
                <th scope="col">
                  <PropertyDataItem {property} />
                </th>
              {/each}
            </tr>
          </thead>
          <tbody>
            {#each Object.entries(groupBy(authorizations.items, "principal.id"))
              .map( ([userid, auths]) => [auths[0].principal, groupBy(auths, "scope")] )
              .sort(comparer(([principal, byProperty]) => principal.name)) as [principal, byProperty]}
              <tr>
                <th scope="row">
                  <data class="user" value={principal.id}
                    ><dfn>{principal.name}</dfn>
                    <address>
                      <a href="mailto:{principal.email}">{principal.email}</a>
                    </address>
                  </data>
                </th>

                {#each sorted as property}
                  {@const userPropertyRole = Object.entries(
                    byProperty[property.id]?.[0]?.roles ?? {}
                  ).reduce((result, kv) => {
                    if (!result && !!kv[1]) return kv[0];
                    return result;
                  }, "")}
                  <td
                    ><form
                      class:add={!userPropertyRole}
                      class:remove={!!userPropertyRole}
                    >
                      <input
                        type="hidden"
                        name="current"
                        value={userPropertyRole}
                      />

                      <data class="role" value={userPropertyRole}
                        >{roles[userPropertyRole] ?? "No Access"}</data
                      >
                      <select name="role" value={userPropertyRole}>
                        <option value="admin">Full Access</option>
                        <option value="patrol">Enforcement</option>
                        <option value="">No Access</option>
                      </select>
                    </form></td
                  >
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      {/await}
    {/if}
  </figure>
</section>
