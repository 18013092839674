<script context="module">
  const calendar = {
    P1D: "day",
    P1W: "week",
    P1M: "month",
    P1Y: "year",
  };
</script>

<script>
  import EnforcementChecksTargetSetItem from "./EnforcementChecksTargetSetItem.svelte";

  export let item;
</script>

<dt>Checks</dt>
<dd>
  {#if item.enforcement.target}
    Target {item.enforcement.target.minimum} per {calendar[
      item.enforcement.target.per
    ] || item.enforcement.target.per}
  {:else}
    No target set
  {/if}
  <EnforcementChecksTargetSetItem {item} />
</dd>
