<script lang="ts">
  import Image from "$components/util/Image.svelte";
  import { thumbnail } from "$utils/filepreview";

  export let property: Property;
</script>

<figure class="photo {property.type}">
  <Image
    class="photo"
    alt="{property.name} photo"
    src={thumbnail(property.photo)}
  />
  <figcaption>
    <slot />
  </figcaption>
</figure>
