<script context="module">
  const formats = {
    time: "h:mm a zzz",
  };
</script>

<script>
  import { format, toZonedTime } from "date-fns-tz";
  export let item;
</script>

<time
  class={item.type}
  datetime={item.datetime || item.date || item.interval || ""}
>
  <abbr
    >{format(
      toZonedTime(item.datetime || item.date, item.timezone),
      item.format || formats[item.type] || "h:mm a zzz",
      {
        timeZone: item.timezone,
      }
    )}</abbr
  >
</time>
