<script lang="ts" context="module">
  let i = 0;
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Input from "./Input.svelte";

  export let type = "text";
  export let name: string | null | undefined = null;
  export let title: string | null | undefined = null;
  export let label: string | null | undefined = null;
  export let placeholder: string | null | undefined = null;
  export let required = false;
  export let value: string = "";
  export let minlength: string | null | undefined = null;
  export let maxlength: string | null | undefined = null;
  export let autocomplete: string | null | undefined = null;
  export let autocorrect: string | null | undefined = null;
  export let spellcheck: boolean | "true" | "false" | null | undefined = null;
  export let autocapitalize: string | null | undefined = null;
  export let multiple = false;

  const id = `${name}-${i++}`;

  if (label && !placeholder) placeholder = required ? "required" : "optional";

  const events = createEventDispatcher<{
    change: {
      name: string;
      value: string;
    };
  }>();

  function change(name: string, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    if (name)
      events("change", {
        name,
        value,
      });
  }
</script>

<fieldset>
  {#if label}
    <label for={id}>{label}</label>
  {/if}
  <Input
    {type}
    {id}
    {name}
    {title}
    {required}
    {placeholder}
    {value}
    {minlength}
    {maxlength}
    {autocomplete}
    {spellcheck}
    {autocorrect}
    {autocapitalize}
    {multiple}
    on:change={({ detail }) => change(detail.name, detail.value)}
  />
</fieldset>
