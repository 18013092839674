<script lang="ts">
  import Loading from "$components/Loading.svelte";

  export let permits: Permits;
  export let spaces: Spaces;

  interface Allocation {
    space: Space;
    vehicles: Vehicle[];
  }

  let allocations: [string, Allocation][] = [];
  $: if (permits?.items && spaces?.items) {
    let allocation = new Map<string, Allocation>();
    for (const [id, space] of Object.entries(spaces?.items ?? {})) {
      allocation.set(id, { vehicles: [], space });
    }

    for (const [_, val] of Object.entries(permits?.items ?? {})) {
      const id = val?.space?.id;
      if (!id) continue;
      if (allocation.has(id)) {
        const item = allocation.get(val.space.id);
        allocation.set(id, {
          ...item,
          vehicles: [...(item?.vehicles ?? []), val?.vehicle].filter(Boolean),
        });
      } else {
        allocation.set(id, { vehicles: [val?.vehicle], space: val.space });
      }
    }

    allocations =
      [...allocation].sort(([key_a, a], [key_b, b]) =>
        a?.space.capacity > b?.space.capacity ? -1 : 1
      ) ?? [];
  }
  $: console.log("permits:", permits);
  $: console.log("spaces:", spaces);
  $: console.log("allocations:", allocations);
</script>

<section class="properties">
  {#if allocations?.length}
    <table>
      <thead>
        <tr>
          <th>Space</th>
          <th>Capacity</th>
          <th># Vehicles Assigned</th>
        </tr>
      </thead>
      <tbody>
        {#each allocations as [key, val] (key)}
          <tr>
            <td>
              {val.space.name}
            </td>
            <td>
              {val.space.capacity}
            </td>
            <td>
              {val.vehicles.length}
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  {:else}
    <Loading message="Loading space allocations..." />
  {/if}
</section>
