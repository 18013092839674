<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import TextareaField from "./TextareaField.svelte";

  export let name = "notes";
  export let label: string | null | undefined = null;
  export let placeholder: string | null | undefined = null;
  export let required = false;
  export let value: string = "";
  export let autocomplete: string = "off";

  const events = createEventDispatcher();
</script>

<TextareaField
  {name}
  {label}
  {placeholder}
  {required}
  {autocomplete}
  bind:value
  on:change={(e) => events("change", e.detail)}
/>
